import User from './User';

const schema = {
    firstName: {
        defaultValue: '',
    },
    lastName: {
        defaultValue: '',
    },
    collegiateNumber: {
        defaultValue: '',
    },
};

class Technician extends User{

    constructor(obj){
        super(schema, obj);
        this.role ='technician';
    }

}

export default Technician;