import React, { Component } from "react";
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import history from '../../history';
import DefaultAvatar from '../../assets/img/avatarImg.png'
import { isCorrectRole } from "../../components/PrivateRoute";
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";

import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import {
  setContainerClassnames,
  clickOnMobileMenu,
  logoutUser,
  changeLocale,
  getLoggedUser,
} from "../../redux/actions";

import { menuHiddenBreakpoint, searchPath, localeOptions } from "../../constants/defaultValues";
import IntlMessages from "../../util/IntlMessages";
import './TopNav.css';
class TopNav extends Component {
  constructor(props) {
    super(props);
    this.menuButtonClick = this.menuButtonClick.bind(this);
    this.mobileMenuButtonClick = this.mobileMenuButtonClick.bind(this);
    this.search = this.search.bind(this);
    this.handleChangeLocale = this.handleChangeLocale.bind(this);
    this.handleDocumentClickSearch = this.handleDocumentClickSearch.bind(this);
    this.addEventsSearch = this.addEventsSearch.bind(this);
    this.removeEventsSearch = this.removeEventsSearch.bind(this);

    this.state = {
      isInFullScreen: false,
      searchKeyword: ""
    };
  }

  componentDidMount() {
    this.props.getLoggedUser();
  }

  isInFullScreen = () => {
    return (
      (document.fullscreenElement && document.fullscreenElement !== null) ||
      (document.webkitFullscreenElement &&
        document.webkitFullscreenElement !== null) ||
      (document.mozFullScreenElement &&
        document.mozFullScreenElement !== null) ||
      (document.msFullscreenElement && document.msFullscreenElement !== null)
    );
  };

  handleChangeLocale = locale => {
    this.props.changeLocale(locale);
  };

  handleSearchIconClick = e => {
    if (window.innerWidth < menuHiddenBreakpoint) {
      let elem = e.target;
      if (!e.target.classList.contains("search")) {
        if (e.target.parentElement.classList.contains("search")) {
          elem = e.target.parentElement;
        } else if (
          e.target.parentElement.parentElement.classList.contains("search")
        ) {
          elem = e.target.parentElement.parentElement;
        }
      }

      if (elem.classList.contains("mobile-view")) {
        this.search();
        elem.classList.remove("mobile-view");
        this.removeEventsSearch();
      } else {
        elem.classList.add("mobile-view");
        this.addEventsSearch();
      }
    } else {
      this.search();
    }
  };
  addEventsSearch() {
    document.addEventListener("click", this.handleDocumentClickSearch, true);
  }
  removeEventsSearch() {
    document.removeEventListener("click", this.handleDocumentClickSearch, true);
  }

  handleDocumentClickSearch(e) {
    let isSearchClick = false;
    if (
      e.target &&
      e.target.classList &&
      (e.target.classList.contains("navbar") ||
        e.target.classList.contains("simple-icon-magnifier"))
    ) {
      isSearchClick = true;
      if (e.target.classList.contains("simple-icon-magnifier")) {
        this.search();
      }
    } else if (
      e.target.parentElement &&
      e.target.parentElement.classList &&
      e.target.parentElement.classList.contains("search")
    ) {
      isSearchClick = true;
    }

    if (!isSearchClick) {
      const input = document.querySelector(".mobile-view");
      if (input && input.classList) input.classList.remove("mobile-view");
      this.removeEventsSearch();
      this.setState({
        searchKeyword: ""
      });
    }
  }

  handleSearchInputChange(e) {
    this.setState({
      searchKeyword: e.target.value
    });
  }
  handleSearchInputKeyPress(e) {
    if (e.key === 'Enter') {
      this.search()
    }
  }

  search() {
    this.props.history.push(searchPath + "/" + this.state.searchKeyword)
    this.setState({
      searchKeyword: ""
    });
  }
  toggleFullScreen = () => {
    const isInFullScreen = this.isInFullScreen();

    var docElm = document.documentElement;
    if (!isInFullScreen) {
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
    this.setState({
      isInFullScreen: !isInFullScreen
    });
  };

  handleLogout = () => {
    this.props.logoutUser({ history: this.props.history });
  };

  handleViewProfile = () => {
    history.push(`/app/profile`);
  }

  menuButtonClick(e, menuClickCount, containerClassnames) {
    e.preventDefault();

    setTimeout(() => {
      var event = document.createEvent("HTMLEvents");
      event.initEvent("resize", false, false);
      window.dispatchEvent(event);
    }, 350);
    this.props.setContainerClassnames(++menuClickCount, containerClassnames);
  }
  mobileMenuButtonClick(e, containerClassnames) {
    e.preventDefault();
    this.props.clickOnMobileMenu(containerClassnames);
  }

  getAvatar = (logUser) => (logUser && logUser.avatar && logUser.avatar.includes('data')) ? logUser.avatar : DefaultAvatar;
  getNameUser = (logUser) => (logUser && logUser.firstName) ? logUser.firstName.concat(' ', logUser.lastName) : 'Usuario';

  render() {
    const { containerClassnames, menuClickCount } = this.props;
    const { messages } = this.props.intl;
    const { logUser } = this.props;

    return (
      <nav className="navbar fixed-top">
        {
          isCorrectRole(['admin', 'technician', 'doctor' , 'clinic']) &&
          <NavLink
            to="#"
            className="menu-button d-none d-md-block"
            onClick={e =>
              this.menuButtonClick(e, menuClickCount, containerClassnames)
            }
          >
            <svg
              className="main"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 9 17"
            >
              <rect x="0.48" y="0.5" width="7" height="1" />
              <rect x="0.48" y="7.5" width="7" height="1" />
              <rect x="0.48" y="15.5" width="7" height="1" />
            </svg>
            <svg
              className="sub"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 18 17"
            >
              <rect x="1.56" y="0.5" width="16" height="1" />
              <rect x="1.56" y="7.5" width="16" height="1" />
              <rect x="1.56" y="15.5" width="16" height="1" />
            </svg>
          </NavLink>
        }
        <NavLink
          to="#"
          className="menu-button-mobile d-xs-block d-sm-block d-md-none"
          onClick={e => this.mobileMenuButtonClick(e, containerClassnames)}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
            <rect x="0.5" y="0.5" width="25" height="1" />
            <rect x="0.5" y="7.5" width="25" height="1" />
            <rect x="0.5" y="15.5" width="25" height="1" />
          </svg>
        </NavLink>

        <div className="d-inline-block">
          <UncontrolledDropdown className="ml-2">
            <DropdownToggle
              caret
              color="light"
              size="sm"
              className="language-button"
            >
              <span className="name">{this.props.locale.toUpperCase()}</span>
            </DropdownToggle>
            <DropdownMenu className="mt-3" right>
              {
                localeOptions.map((l) => {
                  return (
                    <DropdownItem onClick={() => this.handleChangeLocale(l.id)} key={l.id}>
                      {l.name}
                    </DropdownItem>
                  )
                })
              }
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>


        <Link className="navbar-logo" to="/">
          <span className="logo d-none d-xs-block" />
          <span className="logo-mobile d-block d-xs-none" />
        </Link>
        {this.props.uploadFileLoading !== 0
            ? <div className="main-container">
                <span className="span-upload"><IntlMessages id="notify.upload.state" /></span>
                <i className="iconsmind-Arrow-Up2"></i>
              </div>
            : null
        }
        <div className="ml-auto">
          <div className="header-icons d-inline-block align-middle">
            <button
              className="header-icon btn btn-empty d-none d-sm-inline-block"
              type="button"
              id="fullScreenButton"
              onClick={this.toggleFullScreen}
            >
              {this.state.isInFullScreen ? (
                <i className="simple-icon-size-actual d-block" />
              ) : (
                <i className="simple-icon-size-fullscreen d-block" />
              )}
            </button>
          </div>
          <div className="user d-inline-block">
            <UncontrolledDropdown className="dropdown-menu-right">
              <DropdownToggle className="p-0" color="empty">
                <span className="name mr-1">{this.getNameUser(logUser)}</span>
                <span>
                  <img alt="Profile" src={this.getAvatar(logUser)} />
                </span>
              </DropdownToggle>
              <DropdownMenu className="mt-3" right>
                {
                  isCorrectRole(['admin', 'technician', 'doctor', 'clinic']) && <DropdownItem onClick={this.handleViewProfile}>
                    <IntlMessages id="menu.viewProfile" />
                  </DropdownItem>}
                <DropdownItem divider />
                <DropdownItem onClick={this.handleLogout}>
                  <IntlMessages id="menu.logOut" />
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = ({ menu, settings, loggedUser, patients }) => {
  const { containerClassnames, menuClickCount } = menu;
  const { locale } = settings;
  const logUser = loggedUser.user;
  const { uploadFileLoading } = patients;

  return { containerClassnames, menuClickCount, locale, logUser,  uploadFileLoading };
};
export default injectIntl(connect(
  mapStateToProps,
  { setContainerClassnames, clickOnMobileMenu, logoutUser, changeLocale, getLoggedUser }
)(TopNav));
