import React from "react";

export default class VideoComponent extends React.Component{

    constructor(props){
        super(props);
        this.state={
            width: 200,
            height: 200
        }
    }

    componentDidMount(){
        this.setState({
            width :document.querySelector('.video-wrapper').clientWidth,
            height :document.querySelector('.fullScreenVideo').clientHeight - (16*4)
        })
    }

    render(){
        const { src } = this.props;
        const { width, height } = this.state;
        return(

            <video autoPlay preload controls width={width} style={{maxHeight: height}}>
                <source src={ src }/>
                Your browser does not support HTML5 video.
            </video>
        )
    }
}