import {
    GET_LEGAL_TEXT,
    GET_LEGAL_TEXT_SUCCESS,
    UPDATE_LEGAL_TEXT,
    UPDATE_LEGAL_TEXT_SUCCESS,
    CREATE_LEGAL_TEXT,
    CREATE_LEGAL_TEXT_SUCCESS
} from "../../constants/actionTypes";

const INIT_STATE = {
    legal: '',
    privacy: '',
    condition: '',
    loading: false,
};

export default (state = INIT_STATE, action) => {
    let index, aux;
    switch (action.type) {
        case GET_LEGAL_TEXT:
        case UPDATE_LEGAL_TEXT:
        case CREATE_LEGAL_TEXT:
            return { ...state, loading: true };

        case GET_LEGAL_TEXT_SUCCESS:
        case UPDATE_LEGAL_TEXT_SUCCESS:
        case CREATE_LEGAL_TEXT_SUCCESS:
            aux = state;
            if(action.payload.legal) aux.legal = action.payload.legal;
            if(action.payload.privacy) aux.privacy = action.payload.privacy;
            if(action.payload.condition) aux.condition = action.payload.condition;
            return { ...aux, loading: false };

        default:
            return { ...state };
    }
}