import React from 'react';
import './styles.scss'
import WithModal from './../Modal';
import Loading from "../Dropzone/components/Loading";
import IntlMessages from "../../util/IntlMessages";

class Body extends React.Component{
    state={
        loading: false
    };

    componentDidUpdate = (prevProps) => {
        if(this.props.onOkOption && prevProps.onOkOption !== this.props.onOkOption ) {
            this.props.onClick();
            this.setState({
                loading: true
            })
        }
    };

    render() {
        const {loading}  = this.state;
        return (
            loading ?
                <Loading className={"dropzone-loading-component loading"}/> :
                <IntlMessages
                    id="general.remove.confirm"
                />
        )
    }
}

const BodyModal = WithModal(Body);

export const RemoveButtonFlatIcon = ({onClick, className, toggle, ...props}) =>
    <button {...props} onClick={toggle} className={`remove-button ${className}`}>
        <i className="simple-icon-trash"/>
    </button>;


class RemoveButton extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            modal: false
        }
    }

    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    };


    render() {
        const {onClick, className, ...props} = this.props;
        return (
            <>
                <RemoveButtonFlatIcon {...props} toggle={this.toggle} className={className}/>
                <BodyModal
                    onClick={onClick}
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                    title={<IntlMessages id="general.remove.confirm.title" />}
                />
            </>
        )
    }
}

export default RemoveButton;