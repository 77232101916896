import {
    GET_USERS,
    GET_USERS_SUCCESS,
    GET_USERS_BY_ROLE,
    GET_USERS_BY_ROLE_SUCCESS,
    CREATE_USER,
    CREATE_USER_SUCCESS,
    GET_USER,
    GET_USER_SUCCESS,
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    DELETE_USER,
    DELETE_USER_SUCCESS,
    GET_USERS_PERCENTAGE,
    GET_USERS_PERCENTAGE_SUCCESS,
    GET_USERS_REQUEST,
    GET_USERS_REQUEST_SUCCESS,
    CREATE_USER_REQUEST,
    CREATE_USER_REQUEST_SUCCESS,
    DELETE_USER_REQUEST_SUCCESS,
    VALIDATE_USER_REQUEST,
    USER_REQUEST_FAILED
} from "../../constants/actionTypes";

const INIT_STATE = {
    data: [],
    loading: false,
    totalItemCount: 0,
    page: 0,
    limit: 8
};

export default (state = INIT_STATE, action) => {
    let index, aux;
    switch (action.type) {
        case GET_USERS_REQUEST:
        case CREATE_USER_REQUEST:
        case VALIDATE_USER_REQUEST:
            return { ...state, loading: true };
        case GET_USERS_REQUEST_SUCCESS:
            return { ...state, loading: false, data: action.payload.users, totalItemCount: action.payload.total }
        // case GET_USERS_BY_ROLE:
        //     return { ...state, loading: true };
        // case GET_USERS_BY_ROLE_SUCCESS:
        //     return { ...state, loading: false, data: action.payload.users, totalItemCount: action.payload.total }
        // case GET_USER:
        //     return { ...state, loading: true };
        // case GET_USER_SUCCESS:
        //     return { ...state, loading: false, data: action.payload }
        case CREATE_USER_REQUEST_SUCCESS:
        case USER_REQUEST_FAILED:
            return { ...state, loading: false };
        // case UPDATE_USER:
        //     return { ...state, loading: true }
        // case UPDATE_USER_SUCCESS:
        //     return { ...state, loading: false }
        // case DELETE_USER:
        //     return { ...state, loading: true }
        case DELETE_USER_REQUEST_SUCCESS:
            aux = state.data;
            index = state.data.indexOf(
                state.data.find(obj => obj._id === action.payload)
            );
            if (index > -1)
                aux.splice(index, 1);
            return { ...state, data: [...aux], loading: false };
        // case GET_USERS_PERCENTAGE:
        //     return {...state, loading: true}
        // case GET_USERS_PERCENTAGE_SUCCESS:
        //     return {...state, loading: false, data: action.payload}


        default:
            return { ...state }
    }
}