import React, { Component, Fragment } from "react";
import IntlMessages from "../../util/IntlMessages";
import {Row, Card, CardTitle, Form, Label, Input, Button} from "reactstrap";
import { NavLink } from "react-router-dom";

import { Colxx } from "../../components/CustomBootstrap";
import {
  forgotPassword,
} from "../../redux/actions";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";

const validateEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

class ForgotPasswordLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      errors:{}
    };
  }

  componentDidMount() {
    document.body.classList.add("background");
  }
  componentWillUnmount() {
    document.body.classList.remove("background");
  }

  handleClick = () => {
    const { email } = this.state;
    if (!email || !validateEmail(email)) {
      const errors = {
        email: "forms.validation.email"
      };
      this.setState({errors});
    } else {
      this.props.forgotPassword({email});
      this.setState({emailSent: true})
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]:e.target.value,
      errors:{...this.state.errors, [e.target.name]: undefined}
    })
  };

  render() {
    const { errors, emailSent } = this.state;

    return (
      <Fragment>
        <div className="fixed-background" />
        <main>
          <div className="container">
            <Row className="h-100">
              <Colxx xxs="12" md="10" className="mx-auto my-auto">
                <Card className="auth-card">
                  <div className="position-relative image-side ">
                    <p className="text-blue h2"><IntlMessages id="user.forgot-password.title" /></p>
                    <p className="text-blue">
                    <IntlMessages id="user.forgot-password.text" values={{separator: <br/>}} />{" "}
                      <NavLink to={`/register`} className="text-blue">
                        <u><IntlMessages id="user.forgot-password.register" /></u>
                      </NavLink>{" "}
                      <IntlMessages id="user.forgot-password.text2" />.
                    </p>
                  </div>
                  <div className="form-side">
                    <NavLink to={`/`} className="white">
                      <span className="logo-single" />
                    </NavLink>
                    <CardTitle className="mb-4">
                      <IntlMessages id="user.forgot-password" />
                    </CardTitle>
                    <Form>
                      <Label className="form-group has-float-label mb-4">
                        <Input
                          name="email"
                          id="f-email"
                          type="email"
                          defaultValue={this.state.email}
                          onChange={this.handleChange}
                        />
                        <IntlMessages id="user.email" />
                      </Label>

                      {errors.email && <div className="invalid-feedback d-block msg-error"><IntlMessages id={errors.email} /></div>}
                      {emailSent &&  <div className="d-block mb-3 msg-success"> <IntlMessages id={"form.email.sent"}/> </div>}
                      <div className="d-flex justify-content-end align-items-center">
                          <Button
                            color="primary"
                            className="btn-shadow"
                            size="lg"
                            onClick={this.handleClick}
                          >
                            <IntlMessages id="user.reset-password-button" />
                          </Button>
                      </div>
                    </Form>
                  </div>
                </Card>
              </Colxx>
            </Row>
          </div>
        </main>
      </Fragment>
    );
  }
}

export default injectIntl(connect(() => {}, {forgotPassword})(ForgotPasswordLayout))

