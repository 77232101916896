import React from 'react';
import api from "../../../api/registry";
import IntlMessages from "../../../util/IntlMessages";

class TestsPerformedChart extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: '0',
        }
    }

    componentDidMount() {
        new Promise(async (resolve) => {
            const incomingData = await api.getTestsPerformedTotal();
            if(incomingData.data)
                this.setState({
                    data: incomingData.data.total,
                });
            resolve();
        })
    }

    render() {
        return <div class="card-body text-center icons-cards-row d-flex align-items-center justify-content-center flex-column h-100">
            <div>
                <i class="simple-icon-picture"></i>
                <p class="card-text font-weight-semibold mb-0"><IntlMessages id="reports.testsPerformed" /></p>
                <p class="lead text-center">{this.state.data}</p>
            </div>

        </div>
    }
}

export default TestsPerformedChart;