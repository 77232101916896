import React, { Component, Fragment } from "react";

import PageHeader from '../../../components/PageHeader';

import {NavLink} from "react-router-dom";

import IntlMessages from "../../../util/IntlMessages";
import { Colxx } from "../../../components/CustomBootstrap";
import { Formik, Form, Field } from 'formik';
import {
    Row,
    Card,
    CardTitle,
    FormGroup,
    Label,
    CardBody,
    Button
} from "reactstrap";
import { injectIntl} from 'react-intl';
import "react-datepicker/dist/react-datepicker.css";
import { editDiagnosticCenter, getCenter } from "../../../redux/actions";
import { connect } from "react-redux";

class EditDiagnosticCenter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            street: "",
            number: "",
            location: "",
            zipCode: "",
            city: "",
            province: "",
            country: "",
            originalCenter: null,
        };
    }

    componentDidMount(){
        const center = (this.props.diagnosticCenters &&  Array.isArray(this.props.diagnosticCenters)) && this.props.diagnosticCenters.find(center => center._id === this.props.match.params.id);
        if(!center)
            this.props.getCenter(this.props.match.params.id);
        else
            this.setState({
                ...center
            })
    }

    componentDidUpdate(prevProps, prevState, prevContext){
        if(this.props.center !== this.state.originalCenter && prevProps.center !== this.props.center)
            this.setState({
                ...this.props.center,
                originalCenter: this.props.center
            })
    }

    handleSubmit = () => {
        this.props.editDiagnosticCenter({...this.state, _id:this.props.match.params.id, originalCenter: undefined});
    };

    validate = (values) => {
        values = this.state;
        const errors={};

        if (!values.name) {
            errors.name = 'Please enter center name';
        } else if (values.name.length < 3) {
            errors.name = 'Value must be longer than 2 characters';
        }

        if (!values.street) {
            errors.street = 'Please enter street address';
        }

        if (!values.number) {
            errors.number = 'Please enter street number';
        }

        if (!values.location) {
            errors.location = 'Please enter center floor/location';
        }

        if (!values.zipCode) {
            errors.zipCode = 'Please enter zipcode';
        }

        if (!values.city) {
            errors.city = 'Please enter city';
        }

        if (!values.province) {
            errors.province = 'Please enter province';
        }

        if (!values.country) {
            errors.country = 'Please enter country';
        }

        return errors;
    };

    onChange = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const name = e.target.name;
        const value = e.target.value;

        if(value !== this.state[name]){
            this.setState({
                [name]: value
            })
        }
    };

    render() {
        const {messages} = this.props.intl;
        const {
            name,
            street,
            number,
            location,
            zipCode,
            city,
            province,
            country
        } = this.state;
        return(
            <Fragment>
                <PageHeader
                    match={this.props.match}
                    heading={<IntlMessages id="diagnosticcenters.update" />}
                    actions={
                        <NavLink to={"/app/diagnosticcenters"}>
                            <Button
                                outline
                                color="primary"
                                size="lg"
                            >
                                <IntlMessages id="general.back" />
                            </Button>
                        </NavLink>
                    }
                />
                <Row className="mb-4">
                    <Colxx xxs="12">
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <IntlMessages id="forms.patient" />
                                </CardTitle>
                                <Formik
                                    validate={this.validate}
                                    initialValues={this.state}
                                    onSubmit={this.handleSubmit}>
                                    {({ errors, touched, isValidating }) => (
                                        <Form className="av-tooltip tooltip-label-right">

                                            <FormGroup row>
                                                <Colxx md={12} sm={12}>
                                                    <FormGroup>
                                                        <Label for="name">
                                                            <IntlMessages id="forms.name" />
                                                        </Label>

                                                        <Field className="form-control"
                                                               type="text"
                                                               name="name"
                                                               id="name"
                                                               placeholder={messages["forms.name"]}
                                                               value={name}
                                                               onChange={this.onChange}
                                                        />
                                                        {errors.name && touched.name && <div className="invalid-feedback d-block">{errors.name}</div>}
                                                    </FormGroup>
                                                </Colxx>

                                                <Colxx md={4} sm={6} xs={12}>
                                                    <FormGroup>
                                                        <Label for="street">
                                                            <IntlMessages id="forms.street" />
                                                        </Label>
                                                        <Field className="form-control"
                                                               type="text"
                                                               name="street"
                                                               id="street"
                                                               placeholder={messages["forms.street"]}
                                                               value={street}
                                                               onChange={this.onChange}

                                                        />
                                                        {errors.street && touched.street && <div className="invalid-feedback d-block">{errors.street}</div>}
                                                    </FormGroup>
                                                </Colxx>

                                                <Colxx md={4} sm={6} xs={12}>
                                                    <FormGroup>
                                                        <Label for="number">
                                                            <IntlMessages id="forms.number" />
                                                        </Label>
                                                        <Field className="form-control"
                                                               type="text"
                                                               name="number"
                                                               id="number"
                                                               placeholder={messages["forms.number"]}
                                                               value={number}
                                                               onChange={this.onChange}

                                                        />
                                                        {errors.number && touched.number && <div className="invalid-feedback d-block">{errors.number}</div>}
                                                    </FormGroup>
                                                </Colxx>

                                                <Colxx md={4} sm={6} xs={12}>
                                                    <FormGroup>
                                                        <Label for="location">
                                                            <IntlMessages id="forms.location" />
                                                        </Label>
                                                        <Field className="form-control"
                                                               type="text"
                                                               name="location"
                                                               id="location"
                                                               placeholder={messages["forms.location"]}
                                                               value={location}
                                                               onChange={this.onChange}

                                                        />
                                                        {errors.location && touched.location && <div className="invalid-feedback d-block">{errors.location}</div>}
                                                    </FormGroup>
                                                </Colxx>

                                                <Colxx md={4} sm={6} xs={12}>
                                                    <FormGroup>
                                                        <Label for="zipCode">
                                                            <IntlMessages id="forms.zipCode" />
                                                        </Label>
                                                        <Field className="form-control"
                                                               type="text"
                                                               name="zipCode"
                                                               id="zipCode"
                                                               placeholder={messages["forms.zipCode"]}
                                                               value={zipCode}
                                                               onChange={this.onChange}

                                                        />
                                                        {errors.zipCode && touched.zipCode && <div className="invalid-feedback d-block">{errors.zipCode}</div>}
                                                    </FormGroup>
                                                </Colxx>

                                                <Colxx md={4} sm={6} xs={12}>
                                                    <FormGroup>
                                                        <Label for="city">
                                                            <IntlMessages id="forms.city" />
                                                        </Label>
                                                        <Field className="form-control"
                                                               type="text"
                                                               name="city"
                                                               id="city"
                                                               placeholder={messages["forms.city"]}
                                                               value={city}
                                                               onChange={this.onChange}

                                                        />
                                                        {errors.city && touched.city && <div className="invalid-feedback d-block">{errors.city}</div>}
                                                    </FormGroup>
                                                </Colxx>

                                                <Colxx md={4} sm={6} xs={12}>
                                                    <FormGroup>
                                                        <Label for="province">
                                                            <IntlMessages id="forms.province" />
                                                        </Label>
                                                        <Field className="form-control"
                                                               type="text"
                                                               name="province"
                                                               id="province"
                                                               placeholder={messages["forms.province"]}
                                                               value={province}
                                                               onChange={this.onChange}

                                                        />
                                                        {errors.province && touched.province && <div className="invalid-feedback d-block">{errors.province}</div>}
                                                    </FormGroup>
                                                </Colxx>

                                                <Colxx md={4} sm={6} xs={12}>
                                                    <FormGroup>
                                                        <Label for="country">
                                                            <IntlMessages id="forms.country" />
                                                        </Label>
                                                        <Field className="form-control"
                                                               type="text"
                                                               name="country"
                                                               id="country"
                                                               placeholder={messages["forms.country"]}
                                                               value={country}
                                                               onChange={this.onChange}

                                                        />
                                                        {errors.country && touched.country && <div className="invalid-feedback d-block">{errors.country}</div>}
                                                    </FormGroup>
                                                </Colxx>

                                            </FormGroup>

                                            <Button color="primary" className="mt-4 float-right" type="submit">
                                                <IntlMessages id="forms.update" />
                                            </Button>
                                        </Form>
                                    )}
                                </Formik>
                            </CardBody>
                        </Card>
                    </Colxx>
                </Row>

            </Fragment>
        )
    }
}

const mapStateToProps = ({diagnosticcenters, diagnosticcenter}) => ({
    diagnosticCenters: diagnosticcenters.data,
    center: diagnosticcenter.data
});

export default injectIntl(connect(mapStateToProps, {editDiagnosticCenter, getCenter})(EditDiagnosticCenter));