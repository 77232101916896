import React from 'react'

class Loading extends React.PureComponent{
    render(){
        const {className} = this.props;
        return(
            <div className={className}/>
        )
    }
}

export default Loading