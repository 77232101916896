import React from 'react';
import './style.scss';

const DownloadButton = ({onClick, className, ...props}) => {
    return(
        <button {...props} onClick={onClick} className={`fullscreen-button ${className}`}>
            <i className="simple-icon-size-fullscreen"/>
        </button>
    )
};

export default DownloadButton;