import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import React from 'react';
import api from "../../api/users";
import {
    GET_USERS,
    CREATE_USER,
    GET_USER,
    FILTER_USERS,
    GET_USERS_BY_ROLE,
    UPDATE_USER,
    DELETE_USER,
    GET_USERS_PERCENTAGE,
    UPDATE_PASSWORD,
    FORGOT_PASSWORD, 
    RESET_PASSWORD,
    FILTER_USERS_SUCCESS
} from "../../constants/actionTypes";

import {
    getUsersSuccess,
    getUserSuccess,
    createUserSuccess,
    getUsersByRoleSuccess,
    updateUserSuccess,
    deleteUserSuccess,
    getUsersPercentageSuccess,
    filterUsersSuccess,
    updatePasswordSuccess,
    forgotPasswordSuccess, resetPasswordSuccess
} from "./actions";

import history from '../../history';
import {roles} from '../../data/roles';
import {NotificationManager} from "../../components/ReactNotifications";
import IntlMessage from '../../util/IntlMessages'
import {toCamelCase} from "../../util/Utils";


function* getUsers({ payload }) {
    try {

        const { page, limit } = payload;
        const response = yield call(api.getUsers, page, limit);
        if(response.status === 200) {
            yield put(getUsersSuccess(response.data, response.headers.total))
        } else {
            console.log('Get users failed : ', response);
        }
    } catch (error) {
        console.log('Get users  failed : ', error);
    }
}

function* getUsersByRole({ payload }) {
    try {
        const { page, limit, role } = payload;
        const response = yield call(api.getUsersByRole, page, limit, role);
        if(response.status === 200) {
            yield put(getUsersByRoleSuccess(response.data, response.headers.total))
        } else {
            console.log('Get users failed : ', response);
        }
    } catch (error) {
        console.log('Get users  failed : ', error);
    }
}

function* getUser({ payload }) {
    try {
        const response = yield call(api.getUser, payload);
        if(response.status === 200) {
            yield put(getUserSuccess(response.data))
        } else {
            console.log('Get user failed : ', response);
        }
    } catch (error) {
        console.log('Get user  failed : ', error);
    }
}
function* createUser({ payload }) {
    try {
        const user = payload;
        const response = yield call(api.createUser, user);
        if(response.status === 201) {
            yield put(createUserSuccess(response.data));
            history.push(`/app/usuarios/${roles.find(role => role.key === user.role).url}`);
        } else {
            try{
                const res = JSON.parse(response.request.response);
                NotificationManager.create({
                    type: 'error',
                    title: <IntlMessage id={`notify.error.${toCamelCase(res.error.message)}`}/>,
                });
            } catch (e) {
                console.log(response)
            }

        }
    } catch (error) {
        console.log('Create user  failed : ', error);
    }
}

function* updateUser({payload}) {
    try {
        const {user, isProfile} = payload;
        const response = yield call(api.updateUser, user);
        if(response.status === 202) {
            yield put(updateUserSuccess(response.data));
            isProfile ? history.push(`/app/profile`) : history.push(`/app/usuarios/${roles.find(role => role.key === user.role).url}`);
        } else {
            console.log('Edit user failed: ', response);
        }
    } catch (error) {
        console.log('Edit user failed: ', error);
    }
}

function* deleteUser({payload}) {
    try {
        const id = payload;
        const response = yield call(api.deleteUser, id);
        if(response.status === 204) {
            yield put(deleteUserSuccess(id));
        } else {
            console.log('Delete user failed: ', response);
        }
    } catch (error) {
        console.log('Delete user failed: ', error);
    }
}

const filterUsersAsync = async (filter) =>
    await api.filterUsers(filter)
        .then(users => users)
        .catch(error => error);

function* filterUsers({payload}) {
    const {filter, callback} = payload;
    try {
        const response = yield call(filterUsersAsync, filter);
        if(response.status === 200) {
            yield put({
                type: FILTER_USERS_SUCCESS,
                payload: {
                    data: response.data,
                    totalItemCount: response.headers.total,
                }
            })
            if(callback) yield callback(response.data);
        } else {
            console.log('Filter users failed : ', response);
        }
    } catch (error) {
        console.log('Filter users failed : ', error);
    }
}

function* getUsersPercentage({payload}) {
    try {
        const response = yield call(api.getUsersPercentage);
        if( response.status === 200 ){
            yield put(getUsersPercentageSuccess(response.data))
        } else {
            console.log('Get users percentage failed: ', response);
        }
    } catch(error) {
        console.log('Get users percentage failed: ', error);
    }

}

function* updatePassword({payload}) {
    try {
        const response = yield call(api.updatePassword, payload);
        if(response.status === 200) {
            yield put(updatePasswordSuccess(response.data));
        } else {
            console.log('Update password failed: ', response);
        }
    } catch (error) {
        console.log('Update password failed: ', error);
    }
}

function* forgotPassword({payload}) {
    try {
        const response = yield call(api.forgotPassword, payload);
        if(response.status === 200) {
            yield put(forgotPasswordSuccess(response.data));
        } else {
            console.log('Forgot password failed: ', response);
        }
    } catch (error) {
        console.log('Forgot password failed: ', error);
    }
}

function* resetPassword({payload}) {
    try {
        const response = yield call(api.resetPassword, payload);
        const {status} = response;
        console.log('STATUS SAGA:', response.status);
        if(status === 200 ) {
            yield put(resetPasswordSuccess(response.status));
        } else {
            if(response.toString().includes('400')) {
                yield put(resetPasswordSuccess(400));
            } else if (response.toString().includes('404')) {
                yield put(resetPasswordSuccess(404));
            }

            console.log('Reset password failed: ', response);
        }
    } catch (error) {
        console.log('Reset password failed: ', error);
    }
}

export function* watchGetUsers() {
    yield takeEvery(GET_USERS, getUsers);
}

export function* watchCreateUser() {
    yield takeEvery(CREATE_USER, createUser);
}

export function* watchGetUser() {
    yield takeEvery(GET_USER, getUser);
}

export function* watchFilterUsers() {
    yield takeLatest(FILTER_USERS, filterUsers);
}

export function* watchGetUsersByRole() {
    yield takeEvery(GET_USERS_BY_ROLE, getUsersByRole);
}

export function* watchUpdateUser() {
    yield takeEvery(UPDATE_USER, updateUser);
}

export function* watchDeleteUser() {
    yield takeEvery(DELETE_USER, deleteUser);
}

export function* watchGetUsersPercentage() {
    yield takeEvery(GET_USERS_PERCENTAGE, getUsersPercentage)
}

export function* watchUpdatePassword() {
    yield takeEvery(UPDATE_PASSWORD, updatePassword)
}

export function* watchForgotPassword() {
    yield takeEvery(FORGOT_PASSWORD, forgotPassword)
}

export function* watchResetPassword() {
    yield takeEvery(RESET_PASSWORD, resetPassword)
}

export default function* rootSaga() {
    yield all([
        fork(watchGetUser),
        fork(watchCreateUser),
        fork(watchGetUsers),
        fork(watchFilterUsers),
        fork(watchGetUsersByRole),
        fork(watchUpdateUser),
        fork(watchDeleteUser),
        fork(watchUpdatePassword),
        fork(watchForgotPassword),
        fork(watchResetPassword),
    ]);
}
