import Utils from './Utils';

const schema = {
    name: {
        defaultValue: '' //string
    },
    url: {
        defaultValue: '' //string
    },
    upload_at: {
        defaultValue: '' //date
    },
    modified_at: {
        defaultValue: '' //date
    },
    generics: {
        defaultValue: '' //string
    },
    mediaType: {
        defaultValue: '' //enum ["radiography", "stlModel", "dicomViewer", "tac", "other"]
    },
    metadata: {
        defaultValue: [] //[{isDoctor: objectId, data: object},...]
    },
};

class Multimedia extends Utils{

    constructor(obj){
        super(schema, obj);
    }

}

export default Multimedia;