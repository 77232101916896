import imageNoFound from '../../assets/img/imageNoFound.png';

export const getThumbnails = (multimedia = null) => {

    if(multimedia && multimedia.length > 0) {
        let slides = multimedia.filter(reg => reg.mediaType === 'tac');
        if(slides.length > 0) slides = slides.slice(0,3);
        else {
            slides = multimedia.filter(reg => reg.mediaType === 'radiography');
            if(slides.length > 0) slides = slides.slice(0,3);
        }
        if(slides.length > 0) {
            slides = slides.map(el => ({
                src: el.url,
                altText: el.name,
                caption: el._id,
                media: el
            }));
            return slides;
        }
    }

    return [{
        src: imageNoFound,
        altText: 'image no found',
        caption: 'image no found',
        media:{
            url: imageNoFound,
            name: 'image no found',
            key: 'image no found',
        }
    }]
};