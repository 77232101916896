import React, { Fragment } from "react";
import IntlMessages from "../../../util/IntlMessages";
import PageHeader from '../../../components/PageHeader';
import Finder from '../../../components/Finder';
import {
    Row,
    Button,
    Card,
    Badge} from "reactstrap";
import { NavLink } from "react-router-dom";

import { injectIntl} from 'react-intl';

import { Colxx } from "../../../components/CustomBootstrap";
import Pagination from "../../../components/List/Pagination";

import { getDiagnosticCenters, deleteDiagnosticCenter, filterDiagnosticCenters } from "../../../redux/actions";
import { connect } from "react-redux";

import classnames from "classnames";
import mouseTrap from "react-mousetrap";
import { ContextMenu, MenuItem } from "react-contextmenu";
import ContextMenuFn from '../../../components/ContextMenuClass';
import SelectorNumberOfItemsPerPage from '../../../components/SelectorNumberOfItemsPerPage';

class DiagnosticCenters extends ContextMenuFn {
    constructor(props) {
        super(props);
        this.onContextMenuClick = this.onContextMenuClick.bind(this);
        this.toggleSplit = this.toggleSplit.bind(this);
        this.state = {
            selectedItems: [],
            pageSizes: [8, 12, 24],
            selectedPageSize: 8,
            currentPage: 1,
            totalPage: 1,
            search: "",
            lastChecked: null,
            loading: false,
            dropdownSplitOpen: false
        }
    }
    handleCheckChange(event, id) {
        if (
            event.target.tagName === "A" ||
            (event.target.parentElement &&
                event.target.parentElement.tagName === "A")
        ) {
            return true;
        }
        if (this.state.lastChecked == null) {
            this.setState({
                lastChecked: id
            });
        }

        let selectedItems = this.state.selectedItems;
        if (selectedItems.includes(id)) {
            selectedItems = selectedItems.filter(x => x !== id);
        } else {
            selectedItems.push(id);
        }
        this.setState({
            selectedItems
        });

        if (event.shiftKey) {
            let items = this.state.items;
            let start = this.getIndex(id, items, "id");
            let end = this.getIndex(this.state.lastChecked, items, "id");
            items = items.slice(Math.min(start, end), Math.max(start, end) + 1);
            selectedItems.push(
                ...items.map(item => {
                    return item.id;
                })
            );
            selectedItems = Array.from(new Set(selectedItems));
            this.setState({
                selectedItems
            });
        }
        document.activeElement.blur();
    }
    handleKeyPress = (e, searchKey) => {
        if (e.key === "Enter") {
            this.setState(
                {
                    search: e.target.value.toLowerCase()
                },
                () => console.info("GET Diagnostic Centers filtered") //TODO: GET Diagnostic Centers filtered this.dataListRender()
            );

            this.props.filterDiagnosticCenters({
                [searchKey]: e.target.value.toLowerCase(),
            })
        }
    };
    handleOnSearchIconClick = (searchKey, value) => {
        this.props.filterDiagnosticCenters({
            [searchKey]: value,
        })
    }
    changePageSize = (size) => {
        this.setState(
            {
                selectedPageSize: size,
                currentPage: 1
            },
            () => this.props.getDiagnosticCenters(1, size)
        );
    };
    onChangePage(page) {
        this.setState(
            {
                currentPage: page
            },
            () => this.props.getDiagnosticCenters(page, this.state.selectedPageSize)
        );
    }

    toggleSplit() {
        this.setState(prevState => ({
            dropdownSplitOpen: !prevState.dropdownSplitOpen
        }));
    };
    handleChangeSelectAll(isToggle) {
        if (this.state.selectedItems.length >= this.props.data.length) {
            if (isToggle) {
                this.setState({
                    selectedItems: []
                });
            }
        } else {
            this.setState({
                selectedItems: this.props.data.map(x => x._id)
            });
        }
        document.activeElement.blur();
        return false;
    };
    componentDidMount() {
        this.props.getDiagnosticCenters();
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.totalItemCount !== this.props.totalItemCount || prevState.selectedPageSize !== this.state.selectedPageSize) {
            this.setState({
                totalPage: Math.ceil(this.props.totalItemCount/this.state.selectedPageSize)
            });
        }
    }

    onViewInContext=(data)=>{
        this.props.history.push(`detalle/${data.data}`);
    };

    onUpdateInContext = (data) =>{
        this.props.history.push(`editar/${data.data}`);
    };

    onDeleteInContext = (data) =>{
        this.props.deleteDiagnosticCenter(data.data);
    };

    handleClickOnCard(event, entity){
        if( !event.target.classList.contains('badge') &&
            !event.target.classList.contains('mx-1') &&
            event.target.tagName !== 'I')
        {
            event.stopPropagation();
            event.preventDefault();
            this.props.history.push(`detalle/${entity._id}`)
        }
    }

    renderActions(){
        //TODO: Commented DropDown because actions in block aren't developed

        return(
            <Fragment>
                <NavLink to={"/app/diagnosticcenters/crear"}>
                    <Button
                        color="primary"
                        size="lg"
                    >
                        <IntlMessages id="diagnosticcenters.add-new" />
                    </Button>
                </NavLink>
                {/*{"  "}*/}
                {/*<ButtonDropdown*/}
                    {/*isOpen={this.state.dropdownSplitOpen}*/}
                    {/*toggle={this.toggleSplit}*/}
                {/*>*/}
                    {/*<div className="btn btn-primary pl-4 pr-0 check-button">*/}
                        {/*<Label*/}
                            {/*for="checkAll"*/}
                            {/*className="custom-control custom-checkbox mb-0 d-inline-block"*/}
                        {/*>*/}
                            {/*<Input*/}
                                {/*className="custom-control-input"*/}
                                {/*type="checkbox"*/}
                                {/*id="checkAll"*/}
                                {/*checked={*/}
                                    {/*this.state.selectedItems.length >=*/}
                                    {/*this.props.data.length*/}
                                {/*}*/}
                                {/*onChange={() => this.handleChangeSelectAll(true)}*/}
                            {/*/>*/}
                            {/*<span*/}
                                {/*className={`custom-control-label ${*/}
                                    {/*this.state.selectedItems.length > 0 &&*/}
                                    {/*this.state.selectedItems.length <*/}
                                    {/*this.props.data.length*/}
                                        {/*? "indeterminate"*/}
                                        {/*: ""*/}
                                    {/*}`}*/}
                            {/*/>*/}
                        {/*</Label>*/}
                    {/*</div>*/}
                    {/*<DropdownToggle*/}
                        {/*caret*/}
                        {/*color="primary"*/}
                        {/*className="dropdown-toggle-split pl-2 pr-2"*/}
                    {/*/>*/}
                    {/*<DropdownMenu right>*/}
                        {/*<DropdownItem>*/}
                            {/*<IntlMessages id="layouts.delete" />*/}
                        {/*</DropdownItem>*/}
                    {/*</DropdownMenu>*/}
                {/*</ButtonDropdown>*/}
            </Fragment>
        )
    }

    renderHeader() {
        const {messages} = this.props.intl;
        const startIndex= (this.state.currentPage-1)*this.state.selectedPageSize;
        const endIndex= (this.state.currentPage)*this.state.selectedPageSize;
        const {selectedPageSize, pageSizes} = this.state;

        return (

            <PageHeader
                heading={ <h1><IntlMessages id="menu.diagnosticcenters" /></h1>}
                match={this.props.match}
                actions={this.renderActions()}
                finder={<Finder 
                    placeholder={messages["menu.search"]} 
                    searchKeys={['name', "city", "province"]} 
                    onKeyPress={this.handleKeyPress}
                    onSearchIconClick={this.handleOnSearchIconClick}/>}
            >

                <SelectorNumberOfItemsPerPage
                    startIndex={startIndex}
                    endIndex={endIndex}
                    totalItemCount={this.props.totalItemCount}
                    selectedPageSize={selectedPageSize}
                    pageSizes={pageSizes}
                    changePageSize={this.changePageSize}
                />
            </PageHeader>
        );
    }

    render() {
        const {messages} = this.props.intl;

        if (this.props.totalItemCount === 0) {
            return (
                <Fragment>
                    <div className="disable-text-selection">
                        {this.renderHeader()}
                        {!this.props.loading ?
                            <Row>
                                <Colxx xxs="12" className="mb-3">
                                    <h2><IntlMessages id="general.no-results-found" /></h2>
                                </Colxx>
                            </Row>
                            : <div className="loading"/>
                        }
                    </div>
                </Fragment>
            )
        }

        return (
            <Fragment>
                <div className="disable-text-selection">
                    { this.renderHeader() }
                    <Row>
                        {(!this.props.loading && this.props.data) ?
                            this.props.data.map(diagnosticcenter => {
                                return(
                                    <Colxx xxs="12" key={diagnosticcenter._id} className="mb-3">
                                        {/*<ContextMenuTrigger*/}
                                        {/*    id="menu_id"*/}
                                        {/*    data={diagnosticcenter._id}*/}
                                        {/*    collect={collect}*/}
                                        {/*>*/}
                                            <Card
                                                // onClick={event =>
                                                //     this.handleCheckChange(event, diagnosticcenter._id)
                                                // }
                                                onClickCapture={(e)=>this.handleClickOnCard(e, diagnosticcenter)}

                                                className={classnames("d-flex flex-row", {
                                                    active: this.state.selectedItems.includes(
                                                        diagnosticcenter._id
                                                    )
                                                })}
                                            >
                                                <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                                                    <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                                                        <NavLink
                                                            to={`detalle/${diagnosticcenter._id}`}
                                                            className="w-25 w-sm-100"
                                                        >
                                                            <p className="list-item-heading mb-1 truncate">
                                                                {diagnosticcenter.name}
                                                            </p>
                                                        </NavLink>
                                                        <p className="mb-1 text-muted text-small w-30 w-sm-100">
                                                            {diagnosticcenter.city}
                                                        </p>
                                                        <p className="mb-1 text-muted text-small w-20 w-sm-100">
                                                            {diagnosticcenter.province} ({diagnosticcenter.country})
                                                        </p>
                                                        <div className="w-10 w-sm-100">
                                                            <Badge color="primary" pill>
                                                                OK
                                                                {/* TODO: STATUS? */}
                                                            </Badge>
                                                        </div>
                                                    </div>
                                                    <div className="custom-control custom-checkbox pl-1 d-flex align-items-center pr-4">
                                                        <div>
                                                            <a href="#" onClick={() => this.onUpdateInContext({data:diagnosticcenter._id})} aria-label="Editar" title="Editar" className="mx-1 actions-icons">
                                                                <i className="simple-icon-pencil"/>
                                                            </a>
                                                            <a href="#" onClick={() => this.onDeleteInContext({data:diagnosticcenter._id})} aria-label="Borrar" title="Borrar" className="mx-1 actions-icons">
                                                                <i className="simple-icon-trash"/>
                                                            </a>
                                                        </div>
                                                        {/*<CustomInput*/}
                                                        {/*    className="itemCheck mb-1 ml-2"*/}
                                                        {/*    type="checkbox"*/}
                                                        {/*    id={`check_${diagnosticcenter._id}`}*/}
                                                        {/*    checked={this.state.selectedItems.includes(*/}
                                                        {/*        diagnosticcenter._id*/}
                                                        {/*    )}*/}
                                                        {/*    onChange={() => {}}*/}
                                                        {/*    label=""*/}
                                                        {/*/>*/}
                                                    </div>
                                                </div>
                                            </Card>
                                        {/*</ContextMenuTrigger>*/}
                                    </Colxx>
                                )
                            })
                            :<div className="loading"/>
                        }
                        {!this.props.loading &&
                        <Pagination
                            currentPage={this.state.currentPage}
                            totalPage={this.state.totalPage}
                            onChangePage={i => this.onChangePage(i)}
                        />
                        }
                    </Row>
                </div>

                <ContextMenu
                    id="menu_id"
                    onShow={e => this.onContextMenu(e, e.detail.data)}
                >
                    <MenuItem
                        onClick={this.onContextMenuClick}
                        data={{ action: "view" }}
                    >
                        <i className="iconsmind-Profile" /> <span>{messages["layouts.view"]}</span>
                    </MenuItem>
                    <MenuItem
                        onClick={this.onContextMenuClick}
                        data={{ action: "update" }}
                    >
                        <i className="simple-icon-refresh" /> <span>{messages["layouts.update"]}</span>
                    </MenuItem>
                    <MenuItem
                        onClick={this.onContextMenuClick}
                        data={{ action: "delete" }}
                    >
                        <i className="simple-icon-trash" /> <span>{messages["layouts.delete"]}</span>
                    </MenuItem>
                </ContextMenu>
            </Fragment>
        );
    }
}

const mapStateToProps = ({ diagnosticcenters }) => {
    const { data, loading, totalItemCount } = diagnosticcenters;
    return { data, loading, totalItemCount }
};

export default injectIntl(mouseTrap(connect(mapStateToProps, { getDiagnosticCenters, deleteDiagnosticCenter, filterDiagnosticCenters })(DiagnosticCenters)))