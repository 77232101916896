import Utils from './Utils';

const schema = {
    _id:{
        defaultValue: undefined,
    },
    who: {
        defaultValue: '5ce3e0e3097b020047e64b67',
    },
    what: {
        defaultValue: '',
    },
    when: {
        defaultValue: new Date(),
    }
};

class Observation extends Utils{

    constructor(obj){
        super(schema, obj);
    }


    getData() {
        const me = super.getData();
        me.who = me.who.id ? me.who.id : me.who;
        return me
    }
}

export default Observation;
