import React from 'react';
import WithModal from "../Modal";
import IntlMessages from "../../util/IntlMessages";
import Loading from "../Dropzone/components/Loading";

class Body extends React.Component{
    state={
        loading: false
    };

    componentDidUpdate = (prevProps) => {
        if(this.props.onOkOption && prevProps.onOkOption !== this.props.onOkOption ) {
            this.props.onClick();
            this.setState({
                loading: true
            })
        }
    };

    render() {
        const {loading}  = this.state;
        return (
            loading ?
                <Loading className={"dropzone-loading-component loading"}/> :
                <IntlMessages
                    id="general.remove.patient.confirm"
                />
        )
    }
}

const BodyModal = WithModal(Body);

export const RemoveButtonFlatIcon = ({onClick, className, toggle, ...props}) => {

    const handleOnClick = (e) =>{
        e.preventDefault();
        e.stopPropagation();
        toggle()
    };

    return(
    <a href="#" onClick={handleOnClick} aria-label="Borrar" title="Borrar"
       className={`mx-1 actions-icons ${className ? className : ''}`}>
        <i className="simple-icon-trash"/>
    </a>);
};


class RemoveButton extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            modal: false
        }
    }

    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    };


    render() {
        const {onClickCapture, className, ...props} = this.props;
        return (
            <>
                <RemoveButtonFlatIcon {...props} toggle={this.toggle} className={className}/>
                <BodyModal
                    onClick={onClickCapture}
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                    title={<IntlMessages id="general.remove.confirm.title" />}
                />
            </>
        )
    }
}

export default RemoveButton;