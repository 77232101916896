import React from 'react';
import ReactDOM from "react-dom";

const defaultValue = { portalVisible: false };

export const Context = React.createContext(defaultValue);

class Provider extends React.Component {

    constructor(props) {
        super(props);
        this.nodeElement = document.getElementsByTagName('BODY')[0];

        this.functions = {
            handlePortalVisibility: this.handlePortalVisibility
        };

        this.state = {
            componentToDraw: null,
            portalVisible: false,
            ...this.functions
        };
    };

    handlePortalVisibility = (bool = !this.state.portalVisible, componentToDraw = null) => {
        if(bool !== this.state.portalVisible)
            this.setState({ portalVisible: bool, componentToDraw: componentToDraw });
    };

    render(){
        const {children} = this.props;
        const { portalVisible } = this.state;
        const ComponentToDraw = this.state.componentToDraw;

        return(
            <Context.Provider value={{ ...this.state }}>
                { (this.nodeElement && portalVisible && ComponentToDraw) ? ReactDOM.createPortal(<ComponentToDraw />, this.nodeElement) : null }
                {children}
            </Context.Provider>
        )
    }
}

export default Provider;
