import React, { Component } from "react";
import ReactDOM from "react-dom";
import IntlMessages from "../../util/IntlMessages";
import { Nav, NavItem } from "reactstrap";
import { NavLink, withRouter, Link } from "react-router-dom";
import classnames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";
import TeamViewerImg from './../../assets/img/team-viewer-grey-icon.png'
import { connect } from "react-redux";
import { addContainerClassname, changeDefaultClassnames, setContainerClassnames } from "../../redux/actions";

import { isCorrectRole } from '../../components/PrivateRoute';
import { CONDITION, PRIVACY, LEGAL} from '../../data/text-editor-routes';
class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.handleWindowResize = this.handleWindowResize.bind(this);
        this.addEvents = this.addEvents.bind(this);
        this.handleDocumentClick = this.handleDocumentClick.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleProps = this.handleProps.bind(this);
        this.removeEvents = this.removeEvents.bind(this);
        this.getContainer = this.getContainer.bind(this);
        this.getMenuClassesForResize = this.getMenuClassesForResize.bind(this);
        this.setSelectedLiActive = this.setSelectedLiActive.bind(this);

        this.state = {
            selectedParentMenu: "",
            viewingParentMenu: "",
        };
    }

    handleWindowResize(event) {
        if (event && !event.isTrusted) {
            return;
        }
        const { containerClassnames } = this.props;
        let nextClasses = this.getMenuClassesForResize(containerClassnames);
        this.props.setContainerClassnames(0, nextClasses.join(" "));
    }

    handleDocumentClick(e) {
        const container = this.getContainer();
        let isMenuClick = false;
        if (
            e.target &&
            e.target.classList &&
            (e.target.classList.contains("menu-button") ||
                e.target.classList.contains("menu-button-mobile"))
        ) {
            isMenuClick = true;
        } else if (
            e.target.parentElement &&
            e.target.parentElement.classList &&
            (e.target.parentElement.classList.contains("menu-button") ||
                e.target.parentElement.classList.contains("menu-button-mobile"))
        ) {
            isMenuClick = true;
        } else if (
            e.target.parentElement &&
            e.target.parentElement.parentElement &&
            e.target.parentElement.parentElement.classList &&
            (e.target.parentElement.parentElement.classList.contains("menu-button") ||
                e.target.parentElement.parentElement.classList.contains(
                    "menu-button-mobile"
                ))
        ) {
            isMenuClick = true;
        }
        if (
            (container.contains(e.target) && container !== e.target) ||
            isMenuClick
        ) {
            return;
        }
        this.toggle(e);
        this.setState({
            viewingParentMenu: ""
        })
    }

    getMenuClassesForResize(classes) {
        const { menuHiddenBreakpoint, subHiddenBreakpoint } = this.props;
        let nextClasses = classes.split(" ").filter(x => x != "");
        const windowWidth = window.innerWidth;
        if (windowWidth < menuHiddenBreakpoint) {
            nextClasses.push("menu-mobile");
        } else if (windowWidth < subHiddenBreakpoint) {
            nextClasses = nextClasses.filter(x => x != "menu-mobile");
            if (
                nextClasses.includes("menu-default") &&
                !nextClasses.includes("menu-sub-hidden")
            ) {
                nextClasses.push("menu-sub-hidden");
            }
        } else {
            nextClasses = nextClasses.filter(x => x !== "menu-mobile");
            if (
                nextClasses.includes("menu-default") &&
                nextClasses.includes("menu-sub-hidden")
            ) {
                nextClasses = nextClasses.filter(x => x != "menu-sub-hidden");
            }
        }
        return nextClasses;
    }

    getContainer() {
        return ReactDOM.findDOMNode(this);
    }

    toggle() {
        const { containerClassnames, menuClickCount } = this.props;
        const currentClasses = containerClassnames
            ? containerClassnames.split(" ").filter(x => x !== "")
            : "";

        if (currentClasses.includes("menu-sub-hidden") && menuClickCount == 3) {
            this.props.setContainerClassnames(2, containerClassnames);
        } else if (
            currentClasses.includes("menu-hidden") ||
            currentClasses.includes("menu-mobile")
        ) {
            this.props.setContainerClassnames(0, containerClassnames);
        }
    }

    handleProps() {
        this.addEvents();
    }

    addEvents() {
        ["click", "touchstart"].forEach(event =>
            document.addEventListener(event, this.handleDocumentClick, true)
        );
    }
    removeEvents() {
        ["click", "touchstart"].forEach(event =>
            document.removeEventListener(event, this.handleDocumentClick, true)
        );
    }
    setSelectedLiActive() {
        const oldli = document.querySelector(".sub-menu  li.active");
        if (oldli != null) {
            oldli.classList.remove("active");
        }

        /* set selected parent menu */
        const selectedlink = document.querySelector(".sub-menu  a.active");
        if (selectedlink != null) {

            selectedlink.parentElement.classList.add("active");
            this.setState({
                selectedParentMenu: selectedlink.parentElement.parentElement.getAttribute(
                    "data-parent"
                )
            });
        } else {
            var selectedParentNoSubItem = document.querySelector(".main-menu  li a.active");

            if (selectedParentNoSubItem != null) {
                this.setState({
                    selectedParentMenu: selectedParentNoSubItem.getAttribute(
                        "data-flag"
                    )
                });
            } else if (this.state.selectedParentMenu == "") {
                this.setState({
                    selectedParentMenu: "gogo"
                });
            }

        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setSelectedLiActive();
            this.toggle();
            window.scrollTo(0, 0);
        }

        this.handleProps();
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleWindowResize);
        this.handleWindowResize();
        this.handleProps();
        this.setSelectedLiActive();
    }

    componentWillUnmount() {
        this.removeEvents();
        window.removeEventListener("resize", this.handleWindowResize);
    }

    changeDefaultMenuType(e, containerClassnames) {
        e.preventDefault();
        let nextClasses = this.getMenuClassesForResize(containerClassnames);
        this.props.setContainerClassnames(0, nextClasses.join(" "));
    }

    openSubMenu(e, selectedParent) {
        e.preventDefault();
        const { containerClassnames, menuClickCount } = this.props;
        const currentClasses = containerClassnames
            ? containerClassnames.split(" ").filter(x => x != "")
            : "";

        if (!currentClasses.includes("menu-mobile")) {
            if (
                currentClasses.includes("menu-sub-hidden") &&
                (menuClickCount == 2 || menuClickCount == 0)
            ) {
                this.props.setContainerClassnames(3, containerClassnames);
            } else if (
                currentClasses.includes("menu-hidden") &&
                (menuClickCount == 1 || menuClickCount == 3)
            ) {
                this.props.setContainerClassnames(2, containerClassnames);
            } else if (
                currentClasses.includes("menu-default") &&
                !currentClasses.includes("menu-sub-hidden") &&
                (menuClickCount == 1 || menuClickCount == 3)
            ) {
                this.props.setContainerClassnames(0, containerClassnames);
            }
        } else {
            this.props.addContainerClassname(
                "sub-show-temporary",
                containerClassnames
            );
        }
        this.setState({
            viewingParentMenu: selectedParent,
            selectedParentMenu: selectedParent
        });
    }
    changeViewingParentMenu(menu) {
        this.toggle();

        this.setState({
            viewingParentMenu: menu
        })
    }

    render() {
        return (
            <div className="sidebar">
                <div className="main-menu">
                    <div className="scroll">
                        {isCorrectRole(['admin', 'doctor', 'clinic', 'technician']) &&
                            <PerfectScrollbar
                                option={{ suppressScrollX: true, wheelPropagation: false }}
                            >
                                <Nav vertical className="list-unstyled">
                                    <NavItem className={classnames({ active: ((this.state.selectedParentMenu === "pacientes" && this.state.viewingParentMenu == "") || this.state.viewingParentMenu == "pacientes") })}>
                                        <NavLink to="/app/pacientes/lista" onClick={() => this.changeViewingParentMenu('pacientes')} data-flag="pacientes">
                                            <i className="simple-icon-people" />
                                            <IntlMessages id="menu.pacientes" />
                                        </NavLink>
                                    </NavItem>
                                    {
                                        isCorrectRole(['admin']) &&
                                        <NavItem className={classnames({ active: ((this.state.selectedParentMenu === "diagnosticcenters" && this.state.viewingParentMenu == "") || this.state.viewingParentMenu == "diagnosticcenters") })}>
                                            <NavLink to="/app/diagnosticcenters" onClick={() => this.changeViewingParentMenu('diagnosticcenters')} data-flag="diagnosticcenters">
                                                <i className="iconsmind-Shop-2 fw-700" />
                                                <IntlMessages id="menu.diagnosticcenters" />
                                            </NavLink>
                                        </NavItem>
                                    }
                                    {
                                        isCorrectRole(['admin', 'technician']) &&
                                        <NavItem className={classnames({ active: ((this.state.selectedParentMenu === "usuarios" && this.state.viewingParentMenu == "") || this.state.viewingParentMenu == "usuarios") })}>
                                            <NavLink to="/app/usuarios" onClick={(e) => this.openSubMenu(e, 'usuarios')} data-flag="usuarios">
                                                <i className="iconsmind-People-onCloud fw-700" />
                                                <IntlMessages id="menu.usuarios" />
                                            </NavLink>
                                        </NavItem>
                                    }
                                    {
                                        isCorrectRole(['admin']) &&
                                        <NavItem className={classnames({ active: ((this.state.selectedParentMenu === "stats" && this.state.viewingParentMenu === "") || this.state.viewingParentMenu === "stats") })}>
                                            <NavLink to="/app/stats" onClick={() => this.changeViewingParentMenu('stats')} data-flag="stats">
                                                <i className="simple-icon-chart" />
                                                <IntlMessages id="menu.stats" />
                                            </NavLink>
                                        </NavItem>
                                    }
                                    {
                                        isCorrectRole(['admin']) &&
                                        <NavItem className={classnames({ active: ((this.state.selectedParentMenu === "config" && this.state.viewingParentMenu === "") || this.state.viewingParentMenu === "config") })}>
                                            {/*<NavLink to="/app/config" onClick={() => this.changeViewingParentMenu('config')} data-flag="config">*/}
                                            {/*<i className="simple-icon-equalizer" />*/}
                                            {/*<IntlMessages id="menu.config" />*/}
                                            {/*</NavLink> */}
                                            <NavLink to="/app/config" onClick={(e) => this.openSubMenu(e, 'config')} data-flag="config">
                                                <i className="simple-icon-equalizer" />
                                                <IntlMessages id="menu.config" />
                                            </NavLink>
                                        </NavItem>
                                    }{isCorrectRole(['admin', 'technician', 'doctor', 'clinic']) &&
                                        <NavItem>
                                            <a href={`${process.env.PUBLIC_URL}/team-viewer-dident.zip`}>
                                                <img className={'team-viewer-icon'} src={TeamViewerImg} />
                                                <IntlMessages id="menu.teamViwer" />
                                            </a>
                                        </NavItem>}
                                </Nav>
                            </PerfectScrollbar>
                        }
                    </div>
                </div>
                {
                    isCorrectRole(['admin', 'technician']) &&
                    <div className="sub-menu">
                        <div className="scroll">
                            <PerfectScrollbar>
                                <Nav className={classnames({ "d-block": this.state.selectedParentMenu === 'usuarios' })} data-parent="usuarios">
                                    <NavItem>
                                        <NavLink to="/app/usuarios/doctores">
                                            <i className="iconsmind-Doctor" />
                                            <IntlMessages id="menu.doctores" />
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink to="/app/usuarios/clinicas">
                                            <i className="iconsmind-Hospital" />
                                            <IntlMessages id="menu.clinicas" />
                                        </NavLink>
                                    </NavItem>
                                    {
                                        isCorrectRole(['admin']) &&
                                        <>
                                            <NavItem>
                                                <NavLink to="/app/usuarios/operadores">
                                                    <i className="iconsmind-Support" />
                                                    <IntlMessages id="menu.operadores" />
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink to="/app/usuarios/administradores">
                                                    <i className="iconsmind-Administrator" />
                                                    <IntlMessages id="menu.administradores" />
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink to="/app/usuarios/solicitudes">
                                                    <i className="iconsmind-Affiliate" />
                                                    <IntlMessages id="menu.solicitudes" />
                                                </NavLink>
                                            </NavItem>
                                        </>
                                    }
                                </Nav>

                                <Nav className={classnames({ "d-block": this.state.selectedParentMenu === 'config' })} data-parent="config">
                                    {
                                        isCorrectRole(['admin']) &&
                                        <>
                                            <NavItem>
                                                <b><IntlMessages id="menu.editTexts" /></b>
                                            </NavItem>
                                            <NavItem>
                                                <Link to={ `/app/editor-de-texto/${CONDITION}`}>
                                                    <i className="iconsmind-Pencil" />
                                                    <IntlMessages id="menu.condition" />
                                                </Link>
                                            </NavItem>
                                            <NavItem>
                                                <Link to={ `/app/editor-de-texto/${PRIVACY}`}>
                                                    <i className="iconsmind-Pencil" />
                                                    <IntlMessages id="menu.privacy" />
                                                </Link>
                                            </NavItem>
                                            <NavItem>
                                                <Link to={ `/app/editor-de-texto/${LEGAL}`}>
                                                    <i className="iconsmind-Pencil" />
                                                    <IntlMessages id="menu.legal" />
                                                </Link>
                                            </NavItem>
                                        </>
                                    }
                                </Nav>

                            </PerfectScrollbar>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ menu }) => {
    const {
        containerClassnames,
        subHiddenBreakpoint,
        menuHiddenBreakpoint,
        menuClickCount
    } = menu;
    return {
        containerClassnames,
        subHiddenBreakpoint,
        menuHiddenBreakpoint,
        menuClickCount
    };
};
export default withRouter(
    connect(
        mapStateToProps,
        { setContainerClassnames, addContainerClassname, changeDefaultClassnames }
    )(Sidebar)
);
