import React from 'react';
import {Context} from './provider';

const Consumer = (Component) => {
    class withContext extends React.Component{
        render(){
            const {handlePortalVisibility} = this.context;
            return (
                <Component {...this.props} handlePortalVisibility={handlePortalVisibility}/>
            )
        }
    }
    withContext.contextType = Context;

    return withContext;
};

export default Consumer;