import {
    FILTER_USERS,
    GET_USERS,
    GET_USERS_SUCCESS,
    GET_USERS_BY_ROLE,
    GET_USERS_BY_ROLE_SUCCESS,
    CREATE_USER,
    CREATE_USER_SUCCESS,
    GET_USER,
    GET_USER_SUCCESS,
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    DELETE_USER,
    DELETE_USER_SUCCESS,
    GET_USERS_PERCENTAGE,
    FILTER_USERS_SUCCESS,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS, RESET_PASSWORD_SUCCESS, RESET_PASSWORD,
} from "../../constants/actionTypes";

export const filterUsers = (filter, callback) => ({
    type: FILTER_USERS,
    payload: {filter, callback}
});

export const filterUsersSuccess = (users, total) => ({
    type: FILTER_USERS_SUCCESS,
    payload: {users, total}
});

export const getUsers = (page = 1, limit = 8) => ({
    type: GET_USERS,
    payload: {page, limit}
});

export const getUsersSuccess = (users, total) => ({
    type: GET_USERS_SUCCESS,
    payload: {users, total}
});

export const createUser = user => ({
    type: CREATE_USER,
    payload: user
});

export const createUserSuccess = user => ({
    type: CREATE_USER_SUCCESS,
    payload: user
})

export const getUser = id => ({
    type: GET_USER,
    payload: id
})

export const getUserSuccess = user => ({
    type: GET_USER_SUCCESS,
    payload: user
})

export const getUsersByRole = (page = 1, limit = 8, role) => ({
    type: GET_USERS_BY_ROLE,
    payload: {page, limit, role}
});

export const getUsersByRoleSuccess = (users, total) => ({
    type: GET_USERS_BY_ROLE_SUCCESS,
    payload: {users, total}
});

export const updateUser = (user, isProfile = false) => ({
    type: UPDATE_USER,
    payload: {user, isProfile}
});

export const updateUserSuccess = user => ({
    type: UPDATE_USER_SUCCESS,
    payload: user
})

export const deleteUser = id  => ({
    type: DELETE_USER,
    payload: id,
})

export const deleteUserSuccess = id => ({
    type: DELETE_USER_SUCCESS,
    payload: id,
})

export const getUsersPercentage = () => ({
    type: GET_USERS_PERCENTAGE,
    payload: {},
})

export const getUsersPercentageSuccess = (data) => ({
    type: GET_USERS_PERCENTAGE,
    payload: data,
});

export const updatePassword = user  => ({
    type: UPDATE_PASSWORD,
    payload: user,
});

export const updatePasswordSuccess = user  => ({
    type: UPDATE_PASSWORD_SUCCESS,
    payload: user,
});

export const forgotPassword = email  => ({
        type: FORGOT_PASSWORD,
        payload: email
});

export const forgotPasswordSuccess = user  => ({
    type: FORGOT_PASSWORD_SUCCESS,
    payload: user,
});

export const resetPassword = token  => ({
    type: RESET_PASSWORD,
    payload: token
});

export const resetPasswordSuccess = status  => {
    return ({
        type: RESET_PASSWORD_SUCCESS,
        payload: status,
    });
}
