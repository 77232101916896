import React from 'react';
import Ruler from '../../assets/img/ruler.ico';
import './styles.scss'

const OpenButton = ({onClick, onClickCapture, className, ...props}) => {
    return(
        <button {...props} onClick={onClick} onClickCapture={onClickCapture} className={`edit-button ${className}`}>
            <div className={'ruler-icon-wrapper'}>
                <img src={Ruler} alt='Ruler-icon'/>
            </div>
        </button>
    )
};

export default OpenButton;