import axios from "axios";
import { apiUrl } from "../constants/defaultValues";
import Auth from "./auth";

const getLegal = async() =>
    await Auth.refreshToken()
        .then(()=> new Promise((resolve, reject) => {
            axios.get(apiUrl.concat("contents"), {
                headers:{ "X-Auth-Token": localStorage.getItem("access_token")}
            })
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        }))
        .catch((err) => err);

const createLegal = async (text) =>
    await Auth.refreshToken()
        .then( ()=> new Promise((resolve, reject) => {
            axios.post(apiUrl.concat("contents"), text, {
                headers:{ "X-Auth-Token": localStorage.getItem("access_token")}
            })
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        }))
        .catch((err) => err);

const updateLegal = async (text) => {
    return await Auth.refreshToken()
        .then(() => new Promise((resolve, reject) => {
            axios.put(apiUrl.concat("contents"), text, {
                headers: {"X-Auth-Token": localStorage.getItem("access_token")},
                params: {type: text.type}
            })
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        }))
        .catch((err) => err);
};

export default {
    getLegal,
    createLegal,
    updateLegal
};