import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import api from "../../api/stats";
import {
  GET_PATIENTS_TOTAL,
  GET_TESTS_TOTAL
} from "../../constants/actionTypes";
import {
  getPatientsTotalSuccess,
  getTestsTotalSuccess
} from "./actions";
import Auth from "../../api/auth";

const getPatientsTotalAsync = async () => {
    await Auth.refreshToken();
    return await api.getPatientsTotal()
        .then(total => total)
        .catch(error => error);
};

function* getPatientsTotal({payload}) {
  try {
    const {} = payload;
    const patients = yield call(getPatientsTotalAsync);
    if(patients.status === 200) {
      yield put(getPatientsTotalSuccess(patients.data.total)); // Consultar la estructura de datos que devuelva la consulta.
      // Si es un único valor me quedo con "patients.data".
    } else {
      console.log('Get patients total failed : ', patients);
      // Opcionalmente dependiendo de la aplicación podemos ejecutar una función de error alternativa.
      // Por ejemplo para llevar un control mayor de las notificaciones "getPatientsTotalError".
    }
  } catch (error) {
    console.log('Get patients total failed : ', error);
  }
}

const getTestsTotalAsync = async (doctorid) => {
    await Auth.refreshToken();
    return await api.getTestsTotal(doctorid)
        .then(total => total)
        .catch(error => error);
};

function* getTestsTotal({payload}) {
  try {
    const {doctorid} = payload;
    const tests = yield call(getTestsTotalAsync, doctorid);
    if(tests.status === 200) {
      yield put(getTestsTotalSuccess(tests.data.total));
    } else {
      console.log('Get patients total failed : ', tests);
    }
  } catch (error) {
    console.log('Get patients total failed : ', error);
  }
}

export function* watchGetPatientsTotal() {
  yield takeEvery(GET_PATIENTS_TOTAL, getPatientsTotal);
}

export function* watchGetTestsTotal() {
  yield takeEvery(GET_TESTS_TOTAL, getTestsTotal);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetPatientsTotal),
    fork(watchGetTestsTotal)
  ])
};