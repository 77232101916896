import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    LOGOUT_USER,
    GET_LOGGED_USER,
    GET_LOGGED_USER_SUCCESS,
    LOGIN_USER_FAILED
} from "../../constants/actionTypes";

const INIT_STATE = {
    user: undefined,
    loading: false,
    error: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return { ...state, loading: true };
        case LOGIN_USER_SUCCESS:
            // notify.success('Login Success');
            return { ...state, loading: false, user: action.payload, error: false};
        case LOGIN_USER_FAILED:
            // notify.success('Login Success');
            return { ...state, loading: false, error: true };
        case REGISTER_USER:
            return { ...state, loading: true };
        case REGISTER_USER_SUCCESS:
            //notify.success('Register User Success');
            return { ...state, loading: false, user: action.payload.uid };
        case LOGOUT_USER:
            return { ...state, user: null };
        case GET_LOGGED_USER:
            return {...state, loading: true };
        case GET_LOGGED_USER_SUCCESS:
            return {...state, loading: false, user: action.payload }
        default:
            return { ...state };
    }
};
