import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import api from "../../api/registry";
import {
    CREATE_REGISTRY,
    FILTER_REGISTRIES,
    UPDATE_REGISTRY,
    DELETE_REGISTRY, GET_REGISTRY, SET_OBSERVATIONS
} from "../../constants/actionTypes";
import history from '../../history';
import {
    createRegistrySuccess,
    getRegistriesSuccess,
    updateRegistrySuccess,
    deleteRegistrySuccess,
    getRegistrySuccess, setObservationsSuccess
} from "./actions";
import Observation from "../../models/Observation";

const filterRegistriesAsync = async (filter, increaseCounter) => {
    return await api.filterRegistries(filter, increaseCounter)
        .then(registries => registries)
        .catch(error => error);
};

function* filterRegistries({payload}) {
    const {callback} = payload;
    try {
        const registries = yield call(filterRegistriesAsync, payload.filters, payload.registerEvent);
        if(registries.status === 200) {
            yield put(getRegistriesSuccess(Array.isArray(registries.data) ? registries.data : [registries.data], registries.headers.total));
            callback(registries.data)
        } else {
            console.log('Filter registries failed : ', registries);
        }
    } catch (error) {
        console.log('Filter registries failed : ', error);
    }
}

function* getRegistry({payload}) {
    const {filters, callback} = payload;
    try {
        const registry = yield call(filterRegistriesAsync, filters);
        if(registry.status === 200) {
            yield put(getRegistrySuccess(Array.isArray(registry.data) ? registry.data[0] : registry.data));
            if(callback) callback(registry)
        } else {
            console.log('Get Registry failed : ', registry);
            if(callback) callback(registry)
        }
    } catch (error) {
        console.log('Get Registry failed : ', error);
    }
}

const createRegistryAsync = async (formData) => {
    return await api.createRegistry(formData)
        .then(registry => registry)
        .catch(error => error);
};

function* createRegistry({payload}) {
    try {
        const registry = yield call(createRegistryAsync, payload);
        if(registry.status === 201) {
            yield put(createRegistrySuccess(registry.data));
            history.push('/app/pacientes/historial/'+payload.patientId+'/registro/'+registry.data._id)
        } else {
            console.log('Create registry failed : ', registry);
        }
    } catch (error) {
        console.log('Create registry error: ', error);
    }
}

const updateRegistryAsync = async (formData) => {
    return await api.updateRegistry(formData)
        .then(registry => registry)
        .catch(error => error);
};

function* updateRegistry({payload}) {
    try {
        const registry = yield call(updateRegistryAsync, payload.data);
        if(registry.status === 202) {
            yield put(updateRegistrySuccess(registry.data));
            payload.redirect &&
            history.push('/app/pacientes/historial/'+payload.data.patientId+'/registro/'+registry.data._id);

        } else {
            console.log('Update registry failed : ', registry);
        }
    } catch (error) {
        console.log('Update registry error: ', error);
    }
}


const deleteRegistryAsync = async (id) => {
    return await api.deleteRegistry(id)
        .then(res => res)
        .catch(error => error);
};


function* deleteRegistry({payload}) {
    const {id, callback} = payload;
    try {
        const res = yield call(deleteRegistryAsync, id);
        if(res.status === 204) {
            yield put(deleteRegistrySuccess(id));
            if(callback) callback(id);

        } else {
            console.log('Delete registry failed : ', res);
        }
    } catch (error) {
        console.log('Delete registry error: ', error);
    }
}

const setObservationsAsync = async (idRegistry, observations) => {
    return await api.setObservationsInRegistry(idRegistry, observations)
        .then(res => res)
        .catch(error => error);
};

function* setObservations({payload}) {
    const {idRegistry, observations, callback} = payload;
    const observationsMap = observations.map(obs => new Observation(obs).getData());
    try {
        const res = yield call(setObservationsAsync, idRegistry, observationsMap);
        if(res.status === 202) {
            yield put(setObservationsSuccess(idRegistry, observations));
            if(callback) callback(idRegistry, observations);

        } else {
            console.log('set observations failed : ', res);
        }
    } catch (error) {
        console.log('set observations error: ', error);
    }
}

export function* watchFilterRegistries() {
    yield takeLatest(FILTER_REGISTRIES, filterRegistries);
}

export function* watchCreateRegistry() {
    yield takeEvery(CREATE_REGISTRY, createRegistry);
}

export function* watchUpdateRegistry() {
    yield takeEvery(UPDATE_REGISTRY, updateRegistry);
}

export function* watchDeleteRegistry() {
    yield takeEvery(DELETE_REGISTRY, deleteRegistry);
}

export function* watchGetRegistry() {
    yield takeEvery(GET_REGISTRY, getRegistry);
}

export function* watchSetObservations() {
    yield takeEvery(SET_OBSERVATIONS, setObservations);
}

export default function* rootSaga() {
    yield all([
        fork(watchFilterRegistries),
        fork(watchCreateRegistry),
        fork(watchUpdateRegistry),
        fork(watchDeleteRegistry),
        fork(watchSetObservations),
        fork(watchGetRegistry),
    ])
};
