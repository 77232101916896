import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import PropTypes from 'prop-types';
import {roles} from '../../data/roles';
import History from '../../history';
import Auth from './../../api/auth'

export const isUserLogged = () => {
    const expirationDate = localStorage.getItem('expires_in');
    return expirationDate && new Date(expirationDate).getTime() > new Date().getTime();
};

export const isCorrectRole = (allowedRoles) => {
    const userRole = localStorage.getItem('user_role');
    const fullAllowedRoles = roles.filter(rol => allowedRoles.includes(rol.key));
    return !!fullAllowedRoles.find(rol => rol.hash === userRole);
};

class PrivateComponent extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            userIsLogged: false,
            userRoleIsOk: false,
        }
    }

    componentWillMount(){
        this.props.checkLogin && this.checkUser();
        this.props.checkRole && this.checkRole();
    }

    checkUser = async() => {
        await Auth.refreshToken();
        const expirationDate = localStorage.getItem('expires_in');
        const result = expirationDate && new Date(expirationDate).getTime() > new Date().getTime();
        if(result) this.setState({ userIsLogged: result });
        else History.push('/login')

    };

    checkRole = () =>{
        const result = isCorrectRole(this.props.allowedRoles);
        if(result)
            this.setState({ userRoleIsOk: result });
        else
            History.push(this.props.errorEndPoint)
    };

    render(){
        const { component: Component, ...rest } = this.props;
        const { userIsLogged, userRoleIsOk } = this.state;
        return(
            (userIsLogged && userRoleIsOk) ? <Component {...rest}/> : null
        )
    }
}

const PrivateRoute = ({component, checkLogin, checkRole, allowedRoles,errorEndPoint,...props}) => {
    return <Route {...props} render={props => <PrivateComponent {...props}
                                                                component={component}
                                                                checkLogin={checkLogin}
                                                                checkRole={checkRole}
                                                                errorEndPoint={errorEndPoint}
                                                                allowedRoles={allowedRoles}/> } />
};


PrivateRoute.defaultProps = {
    errorEndPoint: '/login',
    checkLogin: true,
    checkRole: false,
    allowedRoles: []
};

PrivateRoute.propTypes = {
    component: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
    errorEndPoint: PropTypes.string,
    checkLogin: PropTypes.bool,
    checkRole: PropTypes.bool,
    allowedRoles: PropTypes.arrayOf(PropTypes.oneOf(['admin', 'clinic', 'doctor', 'technician'])),
};

export default PrivateRoute;