import {
  GET_PATIENTS_TOTAL,
  GET_PATIENTS_TOTAL_SUCCESS,
  GET_TESTS_TOTAL,
  GET_TESTS_TOTAL_SUCCESS
} from "../../constants/actionTypes";

const INIT_STATE = {
  loading: false,
  totalPatients: 0,
  totalTests: 0
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PATIENTS_TOTAL:
      return { ...state, loading: true };
    case GET_PATIENTS_TOTAL_SUCCESS:
      return { ...state, loading: false, totalPatients: action.payload.total };
    case GET_TESTS_TOTAL:
      return { ...state, loading: true };
    case GET_TESTS_TOTAL_SUCCESS:
      return { ...state, loading: false, totalTests: action.payload.total };
    default:
      return { ...state };
  }
}