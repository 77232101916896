import React, { Component, Fragment } from "react";
import IntlMessages from "../../util/IntlMessages";
import { Row, Card, CardTitle, Form, Label, Input, UncontrolledAlert, FormFeedback } from "reactstrap";
import { NavLink } from "react-router-dom";
import { Colxx } from "../../components/CustomBootstrap";
import { connect } from "react-redux";
import User from "../../models/User";
import {createUserRequest} from '../../redux/actions';
import Button from '../../components/LoadingButton';

class RegisterLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            lastName: '',
            firstName: '',
            role: 'doctor',
            DNI: '',
            collegiateNumber: '',
            clinicName: '',
            errors: {},
            confirmation: false,
        };
    }

    onUserRegister = async() => {
        if (this.validate()) {
            const { lastName, firstName, DNI, collegiateNumber, clinicName, email, role } = this.state;
            const user = new User();
            Object.assign(user, { lastName, firstName, DNI, collegiateNumber, clinicName, email, role });
            delete user.schema;

            this.props.createUserRequest(user)
        }
    };

    validate = () => {

        let errors = {};

        const { lastName, firstName, DNI, collegiateNumber, clinicName, email, role } = this.state;

        if (this.state.role === 'doctor') {
            if (lastName === '')
                errors.lastName = 'forms.validation.lastName';


            if (firstName === '')
                errors.firstName = 'forms.validation.firstName';

            if (DNI === '')
                errors.DNI = 'forms.validation.dni';

            if (collegiateNumber === '')
                errors.collegiateNumber = 'forms.validation.collegiateNumber';

        } else {
            if (clinicName === '')
                errors.clinicName = 'forms.validation.clinicName';

        }

        if (email === '' || /^.+\d+$/.exec(email)) {
            errors.email = 'forms.validation.email';
        }

        this.setState({
            errors,
        });

        return Object.keys(errors).length === 0;
    };

    handleInputChange = (e) => {
        const input = e.target.name;
        this.setState({
            [input]: e.target.value,
        })
    };

    handleAlertChange = () => {
        this.setState({
            confirmation: false,
        })
    };

    componentDidMount() {
        document.body.classList.add("background");
    }

    componentWillUnmount() {
        document.body.classList.remove("background");
    }
    render() {
        const { errors } = this.state;
        return (
            <Fragment>
                <div className="fixed-background" />
                <main>
                    <div className="container">
                        <Row className="h-100">
                            <Colxx xxs="12" md="10" className="mx-auto my-auto">
                                <Card className="auth-card">
                                    <div className="position-relative image-side ">
                                        <p className="text-blue h2 fw-700"><IntlMessages id="user.register.title" /></p>
                                        <p className="text-blue fw-600"><IntlMessages id="user.register.text" />
                                            <NavLink to={`/login`} className="text-blue">
                                                <u><IntlMessages id="user.register.text.login" /></u>
                                            </NavLink>
                                            .
                                        </p>
                                    </div>
                                    <div className="form-side">
                                        <NavLink to={`/`} className="white">
                                            <span className="logo-single" />
                                        </NavLink>
                                        <CardTitle className="mb-4">
                                            <IntlMessages id="user.register" />
                                        </CardTitle>
                                        <Form>
                                            <Label className="form-group has-float-label mb-4">
                                                <Input type="select" name="role" value={this.state.role} onChange={this.handleInputChange}>
                                                    {/* <option><IntlMessages id="forms.doctor" /></option>
                          <option><IntlMessages id="forms.clinic" /></option> */}
                                                    <option value="doctor">Doctor</option>
                                                    <option value="clinic">Clínica</option>
                                                </Input>
                                                <IntlMessages
                                                    id="user.register.rol"
                                                />
                                            </Label>
                                            {this.state.role === 'doctor' &&
                                            <>
                                                <Label className="form-group has-float-label mb-4">
                                                    <Input type="text" defaultValue={this.state.firstName} onChange={this.handleInputChange} name="firstName" invalid={errors.firstName} />
                                                    {errors.firstName && <FormFeedback tooltip={false}><IntlMessages id={errors.firstName} /></FormFeedback>}
                                                    <IntlMessages id="forms.firstName" />
                                                </Label>

                                                <Label className="form-group has-float-label mb-4">
                                                    <Input type="text" defaultValue={this.state.lastName} onChange={this.handleInputChange} name="lastName" invalid={errors.lastName} />
                                                    {errors.lastName && <FormFeedback><IntlMessages id={errors.lastName} /></FormFeedback>}
                                                    <IntlMessages id="forms.lastName" />
                                                </Label>
                                            </>
                                            }
                                            {this.state.role === 'clinic' && <Label className="form-group has-float-label mb-4">
                                                <Input type="text" defaultValue={this.state.clinicName} onChange={this.handleInputChange} name="clinicName" invalid={errors.clinicName} />
                                                {errors.clinicName && <FormFeedback><IntlMessages id={errors.lastName} /></FormFeedback>}
                                                <IntlMessages id="forms.clinicName" />
                                            </Label>}
                                            <Label className="form-group has-float-label mb-4">
                                                <Input type="text" defaultValue={this.state.DNI} onChange={this.handleInputChange} name="DNI" invalid={errors.DNI} />
                                                {errors.firstName &&
                                                <FormFeedback>
                                                    {this.state.role === 'doctor' ? <IntlMessages id="forms.validation.dni" /> : <IntlMessages id="forms.validation.cif" />}
                                                </FormFeedback>}
                                                {this.state.role === 'doctor' ? <IntlMessages id="forms.dni" /> : <IntlMessages id="forms.cif" />}
                                            </Label>
                                            <Label className="form-group has-float-label mb-4">
                                                <Input type="email" onChange={this.handleInputChange} defaultValue={this.state.email} name="email" invalid={errors.email} />
                                                {errors.email && <FormFeedback><IntlMessages id={errors.email} /></FormFeedback>}
                                                <IntlMessages id="user.email" />
                                            </Label>
                                            {this.state.role === 'doctor' && <Label className="form-group has-float-label mb-4">
                                                <Input type="text" defaultValue={this.state.collegiateNumber} onChange={this.handleInputChange} name="collegiateNumber" invalid={errors.collegiateNumber} />
                                                {errors.collegiateNumber && <FormFeedback><IntlMessages id={errors.collegiateNumber} /></FormFeedback>}
                                                <IntlMessages id="forms.collegiateNumber" />
                                            </Label>}
                                            <div className="d-flex justify-content-end align-items-center">
                                                <Button
                                                    loading={this.props.loading}
                                                    color="primary"
                                                    className="btn-shadow"
                                                    size="lg"
                                                    onClick={() => this.onUserRegister()}
                                                >
                                                    <IntlMessages id="user.register-button" />
                                                </Button>
                                            </div>
                                            {this.state.confirmation && <UncontrolledAlert className="mt-4" toggle={this.handleAlertChange} color="info" >
                                                <IntlMessages id="user.register.confirmation" />
                                            </UncontrolledAlert>}
                                        </Form>
                                    </div>
                                </Card>
                            </Colxx>
                        </Row>
                    </div>
                </main>
            </Fragment>
        );
    }
}

const mapStateToProps = ({usersRequest}) => ({
    loading: usersRequest.loading
});

export default connect(mapStateToProps,{createUserRequest})(RegisterLayout);
