import React from 'react';
import './index.scss';

class LoaderBar extends React.PureComponent {
    render(){
        let { charge } = this.props;
        charge = Math.floor(charge);
        return (
            <div className={'LoaderBar-Wrapper'}>
                <div className={`LoaderBar charge-${charge}`}/>
                <p>{charge}%</p>
            </div>
        )
    }
}

export default LoaderBar;