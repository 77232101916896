import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import CreateUser from './crear';
import Users from './lista';
import UserDetail from './detalle';
import EditUser from './editar';
import Pendings from './pendings/index';

import {roles} from '../../data/roles';
import { defaultStartPath } from '../../constants/defaultValues';
import PrivateRoute from '../../components/PrivateRoute';

class EditComponent extends React.PureComponent {
    constructor(props) {
        super(props);

        const rol = roles.find(rol => rol.url === this.props.match.params.role);

        this.state = {
            role: rol,
        }
    }

    handleOnRoleChange = (newRole) => {
        const _newRole = roles.find(r => r.key === newRole);

        this.setState({
            role: _newRole,
        });
    }

    render() {
        return <EditUser { ...this.props} onRoleChange={this.handleOnRoleChange} role={this.state.role} />;
    }
}

export default ({ match }) => {
    const possibleRoles = 'doctores|clinicas|operadores|administradores';
    return (
    <Switch>
        <Redirect exact from={`${match.url}`} to={`${match.url}/${roles[0].url}/lista`} />
        <Redirect exact from={`${match.url}/:role(${possibleRoles})/`} to={`${match.url}/:role(${possibleRoles})/lista`} />
        <Route path={`${match.url}/:role(${possibleRoles})/crear`} component={CreateUser} />
        <Route path={`${match.url}/:role(${possibleRoles})/lista`} component={Users} />
        <Route path={`${match.url}/:role(${possibleRoles})/detalle/:id`} component={UserDetail} />
        <Route path={`${match.url}/:role(${possibleRoles})/editar/:id`} component={EditComponent} />
        <Route path={`${match.url}/solicitudes/:role(${possibleRoles})/editar/:id`} component={EditComponent} />
        <PrivateRoute path={`${match.url}/solicitudes`} component={Pendings} allowedRoles={['admin']} checkRole errorEndPoint={defaultStartPath} />
        {/*<PrivateRoute path={`${match.url}/solicitudes/:id`} component={Pendings} allowedRoles={['admin']} checkRole errorEndPoint={defaultStartPath} />*/}
        <Redirect to="/error" />
    </Switch>

)};