import React from 'react';
import './BottomThumbnail.scss';
import InsistentImg from "../InsistentImg";
import Thumbnail from '../Thumbnails';
import EditButton from '../EditButton';
import { isCorrectRole } from '../../components/PrivateRoute';
import { withPortalCtxt } from './../Portal/Context';
import FullScreenImg from "../FullScreenImg";
import FullScreenVideo from "../FullScreenVideo";
import { allowedVideoFormat, allowedImgFormat, visorTacUrl } from '../../constants/defaultValues';
import ButtonDelete from '../../components/RemoveButton'
import ButtonDownload from '../../components/DownloadButton'
import {getImgFromS3} from "../../api/registry";

const checkComponentsByExtension = (name) => {
    let split = name.split('.');
    const format = split[split.length-1];

    if(allowedVideoFormat.map(f=>f.toUpperCase()).includes(format.toUpperCase()))
        return {component:Thumbnail, type: format};

    if (allowedImgFormat.map(f=>f.toUpperCase()).includes(format.toUpperCase()))
        return {component: InsistentImg, type: 'img'};

    if('pdf'.toUpperCase() === format.toUpperCase())
        return {component: Thumbnail, type: 'pdf'};

    if('zip'.toUpperCase() === format.toUpperCase())
        return {component: Thumbnail, type: 'zip'};

    return {component: InsistentImg, type: 'img'}; //TODO Failed format

};

class BottomThumbnail extends React.Component{

    shouldComponentUpdate(prevProps){
        return this.props.media.url !== prevProps.media.url
    }


    handleOpen = () => {
      const url =
        visorTacUrl +
        "?token=" +
        localStorage.getItem("access_token") +
        "&history=" +
        this.props.idHistory +
        "&file_name=" +
        this.props.media.name +
        "&file_type=" +
        this.props.media.type;
      window.location.href = url;
    };

    handleClick = (e, type) => {
        e.preventDefault && e.preventDefault();
        e.stopPropagation && e.stopPropagation();
        if(type === 'img')
            this.props.handlePortalVisibility( true, ()=> 
            <FullScreenImg 
                multimedia={this.props.multimedia}
                media={this.props.media}
                idHistory={this.props.idHistory}
                type={this.props.type}/> );
        else
        if(allowedVideoFormat.includes(type))
            this.props.handlePortalVisibility(true, () => 
            <FullScreenVideo media={this.props.media}
                idHistory={this.props.idHistory}
                type={this.props.type}/>);
        else
        if(['pdf','zip'].includes(type))
            this.downloadFile()
    };

    downloadFile = () => {
        getImgFromS3(this.props.idHistory, this.props.media, this.props.type)
            .then( res => res.data.url)
            .then((url=> {
                let link = document.createElement("a");
                link.setAttribute('download', this.props.media.name);
                link.setAttribute('target', '_blank');
                link.href = url;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                link.remove();
            }));

    };

    render(){
        const { onDelete, media } = this.props;
        const { mediaType, url, name } = media;
        const Checked = checkComponentsByExtension(name);
        const ThumbnailComponent = Checked.component;
        const ThumbnailType = Checked.type;
        return(
            <div className="wrapped-thumbnail">
                <ThumbnailComponent
                    type={ThumbnailType}
                    src={url}
                    alt={name}
                    onClick={(e)=>this.handleClick(e,ThumbnailType)}
                    betweenName={
                        <div className={'thumbnailButtons'}>
                            { mediaType === 'tac'&& <EditButton onClick={this.handleOpen}/> }
                            <ButtonDownload onClick={this.downloadFile}/>
                            { (isCorrectRole(['admin', 'technician']) && onDelete) && <ButtonDelete onClick={onDelete} />}
                        </div>
                    }
                />
            </div>
        )
    }
}

export default withPortalCtxt(BottomThumbnail)