import React from 'react'
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

export default ({startIndex, endIndex, totalItemCount,selectedPageSize, pageSizes, changePageSize}) => {
    return (
        <div className={"d-flex justify-content-end align-items-center"}>
                <span className="text-muted text-small mr-1 pl-1">{`${startIndex}-${endIndex} of ${
                    totalItemCount
                    } `}
                </span>
            <UncontrolledDropdown className="d-inline-block">
                <DropdownToggle caret color="outline-dark" size="xs">
                    {selectedPageSize}
                </DropdownToggle>
                <DropdownMenu right>
                    {pageSizes.map((size, index) => {
                        return (
                            <DropdownItem
                                key={index}
                                onClick={() => changePageSize(size)}
                            >
                                {size}
                            </DropdownItem>
                        );
                    })}
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    )
}