import React from 'react';
import Dropzone from '../../../../components/Dropzone/index';
import IntlMessages from "../../../../util/IntlMessages";
import * as Api from "../../../../api/registry";
import { Button } from 'reactstrap'
import Thumbnail from '../../../../components/BottomThumbnail';
import STLViewer from '../../../../components/STLViewer';
import { getRegistrySuccess, uploadFilePatient, uploadFilePatientSuccess, uploadFilePatientError } from "../../../../redux/actions";
import { connect } from "react-redux";
import { isCorrectRole } from "../../../../components/PrivateRoute";
import { NotificationManager } from '../../../../components/ReactNotifications';

const checkAllowedFormat = (type) => {
    switch (type){
        case 'stlModel':
            return ['.stl'];
        case 'other':
            return ['.png', '.jpg', '.jpeg', '.mp4', '.mov'];
        case 'report':
            return ['.pdf', '.zip', '.rar'];
        case 'dicomViewer':
            return ['.zip', '.rar'];
        default:
            return ['.png', '.jpg', '.jpeg']
    }
};

const checkComponentsByTypes = (type) => {
    switch (type){
        case 'stlModel':
            return STLViewer;
        default:
            return Thumbnail
    }
};


class CommonUploadFiles extends React.Component {
    state = {
        fileList: [],
        error: false,
        loading: false,
        percentUploaded: 0,
    };
    ThumbnailType = null;
    constructor(props) {
        super(props);
        this.ThumbnailType= checkComponentsByTypes(this.props.TypeOfFiles);
        this.TypeOfFiles = this.props.TypeOfFiles;
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextState.fileList && nextState.fileList.length === 0) {
            if (this.state.loading)
                this.setState({loading: false})
        }
    }

    handleFiles = (files) => {
        let error = false;
        if(this.state.fileList !== files) {
            const fileList = [];
            const includesInMedia = this.props.multimedia.map(media => media.name);

            files.forEach(file => {
                if (!includesInMedia.includes(file.name))
                    fileList.push(file);
                else
                    error = 'name'
            });

            this.setState({
                fileList: fileList,
                error:error
            })
        }
    };

    uploadProgress = (e) => {
        if(e){
            this.setState({
                percentUploaded: e.loaded * 100 /e.total
            })
        }
    };

    uploadFile = async () => {
        this.props.uploadFilePatient({ files: this.state.fileList });
        this.setState({loading: !this.state.loading});
        const idHistory = this.props.match.params.id;
        const res = await Api.signFileByS3(idHistory, this.TypeOfFiles, this.state.fileList, this.uploadProgress);
        if (res.status === 200) {
            this.props.getRegistrySuccess(res.data);
            this.setState({
                fileList: [],
                percentUploaded: 0,
            });
            this.props.uploadFilePatientSuccess();
            NotificationManager.create({
                type: 'success',
                title: <IntlMessages id={'notify.file.uploaded'}/>,
                message: <IntlMessages id={'notify.file.uploaded.message'}/>
            });
        } else {
            this.props.uploadFilePatientError();
            NotificationManager.create({
                type: 'warning',
                title: <IntlMessages id={'notify.file.uploaded.failed'}/>,
                message: <IntlMessages id={'notify.file.uploaded.failed.message'}/>
            });
        }
    };

    deleteUploadedFile = async (media) => {
        const idHistory = this.props.match.params.id;
        const typeTest = media.mediaType;
        const res = await Api.deleteFileFromS3(idHistory, typeTest, media);
        if (res.status === 200) {
            this.props.getRegistrySuccess(res.data);
        }
    };

    render() {
        const {multimedia} = this.props;
        const {loading, fileList, error, percentUploaded} = this.state;
        return (
            <>
                { isCorrectRole(['admin', 'technician']) &&
                <>
                    <Dropzone
                        percentUploaded={percentUploaded}
                        loading={loading}
                        onFilesChanged={this.handleFiles}
                        fileList={fileList}
                        errorFileExtension={<IntlMessages id="forms.upload-files-error"/>}
                        errorFileName={<IntlMessages id="forms.upload-files-error-name"/>}
                        error={error}
                        accept={checkAllowedFormat(this.props.TypeOfFiles)}
                        title={<IntlMessages id="forms.upload-files"/>}
                    />
                    {
                        <div className={'w-100 position-relative float-left'}>
                            <Button className={'mt-3 float-right'} color={'primary'} onClick={this.uploadFile}
                                    disabled={this.state.fileList.length === 0 || loading}>
                                <IntlMessages id="forms.upload-files"/>
                            </Button>
                        </div>
                    }
                </>
                }
                <>
                    {
                        multimedia && multimedia.map(media => (
                            <this.ThumbnailType
                                key={`${media._id}`}
                                type={this.props.TypeOfFiles}
                                idHistory = {this.props.match.params.id}
                                media={media}
                                onDelete={() => this.deleteUploadedFile(media)}
                                multimedia={this.props.TypeOfFiles === 'other' && this.props.multimedia}
                            />
                        ))
                    }
                </>
            </>
        )
    }
}

export default connect(null,{getRegistrySuccess, uploadFilePatient, uploadFilePatientSuccess, uploadFilePatientError})(CommonUploadFiles)