import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import list from './lista';
import create from './crear';
import detail from './detalle';
import editar from './editar';

export default ({ match }) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/lista`} />
    <Route path={`${match.url}/lista`} component={list} />
    <Route path={`${match.url}/crear`} component={create} />
    <Route path={`${match.url}/editar/:id`} component={editar} />
    <Route
      path={`${match.url}/detalle/:id`}
      component={detail}
    />
    <Redirect to="/error" />
  </Switch>
);