import React from 'react';
import './styles.scss'

const DownloadButton = ({onClick, className, ...props}) => {
    return(
        <button {...props} onClick={onClick} className={`download-button ${className}`}>
            <i className="simple-icon-cloud-download"/>
        </button>
    )
};

export default DownloadButton;