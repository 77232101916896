import React from 'react';
import './index.scss';
import {CardBody, CardSubtitle, CardTitle} from "reactstrap";
import {parseToDayHour} from "../../util/Utils";
import {RemoveButtonFlatIcon} from'../RemovePatient'
import {isCorrectRole} from "../PrivateRoute";

class BottomRegistryButton extends React.Component{
    render() {
        const {registry, history, active, modalToggle} = this.props;
        return(
            <CardBody className={`p-4 w-50 w-sm-100 changeRegistry ${active ? 'active' : ''}`} onClick={() => history.push(registry._id)}>
                <div className={'d-flex w-100 flex-row justify-content-between align-items-center'}>
                    <div>
                        <CardTitle className={'mb-1'}>{registry.name}</CardTitle>
                        <CardSubtitle
                            className={'dident-text-light mb-1'}>{parseToDayHour(registry.createdAt)}
                        </CardSubtitle>
                    </div>
                    { ( isCorrectRole(['admin', 'technician']) && !active ) && <RemoveButtonFlatIcon toggle={()=>modalToggle(registry._id)}/> }
                </div>
            </CardBody>
        )
    }
}

export default BottomRegistryButton;