import axios from "axios";
import { apiUrl } from "../constants/defaultValues";
import Auth from "./auth";

const createUser = async (user) => await axios.post(apiUrl.concat("usersrequests/create"), user)
    .then((response) => response)
    .catch((err) => err);

const getUsers = async (page, limit) =>
    await Auth.refreshToken()
        .then(() => new Promise((resolve, reject) => {
            axios.get(apiUrl.concat(`usersrequests?limit=${limit}&page=${page}`), { headers: { "X-Auth-Token": localStorage.getItem("access_token") } })
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        }))
        .catch((err) => err);
//
// const getUsersByRole = async (page, limit, role) =>
//     await Auth.refreshToken()
//         .then(() => new Promise((resolve, reject) => {
//             axios.get(apiUrl.concat(`users?role=${role}&limit=${limit}&page=${page}`), { headers: { "X-Auth-Token": localStorage.getItem("access_token") } })
//                 .then((response) => resolve(response))
//                 .catch((err) => reject(err));
//         }))
//         .catch((err) => err);
//
// const getUser = async (id) =>
//     await Auth.refreshToken()
//         .then(() => new Promise((resolve, reject) => {
//             axios.get(apiUrl.concat(`users?_id=${id}`), { headers: { "X-Auth-Token": localStorage.getItem("access_token") } })
//                 .then((response) => resolve(response))
//                 .catch((err) => reject(err));
//         }))
//         .catch((err) => err);
//
// const getUsersPercentage = () =>
//     Auth.refreshToken()
//         .then(() =>
//             axios.get(apiUrl.concat(`users/percentage`), { headers: { "X-Auth-Token": localStorage.getItem("access_token") } })
//         )
//         .catch((err) => err);
//
// const getUsersAccessesPlatform = (payload) =>
//     Auth.refreshToken()
//         .then(() =>
//             axios.get(apiUrl.concat(`users/connections?startDate=${payload.startDate}&endDate=${payload.endDate}`), { headers: { "X-Auth-Token": localStorage.getItem("access_token") } })
//         )
//         .catch((err) => err);
//
//
const filterUsers = async (filter) =>
    await Auth.refreshToken()
        .then(() => new Promise((resolve, reject) => {
            axios.get(apiUrl.concat("usersrequests"), {
                params: filter,
                headers: { "X-Auth-Token": localStorage.getItem("access_token") },
            })
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        }))
        .catch((err) => err);

const deleteUser = async (id) =>
    await Auth.refreshToken()
        .then(() => new Promise((resolve, reject) => {
            axios.delete(apiUrl.concat(`usersrequests?_id=${id}`), { headers: { "X-Auth-Token": localStorage.getItem("access_token") } })
                .then((response) => resolve(response))
                .catch((err) => reject(err))
        }))
        .catch((err) => err);


const updateUser = async (user) =>
    await Auth.refreshToken()
        .then(() => new Promise((resolve, reject) => {
            axios.put(apiUrl.concat(`usersrequests?_id=${user.id}`), user, { headers: { "X-Auth-Token": localStorage.getItem("access_token") } })
                .then((response) => resolve(response))
                .catch((err) => reject(err))
        }))
        .catch((err) => err);

// const registerUser = async (user) => {
//     try {
//         return await axios.post(apiUrl.concat(`users/register`), user, { headers: { "X-Auth-Token": localStorage.getItem("access_token") } })
//     } catch (error) {
//         console.log('Error:' + error);
//     }
// };




export default {
    getUsers,
    createUser,
    // getUser,
    updateUser,
    filterUsers,
    deleteUser,
    // getUsersByRole,
    // getUsersPercentage,
    // getUsersAccessesPlatform,
    // registerUser
};