
class Utils {

    constructor(schema, obj){
        obj = obj ? obj : {};
        this.schema = schema;
        Object.keys(this.schema).forEach(key => {
            this[key] = obj[key] ? obj[key] : this.schema[key].defaultValue
        })
    }

    getData() {
        const toReturn = {};
        Object.keys(this.schema).forEach(key => {
            toReturn[key] = (this[key] && this[key].getData) ? this[key].getData() : this[key];
        });
        return toReturn;
    }




}

export default Utils;
