export const subHiddenBreakpoint=1440;
export const menuHiddenBreakpoint = 768;
export const MAX_YEAR_RANGE = 199;
export const MIN_YEAR_RANGE = 100;
export const defaultMenuType = 'menu-sub-hidden';//'menu-default'; //'menu-sub-hidden', 'menu-hidden'
export const defaultStartPath = '/app/pacientes/lista';
// export const visorTacUrl='https://dident3d.bluumi.net/angular/private/neoscope'
export const visorTacUrl='https://didentconnect.com/angular/private/neoscope'

export const defaultLocale='es';
export const localeOptions=[
    {id:'en',name:'English'},
    {id:'es',name:'Español'},
];

export const firebaseConfig = {
    apiKey: "AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg",
    authDomain: "gogo-react-login.firebaseapp.com",
    databaseURL: "https://gogo-react-login.firebaseio.com",
    projectId: "gogo-react-login",
    storageBucket: "gogo-react-login.appspot.com",
    messagingSenderId: "216495999563"
};

// export const apiUrl ="http://192.168.0.29:9001/"; //Localhost
// export const apiUrl ="http://localhost:9001/"; //Localhost
//export const apiUrl ="http://3.18.96.193:9001/"; // Servidor AWS
export const apiUrl ="https://didentconnect.com/api/"; // Servidor AWS

export const searchPath = "/app/pacientes/lista";

export const allowedVideoFormat = ['mp4', 'mov'];
export const allowedImgFormat = ['jpg', 'png', 'jpeg'];

// DateFormat options.
export const dateFormatOptions = {
    spanish: "dd/MM/yy",
    english: "MM/dd/yy"
}

export const DECRYPT_KEY = 'claveSecreta';
