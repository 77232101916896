import React from 'react';
import IntlMessages from "../../util/IntlMessages";

const ErrorMessage = ({message, className}) =>{
  return (
      <div className={`text-danger w-100 text-center ${className}`}>
          <IntlMessages id={message} defaultMessage={message}  />
      </div>
  )
};

export default ErrorMessage;