import axios from "axios";
import { apiUrl } from "../constants/defaultValues";
import { logoutUser } from '../redux/actions';
import history from './../history';
import store from './../redux/store';
import crypto from 'crypto';

const saveExpiresTimeInLocalStorage = (expires_in) =>{
    let now = Date.now();
    now = now + expires_in * 1000;
    localStorage.setItem('expires_in', new Date(now).toString())
};

const saveUserInLocalStorage = async (data) => {
    localStorage.setItem('user_id', data.access_token);
    localStorage.setItem('access_token', data.access_token);
    localStorage.setItem('refresh_token', data.refresh_token);
    data.expires_in && saveExpiresTimeInLocalStorage(data.expires_in);

    const loggedUser = await getLoggedUser();
    const cryptoRole = crypto.createHash('sha256').update(loggedUser.data.role ? loggedUser.data.role : 'patient').digest('hex');
    localStorage.setItem('user_role', cryptoRole);
};


const removeUserFromLocalStorage = () => {
    localStorage.removeItem('user_id');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('expires_in');
    localStorage.removeItem('user_role');
};

const refreshToken = () => {
    if(localStorage.getItem("refresh_token"))
        if(localStorage.getItem("expires_in") && new Date(localStorage.getItem("expires_in")) > new Date()) {
            return Promise.resolve(true);
        }
        else {
            return axios.post(apiUrl.concat("users/refresh"), {refresh_token: localStorage.getItem("refresh_token")})
                .then(response => saveUserInLocalStorage(response.data))
                .catch(error => {
                    if(error.response && (error.response.status === 400 || error.response.status === 401)) {
                        store.dispatch(logoutUser({history: history}));
                    }
                    return error
                });
        }
    else {
        removeUserFromLocalStorage();
        history.push('/login')
    }
};

const signInWithEmailAndPassword = (email, password) => {
    return new Promise((resolve, reject) => {
        axios.post(apiUrl.concat("users/login"), {
            user: email,
            password: password
        })
            .then( async response => {
                await saveUserInLocalStorage(response.data);
                resolve(response);
            })
            .catch(error => reject(error));
    })
};

const signOut = async () => {
    await refreshToken();
    return new Promise((resolve, reject) => {
        axios.post(apiUrl.concat("users/logout"), {}, {
            headers: {
                "X-Auth-Token": localStorage.getItem("access_token")
            },
        })
            .then(response => {
                removeUserFromLocalStorage();
                resolve(response)
            })
            .catch(error => {
                removeUserFromLocalStorage();
                reject (error);
            });
    })
};

const getLoggedUser = async () => {
    await refreshToken();
    return new Promise((resolve, reject) => {
        axios.get(apiUrl.concat("users/me"), {
            headers: { "X-Auth-Token": localStorage.getItem("access_token")},
        })
            .then(response => {
                return resolve(response)
            })
            .catch(error => reject(error));
    })
};

export default {
    signInWithEmailAndPassword,
    refreshToken,
    signOut,
    getLoggedUser
};