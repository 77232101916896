import User from './User';

const schema = {
    firstName: {
        defaultValue: '',
    },
    lastName: {
        defaultValue: '',
    }
};

class Admin extends User{

    constructor(obj){
        super(schema, obj);
        this.role = 'admin';
    }

}

export default Admin;