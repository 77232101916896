import React, { Component } from 'react';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption
} from 'reactstrap';
import './styles.scss';
import {withPortalCtxt} from "../Portal/Context";
import EditButton from '../../components/EditButton';
import InsistentImg from '../../components/InsistentImg';
import { NotificationManager } from '../ReactNotifications';
import { visorTacUrl } from '../../constants/defaultValues';

class CarouselClass extends Component {
    constructor(props) {
        super(props);
        this.state = { activeIndex: 0 };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
    }

    componentWillUpdate(nextProps){
        if(this.props.items !== nextProps.items)
            this.setState({activeIndex: 0})
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.props.items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.props.items.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }

    handleOpen = (e,media) => {
        // e.stopPropagation && e.stopPropagation();
        // e.preventDefault && e.preventDefault();
        const url =
        visorTacUrl +
        "?token=" +
        localStorage.getItem("access_token") +
        "&history=" +
        this.props.registry._id +
        "&file_name=" +
        media.name +
        "&file_type=" +
        media.type;
      window.location.href = url;
    };

    render() {
        const { items } = this.props;
        const { activeIndex } = this.state;
        const medias = items.map(el => el.media);
        const slides = medias.map((item) => {
            return (
                <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={`carousel-${item._id}`}
                >
                    <InsistentImg src={item.url} alt={item.name} _id={item._id} noWrap betweenName={
                        item.name !== "image no found" && item.mediaType === 'tac'&& <EditButton onClickCapture={(e)=>this.handleOpen(e,item)}/> }
                    />
                    {item.caption && <CarouselCaption captionText={item.caption} captionHeader={item.caption} />}
                </CarouselItem>
            );
        });

        return (
            <Carousel
                activeIndex={activeIndex}
                next={this.next}
                previous={this.previous}
            >
                {items.length > 1 && <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />}
                {slides}
                {items.length > 1 && <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />}
                {items.length > 1 && <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />}
            </Carousel>
        );
    }
}

export default withPortalCtxt(CarouselClass);