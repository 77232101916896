import axios from "axios";
import { apiUrl } from "../constants/defaultValues";
import Auth from "./auth";

const filterDiagnosticCenters = async (filter) =>
    await Auth.refreshToken()
        .then(()=> new Promise((resolve, reject) => {
            axios.get(apiUrl.concat("diagnosticCenters"),{ params: filter, headers:{ "X-Auth-Token": localStorage.getItem("access_token")} })
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        }))
        .catch((err) => err);


const getDiagnosticCenters = async (page, limit) =>
    await Auth.refreshToken()
        .then(()=>new Promise((resolve, reject) => {
            axios.get(apiUrl.concat("diagnosticCenters?page=", page-1, "&limit=", limit), {headers:{ "X-Auth-Token": localStorage.getItem("access_token")}})
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        }))
        .catch((err) => err);



const createDiagnosticCenter = async (diagnosticcenter) =>
    await Auth.refreshToken()
        .then( ()=> new Promise((resolve, reject) => {
            axios.post(apiUrl.concat("diagnosticCenters"), diagnosticcenter, {headers:{ "X-Auth-Token": localStorage.getItem("access_token")}})
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        }))
        .catch((err) => err);


const updateDiagnosticCenter = async (diagnosticcenter) =>
    await Auth.refreshToken()
        .then(()=>new Promise((resolve, reject) => {
            axios.put(apiUrl.concat("diagnosticCenters"), diagnosticcenter, {headers:{ "X-Auth-Token": localStorage.getItem("access_token")} , params: {_id: diagnosticcenter._id}})
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        }))
        .catch((err) => err);



const deleteDiagnosticCenter = async (id) =>
    await Auth.refreshToken()
        .then(()=>new Promise((resolve, reject) => {
            axios.delete(apiUrl.concat("diagnosticCenters"), {headers:{ "X-Auth-Token": localStorage.getItem("access_token")}, params:{ _id: id }})
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        }))
        .catch((err) => err);



const getCenter = async (id) =>
    await Auth.refreshToken()
        .then(()=> new Promise((resolve, reject) => {
            axios.get(apiUrl.concat("diagnosticCenters?_id=", id), { headers:{ "X-Auth-Token": localStorage.getItem("access_token")} })
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        }))
        .catch((err) => err);


export default {
    filterDiagnosticCenters,
    getDiagnosticCenters,
    deleteDiagnosticCenter,
    updateDiagnosticCenter,
    createDiagnosticCenter,
    getCenter
};