import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import HistoryList from '../../history/lista';
// import create from './crear';
// import edit from './editar';
import registry from '../../history/detalle';


export default ({ match }) => (
    <Switch>
        <Route exact path={`/app/pacientes/historial/:patient/registro/:id`} component={registry} />
        {/*<Route path={`/app/pacientes/historial/:id`} component={HistoryList} />*/}
        <Redirect to="/error" />
    </Switch>
);

// export default ({match}) => (<><p>nothen</p>{console.log(match)}</>)