import React from "react";
import IntlMessages from "../../../util/IntlMessages";
import {emptyString,parseToDayHour} from "../../../util/Utils";
import CommonUploadFiles from './sections/commonUploadFiles';
import {getRegistry, filterRegistries, deleteRegistry} from './../../../redux/registry/actions';
import {
    Row,
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import { injectIntl} from 'react-intl';
import { connect } from "react-redux";
import mouseTrap from "react-mousetrap";
import ContextMenuClass from '../../../components/ContextMenuClass';
import PageHeader from "../../../components/PageHeader";
import Summary from './sections/sumario';
import Tabs,{BadgeForTab} from '../../../components/Tabs'
import GetPatient from "../../../util/GetPatientFromReduxOrBD";
import {PortalCtxt} from "../../../components/Portal/Context";
import {NavLink} from "react-router-dom";
import {isCorrectRole} from "../../../components/PrivateRoute";
import RemoveRegistry, {BodyModal} from '../../../components/RemoveRegistry';
import Avatar from '../../../components/Avatar/index';

const calcEntryTab = (state) => {
    if(state)
        switch (state.tab) {
            case 'radiography':
                return 1;
            case 'other':
                return 2;
            case 'stlModel':
                return 3;
            case 'dicomViewer':
                return 4;
            case 'tac':
                return 5;
            default:
                return 0;
        }
    return 0
};

class HistoryList extends ContextMenuClass {
    constructor(props) {
        super(props);
        this.state = {
            entryTab : calcEntryTab(props.location.state),
            history: [],
            registry: {},
            removeModal: false,
            patientName: '',
            loading: false,
            dropdownSplitOpen: false,
        };
    }

    ifUserDoesntExist = (res) =>{
        if(!res.data)
            this.props.history.push('/app/pacientes/lista')
    };

    componentDidMount(){
        const patient = GetPatient(this.props.match.params.patient);
        this.props.filterRegistries({patientId: this.props.match.params.patient});
        this.props.getRegistry({_id: this.props.match.params.id}, this.ifUserDoesntExist);
        this.setState({
            patientName: patient.firstName ? `${patient.firstName} ${patient.lastName}`: emptyString(),
        });
    }

    componentWillUpdate(nextProps, nextState, nextContext){
        if(nextProps.patientsData !== this.props.patientsData){
            const patient =  nextProps.patientsData && nextProps.patientsData.find(pat => pat._id === this.props.match.params.patient);
            if(patient)
                this.setState({
                    patientName: `${patient.firstName} ${patient.lastName}`
                })

        }
        if(nextProps.registryData !== this.props.registryData){
            this.setState({ registry: nextProps.registryData })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.totalItemCount !== this.props.totalItemCount || prevState.selectedPageSize !== this.state.selectedPageSize) {
            this.setState({
                totalPage: Math.ceil(this.props.totalItemCount/this.state.selectedPageSize)
            });
        }

        if(prevProps.registryData !== this.props.registryData){
            if(this.state.registry !== this.props.registryData)
                this.setState({ registry: this.props.registryData })
        }

        if(this.props.match.params.id !== prevProps.match.params.id){
            this.props.getRegistry({_id: this.props.match.params.id});
        }
    }

    toggleSplit = () => {
        this.setState(prevState => ({
            dropdownSplitOpen: !prevState.dropdownSplitOpen
        }));
    };

    renderActions = () => {
        return(
            isCorrectRole(['admin', 'technician']) && <ButtonDropdown
                className="w-xs-100"
                isOpen={this.state.dropdownSplitOpen}
                toggle={this.toggleSplit}
            >
                <DropdownToggle
                    caret
                    color="primary"
                    className="dropdown-toggle-split pl-4 pr-4 text-uppercase w-xs-100"
                >
                    {<span className='mr-2'><IntlMessages id="general.actions" /></span>}
                </DropdownToggle>

                <DropdownMenu right>
                    <NavLink to={`/app/pacientes/editar/${this.props.match.params.patient}`}>
                        <DropdownItem>
                            <IntlMessages id="patients.edit" />
                        </DropdownItem>
                    </NavLink>
                    <NavLink to={`/app/pacientes/historial/${this.props.match.params.patient}/crear`}>
                        <DropdownItem>
                            <IntlMessages id="registry.button-add" />
                        </DropdownItem>
                    </NavLink>
                    <NavLink to={`/app/pacientes/historial/${this.props.match.params.patient}/editar/${this.props.match.params.id}`}>
                        <DropdownItem>
                            <IntlMessages id="registry.button-edit" />
                        </DropdownItem>
                    </NavLink>

                    <RemoveRegistry toggle={this.toggle}/>
                </DropdownMenu>
            </ButtonDropdown>
        )
    };

    onDeleteSuccessItSelf = () => {
        this.toggle();
        this.props.history.push(`/app/pacientes/lista`);
    };

    handleDeleteItSelf = () => {
        this.props.deleteRegistry(this.props.match.params.id, this.onDeleteSuccessItSelf);
    };

    renderTitle(){
        const patient = GetPatient(this.props.match.params.patient);
        return (<>
            <div className="d-md-flex">
                <div>
                    { patient.avatar && <Avatar className="img_avatar" patient={patient} /> }
                </div>
                <div>
                    <h1 className={'ml-2 mb-2 pr-2 pb-0'}>
                        { this.state.registry.name || emptyString() }
                        <div>
                            <small className={'dident-text-secondary'}>
                                { this.state.registry.createdAt ? parseToDayHour(this.state.registry.createdAt) : emptyString() }
                            </small>
                        </div>
                        <div>
                            <small className={'dident-text-light'}>{this.state.patientName}</small>
                        </div>
                    </h1>
                </div>
            </div>
        </>)
    }

    renderHeader() {
        return (
            <PageHeader
                heading={this.renderTitle()}
                match={this.props.match}
                actions={this.renderActions()}
                finder={undefined}
            />
        );
    }

    includeTabHeaders = () => {
        const { registry } = this.state;
        let badges = {
            summary: (registry && registry.multimediaCount )? registry.multimediaCount.summary || 0 : 0,
            radiography: (registry && registry.multimediaCount )? registry.multimediaCount.radiography || 0 : 0,
            other: (registry && registry.multimediaCount )? registry.multimediaCount.other || 0 : 0,
            stlModel: (registry && registry.multimediaCount )? registry.multimediaCount.stlModel || 0 : 0,
            dicomViewer: (registry && registry.multimediaCount )? registry.multimediaCount.dicomViewer || 0 : 0,
            tac: (registry && registry.multimediaCount )? registry.multimediaCount.tac || 0 : 0,
            report: (registry && registry.multimediaCount )? registry.multimediaCount.report || 0 : 0,
        };

        return Object.keys(badges).map(key=>{
            return (
                {
                    label: <IntlMessages id={`history.${key}`} />,
                    badge: badges[key] > 0 ? <BadgeForTab className={`badge-${key}`}>{badges[key]}</BadgeForTab> : undefined
                }
            )
        });
    };

    toggle = () => {
        this.setState(prevState => ({
            removeModal: !prevState.removeModal
        }));
    };

    render() {

        const { match, registriesData, history } = this.props;
        const { registry,entryTab, removeModal } = this.state;

        return (
            <div className="disable-text-selection">
                {this.renderHeader()}
                <Row>
                    <PortalCtxt>
                        <Tabs
                            active={ entryTab }
                            headers={this.includeTabHeaders()}
                        >
                            <Summary
                                modalToggle={this.toggle}
                                key={'Summary'}
                                registry={registry}
                                registries={registriesData}
                                history={history}
                                match={match}
                            />

                            <CommonUploadFiles
                                key={'radiography'}
                                TypeOfFiles={"radiography"}
                                match={match}
                                multimedia={registry.multimedia && registry.multimedia.filter(file => file.mediaType === "radiography")}
                            />

                            <CommonUploadFiles
                                key={'other'}
                                TypeOfFiles={"other"}
                                match={match}
                                multimedia={registry.multimedia && registry.multimedia.filter(file => file.mediaType === "other")}
                            />

                            <CommonUploadFiles
                                key={'stlModel'}
                                TypeOfFiles={"stlModel"}
                                match={match}
                                multimedia={registry.multimedia && registry.multimedia.filter(file => file.mediaType === "stlModel")}
                            />

                            <CommonUploadFiles
                                key={'dicomViewer'}
                                TypeOfFiles={"dicomViewer"}
                                match={match}
                                multimedia={registry.multimedia && registry.multimedia.filter(file => file.mediaType === "dicomViewer")}
                            />

                            <CommonUploadFiles
                                key={'tac'}
                                TypeOfFiles={"tac"}
                                match={match}
                                multimedia={registry.multimedia && registry.multimedia.filter(file => file.mediaType === "tac")}
                            />

                            <CommonUploadFiles
                                key={'report'}
                                TypeOfFiles={"report"}
                                match={match}
                                multimedia={registry.multimedia && registry.multimedia.filter(file => file.mediaType === "report")}
                            />
                        </Tabs>

                        <BodyModal
                            onClick={this.handleDeleteItSelf}
                            isOpen={removeModal}
                            toggle={this.toggle}
                            title={<IntlMessages id="general.remove.confirm.title" />}
                        />

                    </PortalCtxt>

                </Row>
            </div>
        )

    }
}

const mapStateToProps = ({patients, registries}) => {
    return {
        patientsData: patients.data,
        registriesData: registries.data,
        registryData: registries.single,
    }
};

export default injectIntl(mouseTrap(connect(mapStateToProps, {getRegistry,filterRegistries, deleteRegistry})(HistoryList)))