import React from 'react';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {toCamelCase} from '../../util/Utils'
import IntlMessage from '../../util/IntlMessages'
import api from "../../api/usersRequest";
import apiUser from "../../api/users";
import {
    GET_USERS_REQUEST,
    CREATE_USER_REQUEST,
    DELETE_USER_REQUEST,
    VALIDATE_USER_REQUEST,
    FILTER_USER_REQUEST,
    UPDATE_USER_REQUEST,
} from "../../constants/actionTypes";

import {
    createUserRequestSuccess, deleteUserRequestSuccess,
    getUsersRequestSuccess, userRequestFailed, updateUserRequestSuccess
} from "./actions";

import History from '../../history';
import { NotificationManager } from '../../components/ReactNotifications'
import addUnexpectedError  from '../../components/UnexpectedErrorNotify'

function* getUsersRequest({ payload }) {
    try {

        const { page, limit } = payload;
        const response = yield call(api.getUsers, page, limit);
        if(response.status === 200) {
            yield put(getUsersRequestSuccess(response.data, response.headers.total))
        } else {
            console.log('Get users failed : ', response);
        }
    } catch (error) {
        console.log('Get users  failed : ', error);
    }
}

function* createUserRequest({ payload }) {
    try {
        const response = yield call(api.createUser, payload);
        if(response.status === 201) {
            yield put(createUserRequestSuccess(response.data));
            History.push(`/app/login`);
            NotificationManager.create({
                type: 'success',
                title: <IntlMessage id={'notify.success.userRequestCreateTitle'}/>,
                message: <IntlMessage id={'notify.success.userRequestCreateMessage'}/>,
            });
        } else {
            yield put(userRequestFailed());
            NotificationManager.create({
                type: 'error',
                title: <IntlMessage id={`notify.error.${toCamelCase(response.request.response)}`}/>,
            });
        }
    } catch (error) {
        addUnexpectedError();
        console.log('Create user  failed : ', error);
    }
}

function* updateUserRequest({payload}) {
    try {
        const {user} = payload;
        const response = yield call(api.updateUser, user);
        if(response.status === 202) {
            yield put(updateUserRequestSuccess(response.data));
            History.push(`/app/usuarios/solicitudes`);
        } else {
            console.log('Edit request user failed: ', response);
        }
    } catch (error) {
        console.log('Edit request user failed: ', error);
    }
}

function* deleteUserRequest({payload}) {
    try {
        const id = payload;
        const response = yield call(api.deleteUser, id);
        if(response.status === 204) {
            yield put(deleteUserRequestSuccess(id));
        } else {
            console.log('Delete user failed: ', response);
        }
    } catch (error) {
        console.log('Delete user failed: ', error);
    }
}

function* validateUserRequest({payload}) {
    const {_id, ...user} = payload;
    try {
        let response = yield call(apiUser.createUser, {...user, avatar:'-',idKeyrock:'-'});
        if(response.status === 201) {
            yield put(deleteUserRequestSuccess(_id));
        }
    } catch (error) {
        console.log('Delete user failed: ', error);
    }
}


const filterUserRequestAsync = async (filter) =>
    await api.filterUsers(filter)
        .then(users => users)
        .catch(error => error);

function* filterUserRequest({payload}) {
    try {
        const response = yield call(filterUserRequestAsync, payload.filter);
        if(response.status === 200) {
            yield put(getUsersRequestSuccess(response.data, response.headers.total));
        } else {
            console.log('Filter users failed : ', response);
        }
    } catch (error) {
        console.log('Filter users failed : ', error);
    }
}


export function* watchGetUsersRequest() {
    yield takeEvery(GET_USERS_REQUEST, getUsersRequest);
}

export function* watchValidateUsersRequest() {
    yield takeEvery(VALIDATE_USER_REQUEST, validateUserRequest);
}

export function* watchCreateUserRequest() {
    yield takeEvery(CREATE_USER_REQUEST, createUserRequest);
}

export function* watchFilterUsersRequest() {
    yield takeEvery(FILTER_USER_REQUEST, filterUserRequest);
}

export function* watchUpdateUserRequest() {
    yield takeEvery(UPDATE_USER_REQUEST, updateUserRequest);
}

export function* watchDeleteUserRequest() {
    yield takeEvery(DELETE_USER_REQUEST, deleteUserRequest);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetUsersRequest),
        fork(watchCreateUserRequest),
        fork(watchFilterUsersRequest),
        fork(watchUpdateUserRequest),
        fork(watchDeleteUserRequest),
        fork(watchValidateUsersRequest),

    ]);
}