import React, { Component, Fragment } from "react";
import IntlMessages from "../../../util/IntlMessages";
import DefaultUser from '../../../assets/img/avatarImg.png'
import { Colxx } from "../../../components/CustomBootstrap";
import PageHeader from '../../../components/PageHeader';
import {
    Row,
    Card,
    CardBody,
    ButtonDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
} from "reactstrap";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import mouseTrap from "react-mousetrap";
import { getUser, deleteUser } from "../../../redux/users/actions";

import { roles } from "../../../data/roles";
import { isCorrectRole } from "../../../components/PrivateRoute";
import PatientDetailCard from './PatientDetailCard';

class UserDetail extends Component {
    constructor(props) {
        super(props);

        const rol = roles.find(rol => rol.url === this.props.match.params.role);

        this.toggleSplit = this.toggleSplit.bind(this);
        this.state = {
            dropdownSplitOpen: false,
            originalUser: null,
            firstName: "",
            lastName: "",
            collegiateNumber: "",
            clinicName: "",
            password: "",
            repeatPassword: "",
            diagnosticCenters: [],
            email: "",
            currentRole: rol,
            disabled: true,
            avatar: '',
            DNI: '',
            cif: '',
            patients: []
        };

        //only admins can access to admin/technician crud
        if (!this.isProfile() && ['admin', 'technician'].includes(this.state.currentRole.key) && !isCorrectRole(['admin'])) {
            this.props.history.push('/app/usuarios');
        }
    }
    componentDidMount() {

        const user = this.props.users ? Array.isArray(this.props.users) ? this.props.users.find(user => user._id === this.props.match.params.id) : this.props.users : undefined;

        this.props.getUser(this.props.match.params.id);

        if (user) {
            this.setState({
                ...user
            })
        }
    }

    componentDidUpdate(prevProps, prevState, prevContext) {
        if (this.props.user !== this.state.originalUser && prevProps.user !== this.props.user)
            this.setState({
                ...this.props.user,
                originalUser: this.props.user
            })
    }

    toggleSplit() {
        this.setState(prevState => ({
            dropdownSplitOpen: !prevState.dropdownSplitOpen
        }));
    }

    onDeleteUser = () => {
        const { deleteUser, match } = this.props;
        deleteUser(match.params.id);
        this.props.history.push(`/app/usuarios/${this.state.currentRole.url}`);
    };

    isProfile = () => {
        return this.props.match.url === '/app/profile';
    };

    getUrlForEdit = () => {
        return this.isProfile() ?
            `/app/profile/editar` :
            `/app/usuarios/${this.state.currentRole.url}/editar/${this.props.match.params.id}`;

    };

    renderActions() {
        return (
            isCorrectRole(['admin', 'technician', 'doctor', 'clinic']) && <ButtonDropdown
                className="w-xs-100"
                isOpen={this.state.dropdownSplitOpen}
                toggle={this.toggleSplit}
            >
                <DropdownToggle
                    caret
                    color="primary"
                    className="dropdown-toggle-split pl-4 pr-4 text-uppercase w-xs-100"
                >
                    {<span className='mr-2'><IntlMessages id="general.actions" /></span>}
                </DropdownToggle>

                <DropdownMenu right>
                    <NavLink to={`/app/usuarios/${this.state.currentRole.url}`}>
                        <DropdownItem>
                            <IntlMessages id="general.back" />
                        </DropdownItem>
                    </NavLink>
                    <NavLink to={this.getUrlForEdit()}>
                        <DropdownItem>
                            <IntlMessages id="general.edit" />
                        </DropdownItem>
                    </NavLink>
                    {!this.isProfile() && <DropdownItem onClick={this.onDeleteUser} >
                        <IntlMessages id="general.delete" />
                    </DropdownItem>}
                </DropdownMenu>
            </ButtonDropdown>
        );
    }

    render() {
        const { key } = this.state.currentRole;

        return (
            <Fragment>

                <PageHeader
                    heading={<IntlMessages id={`menu.${this.state.currentRole.url}`} />}
                    match={this.props.match}
                    actions={this.renderActions()}
                    role={this.state.currentRole.url}
                />

                <Row>
                    {!this.props.loading ?
                        <Colxx xxs="12" className="mb-3">
                            <Card>
                                <CardBody>
                                    <Row>
                                        {this.state.avatar && <Colxx sm="2" className="avatar">
                                            <img
                                                alt={this.state.firstName ? this.state.firstName : this.state.clinicName}
                                                src={this.state.avatar.length > 4 ? this.state.avatar : DefaultUser}
                                                width={100}
                                            />
                                        </Colxx>}
                                        <Colxx>
                                            <Row>
                                                <Colxx>
                                                    <h1 className="mb-4">
                                                        {key !== 'clinic' ? this.state.firstName.concat(" ", this.state.lastName)
                                                            : this.state.clinicName}
                                                    </h1>

                                                    {this.state.avatar && <><p className="text-muted text-small mb-2"><IntlMessages id={`forms.${key === 'clinic' ? 'cif' : 'dni'}`} /></p>
                                                        <p className="mb-3">{this.state.DNI}</p></>}

                                                    {key === 'doctor' &&
                                                        <><p className="text-muted text-small mb-2"><IntlMessages id="forms.collegiateNumber" /></p>
                                                            <p className="mb-3">{this.state.collegiateNumber}</p></>
                                                    }
                                                    <p className="text-muted text-small mb-2"><IntlMessages id="forms.email" /></p>
                                                    <p className="mb-3">{this.state.email}</p>

                                                    <p className="text-muted text-small mb-2"><IntlMessages id="forms.disabled" /></p>
                                                    <p className="mb-3">{this.state.disabled ? <IntlMessages id="general.yes" /> : <IntlMessages id="general.no" />}</p>
                                                </Colxx>
                                            </Row>
                                            <Row>
                                                <Colxx sm={12} md={3}>
                                                    <p className="text-muted text-small mb-2"><IntlMessages id="forms.diagnosticCenters" /></p>
                                                    <ul>
                                                        {this.state.diagnosticCenters.map((center, i) => <li key={i} className="mb-3">{center.name}</li>)}
                                                    </ul>
                                                </Colxx>
                                                <Colxx sm={12} md={6}>
                                                    <p className="text-muted text-small mb-2"><IntlMessages id="forms.patients" /></p>
                                                    <ul className="pl-0">
                                                        {this.state.patients.reverse().map((patient, i) => (
                                                            <PatientDetailCard patient={patient} />
                                                        ))}
                                                    </ul>
                                                </Colxx>
                                            </Row>
                                        </Colxx>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Colxx>
                        : <div className="loading" />
                    }
                </Row>
            </Fragment>
        );
    }
}

const mapStateToProps = ({ users }) => ({
    user: users.data,
    loading: users.loading,
    users: users.data
});


export default injectIntl(mouseTrap(connect(mapStateToProps, { getUser, deleteUser })(UserDetail)))