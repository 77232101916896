import {
    GET_PATIENTS,
    GET_PATIENTS_SUCCESS,
    CREATE_PATIENT,
    CREATE_PATIENT_KEYROCK,
    CREATE_PATIENT_KEYROCK_SUCCESS,
    CREATE_PATIENT_SUCCESS,
    DELETE_PATIENT,
    DELETE_PATIENTS_SUCCESS,
    UPDATE_PATIENT,
    UPDATE_PATIENT_SUCCESS,
    FILTER_PATIENTS,
    UPLOAD_FILE_PATIENT,
    UPLOAD_FILE_PATIENT_SUCCESS,
    UPLOAD_FILE_PATIENT_ERROR,
} from "../../constants/actionTypes";

export const updatePatient = (formData) => ({
    type: UPDATE_PATIENT,
    payload: formData
});

export const updatePatientSuccess = (patient) => ({
    type: UPDATE_PATIENT_SUCCESS,
    payload: patient
});

export const deletePatient = (formData) => ({
    type: DELETE_PATIENT,
    payload: formData
});

export const deletePatientSuccess = (id) => ({
    type: DELETE_PATIENTS_SUCCESS,
    payload: id
});

export const getPatients = (page = 1, limit = 8) => ({
    type: GET_PATIENTS,
    payload: {page, limit}
});

export const filterPatients = (filters) => ({
    type: FILTER_PATIENTS,
    payload: {filters}
});

export const getPatientsSuccess = (patients, total) => ({
    type: GET_PATIENTS_SUCCESS,
    payload: {patients, total}
});

export const createPatient = (formData) => ({
    type: CREATE_PATIENT,
    payload: formData
});

export const createPatientSucess = patient => ({
    type: CREATE_PATIENT_SUCCESS,
    payload: patient
});

export const createPatientKeyrock = (id) => ({
    type: CREATE_PATIENT_KEYROCK,
    payload: id
});

export const createPatientKeyrockSuccess = patient => ({
    type: CREATE_PATIENT_KEYROCK_SUCCESS,
    payload: patient
});

export const uploadFilePatient = (files) => ({
    type: UPLOAD_FILE_PATIENT,
    payload: files
})

export const uploadFilePatientSuccess = () => ({
    type: UPLOAD_FILE_PATIENT_SUCCESS,
})

export const uploadFilePatientError = () => ({
    type: UPLOAD_FILE_PATIENT_ERROR,
})