import React, { } from "react";
import IntlMessages from "../../../../util/IntlMessages";
import { Colxx } from "../../../../components/CustomBootstrap";
import CustomSelectInput from "../../../../components/CustomSelectInput";

import {
    FormGroup,
    Label
} from "reactstrap";
import { Async as AsyncSelect } from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { updateRegistry } from '../../../../redux/actions';
import store from '../../../../redux/store';

class AuthDoctorsCombo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
            registry: this.props.registry,
            okOption: this.props.onOkOption,
            originalValue: this.props.value,
        }
    }

    componentWillUpdate(nextProps){
        if (this.props.onOkOption !== nextProps.onOkOption && this.state.okOption !== nextProps.onOkOption){
            this.setState({
                okOption: nextProps.onOkOption,
            })
        }
    }
    
    componentDidUpdate = () => {
        if(this.state.okOption === true) {

            const prevUsers = this.state.originalValue;
            const registry = this.state.registry;
            const authDoctors = this.state.value.map(user => 
                 ({id: user.key, enabled: (!prevUsers.includes(user) ? true : user.enabled) }));
            store.dispatch(updateRegistry({_id: registry._id, authDoctors }, false));
            this.setState({
                okOption: false,
            }, this.props.onToggle);
            
            // This is provisional
            //TODO: Improve AuthDoctor update
            setTimeout(() => window.location.reload(), 1000);
        }
    };

    handleChangeMulti = (selectedOptions) => {
        this.setState({value: selectedOptions});
    };

    render() {
        return (<Colxx sm={12}>
            <FormGroup>
                <Label for="authDoctors">
                    <IntlMessages id="patients.authDoctors" />
                </Label>
                <AsyncSelect
                    key={'field.diagnosticCenters'}
                    components={{ Input: CustomSelectInput }}
                    className="react-select"
                    classNamePrefix="react-select"
                    isMulti
                    name="authDoctors"
                    id="authDoctors"
                    placeholder={this.props.messages["forms.authDoctors"]}
                    value={this.state.value}
                    onChange={(opt) => this.handleChangeMulti(opt)}
                    defaultOptions
                    cacheOptions
                    loadOptions={this.props.loadOptions}
                />
            </FormGroup>
        </Colxx>)
    }
}


export default AuthDoctorsCombo;