import {
    GET_DIAGNOSTICCENTERS,
    CREATE_DIAGNOSTICCENTER,
    CREATE_DIAGNOSTICCENTER_SUCCESS,
    UPDATE_DIAGNOSTICCENTER_SUCCESS,
    DELETE_DIAGNOSTICCENTER_SUCCESS,
    GET_DIAGNOSTICCENTERS_SUCCESS,
    GET_CENTER,
    GET_CENTER_SUCCESS
} from "../../constants/actionTypes";

const INIT_STATE = {
    data: [],
    loading: false,
    totalItemCount: 0,
    page:1,
    limit:8
};

export default (state = INIT_STATE, action) => {
    let index, aux;
    switch (action.type) {
        case GET_DIAGNOSTICCENTERS:
            return { ...state, loading: true };

        case GET_DIAGNOSTICCENTERS_SUCCESS:
            return { ...state, loading: false, data: action.payload.diagnosticcenters, totalItemCount: action.payload.total };

        case UPDATE_DIAGNOSTICCENTER_SUCCESS:
            return { ...state, loading: false};

        case DELETE_DIAGNOSTICCENTER_SUCCESS:
            aux = state.data;
            index = state.data.indexOf(
                state.data.find(obj => obj._id === action.payload)
            );
            if(index > -1)
                aux.splice(index,1);
            return { ...state, data: [...aux], loading: false};

        case GET_CENTER:
            return { ...state, loading: true };

        case GET_CENTER_SUCCESS:
            return { ...state, loading: false, data: action.payload };

        default:
            return { ...state };
    }
}