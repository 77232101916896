
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import Idm from "../../api/auth";
import {
    LOGIN_USER,
    REGISTER_USER,
    LOGOUT_USER,
    GET_LOGGED_USER
} from '../../constants/actionTypes';

import {
    loginUserSuccess,
    registerUserSuccess,
    getLoggedUserSuccess,
    loginUserFailed
} from './actions';

import auth from '../../api/auth'

const loginWithEmailPasswordAsync = async (email, password) =>
    await Idm.signInWithEmailAndPassword(email, password)
        .then(authUser => authUser)
        .catch(error => error);


function* loginWithEmailPassword({ payload }) {
    const { email, password } = payload.user;
    const { history } = payload;
    try {
        const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
        if (loginUser.status === 200) {
            yield put(loginUserSuccess(loginUser));
            history.push('/');
        }
        else {
            yield put(loginUserFailed());
        }
    } catch (error) {
        // catch throw
        console.log('login error : ', error)
    }
}

const registerWithEmailPasswordAsync = async (email, password) =>
    await auth.createUserWithEmailAndPassword(email, password)
        .then(authUser => authUser)
        .catch(error => error);

function* registerWithEmailPassword({ payload }) {
    const { email, password } = payload.user;
    const { history } = payload;
    try {
        const registerUser = yield call(registerWithEmailPasswordAsync, email, password);
        if (!registerUser.message) {
            localStorage.setItem('user_id', registerUser.user.uid);
            yield put(registerUserSuccess(registerUser));
            history.push('/')
        } else {
            // catch throw
            console.log('register failed :', registerUser.message)
        }
    } catch (error) {
        // catch throw
        console.log('register error : ', error)
    }
}

function* getLoggedUser() {
    try {
        const response = yield call(Idm.getLoggedUser);
        if(response.status === 200){
            yield put(getLoggedUserSuccess(response.data));
        } else {
            console.log('Logged user could not be retrieved. Error: ', response.error)
        }
    } catch (error) {
        console.log('Logged user could not be retrieved. Error: ', error)
    }
}



const logoutAsync = async (history) => {
    return await Idm.signOut().then(authUser => authUser ).catch(error => error);
};

function* logout({payload}) {
    const { history } = payload;
    try {
        yield call(logoutAsync, history);
        history.push("/login")
    } catch (error) {
    }
}



export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchGetLoggedUser(){
    yield takeEvery(GET_LOGGED_USER, getLoggedUser);
}


export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
        fork(watchGetLoggedUser),
    ]);
}