import React from 'react';
import { Badge } from "reactstrap";
import './index.css'
const multimediaShortcut = {
    radiography: 'Rx',
    stlModel: 'M',
    dicomViewer: 'V',
    tac: 'T',
    other: 'O',
    report: 'R',
};

const MultimediaBadge = ({multimedia, key, ...rest}) => {
    return <Badge {...rest} alt={multimedia} key={key} className={`badge badge-${multimedia}`} pill  style={{cursor: 'pointer'}}>{multimediaShortcut[multimedia]}</Badge>} ;


export default MultimediaBadge;