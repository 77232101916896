import React, { Component } from 'react';
// import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
import './style.scss'
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import IntlMessages from "../../util/IntlMessages";
import { Button } from 'reactstrap';
import PageHeader from './../../components/PageHeader'
import History from './../../history';
import {connect} from 'react-redux';
import {createLegalTexts, getLegalTexts, updateLegalTexts} from '../../redux/legal/actions'
import Editor from "nib-core";
import { convertToHTML, convertFromHTML } from 'nib-converter';

const defaultState = {
    "doc": {
        "type": "doc",
        "content": [
            {
                "type": "paragraph"
            }
        ]
    },
    "selection": {
        "type": "text",
        "anchor": 1,
        "head": 1
    }
};

const styleConfig = {
    wrapper: () => ({
      minHeight: 500,
      width: "100%"
    }),
    editor: () => ({
      height: "calc(500px - 44px)",
      padding: 15,
      overflowX: 'hidden',
      overflowY: 'auto',
      width: "100%"
    })
  };

class EditorConvertToHTML extends Component {
    state = {
        editorState: undefined,
        action: 'create',
        type: this.props.location.pathname.split("/")[3],
    };
    
    componentWillMount(){
        const type = this.props.location.pathname.split("/")[3];
        if(!this.props[type] || this.props[type] === '') 
        this.props.getLegalTexts();
        
        if(this.props[type]){
            this.setState({
                editorState: convertFromHTML(JSON.parse(this.props[type])),
                action: 'update'
            });
        }
    }
    
    componentDidUpdate(prevProps) {
        const type = this.props.location.pathname.split("/")[3];
        if (this.state.type !== prevProps.location.pathname.split("/")[3]) {
            this.setState({
                editorState: undefined,
                type: this.props.location.pathname.split("/")[3],
            }, () => {
                const text = this.props[type] ? JSON.parse(this.props[type]) : '';
                if(text) {
                    const editorState = convertFromHTML(text);
                    this.setState({
                        editorState: editorState,
                        action: 'update'
                    });
                }
                else
                this.setState({
                    editorState: defaultState,
                    action: 'create'
                });
            })
        }
        if (this.props[type] !== prevProps[type] || type !== prevProps.location.pathname.split("/")[3]) {
            const text = this.props[type] ? JSON.parse(this.props[type]) : '';
            if(text) {
                const editorState = convertFromHTML(text);
                this.setState({
                    editorState: editorState,
                    action: 'update'
                });
            }
            else
            this.setState({
                editorState: defaultState,
                action: 'create'
            });
        }
    }
    
    goBack = () =>{
        History.goBack()
    };
    
    submit = () => {
        const {action, editorState} = this.state;
        if(action) {
            if(action === 'create')
            this.props.createLegalTexts({
                type: this.props.location.pathname.split("/")[3],
                text: JSON.stringify(convertToHTML((editorState).doc))
            });
            else if(action === 'update')
            this.props.updateLegalTexts({
                type: this.props.location.pathname.split("/")[3],
                text: JSON.stringify(convertToHTML((editorState).doc))
            });
        }
    };
    
    
    onChangeEditor = (state) =>{
        this.setState({
            editorState: state
        })
    }
    
    render(){
        const {editorState} = this.state;
        const { location, match } = this.props;
        match.path = match.path.replace('editor-de-texto',location.pathname.split("/")[3] );
        match.url = match.url.replace('editor-de-texto',location.pathname.split("/")[3] );
        const heading = <h1><IntlMessages id={`menu.${location.pathname.split("/")[3]}`} /></h1>;
        return(
            <div>
            <PageHeader match={match} heading={heading}/>

            {editorState !== undefined 
            ? <Editor
            styleConfig={styleConfig}
            defaultValue={editorState} 
            onChange={this.onChangeEditor}
            />
            : null
            }
        
            <div className="float-right mt-3 mb-2">
            <Button color="default" className={'mr-2'} onClick={this.goBack} >
            <IntlMessages id="general.cancel"  />
            </Button>
            <Button color="primary" onClick={this.submit}>
            <IntlMessages id="general.accept" />
            </Button>
            </div>
            
            </div>
            )
        }
        
    }

    
    const mapStateToProps = ({legal}) => ({
        condition: legal.condition,
        legal: legal.legal,
        loading: legal.loading,
        privacy: legal.privacy,
    });
    
    export default connect(mapStateToProps, {createLegalTexts, updateLegalTexts, getLegalTexts})(EditorConvertToHTML)