import React from 'react';
import ZipThumb from '../../assets/img/Files-Icons/zip.png';
import Mp4Thumb from '../../assets/img/Files-Icons/mp4.png';
import MovThumb from '../../assets/img/Files-Icons/mov.png';
import PDFThumb from '../../assets/img/Files-Icons/pdf.png';
import RarThumb from '../../assets/img/Files-Icons/rar.png';
import STLThumb from '../../assets/img/Files-Icons/stl.png';
import './style.scss'


const chooseThumbByType = (type) =>{
    switch (type){
        case 'mp4':
            return Mp4Thumb;
        case 'mov':
            return MovThumb;
        case 'rar':
            return RarThumb;
        case 'zip':
            return ZipThumb;
        case 'pdf':
            return PDFThumb;
            case 'stl':
            return STLThumb;
        default:
            return ZipThumb;
    }
};

class ZipThumbnail extends React.Component{

    render(){
        const { onClick, alt, type, betweenName } = this.props;
        return(
            <div className={'thumbnail-wrapper'}>
                <img
                    src={chooseThumbByType(type)}
                    className={onClick ? 'dident-cursor-pointer insistent-img-hover': '' }
                    onClick={ onClick }
                    alt={ `thumbnail-${alt}` }
                />
                {betweenName && betweenName}
                <p className={'alt'}>{alt}</p>
            </div>
        )
    }
}

export default ZipThumbnail;