import React from 'react';
import PageHeader from '../../../components/PageHeader';
import IntlMessages from './../../../util/IntlMessages';
import {connect} from 'react-redux';
import { getLegalTexts } from '../../../redux/legal/actions';
import {isCorrectRole} from "../../../components/PrivateRoute";
import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    ButtonDropdown,
} from "reactstrap";

import {Link} from 'react-router-dom';

class TextDisplayer extends React.Component{

    state={
        dropdownSplitOpen: false
    };

    componentWillMount(){
        const type = this.props.location.state ? this.props.location.state.content : null ;
        if(!type || !this.props[type])
            this.props.getLegalTexts()
    }

    toggleSplit = () => {
        this.setState(prevState => ({
            dropdownSplitOpen: !prevState.dropdownSplitOpen
        }));
    };

    buildActions = () =>{
        return(
            isCorrectRole(['admin']) ?
                <ButtonDropdown
                    isOpen={this.state.dropdownSplitOpen}
                    toggle={this.toggleSplit}
                >
                    <DropdownToggle
                        caret
                        color="primary"
                        className="dropdown-toggle-split pl-4 pr-4 text-uppercase"
                    >
                        {<span className='mr-2'><IntlMessages id="general.actions" /></span>}
                    </DropdownToggle>
                    <DropdownMenu right>
                        <Link to={`/app/editor-de-texto/${this.props.location.pathname.split("/")[2]}`}>
                            <DropdownItem>
                                <IntlMessages id="general.edit" />
                            </DropdownItem>
                        </Link>
                    </DropdownMenu>
                </ButtonDropdown> : null
        )
    };

    render(){
        const { location, match } = this.props;

        const type = location.pathname ? location.pathname.split("/")[2] : null;
        const heading = <h1><IntlMessages id={`menu.${type}`} /></h1>;
        const actions = this.buildActions();
        const text = (type && this.props[type]) ? JSON.parse(this.props[type]) : '';
        return(
            <>
                <PageHeader match={match} heading={heading} actions={actions}/>
                <div dangerouslySetInnerHTML={{__html:text}}/>
            </>
        )
    }
}

const mapStateToProps = ({legal}) => ({
    condition: legal.condition,
    legal: legal.legal,
    loading: legal.loading,
    privacy: legal.privacy,
});

export default connect(mapStateToProps, { getLegalTexts })(TextDisplayer);