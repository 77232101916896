import React from 'react';
import {findDOMNode} from 'react-dom';
import { Button } from "reactstrap";
import './style.scss';

const Loading = (
    <div className="lds-ring">
        <div/>
        <div/>
        <div/>
        <div/>
    </div>
);

class LoadingButton extends React.PureComponent {

    button = null;
    width = 0;

    componentDidMount(){
        this.width = findDOMNode(this.button).offsetWidth
    }

    render() {
        const {children, loading, ...rest} = this.props;
        return (
            <Button
                {...rest}
                style={{minWidth:this.width}}
                ref={(node) => this.button = node}
                children={loading ? Loading : children}
            />
        )
    }
}

export default LoadingButton