import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    GET_LOGGED_USER,
    GET_LOGGED_USER_SUCCESS,
    LOGIN_USER_FAILED,
} from '../../constants/actionTypes';

export const loginUser = (user, history) => ({
    type: LOGIN_USER,
    payload: { user, history }
});

export const loginUserSuccess = (user) => ({
    type: LOGIN_USER_SUCCESS,
    payload: user
});

export const loginUserFailed = () => ({
    type: LOGIN_USER_FAILED,
});

export const registerUser = (user, history) => ({
    type: REGISTER_USER,
    payload: { user, history }
});
export const registerUserSuccess = (user) => ({
    type: REGISTER_USER_SUCCESS,
    payload: user
});

export const logoutUser = (history) => ({
    type: LOGOUT_USER,
    payload : history
});

export const getLoggedUser = () => ({
    type: GET_LOGGED_USER,
    payload: {}
});

export const getLoggedUserSuccess = (user) => ({
    type: GET_LOGGED_USER_SUCCESS,
    payload: user
});