import {
    CREATE_REGISTRY,
    CREATE_REGISTRY_SUCCESS,
    UPDATE_REGISTRY,
    UPDATE_REGISTRY_SUCCESS,
    GET_REGISTRIES,
    GET_REGISTRY,
    GET_REGISTRY_SUCCESS,
    UPDATE_MULTIMEDIA_SUCCESS,
    GET_REGISTRIES_SUCCESS, DELETE_REGISTRY, DELETE_REGISTRY_SUCCESS, SET_OBSERVATIONS, SET_OBSERVATIONS_SUCCESS
} from "../../constants/actionTypes";

const INIT_STATE = {
    data: [],
    single: {},
    loading: false,
    totalItemCount: 0,
    page:1,
    limit:8
};

export default (state = INIT_STATE, action) => {
    let index, aux, single;
    switch (action.type) {
        /** All Loading cases **/
        case CREATE_REGISTRY:
        case UPDATE_REGISTRY:
        case GET_REGISTRIES:
        case DELETE_REGISTRY:
        case SET_OBSERVATIONS:
        case GET_REGISTRY:
        case CREATE_REGISTRY_SUCCESS:
        case UPDATE_REGISTRY_SUCCESS:
            return { ...state, loading: true };

        case UPDATE_MULTIMEDIA_SUCCESS:
            const {registry, multimediaID} = action.payload;
            single = state.single;
            aux = state.data;

            index = state.data.indexOf(state.data.find(obj => obj._id === registry._id));
            const RegistryInRedux = aux.splice(index)[0];

            const indexMultimedia = RegistryInRedux.multimedia.indexOf(RegistryInRedux.multimedia.find(e => e._id === multimediaID));
            RegistryInRedux.multimedia[indexMultimedia].metadata = registry.multimedia.find(e => e._id === multimediaID).metadata;

            if(index > -1)
                aux[index] = RegistryInRedux;

            if(single._id === registry._id)
                single = {...RegistryInRedux};

            return { ...state, data: [...aux], single, loading: false};

        case GET_REGISTRIES_SUCCESS:
            return { ...state, loading: false, data: action.payload.registries, totalItemCount: action.payload.total};


        case SET_OBSERVATIONS_SUCCESS:
            aux = state.data;
            single = state.single;
            index = state.data.indexOf(
                state.data.find(obj => obj._id === action.payload.idRegistry)
            );
            if(index > -1)
                aux[index].observations = [...action.payload.observations];

            if(single._id === action.payload.idRegistry)
                single.observations = [...action.payload.observations];
            return { ...state, data: [...aux], single, loading: false};

        case GET_REGISTRY_SUCCESS:
            aux = state.data;
            index = state.data.indexOf(
                state.data.find(obj => obj._id === action.payload.registry._id)
            );
            if(index > -1)
                aux[index] = action.payload.registry;
            return { ...state, loading: false, single: action.payload.registry, data:[...aux]};

        case DELETE_REGISTRY_SUCCESS:
            aux = state.data;
            index = state.data.indexOf(
                state.data.find(obj => obj._id === action.payload)
            );
            if(index > -1)
                aux.splice(index,1);
            return { ...state, data: [...aux], loading: false};
        default:
            return { ...state };
    }
}