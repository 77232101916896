import React, { Fragment } from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { NavLink } from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";

const getMenuTitle = sub => {
    return <IntlMessages id={`menu.${sub}`} />;
};

const getUrl = (path, sub, index, role, url, urls) => {
    let UUID = undefined;

    url = url.split('/');
    if( url[index + 1] && !urls.includes(url[index + 1]) && !role)
        UUID = url[index + 1];
    if (index === 0) {
        return "";
    } else {
        if(sub === role){
            return path.split(':role')[0] + role;
        }
        if(UUID)
          return path.split(sub)[0] + sub + '/' + UUID;
        return path.split(sub)[0] + sub;
    }
};

const BreadcrumbContainer = ({ heading, match, role }) => {
    return (
        <Fragment>
            <BreadcrumbItems match={match} role={role} />
        </Fragment>
    );
};

export const BreadcrumbItems = ({ match, role }) => {
    const path = match.path.substr(1);
    const url = match.url.substr(1);
    let paths = path.split("/");
    let urls = url.split("/");
    if (paths[paths.length - 1].indexOf(":") > -1) {
        urls.pop();
        let i = 0;
        while(i < paths.length){
            if(paths[i].indexOf(":") > -1 && !paths[i].includes(":role")){
                urls.splice(i, 1);
            }
            i++;
        }
    }

    return (
        <Fragment>
            <Breadcrumb className="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block">
                {urls.map((sub, index) => {
                    if(sub !== 'historial' && (paths[index] && !paths[index].includes(':patient')))
                    return (
                        <BreadcrumbItem key={index} active={urls.length === index + 1}>
                            {urls.length !== index + 1 ? (
                                <NavLink to={"/" + getUrl(path, sub, index, role, url, urls)}>
                                    {getMenuTitle(sub)}
                                </NavLink>
                            ) : (
                                getMenuTitle(sub)
                            )}
                        </BreadcrumbItem>
                    );
                })}
            </Breadcrumb>
        </Fragment>
    );
};

export default BreadcrumbContainer;
