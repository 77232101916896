import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import IntlMessages from '../../util/IntlMessages';

const WithModal = (TheComponent) => {
    return class Wrapper extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                okOption: false,
                cancelOption: false,
            }
        }
        handleOnOk = () => {
            this.setState({
                okOption: true,
            })
        };

        toggle = (close=true) => {
            if (this.state.okOption) {
                this.setState({
                    okOption: false,
                })
            }
            close && this.props.toggle();
        };
        render() {
            const toggle = this.toggle;
            const { isOpen, title, okOption, cancelOption, className, ...rest } = this.props;
            return (<Modal isOpen={isOpen} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody>
                    <TheComponent {...rest} onOkOption={this.state.okOption} onToggle={toggle} />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.handleOnOk}><IntlMessages id="general.accept" /></Button>{' '}
                    <Button color="default" onClickCapture={toggle}><IntlMessages id="general.cancel" /></Button>
                </ModalFooter>
            </Modal>)
        }
    }
};

export default WithModal;