import React from 'react';
import IntlMessages, { injectIntl } from '../../../util/IntlMessages'
import { lineChartConfig } from "../../../constants/chartConfig";
import { Card, CardBody, FormGroup, Row, } from 'reactstrap'
import { Colxx } from "../../CustomBootstrap/index";
import DatePicker from "react-datepicker";
import { dateFormatOptions } from '../../../constants/defaultValues';
import { LineShadow } from "../index";
import Api from '../../../api/stats'
import ErrorMessage from "../../ErrorMenssage";

const defaultTotal = {
    dicomViewer: 0,
    other: 0,
    radiography: 0,
    stlModel: 0,
    tac: 0
};

const colors = {
    dicomViewer: '#9558a5',
    other: '#47bd43',
    radiography: '#3f72d8',
    stlModel: '#ada91d',
    tac: '#e0582b'
};

const legend = {
    display: true
};

const options = {
    ...lineChartConfig.options,
    scales: {
        yAxes: [
            {
                gridLines: {
                    display: true,
                    lineWidth: 1,
                    color: "rgba(0,0,0,0.1)",
                    drawBorder: false
                },
                ticks: {
                    beginAtZero: true,
                    // stepSize: 5,
                    min: 0,
                    // max: 70,
                    padding: 20
                }
            }
        ],
        xAxes: [
            {
                gridLines: {
                    display: false
                }
            }
        ]
    }
};

const createDataset = (values) => {
    const keys = Object.keys(defaultTotal);
    const toReturn = [];
    keys.forEach(key => {
        toReturn.push({
            label: key,
            data: values.map(val => val[key] ? val[key] : 0),
            borderColor: colors[key],
            pointBackgroundColor: '#fff',
            pointBorderColor: colors[key],
            pointHoverBackgroundColor: colors[key],
            pointHoverBorderColor: colors[key],
            pointRadius: 6,
            pointBorderWidth: 2,
            pointHoverRadius: 8,
            fill: false
        })
    });

    return toReturn;
};

const formatDate = (date) => {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
};

const checkDifferentYears = (results) => {
    let aux = false;
    let year = null;
    results.forEach(data => {
        const getYear = new Date(data.date).getFullYear();
        if (year && getYear !== year) {
            aux = true;
        }
        year = getYear;
    });
    return aux
};

const calcAllDaysBetween = (init, end) => {
    return (new Date(end) - new Date(init)) / 1000 / 24 / 60 / 60;
};

const sumDaysToDate = (init, days) => {
    return new Date(new Date(init).getTime() + (days * 60 * 60 * 24 * 1000))
};

const resDaysToDate = (init, days) =>
    new Date(new Date(init).getTime() - (days * 60 * 60 * 24 * 1000));

const createLabelsDays = (init, days) => {
    const allDays = [new Date(init)];
    for (let i = 1; i <= days; i++) {
        allDays.push(sumDaysToDate(init, i))
    }
    return allDays
};

const stringMonth = (number, options) => {
    return options[`month.${number}`] ? options[`month.${number}`] : number
};

const createLabelsMonth = (iniDate, endDate) => {
    const allMonth = [];
    let iniMonth = new Date(iniDate).getMonth() + 1;
    let endMonth = new Date(endDate).getMonth() + 1;
    const diff = Math.abs(endMonth - iniMonth + 1);
    for (let i = 1; i <= diff; i++) {
        allMonth.push(iniMonth);
        if (iniMonth === 12)
            iniMonth = 0;
        iniMonth++
    }
    return allMonth;
};

const findInResults = (date, results) => {
    const finded = results.find(res => res.date === date);
    return finded ? finded.total : defaultTotal;
};

const getMonthFromParseDate = (date) => {
    return date.split('-')[1].replace('0', '');
};

const getYearFromParseDate = (date) => {
    return date.split('-')[0];
};

const findInResultsMonth = (month, results, year) => {
    let toReturn = { total: null };
    if (results) {
        const finded = results.filter(res => getMonthFromParseDate(res.date) === month + '' && getYearFromParseDate(res.date) === year + '');
        if (finded.length > 0) {
            const newTotal = { ...defaultTotal };
            finded.forEach(row => {
                Object.keys(defaultTotal).forEach(key => {
                    newTotal[key] += row.total[key] ? row.total[key] : 0;
                });
            });
            toReturn.total = newTotal
        }
    }
    return toReturn.total ? toReturn.total : defaultTotal;
};

class ManagedTestChart extends React.Component {
    state = {
        error: '',
        iniDate: resDaysToDate(new Date().getTime(), 8),
        endDate: new Date(),
        data: {},
    };

    componentDidUpdate(prevProps, prevState, prevContext) {
        if ((prevState.iniDate !== this.state.iniDate || prevState.endDate !== this.state.endDate) &&
            this.state.iniDate && this.state.endDate && this.state.endDate !== '' && this.state.iniDate !== '') {
            this.getResultsFromDataBase();
        }

        if (prevProps.user !== this.props.user)
            this.getResultsFromDataBase();
    }

    getYearByMonth = (month) => {
        return month <= 12 ? new Date(this.state.iniDate).getFullYear() : new Date(this.state.endDate).getFullYear();
    };

    getResultsFromDataBase = async () => {
        const AllDaysBetweens = calcAllDaysBetween(this.state.iniDate, this.state.endDate);

        const params = {
            multimedia: true,
            startDate: this.state.iniDate !== '' ? formatDate(this.state.iniDate) : null,
            endDate: this.state.endDate !== '' ? formatDate(this.state.endDate) : null,
            userId: this.props.user._id || ''
        };

        let results = await Api.getMultimediaReports(params);
        if (results.status === 200) {
            results = results.data;
            let labels = [];
            let values = [];

            if (AllDaysBetweens > 0 && AllDaysBetweens <= 32) {
                labels = createLabelsDays(this.state.iniDate, AllDaysBetweens);
                labels = labels.map(date => formatDate(date));
                values = labels.map(label => findInResults(label, results));
                const dataset = createDataset(values);
                this.setState({
                    data: { datasets: dataset, labels: labels }
                });
            }
            else {
                labels = createLabelsMonth(this.state.iniDate, this.state.endDate);
                values = labels.map(label => findInResultsMonth(label, results, this.getYearByMonth(label)));
                labels = labels.map(date => stringMonth(date, this.props.intl.messages));

                const dataset = createDataset(values);
                this.setState({
                    data: { datasets: dataset, labels: labels }
                });
            }
        }
        else
            this.setState({
                error: 'ha ocurrido un error innesperado'
            });

    };

    handleChangeDate = (key, value) => {
        let error = false;

        if (key === 'endDate' && this.state.iniDate || key === 'iniDate' && this.state.endDate) {
            const from = key === 'endDate' ? this.state.iniDate : value;
            const to = key === 'iniDate' ? this.state.endDate : value;
            const days = calcAllDaysBetween(from, to);
            if (days >= 365) error = 'date.error.lessThan365';
            if (days < 1) error = 'date.error.noValidRange';
        }

        if (this.state[key] !== value && !error)
            this.setState({ [key]: value, error: null });
        else this.setState({
            error: error, endDate: '',
            startDate: ''
        });

    };

    render() {
        const { data, error } = this.state;
        const { intl } = this.props;
        return (
            <Row>
                <Colxx xxs="12" className="mb-4">
                    <Card>
                        <CardBody>
                            <div className="float-left float-none-xs">
                                <div className="d-inline-block">
                                    <h5 className="d-inline">
                                        <IntlMessages id="reports.managed-test" />
                                    </h5>
                                    <span className="text-muted text-small d-block">
                                        <IntlMessages id="stats.by-type" />
                                    </span>
                                </div>
                            </div>

                            <div className="input-daterange input-group p-2" id="datepicker">
                                {error && <ErrorMessage className='mb-4' message={error} />}
                                <FormGroup className={'w-50'} >
                                    <DatePicker
                                        autoComplete='off'
                                        id="iniDate"
                                        name="iniDate"
                                        selected={this.state.iniDate}
                                        onChange={(value) => this.handleChangeDate('iniDate', value)}
                                        dateFormat={dateFormatOptions.spanish}
                                        placeholderText={intl.messages['date.init']}
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />
                                </FormGroup>

                                <FormGroup className={'w-50'}>
                                    <DatePicker
                                        autoComplete='off'
                                        id="endDate"
                                        name="endDate"
                                        selected={this.state.endDate}
                                        onChange={(value) => this.handleChangeDate('endDate', value)}
                                        dateFormat={dateFormatOptions.spanish}
                                        placeholderText={intl.messages['date.end']}
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />
                                </FormGroup>
                            </div>

                            <div className="dashboard-line-chart">
                                <LineShadow {...lineChartConfig} options={options} data={data} legend={legend} />
                            </div>
                        </CardBody>
                    </Card>
                </Colxx>
            </Row>
        )
    }
}

export default injectIntl(ManagedTestChart)