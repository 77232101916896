import React, { Fragment } from "react";
import IntlMessages from "../../../util/IntlMessages";
import {
    Row,
    Button,
    Card,
} from "reactstrap";
import { NavLink } from "react-router-dom";

import { injectIntl } from 'react-intl';
import Finder from '../../../components/Finder'
import { Colxx } from "../../../components/CustomBootstrap";
import Pagination from "../../../components/List/Pagination";
import Avatar from '../../../components/Avatar';
import { getPatients, deletePatient, filterPatients, createPatientKeyrock } from "../../../redux/actions";
import { connect } from "react-redux";

import classnames from "classnames";
import mouseTrap from "react-mousetrap";
import { ContextMenu, MenuItem } from "react-contextmenu";
import ContextMenuClass from '../../../components/ContextMenuClass';
import PageHeader from "../../../components/PageHeader";
import SelectorNumberOfItemsPerPage from './../../../components/SelectorNumberOfItemsPerPage';
import { isCorrectRole } from '../../../components/PrivateRoute';
import MultimediaBadge from "../../../components/MultimediaBadge";
import RemovePatient from "../../../components/RemovePatient";

import GenerateButton from '../../../components/PDF/Modals';
class Patients extends ContextMenuClass {
    constructor(props) {
        super(props);
        this.onContextMenuClick = this.onContextMenuClick.bind(this);
        this.toggleSplit = this.toggleSplit.bind(this);
        this.state = {
            selectedItems: [],
            pageSizes: [8, 12, 24],
            selectedPageSize: 8,
            currentPage: 1,
            totalPage: 1,
            search: "",
            lastChecked: null,
            loading: false,
            dropdownSplitOpen: false,
            patientName: "",
            patientDNI: "",
            patientPassword: "",
            finishPDFLoad: false,
        }
    }

    componentDidMount() {
        this.props.getPatients();
        this.setState({
            totalPage: Math.ceil(this.props.totalItemCount / this.state.selectedPageSize)
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.totalItemCount !== this.props.totalItemCount || prevState.selectedPageSize !== this.state.selectedPageSize) {
            this.setState({
                totalPage: Math.ceil(this.props.totalItemCount / this.state.selectedPageSize)
            });
        }
        if ((this.props.data.length) && isCorrectRole(['patient'])) {
            this.props.history.push({
                pathname: this.createLink(this.props.data[0])
            })
        }
    }

    handleCheckChange(event, id) {
        if (
            event.target.tagName === "A" ||
            (event.target.parentElement &&
                event.target.parentElement.tagName === "A")
        ) {
            return true;
        }
        if (this.state.lastChecked == null) {
            this.setState({
                lastChecked: id
            });
        }

        let selectedItems = this.state.selectedItems;
        if (selectedItems.includes(id)) {
            selectedItems = selectedItems.filter(x => x !== id);
        } else {
            selectedItems.push(id);
        }
        this.setState({
            selectedItems
        });

        if (event.shiftKey) {
            let items = this.state.items;
            let start = this.getIndex(id, items, "id");
            let end = this.getIndex(this.state.lastChecked, items, "id");
            items = items.slice(Math.min(start, end), Math.max(start, end) + 1);
            selectedItems.push(
                ...items.map(item => {
                    return item.id;
                })
            );
            selectedItems = Array.from(new Set(selectedItems));
            this.setState({
                selectedItems
            });
        }
        document.activeElement.blur();
    }
    handleKeyPress = (e, searchKey) => {
        if (e.key === "Enter") {
            this.setState(
                {
                    search: e.target.value.toLowerCase()
                },
                () => console.info("GET Patients filtered") //TODO: GET Patients filtered this.dataListRender()
            );

            this.props.filterPatients({
                [searchKey]: e.target.value.toLowerCase(),
            })
        }
    };

    handleOnSearchIconClick = (searchKey, value) => {
        this.props.filterPatients({
            [searchKey]: value,
        })
    }

    changePageSize = (size) => {
        this.setState(
            {
                selectedPageSize: size,
                currentPage: 1
            },
            () => this.props.getPatients(1, size)
        );
    };

    onChangePage(page) {
        this.setState(
            {
                currentPage: page
            },
            () => this.props.getPatients(page, this.state.selectedPageSize)
        );
    }

    toggleSplit() {
        this.setState(prevState => ({
            dropdownSplitOpen: !prevState.dropdownSplitOpen
        }));
    };
    handleChangeSelectAll(isToggle) {
        if (this.state.selectedItems.length >= this.props.data.length) {
            if (isToggle) {
                this.setState({
                    selectedItems: []
                });
            }
        } else {
            this.setState({
                selectedItems: this.props.data.map(x => x._id)
            });
        }
        document.activeElement.blur();
        return false;
    };

    onViewInContext = (data) => {
        this.props.history.push(`historial/${data.data}`);
    };

    generateUserInKeyrock = async (id) => {
        this.props.createPatientKeyrock(id);
        this.props.data.find(obj => {
            if (obj._id === id){
                setTimeout(() => { 
                    this.props.history.push(`/app/pacientes/imprimir/${obj.password}`); }, 1000);
            } return null;
        })
    }

    generateUserFall = (id) => {
        this.props.history.push(`/app/pacientes/editar/${id}`); 
    } 

    onUpdateInContext = (event, data) => {
        if (event.stopPropagation && event.preventDefault) {
            event.stopPropagation();
            event.preventDefault();
            this.props.history.push(`editar/${data.data}`);
        }
        else this.props.history.push(`editar/${event.data}`);
    };

    onDeleteInContext = (event, data) => {
        if (event && event.stopPropagation && event.preventDefault) {
            event.stopPropagation();
            event.preventDefault();
            this.props.deletePatient(data.data);
        }
        else this.props.deletePatient(event.data);
    };

    handleClickOnCard(event, entity) {
        if (!event.target.classList.contains('badge') &&
            !event.target.classList.contains('mx-1') &&
            event.target.tagName !== 'I') {
            event.stopPropagation();
            event.preventDefault();
            this.props.history.push(this.createLink(entity))
        }
    }

    renderActions = () => {
        return (
            <Fragment>
                <NavLink to={"/app/pacientes/crear"}>
                    <Button
                        color="primary"
                        size="lg"
                    >
                        <IntlMessages id="patients.add-new" />
                    </Button>
                </NavLink>
            </Fragment>
        )
    };

    renderHeader() {
        const { messages } = this.props.intl;
        const startIndex = (this.state.currentPage - 1) * this.state.selectedPageSize;
        const endIndex = (this.state.currentPage) * this.state.selectedPageSize;
        const { selectedPageSize, pageSizes } = this.state;
        return (
            <PageHeader
                heading={<h1><IntlMessages id="menu.pacientes" /></h1>}
                match={this.props.match}
                actions={isCorrectRole(['admin', 'technician']) ? this.renderActions() : ''}
                finder={<Finder
                    placeholder={messages["menu.search"]}
                    searchKeys={['lastName', 'firstName', 'dni']}
                    onKeyPress={this.handleKeyPress}
                    onSearchIconClick={this.handleOnSearchIconClick} />}
            >
                <SelectorNumberOfItemsPerPage
                    startIndex={startIndex}
                    endIndex={endIndex}
                    totalItemCount={this.props.totalItemCount}
                    selectedPageSize={selectedPageSize}
                    pageSizes={pageSizes}
                    changePageSize={this.changePageSize}
                />
            </PageHeader>
        );
    }

    createLink = (patient) => {
        if (patient.lastHistory)
            return `historial/${patient._id}/registro/${patient.lastHistory}`;
        else if (isCorrectRole(['admin', 'technician']))
            return `historial/${patient._id}/crear`;
        return `/app/pacientes/lista`
    };

    handleClickOnBadge = (e, patient, multimedia) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.history.push({
            pathname: this.createLink(patient),
            state: {
                tab: multimedia
            }
        })
    };
    render() {
        const { messages } = this.props.intl;

        if (this.props.totalItemCount === 0) {
            return (
                <Fragment>
                    <div className="disable-text-selection">
                        {this.renderHeader()}
                        {!this.props.loading ?
                            <Row>
                                <Colxx xxs="12" className="mb-3">
                                    <h2><IntlMessages id="general.no-results-found" /></h2>
                                </Colxx>
                            </Row>
                            : <div className="loading" />
                        }
                    </div>
                </Fragment>
            )
        }
        return (
            <Fragment>
                <div className="disable-text-selection">
                    {this.renderHeader()}
                    <Row>
                        {!this.props.loading ?
                            this.props.data.map((patient) => {
                                return (
                                    <Colxx xxs="12" key={patient._id} className="mb-3">
                                            <Card
                                                className={classnames("align-items-top d-flex flex-row ", {
                                                    "no-last-history": !patient.lastHistory
                                                })}
                                            >
                                            <Avatar patient={patient} />
                                            <div className="d-flex flex-grow-1 min-width-zero">
                                                <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                                                    <NavLink
                                                        to={this.createLink(patient)}
                                                        className="w-25 w-sm-100"
                                                    >
                                                        <p className="list-item-heading mb-0 p-0 truncate">
                                                            {patient.firstName.concat(" ", patient.lastName)}
                                                        </p>
                                                        {
                                                            !patient.lastHistory && <p className={'text-muted alert-no-registry p-0 m-0'}>
                                                                <i>No hay registros para este paciente</i>
                                                            </p>
                                                        }
                                                    </NavLink>
                                                    <p className="mb-1 text-muted text-small w-30 w-sm-100">
                                                        {patient.email}
                                                    </p>
                                                    <p className="mb-1 text-muted text-small w-20 w-sm-100">
                                                        {patient.telephone}
                                                    </p>
                                                    <p className="mb-1 text-muted text-small w-20 w-sm-100 d-flex align-items-center">
                                                        <ul className={'list list-unstyled mb-0'}>
                                                            {patient.authDoctors && patient.authDoctors.map(doctor => <li><i className={'authDoctors iconsmind-Doctor'} /> {doctor.name}</li>)}
                                                        </ul>
                                                    </p>
                                                    <div className="w-20 w-sm-100">
                                                        {patient.multimediaCount && Object.keys(patient.multimediaCount).map((key, index) => {
                                                            return (
                                                                <MultimediaBadge
                                                                    onClickCapture={(e) => this.handleClickOnBadge(e, patient, key)}
                                                                    multimedia={key}
                                                                    key={index}
                                                                />
                                                            )
                                                        })}
                                                    </div>
                                                    <p className="mb-1 text-muted text-small w-20 w-sm-100 d-flex">
                                                        {Intl.DateTimeFormat('es-ES', { month: '2-digit', day: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(new Date(patient.createdAt))}
                                                    </p>
                                                </div>
                                                {isCorrectRole(['admin', 'technician']) &&
                                                    <div className="custom-control custom-checkbox pl-1 d-flex align-items-center pr-4 actions-list ">
                                                        <div className="flex-column d-flex d-md-block">
                                                            {patient.DNI 
                                                                ? <GenerateButton onClickCapture={() => { this.generateUserInKeyrock(patient._id)} } className="p-2"/>
                                                                : <GenerateButton onClickCapture={() => { this.generateUserFall(patient._id) } } className="p-2" error={true}/>
                                                            }
                                                            <a href="#" onClickCapture={(e) => this.onUpdateInContext(e,{data:patient._id})} aria-label="Editar" title="Editar" className="mx-1 actions-icons p-2">
                                                                <i className="simple-icon-pencil"/>
                                                            </a>
                                                            <RemovePatient onClickCapture={() => this.onDeleteInContext({data:patient._id})} className="p-2"/>
                                                        </div>
                                                    </div>
                                                    }
                                                </div>
                                            </Card>
                                    </Colxx>
                                )
                            })
                            : <div className="loading" />
                        }
                        {!this.props.loading ?
                            <Pagination
                                currentPage={this.state.currentPage}
                                totalPage={this.state.totalPage}
                                onChangePage={i => this.onChangePage(i)}
                            />
                            : <div />
                        }
                    </Row>
                </div>

                <ContextMenu
                    id="menu_id"
                    onShow={e => this.onContextMenu(e, e.detail.data)}
                >
                    <MenuItem
                        onClick={this.onContextMenuClick}
                        data={{ action: "view" }}
                    >
                        <i className="iconsmind-Profile" /> <span>{messages["layouts.view"]}</span>
                    </MenuItem>
                    {
                        isCorrectRole(['admin', 'technician']) &&
                        <>
                            <MenuItem
                                onClick={this.onContextMenuClick}
                                data={{ action: "update" }}
                            >
                                <i className="simple-icon-refresh" /> <span>{messages["layouts.update"]}</span>
                            </MenuItem>
                            <MenuItem
                                onClick={this.onContextMenuClick}
                                data={{ action: "delete" }}
                            >
                                <i className="simple-icon-trash" /> <span>{messages["layouts.delete"]}</span>
                            </MenuItem>
                        </>
                    }
                </ContextMenu>
            </Fragment>
        );
    }
}

const mapStateToProps = ({ patients }) => {
    const { data, loading, totalItemCount } = patients;
    return { data, loading, totalItemCount }
};

export default injectIntl(mouseTrap(connect(mapStateToProps, { getPatients, deletePatient, filterPatients, createPatientKeyrock })(Patients)))
