import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import api from "../../api/diagnosticcenters";
import history from '../../history';

import {
    GET_DIAGNOSTICCENTERS,
    CREATE_DIAGNOSTICCENTER,
    UPDATE_DIAGNOSTICCENTER,
    DELETE_DIAGNOSTICCENTER,
    FILTER_DIAGNOSTICCENTERS,
    GET_CENTER
} from "../../constants/actionTypes";
import {
    getDiagnosticCentersSuccess,
    createDiagnosticCenterSucess,
    updateDiagnosticCenterSucess,
    deleteDiagnosticCenterSucess,
    getCenterSuccess
} from "./actions";

const filterDiagnosticCentersAsync = async (filter) => {
    return await api.filterDiagnosticCenters(filter)
        .then(diagnosticcenters => diagnosticcenters)
        .catch(error => error);
};

function* filterDiagnosticCenters({payload}) {
    try {
        const diagnosticcenters = yield call(filterDiagnosticCentersAsync, payload.filter);
        if(diagnosticcenters.status === 200) {
            yield put(getDiagnosticCentersSuccess(diagnosticcenters.data, diagnosticcenters.headers.total));
        } else {
            console.log('Filter diagnostic centers failed : ', diagnosticcenters);
        }
    } catch (error) {
        console.log('Filter diagnostic centers failed : ', error);
    }
}

const getDiagnosticCentersAsync = async (page, limit) => {
    return await api.getDiagnosticCenters(page, limit)
        .then(diagnosticcenters => diagnosticcenters)
        .catch(error => error);
};

function* getDiagnosticCenters({payload}) {
    try {
        const {page, limit} = payload;
        const diagnosticcenters = yield call(getDiagnosticCentersAsync, page, limit);
        if(diagnosticcenters.status === 200) {
            yield put(getDiagnosticCentersSuccess(diagnosticcenters.data, diagnosticcenters.headers.total));
        } else {
            console.log('Get diagnostic centers failed : ', diagnosticcenters);
        }
    } catch (error) {
        console.log('Get diagnostic centers failed : ', error);
    }
}

const createDiagnosticCenterAsync = async (formData) => {
    return await api.createDiagnosticCenter(formData)
        .then(diagnosticcenter => diagnosticcenter)
        .catch(error => error);
};

function* createDiagnosticCenter({payload}) {
    try {
        const diagnosticcenter = yield call(createDiagnosticCenterAsync, payload);
        if(diagnosticcenter.status === 201) {
            yield put(createDiagnosticCenterSucess(diagnosticcenter.data));
            history.push('/app/diagnosticcenters/lista')
        } else {
            console.log('Create diagnostic center failed : ', diagnosticcenter);
        }
    } catch (error) {
        console.log('Create diagnostic center error: ', error);
    }
}

const updateDiagnosticCenterAsync = async (formData) => {
    return await api.updateDiagnosticCenter(formData)
        .then(diagnosticcenter => diagnosticcenter)
        .catch(error => error);
};

function* updateDiagnosticCenter({payload}) {
    try {
        const diagnosticcenter = yield call(updateDiagnosticCenterAsync, payload);
        if(diagnosticcenter.status === 202) {
            yield put(updateDiagnosticCenterSucess(diagnosticcenter.data));
            history.push(`/app/diagnosticcenters/detalle/${payload._id}`)
        } else {
            console.log('Update diagnostic center failed : ', diagnosticcenter);
        }
    } catch (error) {
        console.log('Update diagnostic center error: ', error);
    }
}

const deleteDiagnosticCenterAsync = async (id) => {
    return await api.deleteDiagnosticCenter(id)
        .then(res => res)
        .catch(error => error);
};

function* deleteDiagnosticCenter({payload}) {
    try {
        const res = yield call(deleteDiagnosticCenterAsync, payload);
        if(res.status === 204) {
            yield put(deleteDiagnosticCenterSucess(payload));
        } else {
            console.log('Delete diagnostic center failed : ', payload);
        }
    } catch (error) {
        console.log('Delete diagnostic center error: ', error);
    }
}

const getCenterAsync = async (id) => {
    return await api.getCenter(id)
        .then(diagnosticcenter => diagnosticcenter)
        .catch(error => error);
};

function* getCenter({payload}) {
    try {
        const id = payload;
        console.log('Calling getCenterAsync with ID '+id+' ...');
        const diagnosticcenter = yield call(getCenterAsync, id);
        if(diagnosticcenter.status === 200) {
            yield put(getCenterSuccess(diagnosticcenter.data));
        } else {
            console.log('Get diagnostic center failed : ', diagnosticcenter);
        }
    } catch (error) {
        console.log('Get diagnostic center failed : ', error);
    }
}

export function* watchGetDiagnosticCenters() {
    yield takeEvery(GET_DIAGNOSTICCENTERS, getDiagnosticCenters);
}

export function* watchFilterDiagnosticCenters() {
    yield takeLatest(FILTER_DIAGNOSTICCENTERS, filterDiagnosticCenters);
}

export function* watchCreateDiagnosticCenter() {
    yield takeEvery(CREATE_DIAGNOSTICCENTER, createDiagnosticCenter);
}

export function* watchUpdateDiagnosticCenter() {
    yield takeEvery(UPDATE_DIAGNOSTICCENTER, updateDiagnosticCenter);
}

export function* watchDeleteDiagnosticCenter() {
    yield takeEvery(DELETE_DIAGNOSTICCENTER, deleteDiagnosticCenter);
}

export function* watchGetCenter() {
    yield takeEvery(GET_CENTER, getCenter);
}


export default function* rootSaga() {
    yield all([
        fork(watchGetDiagnosticCenters),
        fork(watchFilterDiagnosticCenters),
        fork(watchCreateDiagnosticCenter),
        fork(watchUpdateDiagnosticCenter),
        fork(watchDeleteDiagnosticCenter),
        fork(watchGetCenter)
    ])
};