import React, { Fragment } from "react";
import IntlMessages from "../../util/IntlMessages";
import {Row, Card, CardTitle} from "reactstrap";
import { NavLink } from "react-router-dom";

import { Colxx } from "../../components/CustomBootstrap";
import {
  resetPassword,
} from "../../redux/actions";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";

class ResetPasswordLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      errors:{}
    };
  }

  componentDidUpdate(prevProps) {
    if(this.props.status !== prevProps.status){
      this.setState({loading: !!this.state.loading})
    }
  }

  componentDidMount() {
    document.body.classList.add("background");
    this.props.resetPassword(this.props.match.params.token);
  }
  componentWillUnmount() {
    document.body.classList.remove("background");
  }

  render() {
    const { status } = this.props;

    return (
      <Fragment>
        <div className="fixed-background" />
        <main>
          <div className="container">
            <Row className="h-100">
              <Colxx xxs="12" md="10" className="mx-auto my-auto">
                <Card className="auth-card">
                  <div className="position-relative image-side ">
                    <p className="text-blue h2"><IntlMessages id="user.reset-password.title" /></p>
                    <p className="text-blue">
                      <IntlMessages id="user.reset-password.text" />.
                    </p>
                  </div>
                  <div className="form-side">
                    <NavLink to={`/`} className="white">
                      <span className="logo-single" />
                    </NavLink>
                    <CardTitle className="mb-4">
                      <IntlMessages id="user.reset-password" />
                    </CardTitle>
                    { this.props.status ?
                      <div className={this.props.status === 200 ? 'msg-success' : 'msg-error'}>
                        <IntlMessages id={`form.reset-password.${status}`}/>
                      </div>
                      :
                      <div>
                        <IntlMessages id="form.reset-password.processing"/>
                      </div>
                    }
                  </div>
                </Card>
              </Colxx>
            </Row>
          </div>
        </main>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
      status: state.users.status
    });

export default injectIntl(connect(mapStateToProps, {resetPassword})(ResetPasswordLayout))

