import React from 'react';
import api from "../../../api/patients";
import IntlMessages from "../../../util/IntlMessages";

class RegisteredPatientsChart extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: '0',
        }
    }

    componentDidMount() {
        new Promise(async (resolve) => {
            const incomingData = await api.getPatientsTotal();
            this.setState({
                data: incomingData.data,
            })
            resolve();
        })
    }

    render() {
        return <div class="card-body text-center icons-cards-row d-flex align-items-center justify-content-center flex-column h-100">
            <div>
                <i class="simple-icon-people"></i>
                <p class="card-text font-weight-semibold mb-0"><IntlMessages id="reports.registerPatients" /></p>
                <p class="lead text-center">{this.state.data}</p>
            </div>

        </div>
    }
}

export default RegisteredPatientsChart;