import React, { Component, Fragment } from "react";
import IntlMessages from "../../../util/IntlMessages";
import { Colxx } from "../../../components/CustomBootstrap";
import CustomSelectInput from "../../../components/CustomSelectInput";
import { Formik, Form } from 'formik';
import PageHeader from '../../../components/PageHeader'

import {
    Row,
    Card,
    CardTitle,
    FormGroup,
    Label,
    Input,
    CardBody,
    Button} from "reactstrap";
import {Async as AsyncSelect} from "react-select";
import { injectIntl} from 'react-intl';
import { createRegistry, filterUsers } from "../../../redux/actions";
import { connect } from "react-redux";
import Observation from "../../../models/Observation";
import GetPatient from "../../../util/GetPatientFromReduxOrBD";
import {emptyString} from "../../../util/Utils";

class CreateHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patientName: '',
            name: null,
            authDoctors: [],
            observations: []
        };
        this.valueInFilter = '';
    }

    validate = (values) => {
        values = this.state;
        let errors = {};

        if (!values.name) {
            errors.name = 'Please enter name';
        }

        return errors;
    };

    componentDidMount(){
        this.props.filterUsers({role:'clinic,doctor'});
        const patient = GetPatient(this.props.match.params.patient);
        this.setState({
            patientName: patient.firstName ? `${patient.firstName} ${patient.lastName}`: emptyString()
        });
    }

    componentWillUpdate(nextProps, nextState, nextContext){
        if(nextProps.patientsData !== this.props.patientsData){
            const patient =  nextProps.patientsData && nextProps.patientsData.find(pat => pat._id === this.props.match.params.patient);
            if(patient)
                this.setState({
                    patientName: `${patient.firstName} ${patient.lastName}`
                })

        }
    }

    handleChange = (e) =>{
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        })
    };

    handleObservationChange = (index, value) => {
        const aux = this.state.observations;
        aux[index] = value;

        this.setState({
            observations: [...aux]
        })
    };

    handleChangeMulti = selectedOptions => {
        this.setState({authDoctors: selectedOptions});
    };


    addObservation = (e) =>{
        e.preventDefault();
        e.stopPropagation();

        const aux = this.state.observations;
        aux.push('');

        this.setState({
            observations: [...aux]
        })
    };

    deleteObservation = (e, index) => {
        e.preventDefault();
        e.stopPropagation();

        const aux = this.state.observations;
        aux.splice(index,1);

        this.setState({
            observations: [...aux]
        })
    };

    handleSubmit = (e) =>{
        if(e.preventDefault)
            e.preventDefault();
        if(e.stopPropagation)
            e.stopPropagation();

        const registry = {...this.state};
        // registry.observations = this.parseToObservations();
        registry.observations = [];
        registry.authDoctors = this.state.authDoctors.map(user => ({id: user.key, enabled: true}));
        registry.patientId = this.props.match.params.patient;
        registry.report = "Report";
        this.props.createRegistry(registry)
    };

    parseToOptionsDoctors = (usersOptions) =>{
        return usersOptions.map(user => ({
            label: user.clinicName ? user.clinicName : user.firstName + ' ' + user.lastName,
            value: user._id,
            key: user._id
        }))
    };

    loadOptionsDoctors = (inputValue, callback) => {
        this.valueInFilterAuthDoctors = inputValue;
        setTimeout(()=> {
            if(this.valueInFilterAuthDoctors === inputValue){
                if (inputValue.length > 2){
                   new Promise((resolve => {
                        this.props.filterUsers({firstName: inputValue, role: 'doctor'}, (users)=>{
                            console.log('response in user', users);
                            return resolve(users)
                        });
                    })).then(doctors=>{
                        this.props.filterUsers({clinicName: inputValue, role: 'clinic'}, (clinics)=> {
                            console.log('response in clinic', clinics);
                            return callback(this.parseToOptionsDoctors([...doctors, ...clinics]))
                        } );
                    });
                }
                else
                    this.props.filterUsers({role: 'clinic,doctor'}, (users)=>{
                        callback(this.parseToOptionsDoctors(users))
                    });
            }
        },500);
    };

    parseToObservations(){
        return this.state.observations.map(obs => new Observation({who: this.props.me._id , what:obs, when: new Date()}).getData())
    }

    renderTitle(){
        return (<>
            <h1 className={'mb-1 pb-0'}><IntlMessages id="registry.button-add" />
                <div>
                    <small className={'dident-text-light'}>{this.state.patientName}</small>
                </div>
            </h1>
        </>)
    }

    renderHeader() {
        return (
            <PageHeader
                heading={this.renderTitle()}
                match={this.props.match}
                finder={undefined}
            />
        );
    }

    render() {
        const {messages} = this.props.intl;
        return(
            <Fragment>
                { this.renderHeader() }

                <Row className="mb-4">
                    <Colxx xxs="12">
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <IntlMessages id="forms.registry-data" />
                                </CardTitle>
                                <Formik
                                    validate={this.validate}
                                    initialValues={this.state}
                                    onSubmit={this.handleSubmit}>
                                    {({ errors, touched }) => (
                                        <Form className="av-tooltip tooltip-label-right">
                                            <FormGroup row>
                                                <Colxx xxs={12}>
                                                    <FormGroup>
                                                        <Label for="firstName">
                                                            <IntlMessages id="registry.name" />
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            name="name"
                                                            id="name"
                                                            placeholder={messages["registry.name"]}
                                                            value={this.state.name}
                                                            onChange={this.handleChange}
                                                        />
                                                        {errors.name && touched.name && <div className="invalid-feedback d-block">{errors.name}</div>}
                                                    </FormGroup>
                                                </Colxx>

                                                <Colxx xxs={12}>
                                                    <FormGroup>
                                                        <Label for="diagnosticCenters">
                                                            <IntlMessages id="registry.authDoctors" />
                                                        </Label>
                                                        <AsyncSelect
                                                            components={{ Input: CustomSelectInput }}
                                                            className="react-select"
                                                            classNamePrefix="react-select"
                                                            isMulti
                                                            name="authDoctors"
                                                            id="authDoctors"
                                                            placeholder={messages["registry.allowed-users"]}
                                                            value={this.state.authDoctors}
                                                            onChange={this.handleChangeMulti}
                                                            defaultOptions
                                                            cacheOptions
                                                            loadOptions={this.loadOptionsDoctors}
                                                        />
                                                        {errors.authDoctors && touched.authDoctors && <div className="invalid-feedback d-block">{errors.authDoctors}</div>}
                                                    </FormGroup>
                                                </Colxx>

                                                {/*<Colxx xxs={12} key={`separator`} className={'mt-4 mb-5'}>*/}
                                                    {/*<Separator/>*/}
                                                {/*</Colxx>*/}

                                                {/*<Colxx xxs={12} key={`observations`} className={'mt-2'}>*/}
                                                {/*    <FormGroup>*/}
                                                {/*        <div className="d-flex justify-content-between align-items-end mb-2">*/}
                                                {/*            <Label for="observations">*/}
                                                {/*                <IntlMessages id="forms.observations" />*/}
                                                {/*            </Label>*/}
                                                {/*            <Button color={"default"} onClick={this.addObservation}>*/}
                                                {/*                <IntlMessages id="forms.add.observation" />*/}
                                                {/*            </Button>*/}
                                                {/*        </div>*/}
                                                {/*        {this.state.observations.length > 0?*/}
                                                {/*            this.state.observations.map((obs, index)=>(*/}

                                                {/*            <InputGroup className={'mb-2'}>*/}
                                                {/*                <Input*/}
                                                {/*                    type="input"*/}
                                                {/*                    name={`observation ${index}`}*/}
                                                {/*                    id={`observation ${index}`}*/}
                                                {/*                    placeholder={messages["forms.observations"]}*/}
                                                {/*                    value={obs}*/}
                                                {/*                    onChange={(e)=>this.handleObservationChange(index,e.target.value)}*/}
                                                {/*                />*/}
                                                {/*                <InputGroupAddon addonType="append">*/}
                                                {/*                    <Button onClick={(e)=>this.deleteObservation(e,index)} color={'danger'}>X</Button>*/}
                                                {/*                </InputGroupAddon>*/}
                                                {/*            </InputGroup>*/}

                                                {/*        )):*/}
                                                {/*            <IntlMessages id={'forms.non.observation'}/>*/}
                                                {/*        }*/}
                                                {/*    </FormGroup>*/}
                                                {/*</Colxx>*/}
                                            </FormGroup>
                                            <Button color="primary" className="mt-4 float-right">
                                                <IntlMessages id="registry.button-add" />
                                            </Button>
                                        </Form> )}
                                </Formik>
                            </CardBody>
                        </Card>
                    </Colxx>
                </Row>
            </Fragment>
        )
    }
}

const mapStateToProps = ({users, patients, authUser}) => ({
    usersOptions: users.data,
    patientsData: patients.data,
    me: authUser.user
});

export default injectIntl(connect(mapStateToProps, {createRegistry, filterUsers})(CreateHistory));