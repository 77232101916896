import React from 'react';
import Api from '../../../api/stats'
import {LineShadow} from '../index';
import {injectIntl} from '../../../util/IntlMessages'

const options = {
    responsive:true,
    plugins: {
        datalabels: {
            display: false
        }
    },
    scales: {
        xAxes: [{
            display: true, // this will hide vertical lines
            gridLines: false,
        }],
        yAxes: [{
            offset: true,
            ticks: {
                beginAtZero: true,
                callback: function (value, index, values) {
                    return value;
                },
                padding: 20
            }
        }]
    },
    legend: {
        display: false,
    },
    tooltips: {
        // enabled: false,
        mode: 'point',
        intersect: true,
    }
};

/***********************************************************************/

const resDaysToDate = (init, days)=>
    new Date( new Date(init).getTime() - (days * 60 * 60 * 24 * 1000) );


const createLabelsDays = (init, days) => {
    const allDays = [new Date(init)];
    for(let i=1; i < days; i++){
        allDays.push(resDaysToDate(init, i))
    }
    return allDays.reverse()
};

const formatDate = (date) => {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
};

const transformToDay = (date, message) => message[`day.${date.getDay()}`];

const createDataset = (values) => ({
    label: 'Visualizations',
    data: values,
    borderColor: '#0098a8',
    pointBackgroundColor: '#fff',
    pointBorderColor: '#0098a8',
    pointHoverBackgroundColor: '#0098a8',
    pointHoverBorderColor: '#0098a8',
    pointRadius: 6,
    pointBorderWidth: 2,
    pointHoverRadius: 8,
    fill: false
});

/***********************************************************************/

const findInResults = (date, results) => {
    const finded = results.find(res=>res.date === date);
    return finded ? finded.total : 0;
};

class RegistryVisualizations extends React.Component {
    constructor(props){
        super(props);
        this.state={
            iniDate: new Date().toDateString(),
            data: {
                datasets: [createDataset([])],
                labels: []
            },
        }
    }
    componentDidUpdate(prevProps, prevState, prev){
        if(this.props.registry._id !== prevProps.registry._id)
            this.getStats().then(res => {
                res.data && this.createChart(res.data)
            })
    }

    getStats = async() => await Api.getMultimediaReports({
        typeEvent:'visualization',
        _id: this.props.registry._id,
        startDate: formatDate(resDaysToDate( this.state.iniDate, 8)),
        endDate: formatDate(this.state.iniDate)
    });


    createChart =(results)=>{
        let labels = createLabelsDays(this.state.iniDate, 8);
        let values = labels.map(label => findInResults(formatDate(label), results));
        labels = labels.map(label => transformToDay(label,this.props.intl.messages));
        values = createDataset(values);
        this.setState({
            data:{datasets: [values], labels}
        });
    };


    render(){
        const {data} = this.state;
        return(
            <LineShadow data={data} options={options} />
        )
    }
}

export default injectIntl(RegistryVisualizations);