import React from 'react';
import './style.scss';
import { withPortalCtxt } from "../Portal/Context";
import  Viewer  from 'stl-viewer';
import CloseButton from "../CloseButton";

class FullScreenSTL extends React.Component{

    constructor(props){
        super(props);
        this.component = null;
        this.state={
            src : this.props.media.url
        }
    }

    componentDidMount(){
        document.querySelector('body').classList.add('dident-overflow-hidden');
        if(this.component)
            setTimeout( ()=>this.component.classList.add('mounted'), 1 )
    }

    handleClick = (e) =>{
        if( e.target === this.component || e.target.classList.contains('Close-Button')){
            this.component.classList.remove('mounted');
            setTimeout( ()=> {
                this.props.handlePortalVisibility(false);
                document.querySelector('body').classList.remove('dident-overflow-hidden')
            } , 250)
        }
    };
    render(){
        const { src } = this.state;

        return(
            <div className={'fullScreenSTL'} ref={node=>this.component=node} onClick={this.handleClick}>
                <CloseButton onClickCapture={this.handleClick}/>
                <div className={'stl-wrapper'} id={'stl-wrapper'}>
                    <ViewerComponent src={src}/>
                </div>
            </div>
        )
    }
}


class ViewerComponent extends React.Component{

    constructor(props){
        super(props);
        this.state={
            width: 200,
            height: 200
        }
    }

    componentDidMount(){
        this.setState({
            width :document.querySelector('.stl-wrapper').clientWidth,
            height :document.querySelector('.fullScreenSTL').clientHeight - (16*4)
        })
    }

    render(){
        const { src } = this.props;
        const { width, height } = this.state;
        return(
            <Viewer
                model={ src }
                width={width}
                height={height}
                lights={[[0.05, 0.05, 0.05], [-0.05, -0.05, -0.05]]}
                className={'sceneClassName'}
                modelColor={'#98906b'}
                backgroundColor='#EAEAEA'
                rotate={false}
                orbitControls={true}
            />
        )
    }
}

export default withPortalCtxt(FullScreenSTL);