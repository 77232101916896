import axios from "axios";
import { apiUrl } from "../constants/defaultValues";
import Auth from "./auth";

const createUser = async (user) =>
    await Auth.refreshToken()
        .then(() => new Promise((resolve, reject) => {
            axios.post(apiUrl.concat("users"), user, { headers: { "X-Auth-Token": localStorage.getItem("access_token") } })
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        }))
        .catch((err) => err);

const getUsers = async (page, limit) =>
    await Auth.refreshToken()
        .then(() => new Promise((resolve, reject) => {
            axios.get(apiUrl.concat(`users?limit=${limit}&page=${page}`), { headers: { "X-Auth-Token": localStorage.getItem("access_token") } })
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        }))
        .catch((err) => err);


const getUsersByRole = async (page, limit, role) =>
    await Auth.refreshToken()
        .then(() => new Promise((resolve, reject) => {
            axios.get(apiUrl.concat(`users?sort=createdAt&order=DESC&role=${role}&limit=${limit}&page=${page}`), { headers: { "X-Auth-Token": localStorage.getItem("access_token") } })
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        }))
        .catch((err) => err);

const getUser = async (id) =>
    await Auth.refreshToken()
        .then(() => new Promise((resolve, reject) => {
            axios.get(apiUrl.concat(`users?_id=${id}`), { headers: { "X-Auth-Token": localStorage.getItem("access_token") } })
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        }))
        .catch((err) => err);

const getUsersPercentage = () =>
    Auth.refreshToken()
        .then(() =>
            axios.get(apiUrl.concat(`users/percentage`), { headers: { "X-Auth-Token": localStorage.getItem("access_token") } })
        )
        .catch((err) => err);

const getUsersAccessesPlatform = ({startDate, endDate, user}) =>
    Auth.refreshToken()
        .then(() =>
            axios.get(apiUrl.concat(`users/reports?startDate=${startDate}&endDate=${endDate}&userId=${user}`), { headers: { "X-Auth-Token": localStorage.getItem("access_token") } })
        )
        .catch((err) => err);


const filterUsers = async (filter) =>
    await Auth.refreshToken()
        .then(() => new Promise((resolve, reject) => {
            axios.get(apiUrl.concat("users"), {
                params: filter,
                headers: { "X-Auth-Token": localStorage.getItem("access_token") },
            })
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        }))
        .catch((err) => err);

const deleteUser = async (id) =>
    await Auth.refreshToken()
        .then(() => new Promise((resolve, reject) => {
            axios.delete(apiUrl.concat(`users?_id=${id}`), { headers: { "X-Auth-Token": localStorage.getItem("access_token") } })
                .then((response) => resolve(response))
                .catch((err) => reject(err))
        }))
        .catch((err) => err);


const updateUser = async (user) =>
    await Auth.refreshToken()
        .then(() => new Promise((resolve, reject) => {
            axios.put(apiUrl.concat(`users?_id=${user.id}`), user, { headers: { "X-Auth-Token": localStorage.getItem("access_token") } })
                .then((response) => resolve(response))
                .catch((err) => reject(err))
        }))
        .catch((err) => err);

const registerUser = async (user) => {
    try {
        return await axios.post(apiUrl.concat(`users/register`), user, { headers: { "X-Auth-Token": localStorage.getItem("access_token") } })
    } catch (error) {
        console.log('Error:' + error);
    }
};

const updatePassword = async (user) => {
  return await Auth.refreshToken()
    .then(() => new Promise((resolve, reject) => {
      axios.put(apiUrl.concat(`users/password?_id=${user.id}`), {password: user.password}, {headers: {"X-Auth-Token": localStorage.getItem("access_token")}})
        .then((response) => resolve(response))
        .catch((err) => reject(err))
    }))
    .catch((err) => err);
};

const forgotPassword = async (email) => {
  return new Promise((resolve, reject) => {
      axios.put(apiUrl.concat(`users/forgotpassword`), email)
        .then((response) => resolve(response))
        .catch((err) => reject(err))
    })
    .catch((err) => err);
};

const resetPassword = async (token) => {
  return new Promise((resolve, reject) => {
      axios.put(apiUrl.concat(`users/resetpassword`), {token})
        .then((response) => resolve(response))
        .catch((err) => reject(err))
    })
    .catch((err) => err);
};


export default {
    getUsers,
    createUser,
    getUser,
    updateUser,
    filterUsers,
    deleteUser,
    getUsersByRole,
    getUsersPercentage,
    getUsersAccessesPlatform,
    registerUser,
    updatePassword,
    forgotPassword,
    resetPassword
};
