import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import loggedUser from './auth/reducer';
import patients from './patients/reducer';
import diagnosticcenters from './diagnosticcenters/reducer';
import diagnosticcenter from './diagnosticcenters/reducer';
import users from './users/reducer';
import usersRequest from './usersRequest/reducer';
import registries from './registry/reducer';
import legal from './legal/reducer';
import stats from "./stats/reducer";

const reducers = combineReducers({
    menu,
    settings,
    authUser,
    loggedUser, 
    patients,
    diagnosticcenters,
    diagnosticcenter,
    users,
    usersRequest,
    stats,
    legal,
    registries,
});

export default reducers;