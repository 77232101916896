import React, { Component, Fragment } from "react";
import { findDOMNode } from 'react-dom';
import IntlMessages from "../../../util/IntlMessages";
import DatePicker from "react-datepicker";
import AvatarPreview from '../../../components/AvatarPreview';
import { dateFormatOptions } from '../../../constants/defaultValues';
import { Colxx, Separator } from "../../../components/CustomBootstrap";
import BreadcrumbContainer from "../../../components/BreadcrumbContainer";
import CustomSelectInput from "../../../components/CustomSelectInput";
import { Formik, Form } from 'formik';
import Patient from '../../../models/Patient'

import {
    Row,
    Card,
    CardTitle,
    FormGroup,
    Label,
    Input,
    CardBody,
    Button,
    InputGroup,
    InputGroupAddon
} from "reactstrap";
import Select, { Async as AsyncSelect } from "react-select";
import { injectIntl } from 'react-intl';
import "react-datepicker/dist/react-datepicker.css";
import { countries } from "../../../data/countries";
import {
    updatePatient,
    filterPatients,
    getDiagnosticCenters,
    filterDiagnosticCenters,
    filterUsers
} from "../../../redux/actions";
import { connect } from "react-redux";
import Observation from "../../../models/Observation";

class CreatePatient extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            bornDate: null,
            country: null,
            firstName: "",
            lastName: "",
            DNI: "",
            telephone: "",
            diagnosticCenters: [],
            email: "",
            avatar: "",
            observations: [],
            originalPatient: null
        };
        
        this.valueInFilter = '';
        this.file = null;
        this.DOMfile = null;
        this.errorAvatarSize = false;
    }
    
    componentDidMount() {
        if (this.file) {
            this.DOMfile = findDOMNode(this.file);
        }
        this.props.getDiagnosticCenters();
        const patient = this.props.patients.find(patient => patient._id === this.props.match.params.id);
        if (!patient)
            this.props.filterPatients({ _id: this.props.match.params.id });
        else
            this.parsePatientToState(patient);        
    }
    
    parseToDiagnosticCenter = (diagnosticCentersIds) => {
        return diagnosticCentersIds.map(diagnosticCenter => ({
            key: diagnosticCenter._id,
            label: diagnosticCenter.name,
            value: diagnosticCenter._id
        }))
    };
    
    parsePatientToState = (patient) => {
        const diagnosticCenters = this.parseToDiagnosticCenter(patient.diagnosticCenters);
        const authDoctors = this.parseToOptionsDoctors(patient.authDoctors);
        this.setState({
            ...patient,
            authDoctors,
            originalPatient: patient,
            diagnosticCenters
        })
    };
    
    componentDidUpdate(prevProps, prevState, prevContext) {
        if (this.props.patients !== this.state.originalPatient && prevProps.patients !== this.props.patients) {
            const patient = this.props.patients.find(patient => patient._id === this.props.match.params.id);
            this.parsePatientToState(patient);
        }
    }
    
    validate = (values) => {
        values = this.state;
        let errors = {};
        
        if (!values.bornDate) {
            errors.bornDate = 'Please select a born date';
        }
        
        if (!values.country) {
            errors.country = 'Please enter a country';
        }
        
        if (!values.firstName) {
            errors.firstName = 'Please enter first name';
        }
        
        if (!values.lastName) {
            errors.lastName = 'Please enter last name';
        }
        
        // if (!values.DNI) {
        //     errors.DNI = 'Please enter DNI';
        // }
        
        // if (!values.telephone) {
        //     errors.telephone = 'Please enter telephone';
        // }
        
        if (values.diagnosticCenters.length === 0) {
            errors.diagnosticCenters = 'Please select diagnosticCenter';
        }
        
        // if (!values.email) {
        //     errors.email = 'Please enter email';
        // }
        
        // if (!values.avatar) {
        //     errors.avatar = 'Please add an avatar';
        // }
        
        if(this.errorAvatarSize) {
            errors.avatar = 'Please add an avatar (Max size: 100KB)';
        }
        
        return errors;
    };
    
    handleChangeFileInput = (e, { validateForm }) => {
        const name = e.target.name;
        if (this.DOMfile && this.DOMfile.files && this.DOMfile.files[0]) {
            if (this.DOMfile.files[0].size <= 100000) {
                let reader = new FileReader();
                reader.readAsDataURL(this.DOMfile.files[0]);
                reader.onloadend = () => {
                    this.setState({
                        [name]: reader.result
                    }, () => {
                        
                        this.errorAvatarSize = false;
                    });
                };
                
            } else {
                this.errorAvatarSize = true;
            }
        }
    };
    
    handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        
        this.setState({
            [name]: value
        })
    };
    
    handleCountryChange = (country) => {
        this.setState({
            country: country.value
        });
    };
    
    handleObservationChange = (index, value) => {
        const aux = this.state.observations;
        aux[index] = new Observation({ what: value, who: this.props.me });
        
        this.setState({
            observations: [...aux]
        })
    };
    
    handleChangeMulti = (name,selectedOptions) => {
        this.setState({[name]: selectedOptions});
    };
    
    parseToOptions = () => this.parseToDiagnosticCenter(this.props.centerOptions);
    
    loadOptions = (inputValue, callback) => {
        this.valueInFilter = inputValue;
        setTimeout(() => {
            if (this.valueInFilter === inputValue) {
                if (inputValue.length > 2)
                this.props.filterDiagnosticCenters({ name: inputValue });
                else
                this.props.getDiagnosticCenters();
                
                setTimeout(() => {
                    callback(this.parseToOptions());
                }, 700);
            }
        }, 500);
    };
    
    parseToOptionsDoctors = (users = this.props.usersOptions) => {
        if(users && Array.isArray(users))
        return users.map(user => {
            return {
                label: user.name ? user.name : user.clinicName ? user.clinicName : `${user.firstName} ${user.lastName}`,
                value: user.id ? user.id: user._id,
                key: user.id ? user.id: user._id,
            }
        })
        else {
            return []
        }
    };
    
    loadOptionsDoctors = (inputValue, callback) => {
        this.valueInFilterAuthDoctors = inputValue;
        setTimeout(()=>{
            if(this.valueInFilterAuthDoctors === inputValue){
                if (inputValue.length > 2)
                this.props.filterUsers({firstName: inputValue, role: 'clinic,doctor'});
                else
                this.props.filterUsers({role: 'clinic,doctor'});
                
                setTimeout(() => {
                    callback(this.parseToOptionsDoctors());
                }, 700);
            }
        },500);
    };
    
    addObservation = (e) => {
        e.preventDefault && e.preventDefault();
        e.stopPropagation && e.stopPropagation();
        
        const aux = this.state.observations;
        aux.push('');
        
        this.setState({
            observations: [...aux]
        })
    };
    
    handleChangeDate = (date) => {
        this.setState({
            bornDate: date
        });
    };
    
    deleteObservation = (e, index) => {
        e.preventDefault && e.preventDefault();
        e.stopPropagation && e.stopPropagation();
        
        const aux = this.state.observations;
        aux.splice(index, 1);
        
        this.setState({
            observations: [...aux]
        })
    };
    
    handleSubmit = (e) => {
        e.preventDefault && e.preventDefault();
        e.stopPropagation && e.stopPropagation();
        
        let patient = {...this.state};
        patient.authDoctors = this.state.authDoctors.map(user => ({id: user.key, enabled: true}));
        patient.diagnosticCenters = this.state.diagnosticCenters.map(center => center.key);
        patient = new Patient(patient).getData();
        this.props.updatePatient(patient)
    };
    
    render() {
        const { messages } = this.props.intl;
        
        return (
            <Fragment>
            <Row>
            <Colxx xxs="12">
            <BreadcrumbContainer
            heading={<IntlMessages id="patients.createPatient" />}
            match={this.props.match}
            />
            <Separator className="mb-5" />
            </Colxx>
            </Row>
            
            <Row className="mb-4">
            <Colxx xxs="12">
            <Card>
            <CardBody>
            <CardTitle>
            <IntlMessages id="forms.patient" />
            </CardTitle>
            <Formik
            validate={this.validate}
            initialValues={this.state}
            onSubmit={this.handleSubmit}>
            {({ errors, touched, isValidating, validateForm }) => (
                <Form className="av-tooltip tooltip-label-right">
                <FormGroup row>
                <Colxx sm={6}>
                <FormGroup>
                <Label for="firstName">
                <IntlMessages id="forms.firstName" />
                </Label>
                <Input
                type="text"
                name="firstName"
                id="firstName"
                placeholder={messages["forms.firstName"]}
                value={this.state.firstName}
                onChange={this.handleChange}
                />
                {errors.firstName && touched.firstName && <div className="invalid-feedback d-block">{errors.firstName}</div>}
                </FormGroup>
                </Colxx>
                
                <Colxx sm={6}>
                <FormGroup>
                <Label for="lastName">
                <IntlMessages id="forms.lastName" />
                </Label>
                <Input
                invalid={!!errors.lastName}
                type="text"
                name="lastName"
                id="lastName"
                placeholder={messages["forms.lastName"]}
                value={this.state.lastName}
                onChange={this.handleChange}
                />
                {errors.lastName && touched.lastName && <div className="invalid-feedback d-block">{errors.lastName}</div>}
                </FormGroup>
                </Colxx>
                
                <Colxx sm={4}>
                <FormGroup>
                <Label for="DNI">
                <IntlMessages id="forms.dni" />
                </Label>
                <Input
                type="text"
                name="DNI"
                id="DNI"
                placeholder={messages["forms.dni"]}
                value={this.state.DNI}
                onChange={this.handleChange}
                />
                {errors.DNI && touched.DNI && <div className="invalid-feedback d-block">{errors.DNI}</div>}
                </FormGroup>
                </Colxx>
                <Colxx sm={4}>
                <FormGroup>
                <Label for="country">
                <IntlMessages id="forms.country" />
                </Label>
                <Select
                components={{ Input: CustomSelectInput }}
                className="react-select"
                classNamePrefix="react-select"
                name="country"
                id="country"
                placeholder={messages["forms.country"]}
                value={this.state.country ? countries.find(c => c.value === this.state.country) : null}
                onChange={this.handleCountryChange}
                dateFormat={dateFormatOptions.spanish}
                options={countries.map((c, i) => { return ({ label: c.label, value: c.value, key: i }) })}
                />
                {errors.country && touched.country && <div className="invalid-feedback d-block">{errors.country}</div>}
                </FormGroup>
                </Colxx>
                <Colxx sm={4}>
                <FormGroup>
                <Label for="bornDate">
                <IntlMessages id="forms.bornDate" />
                </Label>
                <DatePicker
                id="bornDate"
                name="bornDate"
                selected={this.state.bornDate ? new Date(this.state.bornDate) : new Date()}
                onChange={this.handleChangeDate}
                dateFormat={dateFormatOptions.spanish}
                placeholderText={messages["forms.bornDate"]}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                />
                {errors.bornDate && touched.bornDate && <div className="invalid-feedback d-block">{errors.bornDate}</div>}
                </FormGroup>
                </Colxx>
                
                <Colxx sm={6}>
                <FormGroup>
                <Label for="telephone">
                <IntlMessages id="forms.telephone" />
                </Label>
                <Input
                type="telephone"
                name="telephone"
                id="telephone"
                placeholder={messages["forms.telephone"]}
                value={this.state.telephone}
                onChange={this.handleChange}
                />
                {errors.telephone && touched.telephone && <div className="invalid-feedback d-block">{errors.telephone}</div>}
                </FormGroup>
                </Colxx>
                <Colxx sm={6}>
                <FormGroup>
                <Label for="email">
                <IntlMessages id="forms.email" />
                </Label>
                <Input
                type="email"
                name="email"
                id="email"
                placeholder={messages["forms.email"]}
                value={this.state.email}
                onChange={this.handleChange}
                />
                {errors.email && touched.email && <div className="invalid-feedback d-block">{errors.email}</div>}
                </FormGroup>
                </Colxx>
                <Colxx sm={6}>
                <FormGroup>
                    <Label for="diagnosticCenters">
                        <IntlMessages id="forms.diagnosticCenters" />
                    </Label>
                    <AsyncSelect
                        components={{ Input: CustomSelectInput }}
                        className="react-select"
                        classNamePrefix="react-select"
                        isMulti
                        name="diagnosticCenters"
                        id="diagnosticCenters"
                        placeholder={messages["forms.diagnosticCenters"]}
                        value={this.state.diagnosticCenters}
                        onChange={(opt)=>this.handleChangeMulti('diagnosticCenters', opt)}
                        defaultOptions
                        cacheOptions
                        loadOptions={this.loadOptions}
                    />
                    {errors.diagnosticCenters && touched.diagnosticCenters && <div className="invalid-feedback d-block">{errors.diagnosticCenters}</div>}
                </FormGroup>
                </Colxx>
                
                <Colxx sm={6}>
                <FormGroup>
                    <Label for="authDoctors">
                        <IntlMessages id="patients.authDoctors" />
                    </Label>
                    <AsyncSelect
                        key={'field.diagnosticCenters'}
                        components={{ Input: CustomSelectInput }}
                        className="react-select"
                        classNamePrefix="react-select"
                        isMulti
                        name="authDoctors"
                        id="authDoctors"
                        placeholder={messages["forms.authDoctors"]}
                        value={this.state.authDoctors}
                        onChange={(opt)=>this.handleChangeMulti('authDoctors', opt)}
                        defaultOptions
                        cacheOptions
                        loadOptions={this.loadOptionsDoctors}
                    />
                    {errors.authDoctors && touched.authDoctors && <div className="invalid-feedback d-block">{errors.authDoctors}</div>}
                </FormGroup>
                </Colxx>
                
                <Colxx sm={6}>
                <FormGroup>
                <Label for="avatar">
                <IntlMessages id="forms.avatar" />
                </Label>
                <Input
                ref={node => this.file = node}
                type="file"
                name="avatar"
                id="avatar"
                accept=".png, .jpg, .svg"
                placeholder={messages["forms.avatar"]}
                label={this.state.avatar ? this.state.avatar : 'Choose File'}
                onChange={(e) => this.handleChangeFileInput(e, {validateForm})}
                />
                {errors.avatar && touched.avatar && <div className="invalid-feedback d-block">{errors.avatar}</div>}
                {this.state.avatar && <AvatarPreview avatar={this.state.avatar} /> }
                </FormGroup>
                </Colxx>
                <Colxx sm={12} key={`observations`} className={'mt-5'}>
                <FormGroup>
                <div className="d-flex justify-content-between align-items-end mb-2">
                <Label for="observations">
                <IntlMessages id="forms.observations" />
                </Label>
                <Button color={"default"} onClick={this.addObservation}>
                <IntlMessages id="forms.add.observation" />
                </Button>
                </div>
                
                {this.state.observations.map((obs, index) => (
                    
                    <InputGroup className={'mb-2'}>
                    <Input
                    type="input"
                    name={`observation ${index}`}
                    id={`observation ${index}`}
                    placeholder={messages["forms.observations"]}
                    value={obs.what ? obs.what : ''}
                    onChange={(e) => this.handleObservationChange(index, e.target.value)}
                    />
                    <InputGroupAddon addonType="append">
                    <Button onClick={(e) => this.deleteObservation(e, index)} color={'danger'}>X</Button>
                    </InputGroupAddon>
                    </InputGroup>
                    
                    ))}
                    </FormGroup>
                    </Colxx>
                    </FormGroup>
                    <Button color="primary" className="mt-4 float-right">
                    <IntlMessages id="forms.update" />
                    </Button>
                    </Form>)}
                    </Formik>
                    </CardBody>
                    </Card>
                    </Colxx>
                    </Row>
                    </Fragment>
                    )
                }
            }
            
            const mapStateToProps = ({diagnosticcenters, patients, users, authUser}) => ({
                centerOptions: diagnosticcenters.data,
                usersOptions: users.data,
                patients: patients.data,
                me: authUser.user
                
            });
            
            export default injectIntl(connect(mapStateToProps, {updatePatient, filterUsers, filterPatients, getDiagnosticCenters, filterDiagnosticCenters})(CreatePatient));