import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';
import { connect } from "react-redux";
import IntlMessages from './../../util/IntlMessages'

class Footer extends React.Component{
    render(){
        return(
            <div className={`footer-page ${this.props.containerClassnames} w-100`}>
                <Link to={ {pathname:'/app/condition', state: {content: 'condition'} } }>
                    <IntlMessages id={'menu.condition'}/>
                </Link>
                <Link to={ {pathname:'/app/privacy', state: {content: 'privacy'} } }>
                    <IntlMessages id={'menu.privacy'}/>
                </Link>
                <Link to={ {pathname:'/app/legal', state: {content: 'legal'} } }>
                    <IntlMessages id={'menu.legal'}/>
                </Link>
            </div>
        )
    }
}

const initMapStateToProps = ({menu}) =>{
    return {
        containerClassnames: menu.containerClassnames
    }
};

export default connect(initMapStateToProps)(Footer);