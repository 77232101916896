import React, { Component, Fragment } from "react";
import { injectIntl } from "react-intl";
import IntlMessages from "../../../util/IntlMessages";
import { Colxx, Separator } from "../../../components/CustomBootstrap";
import BreadcrumbContainer from "../../../components/BreadcrumbContainer";
import AvatarPreview from '../../../components/AvatarPreview';
import CustomSelectInput from "../../../components/CustomSelectInput";
import { Async as AsyncSelect } from "react-select";
import { Formik, Form, Field } from 'formik';
import { createUser } from '../../../redux/users/actions'
import { filterDiagnosticCenters, getDiagnosticCenters, getUsers } from '../../../redux/actions'
import { findDOMNode } from "react-dom";
import { isCorrectRole } from "../../../components/PrivateRoute";

import {
    Row,
    Card,
    CardTitle,
    FormGroup,
    Label,
    CardBody,
    Button,
    Input,
} from "reactstrap";

//Redux
import { connect } from "react-redux";

//Models
import Doctor from "../../../models/Doctor";
import Technician from "../../../models/Technician";
import Clinic from "../../../models/Clinic";
import Admin from "../../../models/Admin";

import { roles } from "../../../data/roles";

class CreateUser extends Component {
    constructor(props) {
        super(props);

        const rol = roles.find(rol => rol.url === this.props.match.params.role);

        this.state = {
            firstName: "",
            lastName: "",
            collegiateNumber: "",
            clinicName: "",
            password: "",
            repeatPassword: "",
            diagnosticCenters: [],
            email: "",
            DNI: "",
            cif: "",
            idKeyrock: '-',
            role: rol,
            disabled: true,
            avatar: '',
        };

        this.file = null;
        this.DOMfile = null;

        //only admins can access to admin/technician crud
        if (['admin', 'technician'].includes(this.state.role.key) && !isCorrectRole(['admin'])) {
            this.props.history.push('/app/usuarios');
        }
    }

    componentDidMount() {

        if (this.file) {
            this.DOMfile = findDOMNode(this.file);
        }

        this.props.getUsers();
        this.props.getDiagnosticCenters();

    }

    handleInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleChangeMulti = (selectedOptions) => {
        this.setState({ diagnosticCenters: selectedOptions });
    };

    handleCheckboxChange = (e) => {
        this.setState({
            disabled: e.target.checked,
        })
    }

    handleSubmit = (values, { resetForm }) => {
        let userToCreate;
        const user = { ...values };

        switch (this.state.role.key) {
            case 'doctor':
                userToCreate = new Doctor(user);
                break;
            case 'clinic':
                userToCreate = new Clinic(user);
                userToCreate.DNI = values.cif;
                break;
            case 'admin':
                userToCreate = new Admin(user);
                break;
            case 'technician':
                userToCreate = new Technician(user);
                break;
            default:
                break;
        }

        userToCreate.diagnosticCenters = this.state.diagnosticCenters.map(center => center.key);
        userToCreate.disabled = this.state.disabled;
        userToCreate.avatar = this.state.avatar;

        this.props.createUser(userToCreate);

    }

    clearForm({ resetForm }) {
        this.setState({
            diagnosticCenters: [],
            avatar: ''
        })
        resetForm();
    }

    loadOptions = (inputValue, callback) => {
        this.valueInFilter = inputValue;
        setTimeout(() => {
            if (this.valueInFilter === inputValue) {
                if (inputValue.length > 2)
                    this.props.filterDiagnosticCenters({ name: inputValue });
                else
                    this.props.getDiagnosticCenters();

                setTimeout(() => {
                    callback(this.parseToOptions());
                }, 700);
            }
        }, 500);
    };

    handleChangeFileInput = (e, { values, validateForm }) => {
        const name = e.target.name;

        if (this.DOMfile && this.DOMfile.files && this.DOMfile.files[0]) {
            if (this.DOMfile.files[0].size <= 100000) {
                let reader = new FileReader();
                reader.readAsDataURL(this.DOMfile.files[0]);
                reader.onloadend = () => {
                    this.setState({
                        [name]: reader.result
                    }, () => {
                        values.errorFotoSize = false;
                        validateForm();
                    });
                };

            } else {
                values.errorFotoSize = true;
                e.target.value = '';
                validateForm();
            }
        }
    };

    parseToOptions = () => {
        const { centerOptions } = this.props;
        return centerOptions.map(center => ({
            label: center.name,
            value: center._id,
            key: center._id
        }))
    };

    validate = (values) => {
        //TODO: refactor
        let errors = {};
        const { role } = this.state;
        if (role.key !== 'clinic') {
            if (!values.firstName) {
                errors.firstName = "forms.validation.firstName";
            }
            if (!values.lastName) {
                errors.lastName = "forms.validation.lastName";
            }
            // if (!values.DNI) {
            //     errors.DNI = "forms.validation.dni";
            // }
        } else {
            if (!values.clinicName) {
                errors.clinicName = "forms.validation.clinicName";
            }

            // if (!values.cif) {
            //     errors.cif = "forms.validation.cif";
            // }
        }

        // if (role.key === 'doctor') {
        // if (!values.collegiateNumber) {
        //     errors.collegiateNumber = "forms.validation.collegiateNumber";
        // }
        // }

        if (!values.email ||  /^.+\d+$/.exec(values.email)) {
            errors.email = "forms.validation.email";
        }

        if (this.state.role.key !== 'doctor') {
            if (!values.password) {
                errors.password = "forms.validation.password";
            }
    
            if (!values.repeatPassword) {
                errors.repeatPassword = "forms.validation.repeatPassword";
            }
        }

        // if(this.state.avatar === ''){
        //     errors.avatar = "forms.validation.avatar";
        // } else {
        if (values.errorFotoSize) {
            errors.avatar = "forms.validation.avatar";
            this.setState({
                avatar: '',
            })
        }
        // }


        // Accurated rules

        if (values.password && values.repeatPassword && values.password !== values.repeatPassword) {
            errors.repeatPassword = "forms.validation.notEqualPasswords";
            errors.password = "forms.validation.notEqualPasswords";
        }

        return errors;
    }

    render() {
        const { role } = this.state;
        const { messages } = this.props.intl;

        return (
            <Fragment>
                <Row>
                    <Colxx xxs="12">
                        <BreadcrumbContainer
                            heading={<IntlMessages id={`${role.key}.create`} />}
                            match={this.props.match}
                            role={this.state.role.url}
                        />
                        <Separator className="mb-5" />
                    </Colxx>
                </Row>
                <Row className="mb-4">
                    <Colxx xxs="12">
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <IntlMessages id={`${role.key}.add-new`} />
                                </CardTitle>
                                <Formik
                                    validate={this.validate}
                                    initialValues={this.state}
                                    onSubmit={this.handleSubmit}
                                    validateOnBlur={false}
                                    validateOnChange={false}>
                                    {({ errors, touched, isValidating, setFieldValue, setFieldTouched, validateForm, values }) => (<Form className="av-tooltip tooltip-label-right">
                                        <FormGroup row>
                                            {role.key !== 'clinic' && <>
                                                <Colxx sm={6}>
                                                    <FormGroup>
                                                        <Label for="firstName">
                                                            <IntlMessages id="forms.firstName" />
                                                        </Label>
                                                        <Field
                                                            className="form-control"
                                                            type="text"
                                                            name="firstName"
                                                            id="firstName"
                                                            placeholder={messages["forms.firstName"]}
                                                        />
                                                        {errors.firstName && touched.firstName && <div className="invalid-feedback d-block"><IntlMessages id={errors.firstName} /></div>}
                                                    </FormGroup>
                                                </Colxx>

                                                <Colxx sm={6}>
                                                    <FormGroup>
                                                        <Label for="lastName">
                                                            <IntlMessages id="forms.lastName" />
                                                        </Label>
                                                        <Field
                                                            className="form-control"
                                                            type="text"
                                                            name="lastName"
                                                            id="lastName"
                                                            placeholder={messages["forms.lastName"]}
                                                        />
                                                        {errors.lastName && touched.lastName && <div className="invalid-feedback d-block"><IntlMessages id={errors.lastName} /></div>}
                                                    </FormGroup>
                                                </Colxx>

                                                <Colxx sm={6}>
                                                    <FormGroup>
                                                        <Label for="DNI">
                                                            <IntlMessages id="forms.dni" />
                                                        </Label>
                                                        <Field
                                                            className="form-control"
                                                            type="text"
                                                            name="DNI"
                                                            id="DNI"
                                                            placeholder={messages["forms.dni"]}
                                                        />
                                                        {errors.DNI && touched.DNI && <div className="invalid-feedback d-block"><IntlMessages id={errors.DNI} /></div>}
                                                    </FormGroup>
                                                </Colxx>

                                                {role.key === 'doctor' && <Colxx sm={6}>
                                                    <FormGroup>
                                                        <Label for="collegiateNumber">
                                                            <IntlMessages id="forms.collegiateNumber" />
                                                        </Label>
                                                        <Field
                                                            className="form-control"
                                                            type="text"
                                                            name="collegiateNumber"
                                                            id="collegiateNumber"
                                                            placeholder={messages["forms.collegiateNumber"]}
                                                        />
                                                        {errors.collegiateNumber && touched.collegiateNumber && <div className="invalid-feedback d-block"><IntlMessages id={errors.collegiateNumber} /></div>}
                                                    </FormGroup>
                                                </Colxx>}</>}
                                            {role.key === 'clinic' && <><Colxx sm={6}>
                                                <FormGroup>
                                                    <Label for="clinicName">
                                                        <IntlMessages id="forms.clinicName" />
                                                    </Label>
                                                    <Field
                                                        className="form-control"
                                                        type="text"
                                                        name="clinicName"
                                                        id="clinicName"
                                                        placeholder={messages["forms.clinicName"]}
                                                    />
                                                    {errors.clinicName && touched.clinicName && <div className="invalid-feedback d-block"><IntlMessages id={errors.clinicName} /></div>}
                                                </FormGroup>
                                            </Colxx>
                                                <Colxx sm={6}>
                                                    <FormGroup>
                                                        <Label for="cif">
                                                            <IntlMessages id="forms.cif" />
                                                        </Label>
                                                        <Field
                                                            className="form-control"
                                                            type="text"
                                                            name="cif"
                                                            id="cif"
                                                            placeholder={messages["forms.cif"]}
                                                        />
                                                        {errors.cif && touched.cif && <div className="invalid-feedback d-block"><IntlMessages id={errors.cif} /></div>}
                                                    </FormGroup>
                                                </Colxx>
                                            </>}
                                            <Colxx>
                                                <FormGroup>
                                                    <Label for="email">
                                                        <IntlMessages id="forms.email" />
                                                    </Label>
                                                    <Field
                                                        className="form-control"
                                                        type="email"
                                                        name="email"
                                                        id="email"
                                                        placeholder={messages["forms.email"]}
                                                        autoComplete="new-email"
                                                    />
                                                    {errors.email && touched.email && <div className="invalid-feedback d-block"><IntlMessages id={errors.email} /></div>}
                                                </FormGroup>
                                            </Colxx>

                                            <Colxx>
                                                <FormGroup>
                                                    <Label for="diagnosticCenters">
                                                        <IntlMessages id="forms.diagnosticCenters" />
                                                    </Label>

                                                    <Field name="diagnosticCenters"
                                                           render={({ field }) => (
                                                               <AsyncSelect
                                                                   components={{ Input: CustomSelectInput }}
                                                                   className="react-select"
                                                                   classNamePrefix="react-select"
                                                                   isMulti
                                                                   name="diagnosticCenters"
                                                                   id="diagnosticCenters"
                                                                   placeholder={messages["forms.diagnosticCenters"]}
                                                                   value={this.state.diagnosticCenters}
                                                                   onChange={(selectedOptions, value) => { this.handleChangeMulti(selectedOptions); setFieldValue("diagnosticCenters", value) }}
                                                                   defaultOptions
                                                                   cacheOptions
                                                                   loadOptions={this.loadOptions}
                                                                   onBlur={() => setFieldTouched("diagnosticCenters", true)}
                                                               />
                                                           )}
                                                    />
                                                    {errors.diagnosticCenters && touched.diagnosticCenters && <div className="invalid-feedback d-block"><IntlMessages id={errors.diagnosticCenters} /></div>}

                                                </FormGroup>
                                            </Colxx>
                                            <div className="w-100"></div>
                                            {this.state.role.key !== 'doctor'
                                            ?   (<Colxx sm={6}>
                                                    <FormGroup>
                                                        <Label for="password">
                                                            <IntlMessages id="forms.password" />
                                                        </Label>
                                                        <Field
                                                            className="form-control"
                                                            type="password"
                                                            name="password"
                                                            id="password"
                                                            placeholder={messages["forms.password"]}
                                                            autoComplete="new-password"
                                                        />
                                                        {errors.password && touched.password && <div className="invalid-feedback d-block"><IntlMessages id={errors.password} /></div>}

                                                    </FormGroup>
                                                </Colxx>)
                                            : null
                                            }
                                            {this.state.role.key !== 'doctor'
                                            ?   (<Colxx sm={6}>
                                                    <FormGroup>
                                                        <Label for="repeatPassword">
                                                            <IntlMessages id="forms.repeatPassword" />
                                                        </Label>
                                                        <Field
                                                            className="form-control"
                                                            type="password"
                                                            name="repeatPassword"
                                                            id="repeatPassword"
                                                            placeholder={messages["forms.repeatPassword"]}
                                                            autoComplete="new-password"
                                                        />
                                                        {errors.repeatPassword && touched.repeatPassword && <div className="invalid-feedback d-block"><IntlMessages id={errors.repeatPassword} /></div>}

                                                    </FormGroup>
                                                </Colxx>)
                                            : null
                                            }

                                            <Colxx sm={6}>
                                                <FormGroup>
                                                    <Label for="avatar">
                                                        <IntlMessages id="forms.avatar" />
                                                    </Label>
                                                    <Input
                                                        ref={node => this.file = node}
                                                        type="file"
                                                        name="avatar"
                                                        id="avatar"
                                                        accept=".png, .jpg, .svg"
                                                        placeholder={messages["forms.avatar"]}
                                                        label={this.state.avatar ? this.state.avatar : 'Choose File'}
                                                        onChange={(e) => { this.handleChangeFileInput(e, { values, validateForm }) }}
                                                    />
                                                    {errors.avatar && <div className="invalid-feedback d-block"><IntlMessages id={errors.avatar} /></div>}
                                                    {this.state.avatar && <AvatarPreview avatar={this.state.avatar} /> }
                                                </FormGroup>
                                            </Colxx>
                                            <Colxx sm={6}>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Field name="disabled" id="disabled" type="checkbox" className="form-check-input" checked={this.state.disabled ? 'checked' : ''} onChange={this.handleCheckboxChange} />{' '}
                                                        <IntlMessages id="forms.disabled" />
                                                    </Label>
                                                </FormGroup>
                                            </Colxx>
                                        </FormGroup>
                                        <Button color="primary" className="mt-4 float-right" type="submit"><IntlMessages id="forms.create" /></Button>
                                    </Form>)}
                                </Formik>
                            </CardBody>
                        </Card>
                    </Colxx>
                </Row>
            </Fragment>
        )
    }
}
const mapStateToProps = ({ diagnosticcenters, users }) => (
    {
        centerOptions: diagnosticcenters.data,
        users: users.data,
        loading: users.loading,
    }
)


export default injectIntl(connect(mapStateToProps, { createUser, filterDiagnosticCenters, getDiagnosticCenters, getUsers })(CreateUser))

