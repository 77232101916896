import React from 'react';
import {Button, FormGroup, Input, InputGroup, InputGroupAddon, Label} from "reactstrap";
import IntlMessages from "../../../../util/IntlMessages";
import Observation from "../../../../models/Observation";
import store from "../../../../redux/store";
import {setObservations} from "../../../../redux/registry/actions";
import {connect} from 'react-redux';

class ObservationsModalBody extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            okOption: this.props.onOkOption,
            observations: this.props.registry.observations ? [...this.props.registry.observations] : []
        };
    }

    componentDidMount() {
        const ModalDialog = document.getElementsByClassName('modal-dialog');
        ModalDialog[0].style.maxWidth = "1000px";
    }

    componentWillUpdate(nextProps){
        if (this.props.onOkOption !== nextProps.onOkOption && this.state.okOption !== nextProps.onOkOption){
            this.setState({
                okOption: nextProps.onOkOption,
            })
        }
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        const { registry } = this.props;
        if(this.state.okOption === true) {
            console.log(this.state.observations);
            this.props.setObservations(registry._id, this.parseToObservations(this.state.observations) ,undefined);
            this.setState({ okOption: false }, this.props.onToggle);
        }

        if(prevProps.registry.observations !== this.props.registry.observations)
            this.setState({
                observations: this.props.registry.observations
            })
    };


    addObservation = (e) =>{
        e.preventDefault();
        e.stopPropagation();

        const aux = this.state.observations;
        aux.push({
            "_id": undefined,
            who: this.props.me,
            what: '' ,
            when: new Date()
        });

        this.setState({
            observations: [...aux]
        })
    };

    deleteObservation = (e, index) => {
        e.preventDefault();
        e.stopPropagation();

        const aux = this.state.observations;
        aux.splice(index,1);

        this.setState({
            observations: [...aux]
        })
    };


    handleObservationChange = (observation, value) => {
        if(this.checkOwner(observation)) {
            const aux = [...this.state.observations];
            const index = aux.indexOf(observation);
            aux[index] = {...aux[index], what: value};
            this.setState({
                observations: [...aux]
            })
        }
    };

    parseToObservations() {
        return this.state.observations.map(obs => {
            return {
                "_id": obs._id ? obs._id : undefined,
                who: obs.who ? {...obs.who} : this.props.me,
                what:obs.what ,
                when: obs.when ? obs.when : new Date()
            }
        });
    }

    checkOwner = (obs) =>{
        const { me } = this.props;
        return obs.who.id ? obs.who.id === me._id: obs.who._id ? obs.who._id === me._id: false
    };

    render(){
        const { observations } = this.state;
        const { messages } = this.props;
        return(
            <>
                <FormGroup>
                    { observations.length > 0 ?
                        observations.map((obs, index) => (
                            <InputGroup className={'mb-2'}>
                                <Input
                                    disabled={!this.checkOwner(obs)}
                                    type="input"
                                    name={obs._id ? obs._id : index}
                                    id={`observation ${index}`}
                                    placeholder={messages["forms.observations"]}
                                    value={obs.what}
                                    onChange={(e) => this.handleObservationChange(obs, e.target.value)}
                                />
                                <InputGroupAddon addonType="append">
                                    <Button onClick={(e) => this.deleteObservation(e, index)}
                                            color={'danger'}>X</Button>
                                </InputGroupAddon>
                            </InputGroup>

                        )) :
                        <IntlMessages id={'forms.non.observation'}/>
                    }
                </FormGroup>
                <div className="w-100 d-flex justify-content-end align-items-end mb-2">
                    <Button color={"default"} onClick={this.addObservation}>
                        <IntlMessages id="forms.add.observation" />
                    </Button>
                </div>
            </>
        )
    }
}


const mapStateToProps = ({authUser}) => ({
    me: authUser.user
});

export default connect(mapStateToProps, {setObservations})(ObservationsModalBody);