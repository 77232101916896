import {
    GET_LEGAL_TEXT,
    UPDATE_LEGAL_TEXT,
    GET_LEGAL_TEXT_SUCCESS,
    UPDATE_LEGAL_TEXT_SUCCESS,
    CREATE_LEGAL_TEXT,
    CREATE_LEGAL_TEXT_SUCCESS
} from './../../constants/actionTypes';


export const getLegalTexts = () => ({
    type: GET_LEGAL_TEXT,
});

export const getLegalTextsSuccess = (payload) => ({
    type: GET_LEGAL_TEXT_SUCCESS,
    payload: payload
});

export const createLegalTexts = ({type,text}) => ({
    type: CREATE_LEGAL_TEXT,
    payload: {type,text}
});

export const createLegalTextsSuccess = (payload) => ({
    type: CREATE_LEGAL_TEXT_SUCCESS,
    payload: payload
});

export const updateLegalTexts = ({type,text}) => ({
    type: UPDATE_LEGAL_TEXT,
    payload: {type,text}
});

export const updateLegalTextsSuccess = (payload) => ({
    type: UPDATE_LEGAL_TEXT_SUCCESS,
    payload: payload
});