import React, { Component, Fragment } from "react";
import Checkbox from '../../../components/Checkbox';
import { injectIntl } from "react-intl";
import PageHeader from "../../../components/PageHeader";
import Select, { Async as AsyncSelect } from "react-select";
import { findDOMNode } from 'react-dom';
import AvatarPreview from '../../../components/AvatarPreview';
import IntlMessages from "../../../util/IntlMessages";
import { Colxx } from "../../../components/CustomBootstrap";
import CustomSelectInput from "../../../components/CustomSelectInput";
import { Formik, Form, Field } from 'formik';
import {
    filterDiagnosticCenters,
    getDiagnosticCenters,
    getUser,
    updateUser,
    updateUserRequest,
    filterUsersRequest,
    updatePassword,
} from '../../../redux/actions'
import { isCorrectRole } from "../../../components/PrivateRoute";
import {
    Row,
    Card,
    CardTitle,
    FormGroup,
    Label,
    CardBody,
    Button,
    Input, InputGroup, InputGroupAddon, InputGroupText
} from "reactstrap";

//Redux
import { connect } from "react-redux";

//Data
import { roles } from "../../../data/roles";

//Models
import Doctor from "../../../models/Doctor";
import Technician from "../../../models/Technician";
import Clinic from "../../../models/Clinic";
import Admin from "../../../models/Admin";
import WithModal from "../../../components/Modal";

class FormPasswordToValidate extends React.Component{
    constructor(props){
        super(props);
        this.state={
            user:'',
            okOption: this.props.onOkOption,
            password:'',
            repeatPassword:'',
            showPassword:'',
            showRepeatPassword:'',
            errors:{}
        }
    }

    componentDidMount(){
        const user = this.props.users.find( u=>u._id === this.props.userId );
        user && this.setState({user})
    }

    componentWillUpdate(nextProps, nextState){
        if (this.props.onOkOption !== nextProps.onOkOption || this.state.okOption !== nextProps.onOkOption){
            this.setState({
                okOption: nextProps.onOkOption,
            })
        }
    }

    validatePasswords =() =>{
        const errors={};

        if (!this.state.password) {
            errors.password = "forms.validation.password";
        }

        if (!this.state.repeatPassword) {
            errors.repeatPassword = "forms.validation.repeatPassword";
        }

        if (this.state.repeatPassword !== this.state.password) {
            errors.repeatPassword = "forms.validation.notEqualPasswords";
        }

        this.props.onToggle(false);
        this.setState({ errors: errors, okOption:false });
        return Object.keys(errors).length === 0
    };

    componentDidUpdate = () => {
        if(this.state.okOption === true) {
            if(this.validatePasswords()){
                const user = this.state.user;
                this.props.updatePassword({ id: this.props.userId, password:this.state.password });
                this.setState({
                    okOption: false,
                },this.props.onToggle)

            }
        }
    };

    handleChange=(e)=>{
        this.setState({
            [e.target.name]:e.target.value,
            errors:{...this.state.errors, [e.target.name]: undefined}
        })
    };

    showPassword=(e, key)=>{
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            [`show${key}`]: !this.state[`show${key}`]
        })
    };

    render(){
        const {intl} = this.props;
        const {messages} = intl;
        const {errors} = this.state;
        return(
          <>
              <h4 className={'mb-4'}>
                  <IntlMessages id={'forms.updatePasswordMessage'}/>
              </h4>

              <Colxx xs={12}>
                  <FormGroup>
                      <Label for="password">
                          <IntlMessages id="forms.password" />
                      </Label>
                      <InputGroup>
                          <Input className="form-control"
                                 type={this.state.showPassword ? 'text' : 'password'}
                                 name="password"
                                 id="name"
                                 onChange={this.handleChange}
                                 value={this.state.password}
                                 placeholder={messages["forms.password"]}
                          />
                          <InputGroupAddon
                            className={'dident-cursor-pointer'}
                            addonType="append"
                            onClickCapture={(e)=>this.showPassword(e,'Password')}
                          >
                              <InputGroupText>
                                  <i className="simple-icon-eye"/>
                              </InputGroupText>
                          </InputGroupAddon>
                      </InputGroup>
                      {errors.password && <div className="invalid-feedback d-block">{messages[errors.password]}</div>}
                  </FormGroup>
              </Colxx>

              <Colxx xs={12}>
                  <FormGroup>
                      <Label for="repeatPassword">
                          <IntlMessages id="forms.repeatPassword" />
                      </Label>
                      <InputGroup>
                          <Input className="form-control"
                                 type={this.state.showRepeatPassword ? 'text' : 'password'}
                                 name="repeatPassword"
                                 id="street"
                                 onChange={this.handleChange}
                                 value={this.state.repeatPassword}
                                 placeholder={messages["forms.repeatPassword"]}
                          />
                          <InputGroupAddon
                            className={'dident-cursor-pointer'}
                            addonType="append"
                            onClickCapture={(e)=>this.showPassword(e,'RepeatPassword')}
                          >
                              <InputGroupText>
                                  <i className="simple-icon-eye"/>
                              </InputGroupText>
                          </InputGroupAddon>
                      </InputGroup>
                      {errors.repeatPassword && <div className="invalid-feedback d-block">{messages[errors.repeatPassword]}</div>}
                  </FormGroup>
              </Colxx>
          </>
        )
    }
}

const mapStateToPropsTop = ({usersRequest, users}) => ({
    users: usersRequest.data,
    user: users.data,
});

const ActivateModal = WithModal(connect(mapStateToPropsTop,{updatePassword})(injectIntl(FormPasswordToValidate)));

class EditUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: "",
            lastName: "",
            collegiateNumber: "",
            clinicName: "",
            diagnosticCenters: [],
            email: "",
            role: this.props.role.key,
            disabled: true,
            originalUser: null,
            avatar: '',
            DNI: '',
            modal:false,
        };

        this.file = null;
        this.DOMfile = null;

        //only admins can access to admin/technician crud
        if (!this.isProfile() && ['admin', 'technician'].includes(this.state.role.key) && !isCorrectRole(['admin'])) {
            this.props.history.push('/app/usuarios');
        }
    }

    componentDidMount() {
        const { user,userRequest, match, getUser, filterUsersRequest } = this.props;

        if (this.file) this.DOMfile = findDOMNode(this.file);


        if(!match.path.includes('solicitudes')){ //Come from "solicitudes"
            const userFound = user ? Array.isArray(user) ? user.find(user => user._id === this.props.match.params.id) : user : undefined;
            if (!userFound) getUser(match.params.id);
            else {
                this.setState({...userFound});
                this.parseUserToState(userFound)
            }
        }
        else { //Come form users list
            const userFound = userRequest ? Array.isArray(userRequest) ? userRequest.find(user => user._id === this.props.match.params.id) : userRequest : undefined;
            if (!userFound) filterUsersRequest({_id:match.params.id});
            else {
                this.setState({...userFound});
                this.parseUserToState(userFound)
            }
        }

        this.props.getDiagnosticCenters();

    }

    parseUserToState = (user) => {
        let diagnosticCenters = [];
        if(Array.isArray(user.diagnosticCenters))
        diagnosticCenters = this.parseToDiagnosticCenter(user.diagnosticCenters);
        const identificationDocument = user.role === 'clinic' ? 'cif' : 'DNI';
        this.setState({
            ...user,
            [identificationDocument]: user.DNI,
            originalUser: user,
            diagnosticCenters
        })
    };

    parseToDiagnosticCenter = (diagnosticCentersIds) => {
        let diagnosticCenters = [];

        if (diagnosticCentersIds.length > 0 && diagnosticCentersIds[0].name) {
            diagnosticCenters = diagnosticCentersIds.map(diagnosticCenter => ({
                key: diagnosticCenter._id,
                label: diagnosticCenter.name,
                value: diagnosticCenter._id
            }))
        } else {
            const { centerOptions } = this.props;
            if (centerOptions) {
                diagnosticCentersIds.forEach((center) => {
                    const _center = centerOptions.find(option => option._id === center);
                    diagnosticCenters.push({
                        key: _center._id,
                        label: _center.name,
                        value: _center._id
                    })
                })
            }
        }
        return diagnosticCenters;
    };

    componentDidUpdate(prevProps, prevState, prevContext) {
        if (!this.props.match.path.includes('solicitudes') && ((this.props.user !== this.state.originalUser && prevProps.user !== this.props.user) || prevProps.role !== this.props.role))
            this.parseUserToState(this.props.user);

        if (this.props.match.path.includes('solicitudes') && ((this.props.userRequest !== this.state.originalUser && prevProps.userRequest !== this.props.userRequest) || prevProps.role !== this.props.role))
            this.parseUserToState(this.props.userRequest);

        if (prevProps.role.key !== this.state.role) {
            this.setState({role: prevProps.role.key})
        }
    }

    handleInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleRoleChange = e => {
        this.setState({
            role: e.value,
        });
        this.props.onRoleChange(e.value);
    };

    handleChangeMulti = (selectedOptions) => {
        this.setState({ diagnosticCenters: selectedOptions });
    };

    handleCheckboxChange = (e) => {
        this.setState({
            disabled: e.target.checked,
        })
    };

    isProfile = () => {
        return this.props.match.url === '/app/profile/editar';
    };

    handleSubmit = (values, { resetForm }) => {
        let userToEdit;
        const user = { ...values };
        const { diagnosticCenters, disabled } = this.state;
        const { updateUser, updateUserRequest, match } = this.props;

        switch (this.state.role) {
            case 'doctor':
                userToEdit = new Doctor(user);
                break;
            case 'clinic':
                userToEdit = new Clinic(user);
                userToEdit.DNI = values.cif;
                break;
            case 'admin':
                userToEdit = new Admin(user);
                break;
            case 'technician':
                userToEdit = new Technician(user);
                break;
            default:
                break;
        }

        userToEdit.diagnosticCenters = diagnosticCenters.map(center => center.key);
        userToEdit.disabled = disabled;
        userToEdit.id = match.params.id;
        userToEdit.avatar = this.state.avatar;
        delete userToEdit.email;
        delete userToEdit.schema;

        if(!match.path.includes('solicitudes')) updateUser(userToEdit, this.isProfile());
        else updateUserRequest(userToEdit);


    };

    handleChangeFileInput = (e, { values, validateForm }) => {
        const name = e.target.name;

        if (this.DOMfile && this.DOMfile.files && this.DOMfile.files[0]) {
            if (this.DOMfile.files[0].size <= 100000) {
                let reader = new FileReader();
                reader.readAsDataURL(this.DOMfile.files[0]);
                reader.onloadend = () => {
                    this.setState({
                        [name]: reader.result
                    });
                };
                values.errorFotoSize = false;
                validateForm();

            } else {
                values.errorFotoSize = true;
                e.target.value = '';
                validateForm();
            }
        }

    };

    clearForm({ resetForm }) {
        this.setState({
            diagnosticCenters: []
        });
        resetForm();
    }

    loadOptions = (inputValue, callback) => {
        this.valueInFilter = inputValue;
        setTimeout(() => {
            if (this.valueInFilter === inputValue) {
                if (inputValue.length > 2)
                    this.props.filterDiagnosticCenters({ name: inputValue });
                else
                    this.props.getDiagnosticCenters();

                setTimeout(() => {
                    callback(this.parseToOptions());
                }, 700);
            }
        }, 500);
    };

    parseToOptions = () => {
        const { centerOptions } = this.props;
        return centerOptions.map(center => ({
            label: center.name,
            value: center._id,
            key: center._id
        }))
    };

    selectRolesOptions = () => {
        return roles.map((role, i) => {
            return ({ label: <IntlMessages id={`forms.${role.key}`} />, value: role.key, key: i })
        })
    };

    validate = (values) => {

        //TODO: refactor
        let errors = {};
        const { role } = this.props;

        if (role.key !== 'clinic') {
            if (!values.firstName) {
                errors.firstName = "forms.validation.firstName";
            }
            if (!values.lastName) {
                errors.lastName = "forms.validation.lastName";
            }
            // if (!values.DNI) {
            //     errors.DNI = "forms.validation.dni";
            // }
        } else {
            if (!values.clinicName) {
                errors.clinicName = "forms.validation.clinicName";
            }

            // if (!values.cif) {
            //     errors.cif = "forms.validation.cif";
            // }
        }

        // if (role.key === 'doctor') {
        //     if (!values.collegiateNumber) {
        //         errors.collegiateNumber = "forms.validation.collegiateNumber";
        //     }
        // }

        if (!values.email) {
            errors.email = "forms.validation.email";
        }

        if (values.errorFotoSize) {
            errors.avatar = "forms.validation.avatar";
            this.setState({
                avatar: '',
            })
        }

        return errors;
    };

    toggle = (e,data) =>{
        if(e) {
            e.preventDefault && e.preventDefault();
            e.stopPropagation && e.stopPropagation();
        }
        this.setState(prevState => ({
            modal: !prevState.modal,
            data: data
        }));
    };

    render() {
        const { role } = this.props;
        const { messages } = this.props.intl;

        return (
            <Fragment>
                <Row>
                    <Colxx xxs="12">
                        <PageHeader
                            heading={<IntlMessages id={`${role.key}.edit`} />}
                            match={this.props.match}
                            role={this.props.match.params.role}
                        />
                    </Colxx>
                </Row>
                <Row className="mb-4">
                    <Colxx xxs="12">
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <IntlMessages id={`${role.key}.edit`} />
                                </CardTitle>
                                <Formik
                                    validate={this.validate}
                                    initialValues={this.state}
                                    onSubmit={this.handleSubmit}
                                    validateOnBlur={false}
                                    validateOnChange={false}
                                    enableReinitialize={true}
                                    autoComplete="off">
                                    {({ values, errors, touched, isValidating, setFieldValue, setFieldTouched, validateForm }) => (<Form className="av-tooltip tooltip-label-right">
                                        <FormGroup row>
                                            {role.key !== 'clinic' && <>
                                                <Colxx sm={6}>
                                                    <FormGroup>
                                                        <Label for="firstName">
                                                            <IntlMessages id="forms.firstName" />
                                                        </Label>
                                                        <Field
                                                            className="form-control"
                                                            type="text"
                                                            name="firstName"
                                                            id="firstName"
                                                            placeholder={messages["forms.firstName"]}
                                                        />
                                                        {errors.firstName && touched.firstName && <div className="invalid-feedback d-block"><IntlMessages id={errors.firstName} /></div>}
                                                    </FormGroup>
                                                </Colxx>

                                                <Colxx sm={6}>
                                                    <FormGroup>
                                                        <Label for="lastName">
                                                            <IntlMessages id="forms.lastName" />
                                                        </Label>
                                                        <Field
                                                            className="form-control"
                                                            type="text"
                                                            name="lastName"
                                                            id="lastName"
                                                            placeholder={messages["forms.lastName"]}
                                                        />
                                                        {errors.lastName && touched.lastName && <div className="invalid-feedback d-block"><IntlMessages id={errors.lastName} /></div>}
                                                    </FormGroup>
                                                </Colxx>

                                                <Colxx sm={6}>
                                                    <FormGroup>
                                                        <Label for="DNI">
                                                            <IntlMessages id="forms.dni" />
                                                        </Label>
                                                        <Field
                                                            className="form-control"
                                                            type="text"
                                                            name="DNI"
                                                            id="DNI"
                                                            placeholder={messages["forms.dni"]}
                                                            disabled={this.isProfile() ? 'disabled' : ''}
                                                        />
                                                        {errors.DNI && touched.DNI && <div className="invalid-feedback d-block"><IntlMessages id={errors.DNI} /></div>}
                                                    </FormGroup>
                                                </Colxx>

                                                {role.key === 'doctor' && <Colxx sm={6}>
                                                    <FormGroup>
                                                        <Label for="collegiateNumber">
                                                            <IntlMessages id="forms.collegiateNumber" />
                                                        </Label>
                                                        <Field
                                                            className="form-control"
                                                            type="text"
                                                            name="collegiateNumber"
                                                            id="collegiateNumber"
                                                            placeholder={messages["forms.collegiateNumber"]}
                                                        />
                                                        {errors.collegiateNumber && touched.collegiateNumber && <div className="invalid-feedback d-block"><IntlMessages id={errors.collegiateNumber} /></div>}
                                                    </FormGroup>
                                                </Colxx>}</>}
                                            {role.key === 'clinic' && <><Colxx sm={6}>
                                                <FormGroup>
                                                    <Label for="clinicName">
                                                        <IntlMessages id="forms.clinicName" />
                                                    </Label>
                                                    <Field
                                                        className="form-control"
                                                        type="text"
                                                        name="clinicName"
                                                        id="clinicName"
                                                        placeholder={messages["forms.clinicName"]}
                                                    />
                                                    {errors.clinicName && touched.clinicName && <div className="invalid-feedback d-block"><IntlMessages id={errors.clinicName} /></div>}
                                                </FormGroup>
                                            </Colxx>
                                                <Colxx sm={6}>
                                                    <FormGroup>
                                                        <Label for="cif">
                                                            <IntlMessages id="forms.cif" />
                                                        </Label>
                                                        <Field
                                                            className="form-control"
                                                            type="text"
                                                            name="cif"
                                                            id="cif"
                                                            placeholder={messages["forms.cif"]}
                                                        />
                                                        {errors.cif && touched.cif && <div className="invalid-feedback d-block"><IntlMessages id={errors.cif} /></div>}
                                                    </FormGroup>
                                                </Colxx></>
                                            }
                                            <Colxx>
                                                <FormGroup sm={isCorrectRole(['admin']) ? 6 : 12} >
                                                    <Label for="email">
                                                        <IntlMessages id="forms.email" />
                                                    </Label>
                                                    <Field
                                                        className="form-control"
                                                        type="email"
                                                        name="email"
                                                        id="email"
                                                        placeholder={messages["forms.email"]}
                                                        autoComplete={this.state.email}
                                                        disabled
                                                    />
                                                    {errors.email && touched.email && <div className="invalid-feedback d-block"><IntlMessages id={errors.email} /></div>}
                                                </FormGroup>
                                            </Colxx>

                                            {(!this.isProfile() && isCorrectRole(['admin'])) && <Colxx>
                                                <FormGroup>
                                                    <Label for="role">
                                                        <IntlMessages id="forms.role" />
                                                    </Label>
                                                    <Field name="role" render={() => (
                                                        <Select
                                                            components={{ Input: CustomSelectInput }}
                                                            className="react-select"
                                                            classNamePrefix="react-select"
                                                            name="role"
                                                            id="role"
                                                            placeholder={messages["forms.role"]}
                                                            value={this.state.role ? this.selectRolesOptions().find(role => role.value === this.state.role) : null}
                                                            onChange={this.handleRoleChange}
                                                            disabled={this.isProfile() ? 'disabled' : ''}
                                                            options={this.selectRolesOptions()} />)}
                                                    />
                                                </FormGroup>
                                            </Colxx>}

                                            {!this.isProfile() && <Colxx sm={6}>
                                                <FormGroup>
                                                    <Label for="diagnosticCenters">
                                                        <IntlMessages id="forms.diagnosticCenters" />
                                                    </Label>

                                                    <Field name="diagnosticCenters"
                                                           render={({ field }) => (
                                                               <AsyncSelect
                                                                   components={{ Input: CustomSelectInput }}
                                                                   className="react-select"
                                                                   classNamePrefix="react-select"
                                                                   isMulti
                                                                   name="diagnosticCenters"
                                                                   id="diagnosticCenters"
                                                                   placeholder={messages["forms.diagnosticCenters"]}
                                                                   value={this.state.diagnosticCenters}
                                                                   onChange={(selectedOptions, value) => { this.handleChangeMulti(selectedOptions); setFieldValue("diagnosticCenters", value) }}
                                                                   defaultOptions
                                                                   cacheOptions
                                                                   loadOptions={this.loadOptions}
                                                                   onBlur={() => setFieldTouched("diagnosticCenters", true)}
                                                               />
                                                           )}
                                                    />
                                                    {errors.diagnosticCenters && touched.diagnosticCenters && <div className="invalid-feedback d-block"><IntlMessages id={errors.diagnosticCenters} /></div>}

                                                </FormGroup>
                                            </Colxx>}
                                            <div className="w-100"></div>
                                            <Colxx sm={6}>
                                                <FormGroup>
                                                    <Label for="avatar">
                                                        <IntlMessages id="forms.avatar" />
                                                    </Label>
                                                    <Input
                                                        ref={node => this.file = node}
                                                        type="file"
                                                        name="avatar"
                                                        id="avatar"
                                                        accept=".png, .jpg, .svg"
                                                        placeholder={messages["forms.avatar"]}
                                                        label={this.state.avatar ? this.state.avatar : 'Choose File'}
                                                        onChange={(e) => { this.handleChangeFileInput(e, { values, validateForm }) }}
                                                    />
                                                    {errors.avatar && <div className="invalid-feedback d-block"><IntlMessages id={errors.avatar} /></div>}
                                                    {this.state.avatar && <AvatarPreview avatar={this.state.avatar} /> }
                                                </FormGroup>
                                            </Colxx>

                                            {/* {!this.isProfile() && <Colxx sm={6}>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Field
                                                            disabled={!isCorrectRole(['admin'])}
                                                            name="disabled"
                                                            id="disabled"
                                                            type="checkbox" className="form-check-input"
                                                            checked={this.state.disabled ? 'checked' : ''}
                                                            onChange={this.handleCheckboxChange} />{' '}
                                                        <IntlMessages id="forms.disabled" />
                                                    </Label>
                                                </FormGroup>
                                            </Colxx>} */}

                                            {!this.isProfile() && <Colxx sm={6}>
                                                <Checkbox
                                                    disabled={!isCorrectRole(['admin'])}
                                                    name="disabled"
                                                    id="disabled"
                                                    type="checkbox" className="form-check-input"
                                                    checked={this.state.disabled}
                                                    onChange={this.handleCheckboxChange}
                                                    label={ <><IntlMessages id="forms.disabled" />{!isCorrectRole(['admin']) && <div className={"field-infoText"}>{' (No tienes permiso para modificar este valor. Contacta con un administrador)'}</div>}</>}
                                                    />
                                            </Colxx>}
                                            <Colxx sm={4}>
                                                <Button color="primary" className="mt-4" onClick={() => this.setState({modal:true})}><IntlMessages id="changePassword" /></Button>
                                            </Colxx>
                                        </FormGroup>
                                        <Button color="primary" className="mt-4 float-right" type="submit"><IntlMessages id="forms.edit" /></Button>
                                    </Form>)}
                                </Formik>

                            </CardBody>
                        </Card>
                    </Colxx>
                </Row>
                <ActivateModal
                  isOpen={this.state.modal}
                  userId={this.props.match.params.id}
                  toggle={this.toggle}
                  title={<IntlMessages id="forms.validation.password" />}
                  okOption={() => {}}
                  cancelOption={() => {}}
                  messages={this.props.intl.messages}
                />
            </Fragment>
        )
    }
}
const mapStateToProps = ({ diagnosticcenters, users, usersRequest }) => (
    {
        centerOptions: diagnosticcenters.data,
        user: users.data,
        userRequest: usersRequest.data,
    }
);


export default injectIntl(connect(mapStateToProps, { updateUser, getUser, filterDiagnosticCenters, getDiagnosticCenters, updateUserRequest, filterUsersRequest })(EditUser))

