import React from 'react';
import './style.scss';
import { withPortalCtxt } from "../Portal/Context";
import { getImgFromS3 } from "../../api/registry";
import VideoComponent from "./videoComponent";
import CloseButton from "../CloseButton";

class FullScreenVideo extends React.Component{

    constructor(props){
        super(props);
        this.component = null;
        this.state = {
            src : null
        }
    }

    componentDidMount(){
        getImgFromS3(this.props.idHistory, this.props.media, this.props.type).then( res => this.setState({src: res.data.url}));
        document.querySelector('body').classList.add('dident-overflow-hidden');
        if(this.component)
            setTimeout( ()=>this.component.classList.add('mounted'), 1 )
    }

    handleClick = (e) =>{
        if( e.target === this.component || e.target.classList.contains('Close-Button')){
            this.component.classList.remove('mounted');
            setTimeout( ()=> {
                this.props.handlePortalVisibility(false);
                document.querySelector('body').classList.remove('dident-overflow-hidden')
            } , 250)
        }
    };

    render(){
        const { src } = this.state;
        return(
            <div className={ 'fullScreenVideo' } ref={ node=>this.component=node } onClick={ this.handleClick }>
                <CloseButton onClickCapture={this.handleClick}/>
                <div className={ 'video-wrapper' }>
                    {
                        src && <VideoComponent src={src}/>
                    }
                </div>
            </div>
        )
    }
}

export default withPortalCtxt(FullScreenVideo);