import React from 'react';
import {
  InputGroup,
  InputGroupButtonDropdown,
  Input,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

import { injectIntl } from 'react-intl';
import IntlMessages from "../../util/IntlMessages";

class Finder extends React.Component {
  constructor(props) {
    super(props);

    this.toggleDropDown = this.toggleDropDown.bind(this);
    this.toggleSplit = this.toggleSplit.bind(this);
    this.state = {
      dropdownOpen: false,
      splitButtonOpen: false,
      currentKey: this.props.searchKeys ? this.props.searchKeys[0] : '',
      inputValue: ''
    };
  }

  toggleDropDown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  toggleSplit() {
    this.setState({
      splitButtonOpen: !this.state.splitButtonOpen
    });
  }

  handleOnDropdownClick = (e, searchKey) => {
    this.setState({
      currentKey: searchKey,
    })
  }

  handleOnKeyPress = (e) => {
    this.props.onKeyPress(e, this.state.currentKey);
  }

  handleOnSearchIconClick = () => {
    const {currentKey, inputValue} = this.state;
    this.props.onSearchIconClick(currentKey, inputValue)
  }

  handleInputChange = (e) => {
    this.setState({
      inputValue: e.target.value
    })
  }

  handleOnKeyUp = (e) => {
    const { currentKey, inputValue } = this.state;

    clearTimeout(this.typingTimer);
    this.innerValue = e.target.value;
    if(this.innerValue !== ''){
      this.typingTimer = setTimeout(() => {
        this.setState({
          value: this.innerValue
        });
        this.props.onSearchIconClick(currentKey, inputValue)
      }, 800);
    } else {
      if(this.innerValue === ''){
        this.props.onSearchIconClick(currentKey, '')
      }
    }

  }


  render() {
    const { searchKeys } = this.props;
    return (
      <div className="d-block d-md-inline-block">
        <div className="search-sm d-inline-block float-md-left mr-1 mb-1 align-top w-sm-100">
          <InputGroup>
            <InputGroupButtonDropdown addonType="prepend" isOpen={this.state.dropdownOpen} toggle={this.toggleDropDown}>
              <DropdownToggle caret={searchKeys ? true : false}>
                {searchKeys ?
                  <IntlMessages id={`forms.${this.state.currentKey}`} /> :
                  <IntlMessages id="menu.search" />
                }
              </DropdownToggle>
              {searchKeys && <DropdownMenu>
                {searchKeys.map(
                  (searchKey, i) => <DropdownItem onClick={(e) => this.handleOnDropdownClick(e, searchKey)} key={i}><IntlMessages id={`forms.${searchKey}`} /></DropdownItem>
                )
                }
              </DropdownMenu>}
            </InputGroupButtonDropdown>
            <i className={'simple-icon-magnifier'} onClick={this.handleOnSearchIconClick} /> 
            <Input 
              value={this.state.inputValue} 
              onChange={(e) => this.handleInputChange(e)} 
              onKeyPress={(e) => this.handleOnKeyPress(e)}
              onKeyUp={(e) => this.handleOnKeyUp(e)} />
          </InputGroup>
        </div>
      </div>
    );
  }
}

export default injectIntl(Finder)