import axios from "axios";
import { apiUrl } from "../constants/defaultValues";
import Auth from "./auth";

const getPatients = async (page, limit) =>
    await Auth.refreshToken()
        .then(() => new Promise((resolve, reject) => {
            axios.get(apiUrl.concat("patients?sort=createdAt&order=DESC&page=", page - 1, "&limit=", limit), { headers: { "X-Auth-Token": localStorage.getItem("access_token") } })
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        }))
        .catch((err) => err);

const filterPatients = async (filter) =>
    await Auth.refreshToken()
        .then(() => new Promise((resolve, reject) => {
            axios.get(apiUrl.concat("patients"), {
                params: filter, headers: { "X-Auth-Token": localStorage.getItem("access_token") }
            })
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        }))
        .catch((err) => err);

const createPatient = async (patient) =>
    await Auth.refreshToken()
        .then(() => new Promise((resolve, reject) => {
            axios.post(apiUrl.concat("patients"), patient, { headers: { "X-Auth-Token": localStorage.getItem("access_token") } })
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        }))
        .catch((err) => err);

const createOrUpdatePatientKeyrock = async (id) =>
    await Auth.refreshToken()
        .then(() => new Promise((resolve, reject) => {
            axios.get(apiUrl.concat("patients/credentials"), {
                params: { _id: id }, headers: { "X-Auth-Token": localStorage.getItem("access_token") }
            })
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        }))
        .catch((err) => err);

const updatePatient = async (patient) =>
    await Auth.refreshToken()
        .then(() => new Promise((resolve, reject) => {
            axios.put(apiUrl.concat("patients"), patient, {
                headers: { "X-Auth-Token": localStorage.getItem("access_token") },
                params: {
                    _id: patient._id
                }
            })
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        }))
        .catch((err) => err);

const deletePatient = async (id) =>
    await Auth.refreshToken()
        .then(() => new Promise((resolve, reject) => {
            axios.delete(apiUrl.concat("patients"), {
                headers: { "X-Auth-Token": localStorage.getItem("access_token") },
                params: {
                    _id: id
                }
            })
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        }))
        .catch((err) => err);

const getPatientsTotal = () =>
    Auth.refreshToken()
        .then(() =>
            axios.get(apiUrl.concat(`patients/total`), { headers: { "X-Auth-Token": localStorage.getItem("access_token") } })
        )
        .catch((err) => err);

export default {
    getPatients,
    filterPatients,
    createPatient,
    createOrUpdatePatientKeyrock,
    deletePatient,
    updatePatient,
    getPatientsTotal
};