import React from 'react';
import api from "../../../api/users";
import { roles } from '../../../data/roles';
import { doughnutChartConfig } from "../../../constants/chartConfig";
import { DoughnutShadow } from "../";
import { ThemeColors } from "../../../util/ThemeColors";

const availableRoles = roles.map(rol => rol.url);

class PlatformUsersChart extends React.Component {
  constructor(props) {

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: false
      },
      cutoutPercentage: 80,
      layout: {
        padding: {
          bottom: 20
        }
      },
      tooltips: {
        backgroundColor: ThemeColors().foregroundColor,
        titleFontColor: ThemeColors().primaryColor,
        borderColor: ThemeColors().separatorColor,
        borderWidth: 0.5,
        bodyFontColor: ThemeColors().primaryColor,
        bodySpacing: 10,
        xPadding: 15,
        yPadding: 15,
        cornerRadius: 0.15
      }
    };

    const data = {
      labels: availableRoles,
      datasets: [
        {
          label: "",
          borderColor: [
            ThemeColors().themeColor1,
            ThemeColors().themeColor2,
            ThemeColors().themeColor3,
            ThemeColors().themeColor4
          ],
          backgroundColor: [
            ThemeColors().themeColor1 + '20',
            ThemeColors().themeColor2 + '20',
            ThemeColors().themeColor3 + '20',
            ThemeColors().themeColor4 + '20',
          ],
          borderWidth: 2,
          data: [15, 25, 20, 98]
        }
      ]
    }
    super(props);
    this.state = {
      data,
      options,
    }
  }

  componentDidMount() {
    new Promise(async (resolve) => {
      const incomingData = await api.getUsersPercentage();
      const updatedData = this.state.data;
      updatedData.datasets[0].data = this.parseData(incomingData.data);

      this.setState({
        data: updatedData,
      })
      resolve();
    })
  }

  parseData = (data) => Object.keys(data).map((key) => {
    return Math.trunc(data[key]);
  });

  render() {
    return <DoughnutShadow {...doughnutChartConfig} data={this.state.data} options={this.state.options} />
  }
}


export default PlatformUsersChart;