import React from 'react';
import './style.scss';
import {withPortalCtxt} from "../Portal/Context";
import {getImgFromS3} from "../../api/registry";
import BackgroundImage from '../BackgroundImage';
import CloseButton from './../CloseButton';
import { Button } from 'reactstrap';
import IntlMessages from "../../util/IntlMessages";

class FullScreenImg extends React.Component{

    constructor(props){
        super(props);
        this.component = null;
        this.state={
            src : this.props.media.url,
            index: this.props.multimedia && this.props.multimedia.findIndex((media) => media._id === this.props.media._id),
            url: {},
        }
    }

    componentDidMount(){
        getImgFromS3(this.props.idHistory, this.props.media, this.props.type).then( res => { this.setState({src: res.data.url, url: { ...this.state.url, [this.props.media._id]: res.data.url } })})
        document.querySelector('body').classList.add('dident-overflow-hidden');
        if(this.component)
            setTimeout( ()=>this.component.classList.add('mounted'), 1 )
    }

    handleClick = (e) =>{
        if( e.target === this.component || e.target.classList.contains('Close-Button')){
            this.component.classList.remove('mounted');
            setTimeout( ()=> {
                this.props.handlePortalVisibility(false);
                document.querySelector('body').classList.remove('dident-overflow-hidden')
            } , 250)
        }
    };

    imageNavigation = (index) => {
        if (this.state.url[this.props.multimedia[index]._id]) {
            this.setState({
                src: this.state.url[this.props.multimedia[index]._id],
                index,
            })
        } else {
            getImgFromS3(this.props.idHistory, this.props.multimedia[index], this.props.type).then( res => {
                this.setState({src: res.data.url, url: { ...this.state.url, [this.props.multimedia[index]._id]: res.data.url } });
            });
            this.setState({ src: this.props.multimedia[index].url, index });
        }
    }

    previousImage = () => {
        const index = this.state.index === 0 ? this.props.multimedia.length - 1 : this.state.index - 1;
        this.imageNavigation(index);
    };

    followingImage = () => {
        const index = this.state.index === this.props.multimedia.length -1 ? 0 : this.state.index + 1;
        this.imageNavigation(index);
    };

    render(){
        const { src } = this.state;
        return(
            <div className={'fullScreenImg'} ref={node=>this.component=node} onClick={this.handleClick}>
            <CloseButton onClickCapture={this.handleClick}/>
            {this.props.multimedia && 
                <div className="container">
                    <div className="center">
                        <Button className="button-navigation-left" color={'primary'} onClick={this.previousImage}>
                            <div className="div-button">
                                <i className="simple-icon-arrow-left"/>
                            </div>
                        </Button>
                        <Button className="button-navigation-right" color={'primary'} onClick={this.followingImage}>
                            <div className="div-button">
                                <i className="simple-icon-arrow-right"/>
                            </div>
                        </Button>
                    </div>
                </div>
            }
            <div className={'img-wrapper'}>
                <BackgroundImage
                    url={src}
                    contain
                    alt="asd"
                    />
            </div>
        </div>
        )
    }
}

export default withPortalCtxt(FullScreenImg);