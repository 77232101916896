import {
    GET_DIAGNOSTICCENTERS,
    FILTER_DIAGNOSTICCENTERS,
    CREATE_DIAGNOSTICCENTER,
    UPDATE_DIAGNOSTICCENTER,
    DELETE_DIAGNOSTICCENTER,
    GET_DIAGNOSTICCENTERS_SUCCESS,
    CREATE_DIAGNOSTICCENTER_SUCCESS,
    UPDATE_DIAGNOSTICCENTER_SUCCESS,
    DELETE_DIAGNOSTICCENTER_SUCCESS,
    GET_CENTER,
    GET_CENTER_SUCCESS
} from "../../constants/actionTypes";

export const filterDiagnosticCenters = (filter) => ({
    type: FILTER_DIAGNOSTICCENTERS,
    payload: {filter}
});

export const getDiagnosticCenters = (page = 1, limit = 8) => ({
    type: GET_DIAGNOSTICCENTERS,
    payload: {page, limit}
});

export const getDiagnosticCentersSuccess = (diagnosticcenters, total) => ({
    type: GET_DIAGNOSTICCENTERS_SUCCESS,
    payload: {diagnosticcenters, total}
});

export const createDiagnosticCenter = (formData) => ({
    type: CREATE_DIAGNOSTICCENTER,
    payload: formData
});

export const editDiagnosticCenter = (formData) => ({
    type: UPDATE_DIAGNOSTICCENTER,
    payload: formData
});

export const deleteDiagnosticCenter = (formData) => ({
    type: DELETE_DIAGNOSTICCENTER,
    payload: formData
});

export const createDiagnosticCenterSucess = diagnosticcenter => ({
    type: CREATE_DIAGNOSTICCENTER_SUCCESS,
    payload: diagnosticcenter
});

export const updateDiagnosticCenterSucess = diagnosticcenter => ({
    type: UPDATE_DIAGNOSTICCENTER_SUCCESS,
    payload: diagnosticcenter
});

export const deleteDiagnosticCenterSucess = id => ({
    type: DELETE_DIAGNOSTICCENTER_SUCCESS,
    payload: id
});

export const getCenter = (id) => ({
    type: GET_CENTER,
    payload: id
});

export const getCenterSuccess = diagnosticcenter => ({
    type: GET_CENTER_SUCCESS,
    payload: diagnosticcenter
});