import User from './User';

const schema = {
    clinicName: {
        defaultValue: '',
    }
};

class Clinic extends User{

    constructor(obj){
        super(schema, obj);
        this.role = 'clinic';
    }

}

export default Clinic;