import axios from "axios";
import { apiUrl } from "../constants/defaultValues";
import Auth from "./auth";

const getPatientsTotal = async() =>
    await Auth.refreshToken()
        .then(()=> new Promise((resolve, reject) => {
                axios.get(apiUrl.concat("patients/total"), {
                    headers:{ "X-Auth-Token": localStorage.getItem("access_token")}
                })
                    .then((response) => resolve(response))
                    .catch((err) => reject(err));
            }))
        .catch((err) => err);


const getTestsTotal = async( doctorid ) =>
    await Auth.refreshToken()
        .then(()=> new Promise((resolve, reject) => {
                const url = doctorid === undefined ? apiUrl.concat("histories/multimedia/total") : apiUrl.concat("histories/multimedia/total?docotorId=",doctorid);
                axios.get(url, {
                    headers:{ "X-Auth-Token": localStorage.getItem("access_token")}
                })
                    .then((response) => resolve(response))
                    .catch((err) => reject(err));
            }))
        .catch((err) => err);


const getMultimediaReports = async( params ) =>
    await Auth.refreshToken()
        .then(()=> new Promise((resolve, reject) => {
                axios.get(apiUrl.concat("histories/reports"), {
                    headers:{ "X-Auth-Token": localStorage.getItem("access_token")},
                    params: params
                })
                    .then((response) => resolve(response))
                    .catch((err) => reject(err));
            }))
        .catch((err) => err);



export default {
    getPatientsTotal,
    getTestsTotal,
    getMultimediaReports
};