import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import patientsSaga from './patients/saga';
import diagnosticcentersSaga from './diagnosticcenters/saga';
import statsSaga from './stats/saga';
import usersSaga from './users/saga';
import usersRequestSaga from './usersRequest/saga';
import registrySaga from './registry/saga';
import legalSaga from './legal/saga';

export default function* rootSaga(getState) {
    yield all([
        authSagas(),
        patientsSaga(),
        diagnosticcentersSaga(),
        statsSaga(),
        usersSaga(),
        usersRequestSaga(),
        registrySaga(),
        legalSaga()
    ]);
}
