import {
    GET_REGISTRIES_SUCCESS,
    CREATE_REGISTRY,
    CREATE_REGISTRY_SUCCESS,
    FILTER_REGISTRIES,
    UPDATE_REGISTRY,
    UPDATE_REGISTRY_SUCCESS,
    DELETE_REGISTRY,
    DELETE_REGISTRY_SUCCESS,
    UPDATE_MULTIMEDIA_SUCCESS,
    GET_REGISTRY, GET_REGISTRY_SUCCESS,
    SET_OBSERVATIONS, SET_OBSERVATIONS_SUCCESS
} from "../../constants/actionTypes";


export const createRegistry = (formData) => ({
    type: CREATE_REGISTRY,
    payload: formData
});

export const createRegistrySuccess = registry => ({
    type: CREATE_REGISTRY_SUCCESS,
    payload: registry
});

export const deleteRegistry = (id, callback) => ({
    type: DELETE_REGISTRY,
    payload: {id, callback}
});

export const deleteRegistrySuccess = id => ({
    type: DELETE_REGISTRY_SUCCESS,
    payload: id
});

export const updateRegistry = (data, redirect=true) => ({
    type: UPDATE_REGISTRY,
    payload: {data, redirect}
});

export const updateRegistrySuccess = registry => ({
    type: UPDATE_REGISTRY_SUCCESS,
    payload: registry
});

export const updateMultimediaSuccess = (registry, multimediaID )=> ({
    type: UPDATE_MULTIMEDIA_SUCCESS,
    payload: {registry, multimediaID}
});

export const setObservations = (idRegistry, observations, callback) => ({
    type: SET_OBSERVATIONS,
    payload: {idRegistry, observations, callback}
});

export const setObservationsSuccess = (idRegistry, observations) => ({
    type: SET_OBSERVATIONS_SUCCESS,
    payload: {idRegistry, observations}
});

export const getRegistriesSuccess = (registries, total) => ({
    type: GET_REGISTRIES_SUCCESS,
    payload: {registries, total}
});

export const getRegistrySuccess = (registry) => ({
    type: GET_REGISTRY_SUCCESS,
    payload: {registry}
});

export const getRegistry = (filters, callback) => ({
    type: GET_REGISTRY,
    payload: {filters, callback}
});

export const filterRegistries = (filters, registerEvent = false, callback=()=>{}) => ({
    type: FILTER_REGISTRIES,
    payload: {filters, registerEvent, callback}
});