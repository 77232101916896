import Utils from './Utils';

const schema = {
    email: {
        defaultValue: '',
    },
    idKeyrock: {
        defaultValue: '',
    },
    role:{
        defaultValue: [],
    },
    diagnosticCenters:{
        defaultValue: [],
    },
    disabled:{
        defaultValue: true,
    },
    password:{
        defaultValue: '',
    },
    avatar: {
        defaultValue: '',
    },
    DNI: {
        defaultValue: '',
    }
};

class User extends Utils{

    constructor(externalSchema, obj){
        super(Object.assign(schema, externalSchema), obj)
    }

}

export default User;
