/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import WithModal from "../../Modal";
import IntlMessages from "../../../util/IntlMessages";
import Loading from "../../Dropzone/components/Loading";
class Body extends React.Component{
    constructor(props) {
        super(props); 
        this.state = {
            loading: false
        }
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.onOkOption && prevProps.onOkOption !== this.props.onOkOption ) {
            this.props.onClick();
            this.setState({
                loading: !this.state.loading
            })
        }
    };

    render() {
        const {loading} = this.state;
        return (
            loading 
            ?
                <Loading className={"dropzone-loading-component loading"}/>

            :
                <IntlMessages
                    id={this.props.msg}
                />
        )
    }
}

const BodyModal = WithModal(Body);

export const GenerateButtonFlatIcon = ({onClick, className, toggle, ...props}) => {
    const handleOnClick = (e) =>{
        e.preventDefault();
        e.stopPropagation();
        toggle();
    };

    return(
        <a href="#" onClick={handleOnClick} aria-label="Generar contraseña" title="Generar contraseña"
        className={`mx-1 actions-icons ${className ? className : ''}`}>
        <i className="simple-icon-doc"/>
    </a>);
};


class GenerateButton extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            modal: false
        }
    }

    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    };

    render() {
        const {onClickCapture, className, data, ...props} = this.props;
        return (
            <>
                <GenerateButtonFlatIcon {...props} toggle={this.toggle} className={className}/>
                <BodyModal
                    onClick={onClickCapture}
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                    msg={!props.error ? "general.generate.document.confirm" : "general.generate.document.error"}
                    title={<IntlMessages id={!props.error ? "general.generate.confirm.title" : "general.generate.error.title"} />}
                />
            </>
        )
    }
}

export default GenerateButton;