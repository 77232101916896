import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import history from './history';

import { Provider } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';

import App from './containers/App';
import Store, {persistor}  from './redux/store';

import './index.scss';
import './assets/fonts/iconsmind/style.css';
import './assets/fonts/simple-line-icons/css/simple-line-icons.css';

const MainApp = () => (
    <Provider store={Store}>
        <PersistGate loading={null} persistor={persistor}>
            <Router history={history}>
                <Switch>
                    <Route path="/" component={App} />
                </Switch>
            </Router>
        </PersistGate>
    </Provider>
);

export default MainApp

// export default  ReactDOM.render(
//     <MainApp />,
//     document.getElementById("root")
// );