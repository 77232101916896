import User from './User';

const schema = {
    firstName: {
        defaultValue: '',
    },
    lastName: {
        defaultValue: '',
    },
    collegiateNumber:{
        defaultValue: '',
    }
};

class Doctor extends User{

    constructor(obj){
        super(schema, obj);
        this.role = 'doctor';
    }

}

export default Doctor;