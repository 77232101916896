import React from 'react'
import { Row, Card, CardBody, CardTitle } from 'reactstrap';
import { Colxx } from '../../../components/CustomBootstrap';
import IntlMessages from '../../../util/IntlMessages';
import PlatformUsersChart from '../../../components/Charts/PlatformUsersChart/index';
import RegisteredPatientsChart from '../../../components/Charts/RegisteredPatients/index';
import TestsPerformedChart from '../../../components/Charts/TestsPerformedChart/index';

const GeneralPlatformUseCharts = () => {
  return (
    <Row>
      <Colxx sm={12}>
      <h3>Informes generales de la plataforma</h3>
      </Colxx>
      <Colxx xl="4" lg="6" md="12" className="mb-4">
        <Card className="h-100">
          <CardBody>
            <CardTitle>
              <IntlMessages id="reports.platform-user" />
            </CardTitle>
            <div className="dashboard-donut-chart">
              <PlatformUsersChart />
            </div>
          </CardBody>
        </Card>
      </Colxx>
      <Colxx xl="4" lg="6" md="12" className="mb-4">
        <Card className="h-100 d-flex align-items-center">
          <RegisteredPatientsChart />
        </Card>
      </Colxx>
      <Colxx xl="4" lg="6" md="12" className="mb-4">
        <Card className="h-100 d-flex align-items-center">
          <TestsPerformedChart />
        </Card>
      </Colxx>
    </Row>
  )
}

export default GeneralPlatformUseCharts;