import axios from "axios";
import { apiUrl } from "../constants/defaultValues";
import Auth from "./auth";
import Multimedia from "../models/Multimedia";


const entity = 'histories';

const createRegistry = async (registry) =>
    await Auth.refreshToken()
        .then(() => new Promise((resolve, reject) => {
            axios.post(apiUrl.concat(entity), registry, {
                headers: { "X-Auth-Token": localStorage.getItem("access_token") },
            })
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        }))
        .catch((err) => err);


const updateRegistry = async (registry) =>
    await Auth.refreshToken()
        .then(()=> new Promise((resolve, reject) => {
            axios.put(apiUrl.concat(entity), {...registry} ,{
                headers:{ "X-Auth-Token": localStorage.getItem("access_token")},
                params:{
                    _id: registry._id
                }
            })
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        }))
        .catch((err) => err);

const filterRegistries = async (filter, registerEvent) => {
    await Auth.refreshToken();
    return await axios.get(apiUrl.concat(entity)+'?sort=createdAt&order=DESC', {
        params: {...filter,registerEvent},
        headers: {"X-Auth-Token": localStorage.getItem("access_token")}
    });
};

const deleteRegistry = async (id) =>
    await Auth.refreshToken()
        .then(() => new Promise((resolve, reject) => {
            axios.delete(apiUrl.concat(entity), {
                headers: { "X-Auth-Token": localStorage.getItem("access_token") },
                params: {
                    _id: id
                }
            })
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        }))
        .catch((err) => err);

const updateRegistryMultimediaRequest = async (history, files, idHistory, typeTest, responses) => {
    const newMultimedias = [];
    const errorsMultimedia = [];

    files.forEach((file, index) => {
        if (responses[index].status === 200 && responses[index].statusText === 'OK')
            newMultimedias.push(
                new Multimedia({
                    url: '-',
                    generics: '-',
                    name: file.name,
                    modified_at: new Date(),
                    upload_at: new Date(),
                    mediaType: typeTest,
                }).getData()
            );
        else
            errorsMultimedia.push(file)
    });

    history.multimedia = [...history.multimedia, ...newMultimedias];
    const newHistory = { multimedia: history.multimedia, _id: idHistory };
    await updateRegistry(newHistory);
    return await filterRegistries({ _id: idHistory })
};

const updateRegistryMultimedia = async (files, idHistory, typeTest, responses) => {
    let res = await filterRegistries({ _id: idHistory });
    if (res.statusText === 'OK') {
        let history = res.data;
        res = await updateRegistryMultimediaRequest(history, files, idHistory, typeTest, responses)
    }
    return res
};

const uploadFileToS3 = async (urlSigned, file, uploadProgressCalback) =>
    await axios.request({
        url: urlSigned,
        method: 'PUT',
        data: file,
        headers: { 'Content-Type': file.type },
        onUploadProgress: uploadProgressCalback
    });

const signFileByS3Request = async (idHistory, typeTest, file) =>
    await axios.post(apiUrl.concat('s3'), {}, {
        headers: { "X-Auth-Token": localStorage.getItem("access_token") },
        params: {
            idHistory: idHistory,
            typeTest: typeTest,
            file: file.name,
            "Content-Type": file.type
        }
    });

export const signFileByS3 = async (idHistory, typeTest, files, uploadProgressCalback) => {
    await Auth.refreshToken();
    const promiseArray = [];

    files.forEach((file) => {
        promiseArray.push(new Promise(async (resolve) => {
            let res = await signFileByS3Request(idHistory, typeTest, file);
            if (res.statusText === 'OK' && res.status === 200) {
                res = await uploadFileToS3(res.data.url, file, uploadProgressCalback);
            }
            uploadProgressCalback();
            resolve(res);
        }))
    });

    const res = await Promise.all(promiseArray)
        .then(async (responses) =>
            await updateRegistryMultimedia(files, idHistory, typeTest, responses)
        )
        .catch(e => e);
    return res;

};

const deleteFullImgFromS3 = async (idHistory, typeTest, file) => {
    await Auth.refreshToken();
    return axios.delete(apiUrl.concat('s3/file'), {
        headers: { "X-Auth-Token": localStorage.getItem("access_token") },
        params: {
            idHistory: idHistory,
            typeTest: typeTest,
            file: file.name,
            "Content-Type": file.type
        }
    })
};

const deleteThumbnailFromS3 = async (idHistory, typeTest, file) => {
    await Auth.refreshToken();
    let thumbnail = file.url.split(/\/|\?/);
    thumbnail = thumbnail[thumbnail.length - 2];
    return axios.delete(apiUrl.concat('s3/file'), {
        headers: { "X-Auth-Token": localStorage.getItem("access_token") },
        params: {
            idHistory: idHistory,
            typeTest: typeTest,
            file: thumbnail,
            "Content-Type": file.type
        }
    })
};

const deleteRegistryMultimediaRequest = async (history, file, idHistory) => {
    const found = history.multimedia.find(f => f._id === file._id);
    const index = history.multimedia.indexOf(found);
    history.multimedia.splice(index, 1);
    const newHistory = { multimedia: history.multimedia, _id: idHistory };
    await updateRegistry(newHistory);
    return await filterRegistries({ _id: idHistory })
};

const deleteRegistryMultimedia = async (idHistory, typeTest, file) => {
    let res = await filterRegistries({ _id: idHistory });
    if (res.statusText === 'OK') {
        let history = res.data;
        res = await deleteRegistryMultimediaRequest(history, file, idHistory)
    }
    return res
};

export const deleteFileFromS3 = async (idHistory, typeTest, file) => {
    let res = await deleteFullImgFromS3(idHistory, typeTest, file);
    if (res.statusText === 'OK' && res.status === 200) {
        res = await deleteThumbnailFromS3(idHistory, typeTest, file);
        if (res.statusText === 'OK' && res.status === 200)
            res = await deleteRegistryMultimedia(idHistory, typeTest, file)
    }
    return res
};

export const getImgFromS3 = async (idHistory, file, typeTest) => {
    await Auth.refreshToken();
    return axios.get(apiUrl.concat('s3/download'), {
        headers: {
            "X-Auth-Token": localStorage.getItem("access_token"),
        },
        params: {
            idHistory: idHistory,
            typeTest: typeTest,
            file: file.name,
            "Content-Type": file.type
        }
    })
};

export const saveMetaDataToS3 = async (user, idHistory, multimedia, metadata) => {
    await Auth.refreshToken();
    const data = { data:metadata, idDoctor: user._id };
    return axios.put(apiUrl.concat('histories/multimedia'),{metadata:[data]} ,{
        headers: {
            "X-Auth-Token": localStorage.getItem("access_token"),
        },
        params: {
            _id: idHistory,
            idMultimedia: multimedia._id
        },
    })
};

const getTestsPerformedTotal = () =>
    Auth.refreshToken()
        .then(() =>
            axios.get(apiUrl.concat(`histories/multimedia/total`), { headers: { "X-Auth-Token": localStorage.getItem("access_token") } })
        )
        .catch((err) => err);

const getTestsManaged = ({startDate, endDate, user}) =>
    Auth.refreshToken()
        .then(() =>
            axios.get(apiUrl.concat(`histories/reports?startDate=${startDate}&endDate=${endDate}&userId=${user}`), { headers: { "X-Auth-Token": localStorage.getItem("access_token") } })
        )
        .catch((err) => err);

export const setObservationsInRegistry = async (idHistory, observations) => {
    await Auth.refreshToken();
    return axios.put(apiUrl.concat('histories/observation'),{observations} ,{
        headers: {
            "X-Auth-Token": localStorage.getItem("access_token"),
        },
        params: {
            _id: idHistory,
        },
    })
};

export const shareRegistryDoctorEmail = async (emailBody) => 
    await axios.post(apiUrl.concat('histories/registry/shared'), emailBody, {
        headers: { "X-Auth-Token": localStorage.getItem("access_token") },
    });

export default {
    signFileByS3,
    filterRegistries,
    // getRegistries,
    deleteRegistry,
    updateRegistry,
    createRegistry,
    getImgFromS3,
    getTestsPerformedTotal,
    getTestsManaged,
    setObservationsInRegistry,
    shareRegistryDoctorEmail
};