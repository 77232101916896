/* eslint-disable import/no-anonymous-default-export */
// import Patient from '../models/' TODO Make patient model
import store from "../redux/store";
import { filterPatients } from "../redux/actions";

export default (patientId) => {
  const patientsInRedux = store.getState().patients.data;

  if (patientsInRedux.length > 0) {
    const finded = patientsInRedux.find((pat) => pat._id === patientId);
    if (finded) return finded;
  } 
  store.dispatch(filterPatients({ _id: patientId }));
  return {};
};
