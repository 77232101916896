import React, { Fragment } from "react";
import IntlMessages, {injectIntl} from "../../../util/IntlMessages";
import PageHeader from '../../../components/PageHeader/index';
import Finder from '../../../components/Finder/index';
import {
    Row,
    Card,
    CustomInput,
    Label,
    Input,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from "reactstrap";
import { Colxx } from "../../../components/CustomBootstrap/index";
import Pagination from "../../../components/List/Pagination";
import SelectorNumberOfItemsPerPage from "../../../components/SelectorNumberOfItemsPerPage";
import {
    deleteUserRequest,
    filterUsersRequest,
    validateUserRequest,
    getUsersRequest
} from "../../../redux/actions";
import { connect } from "react-redux";
import classnames from "classnames";
import mouseTrap from "react-mousetrap";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import ContextMenuFn from '../../../components/ContextMenuClass/index';
import WithModal from "../../../components/Modal/index";

function collect(props) {
    return { data: props.data };
}

class FormPasswordToValidate extends React.Component{
    constructor(props){
        super(props);
        this.state={
            user:'',
            okOption: this.props.onOkOption,
            password:'',
            repeatPassword:'',
            showPassword:'',
            showRepeatPassword:'',
            errors:{}
        }
    }

    componentDidMount(){
        const user = this.props.users.find( u=>u._id === this.props.userId );
        user && this.setState({user})
    }

    componentWillUpdate(nextProps, nextState){
        if (this.props.onOkOption !== nextProps.onOkOption || this.state.okOption !== nextProps.onOkOption){
            this.setState({
                okOption: nextProps.onOkOption,
            })
        }
    }

    validatePasswords =() =>{
        const errors={};

        if (!this.state.password) {
            errors.password = "forms.validation.password";
        }

        if (!this.state.repeatPassword) {
            errors.repeatPassword = "forms.validation.repeatPassword";
        }

        if (this.state.repeatPassword !== this.state.password) {
            errors.repeatPassword = "forms.validation.notEqualPasswords";
        }

        this.props.onToggle(false);
        this.setState({ errors: errors, okOption:false });
        return Object.keys(errors).length === 0
    };

    componentDidUpdate = () => {
        if(this.state.okOption === true) {
            if(this.validatePasswords()){
                const user = this.state.user;
                this.props.validateUserRequest({...user,password:this.state.password});
                this.setState({
                    okOption: false,
                },this.props.onToggle)

            }
        }
    };

    handleChange=(e)=>{
        this.setState({
            [e.target.name]:e.target.value,
            errors:{...this.state.errors, [e.target.name]: undefined}
        })
    };

    showPassword=(e, key)=>{
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            [`show${key}`]: !this.state[`show${key}`]
        })
    };

    render(){
        const {intl} = this.props;
        const {messages} = intl;
        const {errors} = this.state;
        return(
            <>
                <h4 className={'mb-4'}>
                    <IntlMessages id={'forms.activateMessage'}/>
                </h4>

                <Colxx xs={12}>
                    <FormGroup>
                        <Label for="password">
                            <IntlMessages id="forms.password" />
                        </Label>
                        <InputGroup>
                            <Input className="form-control"
                                   type={this.state.showPassword ? 'text' : 'password'}
                                   name="password"
                                   id="name"
                                   onChange={this.handleChange}
                                   value={this.state.password}
                                   placeholder={messages["forms.password"]}
                            />
                            <InputGroupAddon
                                className={'dident-cursor-pointer'}
                                addonType="append"
                                onClickCapture={(e)=>this.showPassword(e,'Password')}
                            >
                                <InputGroupText>
                                    <i className="simple-icon-eye"/>
                                </InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                        {errors.password && <div className="invalid-feedback d-block">{messages[errors.password]}</div>}
                    </FormGroup>
                </Colxx>

                <Colxx xs={12}>
                    <FormGroup>
                        <Label for="repeatPassword">
                            <IntlMessages id="forms.repeatPassword" />
                        </Label>
                        <InputGroup>
                            <Input className="form-control"
                                   type={this.state.showRepeatPassword ? 'text' : 'password'}
                                   name="repeatPassword"
                                   id="street"
                                   onChange={this.handleChange}
                                   value={this.state.repeatPassword}
                                   placeholder={messages["forms.repeatPassword"]}
                            />
                            <InputGroupAddon
                                className={'dident-cursor-pointer'}
                                addonType="append"
                                onClickCapture={(e)=>this.showPassword(e,'RepeatPassword')}
                            >
                                <InputGroupText>
                                    <i className="simple-icon-eye"/>
                                </InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                        {errors.repeatPassword && <div className="invalid-feedback d-block">{messages[errors.repeatPassword]}</div>}
                    </FormGroup>
                </Colxx>
            </>
        )
    }
}

const mapStateToPropsTop = ({usersRequest}) => ({
    users: usersRequest.data,
});

const ActivateModal = WithModal(connect(mapStateToPropsTop,{validateUserRequest})(injectIntl(FormPasswordToValidate)));

class Index extends ContextMenuFn {
    constructor(props) {
        super(props);
        this.onContextMenuClick = this.onContextMenuClick.bind(this);
        this.toggleSplit = this.toggleSplit.bind(this);
        this.state = {
            data:'',
            modal: false,
            selectedItems: [],
            pageSizes: [8, 12, 24],
            selectedPageSize: 8,
            currentPage: 1,
            totalPage: 1,
            search: "",
            lastChecked: null,
            loading: false,
            dropdownSplitOpen: false
        }
    }
    handleCheckChange(event, id) {
        if (
            event.target.tagName === "A" ||
            (event.target.parentElement &&
                event.target.parentElement.tagName === "A")
        ) {
            return true;
        }
        if (this.state.lastChecked == null) {
            this.setState({
                lastChecked: id
            });
        }

        let selectedItems = this.state.selectedItems;
        if (selectedItems.includes(id)) {
            selectedItems = selectedItems.filter(x => x !== id);
        } else {
            selectedItems.push(id);
        }
        this.setState({
            selectedItems
        });

        if (event.shiftKey) {
            let items = this.state.items;
            let start = this.getIndex(id, items, "id");
            let end = this.getIndex(this.state.lastChecked, items, "id");
            items = items.slice(Math.min(start, end), Math.max(start, end) + 1);
            selectedItems.push(
                ...items.map(item => {
                    return item.id;
                })
            );
            selectedItems = Array.from(new Set(selectedItems));
            this.setState({
                selectedItems
            });
        }
        document.activeElement.blur();
    }

    handleKeyPress = (e, searchKey) => {
        if (e.key === "Enter") {
            this.setState(
                {
                    search: e.target.value.toLowerCase()
                },
                () => console.info("GET Users to Activate filtered")
            );

            this.props.filterUsersRequest({
                [searchKey]: e.target.value.toLowerCase(),
            })
        }
    };

    changePageSize = (size) => {
        this.setState(
            {
                selectedPageSize: size,
                currentPage: 0
            },
            () => this.props.getUsersRequest(0, size)
        );
    }
    onChangePage(page) {
        this.setState(
            {
                currentPage: page
            },
            () => this.props.getUsersRequest(page, this.state.selectedPageSize)
        );
    }

    toggleSplit() {
        this.setState(prevState => ({
            dropdownSplitOpen: !prevState.dropdownSplitOpen
        }));
    };
    handleChangeSelectAll(isToggle) {
        if (this.state.selectedItems.length >= this.props.data.length) {
            if (isToggle) {
                this.setState({
                    selectedItems: []
                });
            }
        } else {
            this.setState({
                selectedItems: this.props.data.map(x => x._id)
            });
        }
        document.activeElement.blur();
        return false;
    };
    componentDidMount() {
        this.props.getUsersRequest();
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.totalItemCount !== this.props.totalItemCount || prevState.selectedPageSize !== this.state.selectedPageSize) {
            this.setState({
                totalPage: Math.ceil(this.props.totalItemCount/this.state.selectedPageSize)
            });
        }
    }

    onViewInContext=(data)=> {
        this.props.history.push(`detalle/${data.data}`);
    };

    onUpdateInContext = (data) => {
        const request = this.props.data.find(u=>u._id === data.data);
        const role = request.role === 'doctor' ? 'doctores' : 'clinicas';
        this.props.history.push(`/app/usuarios/solicitudes/${role}/editar/${data.data}`);
    };

    onDeleteInContext = (data) => {
        this.props.deleteUserRequest(data.data);
    };

    onValidateInContext = (data) => {
        this.toggle(undefined,data.data);
    };

    renderActions(){
        return(
            <Fragment>
                {/*<ButtonDropdown*/}
                    {/*isOpen={this.state.dropdownSplitOpen}*/}
                    {/*toggle={this.toggleSplit}*/}
                {/*>*/}
                    {/*<div className="btn btn-primary pl-4 pr-0 check-button">*/}
                        {/*<Label*/}
                            {/*for="checkAll"*/}
                            {/*className="custom-control custom-checkbox mb-0 d-inline-block"*/}
                        {/*>*/}
                            {/*<Input*/}
                                {/*className="custom-control-input"*/}
                                {/*type="checkbox"*/}
                                {/*id="checkAll"*/}
                                {/*checked={*/}
                                    {/*this.state.selectedItems.length >=*/}
                                    {/*this.props.data.length*/}
                                {/*}*/}
                                {/*onChange={() => this.handleChangeSelectAll(true)}*/}
                            {/*/>*/}
                            {/*<span*/}
                                {/*className={`custom-control-label ${*/}
                                    {/*this.state.selectedItems.length > 0 &&*/}
                                    {/*this.state.selectedItems.length <*/}
                                    {/*this.props.data.length*/}
                                        {/*? "indeterminate"*/}
                                        {/*: ""*/}
                                    {/*}`}*/}
                            {/*/>*/}
                        {/*</Label>*/}
                    {/*</div>*/}
                    {/*<DropdownToggle*/}
                        {/*caret*/}
                        {/*color="primary"*/}
                        {/*className="dropdown-toggle-split pl-2 pr-2"*/}
                    {/*/>*/}
                    {/*<DropdownMenu right>*/}
                        {/*<DropdownItem>*/}
                            {/*<IntlMessages id="layouts.delete" />*/}
                        {/*</DropdownItem>*/}
                    {/*</DropdownMenu>*/}
                {/*</ButtonDropdown>*/}
            </Fragment>
        )
    }

    renderHeader() {
        const {messages} = this.props.intl;
        const startIndex= (this.state.currentPage-1)*this.state.selectedPageSize;
        const endIndex= (this.state.currentPage)*this.state.selectedPageSize;
        const {selectedPageSize, pageSizes} = this.state;
        return (

            <PageHeader
                heading={<h1><IntlMessages id="menu.solicitudes" /></h1>}
                match={this.props.match}
                actions={this.renderActions()}
                finder={<Finder placeholder={messages["menu.search"]} searchKeys={['name', "city", "province"]} onKeyPress={this.handleKeyPress}/>}
            >

                <SelectorNumberOfItemsPerPage
                    startIndex={startIndex}
                    endIndex={endIndex}
                    totalItemCount={this.props.totalItemCount}
                    selectedPageSize={selectedPageSize}
                    pageSizes={pageSizes}
                    changePageSize={this.changePageSize}
                />
            </PageHeader>
        );
    }

    toggle = (e,data) =>{
        if(e) {
            e.preventDefault && e.preventDefault();
            e.stopPropagation && e.stopPropagation();
        }
        this.setState(prevState => ({
            modal: !prevState.modal,
            data: data
        }));
    };

    /*** Checks */

    checkIcon(user){
        return user.role === 'doctor' ? 'iconsmind-Doctor' : 'iconsmind-Hospital'
    }

    checkName(user){
        return user.role === 'doctor' ? `${user.firstName} ${user.lastName}` : user.clinicName
    }

    checkAttrByRole(user){
        const toReturn = [];
        toReturn.push(
            <p key={'user-dni'} className="mb-1 text-muted text-small w-20 w-sm-100"> {user.DNI} </p>,
            <p key={'user-email'} className="mb-1 text-muted text-small w-30 w-sm-100">{user.email}</p>,
            <p key={'user-date'} className="mb-1 text-muted text-small w-30 w-sm-100"><IntlMessages id={'form.createAt'}/> {new Date(user.createdAt).toLocaleDateString()}</p>
        );
        return toReturn
    }

    render() {
        const {messages} = this.props.intl;

        if (this.props.totalItemCount === 0) {
            return (
                <Fragment>
                    <div className="disable-text-selection">
                        {this.renderHeader()}
                        {!this.props.loading ?
                            <Row>
                                <Colxx xxs="12" className="mb-3">
                                    <h2><IntlMessages id="general.no-results-found" /></h2>
                                </Colxx>
                            </Row>
                            : <div className="loading"/>
                        }
                    </div>
                </Fragment>
            )
        }
        const dataUsers = Array.isArray(this.props.data) ? this.props.data : [this.props.data];

        return (
            <Fragment>
                <div className="disable-text-selection">
                    { this.renderHeader() }
                    <Row>
                        {(!this.props.loading && this.props.data) ?
                            dataUsers.map(user => {
                                return(
                                    <Colxx xxs="12" key={user._id} className="mb-3">
                                        {/*<ContextMenuTrigger*/}
                                        {/*    id="menu_id"*/}
                                        {/*    data={user._id}*/}
                                        {/*    collect={collect}*/}
                                        {/*>*/}
                                            <Card
                                                // onClick={event =>
                                                //     this.handleCheckChange(event, user._id)

                                                //}
                                                className={classnames("d-flex flex-row", {
                                                    active: this.state.selectedItems.includes(
                                                        user._id
                                                    )
                                                })}
                                            >
                                                <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                                                    <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                                                        {/*<div><i className={`validate-icon-list ${this.checkIcon(user)}`}/></div>*/}
                                                        <div className="w-25 w-sm-100">
                                                            <p className="list-item-heading mb-1 truncate">
                                                                {this.checkName(user)}
                                                            </p>
                                                        </div>
                                                        {this.checkAttrByRole(user)}

                                                        {/*<div className="w-10 w-sm-100">*/}
                                                        {/*<Badge color="primary" pill>*/}
                                                        {/*OK*/}
                                                        {/*/!* TODO: STATUS? *!/*/}
                                                        {/*</Badge>*/}
                                                        {/*</div>*/}
                                                        {/*<LoadingButton*/}
                                                        {/*loading={this.state.modal}*/}
                                                        {/*style={{maxWidth:140}}*/}
                                                        {/*className={'mt-2'}*/}
                                                        {/*onClickCapture={(e)=>this.toggle(e,user._id)}*/}
                                                        {/*> {messages['layouts.validate']}*/}
                                                        {/*</LoadingButton>*/}
                                                    </div>
                                                    <div className="custom-control custom-checkbox pl-1 d-flex align-items-center pr-4">
                                                        <div>
                                                            <a href="#" onClick={() => this.onUpdateInContext({data:user._id})} aria-label="Editar" title="Editar" className="mx-1 actions-icons">
                                                                <i className="simple-icon-pencil"/>
                                                            </a>
                                                            <a href="#" onClick={() => this.onDeleteInContext({data:user._id})} aria-label="Borrar" title="Borrar" className="mx-1 actions-icons">
                                                                <i className="simple-icon-trash"/>
                                                            </a>
                                                            <a href="#" onClick={() => this.onValidateInContext({data:user._id})} aria-label="Validar" title="Validar" className="mx-1 actions-icons">
                                                                <i className="simple-icon-check"/>
                                                            </a>
                                                        </div>
                                                        {/*<CustomInput*/}
                                                        {/*    className="itemCheck mb-0 ml-2 mb-1"*/}
                                                        {/*    type="checkbox"*/}
                                                        {/*    id={`check_${user._id}`}*/}
                                                        {/*    checked={this.state.selectedItems.includes(*/}
                                                        {/*        user._id*/}
                                                        {/*    )}*/}
                                                        {/*    onChange={() => {}}*/}
                                                        {/*    label=""*/}
                                                        {/*/>*/}
                                                    </div>
                                                </div>
                                            </Card>
                                        {/*</ContextMenuTrigger>*/}
                                    </Colxx>
                                )
                            })
                            :<div className="loading"/>
                        }
                        {!this.props.loading &&
                        <Pagination
                            currentPage={this.state.currentPage}
                            totalPage={this.state.totalPage}
                            onChangePage={i => this.onChangePage(i)}
                        />
                        }
                    </Row>
                </div>

                <ContextMenu
                    id="menu_id"
                    onShow={e => this.onContextMenu(e, e.detail.data)}
                >
                    {/*<MenuItem*/}
                    {/*onClick={this.onContextMenuClick}*/}
                    {/*data={{ action: "view" }}*/}
                    {/*>*/}
                    {/*<i className="iconsmind-Profile" /> <span>{messages["layouts.view"]}</span>*/}
                    {/*</MenuItem>*/}
                    <MenuItem
                        onClick={this.onContextMenuClick}
                        data={{ action: "update" }}
                    >
                        <i className="simple-icon-refresh" /> <span>{messages["layouts.update"]}</span>
                    </MenuItem>
                    <MenuItem
                        onClick={this.onContextMenuClick}
                        data={{ action: "delete" }}
                    >
                        <i className="simple-icon-trash" /> <span>{messages["layouts.delete"]}</span>
                    </MenuItem>
                    <MenuItem
                        onClick={this.onContextMenuClick}
                        data={{ action: "validate" }}
                    >
                        <i className="simple-icon-check" /> <span>{messages["layouts.validate"]}</span>
                    </MenuItem>
                </ContextMenu>

                <ActivateModal
                    isOpen={this.state.modal}
                    userId={this.state.data}
                    toggle={this.toggle}
                    title={<IntlMessages id="forms.validation.password" />}
                    okOption={() => {}}
                    cancelOption={() => {}}
                    messages={this.props.intl.messages}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = ({ usersRequest }) => {
    const { data, loading, totalItemCount } = usersRequest;
    return { data, loading, totalItemCount }
};

export default injectIntl(mouseTrap(connect(mapStateToProps, { deleteUserRequest, filterUsersRequest, getUsersRequest })(Index)))