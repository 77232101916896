import {
    GET_USERS_REQUEST,
    GET_USERS_REQUEST_SUCCESS,
    CREATE_USER_REQUEST,
    CREATE_USER_REQUEST_SUCCESS,
    DELETE_USER_REQUEST,
    DELETE_USER_REQUEST_SUCCESS,
    VALIDATE_USER_REQUEST,
    USER_REQUEST_FAILED,
    FILTER_USER_REQUEST,
    UPDATE_USER_REQUEST,
    UPDATE_USER_REQUEST_SUCCESS
} from "../../constants/actionTypes";


export const getUsersRequest = (page = 0, limit = 8) => ({
    type: GET_USERS_REQUEST,
    payload: {page, limit}
});

export const getUsersRequestSuccess = (users, total) => ({
    type: GET_USERS_REQUEST_SUCCESS,
    payload: {users, total}
});

export const createUserRequest = user => ({
    type: CREATE_USER_REQUEST,
    payload: user
});

export const createUserRequestSuccess = user => ({
    type: CREATE_USER_REQUEST_SUCCESS,
    payload: user
});
/*
export const getUser = id => ({
    type: GET_USER,
    payload: id
})

export const getUserSuccess = user => ({
    type: GET_USER_SUCCESS,
    payload: user
})

export const getUsersByRole = (page = 1, limit = 8, role) => ({
    type: GET_USERS_BY_ROLE,
    payload: {page, limit, role}
});

export const getUsersByRoleSuccess = (users, total) => ({
    type: GET_USERS_BY_ROLE_SUCCESS,
    payload: {users, total}
});
*/

export const updateUserRequest = (user) => ({
    type: UPDATE_USER_REQUEST,
    payload: {user}
});

export const updateUserRequestSuccess = user => ({
    type: UPDATE_USER_REQUEST_SUCCESS,
    payload: user
});

export const deleteUserRequest = id  => ({
    type: DELETE_USER_REQUEST,
    payload: id,
});

export const deleteUserRequestSuccess = id => ({
    type: DELETE_USER_REQUEST_SUCCESS,
    payload: id,
});

export const validateUserRequest = user  => ({
    type: VALIDATE_USER_REQUEST,
    payload: user,
});

export const userRequestFailed = user  => ({
    type: USER_REQUEST_FAILED,
});

export const filterUsersRequest = (filter) => ({
    type: FILTER_USER_REQUEST,
    payload: {filter}
});
