/* SETTINGS */
/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const LOGOUT_USER = 'LOGOUT_USER';
export const GET_LOGGED_USER = 'GET_LOGGED_USER';
export const GET_LOGGED_USER_SUCCESS = 'GET_LOGGED_USER_SUCCESS';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';

/* PATIENTS */
export const GET_PATIENTS = 'GET_PATIENTS';
export const FILTER_PATIENTS = 'FILTER_PATIENTS';
export const GET_PATIENTS_SUCCESS = 'GET_PATIENTS_SUCCESS';
export const CREATE_PATIENT = 'CREATE_PATIENT';
export const CREATE_PATIENT_KEYROCK = 'CREATE_PATIENT_KEYROCK';
export const CREATE_PATIENT_KEYROCK_SUCCESS = 'CREATE_PATIENT_KEYROCK_SUCCESS';
export const CREATE_PATIENT_SUCCESS = 'CREATE_PATIENT_SUCCESS';
export const DELETE_PATIENT = 'DELETE_PATIENT';
export const UPDATE_PATIENT = 'UPDATE_PATIENT';
export const DELETE_PATIENTS_SUCCESS = 'DELETE_PATIENTS_SUCCESS';
export const UPDATE_PATIENT_SUCCESS = 'UPDATE_PATIENT_SUCCESS';
export const UPLOAD_FILE_PATIENT = 'UPLOAD_FILE_PATIENT';
export const UPLOAD_FILE_PATIENT_SUCCESS = 'UPLOAD_FILE_PATIENT_SUCCESS';
export const UPLOAD_FILE_PATIENT_ERROR = 'UPLOAD_FILE_PATIENT_ERROR';

/* DIAGNOSTIC CENTERS */
export const FILTER_DIAGNOSTICCENTERS = 'FILTER_DIAGNOSTICCENTERS';
export const GET_DIAGNOSTICCENTERS = 'GET_DIAGNOSTICCENTERS';
export const GET_DIAGNOSTICCENTERS_SUCCESS = 'GET_DIAGNOSTICCENTERS_SUCCESS';
export const CREATE_DIAGNOSTICCENTER = 'CREATE_DIAGNOSTICCENTER';
export const CREATE_DIAGNOSTICCENTER_SUCCESS = 'CREATE_DIAGNOSTICCENTER_SUCCESS';
export const GET_CENTER = 'GET_CENTER';
export const GET_CENTER_SUCCESS = 'GET_CENTER_SUCCESS';
export const UPDATE_DIAGNOSTICCENTER = "UPDATE_DIAGNOSTICCENTER";
export const DELETE_DIAGNOSTICCENTER = "DELETE_DIAGNOSTICCENTER";
export const UPDATE_DIAGNOSTICCENTER_SUCCESS = "UPDATE_DIAGNOSTICCENTER_SUCCESS";
export const DELETE_DIAGNOSTICCENTER_SUCCESS = "DELETE_DIAGNOSTICCENTER_SUCCESS";

/* REGISTRY */
export const FILTER_REGISTRIES = "FILTER_REGISTRIES";
export const CREATE_REGISTRY = "CREATE_REGISTRY";
export const CREATE_REGISTRY_SUCCESS = "CREATE_REGISTRY_SUCCESS";
export const GET_REGISTRIES = "GET_REGISTRIES";
export const GET_REGISTRIES_SUCCESS = "GET_REGISTRIES_SUCCESS";
export const GET_REGISTRY = "GET_REGISTRY";
export const GET_REGISTRY_SUCCESS = "GET_REGISTRY_SUCCESS";
export const UPDATE_REGISTRY = "UPDATE_REGISTRY";
export const UPDATE_REGISTRY_SUCCESS = "UPDATE_REGISTRY_SUCCESS";
export const DELETE_REGISTRY = "DELETE_REGISTRY";
export const DELETE_REGISTRY_SUCCESS = "DELETE_REGISTRY_SUCCESS";
export const SET_OBSERVATIONS = "SET_OBSERVATIONS";
export const SET_OBSERVATIONS_SUCCESS = "SET_OBSERVATIONS_SUCCESS";
export const UPDATE_MULTIMEDIA_SUCCESS = "UPDATE_MULTIMEDIA_SUCCESS";

/* STATS */
export const GET_PATIENTS_TOTAL = 'GET_PATIENTS_TOTAL';
export const GET_PATIENTS_TOTAL_SUCCESS = 'GET_PATIENTS_TOTAL_SUCCESS';
export const GET_TESTS_TOTAL = 'GET_TESTS_TOTAL';
export const GET_TESTS_TOTAL_SUCCESS = 'GET_TESTS_TOTAL_SUCCESS';


/* USERS */
export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_BY_ROLE = 'GET_USERS_BY_ROLE';
export const GET_USERS_BY_ROLE_SUCCESS = 'GET_USERS_BY_ROLE_SUCCESS';
export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const FILTER_USERS = 'FILTER_USERS';
export const FILTER_USERS_SUCCESS = 'FILTER_USERS_SUCCESS';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const GET_USERS_PERCENTAGE = 'GET_USER_PERCENTAGE';
export const GET_USERS_PERCENTAGE_SUCCESS = 'GET_USER_PERCENTAGE_SUCCESS';

/* USERS REQUEST */
export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_REQUEST_SUCCESS = "GET_USERS_REQUEST_SUCCESS";
export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_REQUEST_SUCCESS = "CREATE_USER_REQUEST_SUCCESS";
export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_REQUEST_SUCCESS = "DELETE_USER_REQUEST_SUCCESS";
export const VALIDATE_USER_REQUEST = "VALIDATE_USER_REQUEST";
export const FILTER_USER_REQUEST = "USER_REQUEST_FAILED";
export const USER_REQUEST_FAILED = "USER_REQUEST_FAILED"; //Generic
export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_REQUEST_SUCCESS = "UPDATE_USER_REQUEST_SUCCESS";

/* LEGAL */
export const GET_LEGAL_TEXT = "GET_LEGAL_TEXT";
export const GET_LEGAL_TEXT_SUCCESS = "GET_LEGAL_TEXT_SUCCESS";
export const UPDATE_LEGAL_TEXT = "UPDATE_LEGAL_TEXT";
export const UPDATE_LEGAL_TEXT_SUCCESS = "UPDATE_LEGAL_TEXT_SUCCESS";
export const CREATE_LEGAL_TEXT = "CREATE_LEGAL_TEXT";
export const CREATE_LEGAL_TEXT_SUCCESS = "CREATE_LEGAL_TEXT_SUCCESS";
export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";

