import './index.scss';
import React from 'react';
import {CardBody, CardSubtitle, CardTitle} from "reactstrap";
export default ({history, patientID}) => (
    <CardBody className={`p-4 w-50 w-sm-100 Bottom-Add-Registry-Button`} onClick={()=>history.push(`/app/pacientes/historial/${patientID}/crear`)}>
        <div className={'d-flex flex-row justify-content-between'}>
            <div>
                <CardTitle className={'plus'}>+</CardTitle>
                <CardSubtitle className={'subtitle-plus'}>Añadir Registro</CardSubtitle>
            </div>
        </div>
    </CardBody>
)