/* Gogo Language Texts

Table of Contents

01.General
02.Inicio de sesión de usuario, cierre de sesión, registro
03.Menú
04.Error
*/


module.exports = {
    /* 01.General */
    "general.copyright": "Gogo React © Todos los derechos reservados.",
    "general.back": "Volver",
    "general.actions": "Acciones",
    "general.no-results-found": "No se han encontrado resultados.",
    "general.edit": "Modificar",
    "general.delete": "Eliminar",
    "general.share": "Compartir",
    "general.change-visibility": "Cambiar visibilidad",
    "general.stop-sharing": "Dejar de compartir",
    "general.sharing-expiration": 'Caduca el {day} a las {time} horas',
    "general.enabled": "Activado",
    "general.disabled": "Desactivado",
    "general.yes": "Sí",
    "general.no": "No",
    "general.accept": "ACEPTAR",
    "general.cancel": "CANCELAR",
    "general.generate.document.confirm": "¿Estás seguro de que quieres generar el archivo PDF?",
    "general.generate.confirm.title": "Generar Archivo",
    "general.generate.document.error": "¿Quiere editar el paciente y añadirle un DNI?",
    "general.generate.error.title": "Error en PDF",
    "general.remove.confirm": "¿Seguro que quiere borrar este archivo?",
    "general.remove.patient.confirm": "¿Seguro que quiere borrar este paciente?",
    "general.remove.registry.confirm": "¿Seguro que quiere borrar este registro?",
    "general.remove.confirm.title": "Confirmar Borrado",


    /* 02.Inicio de sesión de usuario, cierre de sesión, registro */
    "user.login-title": "Acceso",
    "user.register": "Registro",
    "user.forgot-password": "Se te olvidó tu contraseña",
    "user.email": "Email",
    "user.dni": "DNI",
    "user.password": "Contraseña",
    "user.forgot-password-question": "¿Has olvidado tu contraseña?",
    "user.forgot-password.title": "Recuperar Contraseña",
    "user.forgot-password.text":"Si ha olvidado su contraseña, introduzca su correo electrónico y pulse el botón 'Reiniciar'. Recibirá un mensaje por correo con los pasos a seguir para restablecerla.{separator} Si aún no está registrado,",
    "user.forgot-password.register": "solicite acceso",
    "user.forgot-password.text2":"a nuestra plataforma",
    "user.fullname": "Nombre completo",
    "user.login-button": "INICIAR SESIÓN",
    "user.register-button": "REGISTRO",
    "user.reset-password-button": "REINICIAR",
    "user.reset-password": "Reiniciando Contraseña",
    "user.register.title": "Solicitud de Acceso",
    "user.register.text": "Por favor, utiliza este formulario para registrarte. Si ya eres miembro, por favor, ",
    "user.register.text.login": "accede a la plataforma",
    "user.register.rol": "Tipo de usuario",
    "user.register.confirmation": "Información enviada correctamente",
    "user.error-password": "Email o contraseña incorrectos",
    "user.reset-password.title": "Reiniciando Contraseña",
    "user.reset-password.text": "Estamos procediendo a reiniciar su contraseña. Por favor, esté atento a la información que aparecerá a la derecha, para saber cómo proceder a continuación.",

    /* 03.Menú */
    "menu.app": "Inicio",
    "menu.teamViwer": "Descargar TeamViwer",
    "menu.gogo": "Gogo",
    "menu.start": "Comienzo",
    "menu.second-menu": "Segundo Menú",
    "menu.second": "Segundo",
    "menu.ui": "IU",
    "menu.charts": "Gráficos",
    "menu.chat": "Chatea",
    "menu.survey": "Encuesta",
    "menu.todo": "Notas",
    "menu.search": "Búsqueda",
    "menu.pacientes": "Pacientes",
    "menu.toActivate": "Usuarios por Activar",
    "menu.diagnosticcenters": "Centros de diagnóstico",
    "menu.usuarios": "Usuarios",
    "menu.stats": "Informes",
    "menu.config": "Configuración",
    "menu.lista": "Lista",
    "menu.crear": "Añadir",
    "menu.detalle": "Detalle",
    "menu.historial": "Historial",
    "menu.registro": "Registro",
    "menu.doctores": "Doctores",
    "menu.clinicas": "Clínicas",
    "menu.operadores": "Operadores digitales",
    "menu.administradores": "Administradores",
    "menu.editar": "Editar",
    "menu.profile": "Perfil",
    "menu.logOut": "Cerrar sesión",
    "menu.viewProfile": "Ver perfil",
    "menu.editTexts": "Editar textos",
    "menu.condition": "Condiciones de uso",
    "menu.privacy": "Política de privacidad",
    "menu.legal": "Condiciones legales",
    "menu.solicitudes": "Solicitudes",


    /* 04.Error  */
    "layouts.error-title": "Vaya, parece que ha ocurrido un error!",
    "layouts.error-code": "Código de error",
    "layouts.go-back-home": "REGRESAR A INICIO",
    "layouts.delete": "Eliminar",
    "layouts.another-action": "Otra acción",
    "layouts.update": "Modificar",
    "layouts.activate": "Activar",
    "layouts.view": "Ver",
    "layouts.validate": "Validar",

    /* 05.Pacientes */
    "patienst.title": "Pacientes",
    "patients.add-new": "Nuevo paciente",
    "patients.createPatient": "Añadir paciente",
    "patients.authDoctors": "Doctores autorizados",
    "patients.edit": "Editar paciente",

    /* 06.Forms */
    "forms.patient": "Paciente",
    "forms.avatar": "Fotografía",
    "forms.firstName": "Nombre",
    "forms.lastName": "Apellidos",
    "forms.dni": "DNI",
    "forms.country": "País",
    "forms.bornDate": "Fecha de nacimiento",
    "forms.email": "Email",
    "forms.telephone": "Teléfono",
    "forms.diagnosticCenters": "Centros de diagnóstico",
    "forms.patients": "Pacientes",
    "forms.observations": "Observaciones",
    "forms.create": "Crear",
    "forms.update": "Actualizar",
    "forms.add.observation": "Añadir observación",
    "forms.registry-data": "Datos del registro",
    "forms.upload-files": "Subir archivos",
    "forms.upload-files-error": "Solo puedes subir archivos con las siguientes extensiones:",
    "forms.upload-files-error-name": "Ya hay uno o varios archivos con el mismo nombre",
    "forms.edit": "Editar",
    "forms.role": "Rol",
    "form.createAt": 'Creado el:',
    "changePassword": 'Cambiar contraseña',
    "form.email.sent": 'Se ha procesado su solicitud. En breve recibirá un mensaje en el correo electrónico facilitado con instrucciones para restablecer su contraseña',
    "form.reset-password.processing": 'Procesando el renicio de contraseña',
    "form.reset-password.200": 'Revise el correo electrónico, le hemos enviado su nueva contraseña',
    "form.reset-password.400": 'El enlace para reinicar la contraseña ha expirado',
    "form.reset-password.404": 'No se ha encontrado ninguna solicitud de reinicio de contraseña',
    "form-go-back": 'Atrás',
    "form-go-forward": 'Siguiente',

    /* 07.Centros de Diagnóstico */
    "diagnosticcenters.add-new": "Nuevo centro",
    "diagnosticcenters.create": "Añadir centro",
    "diagnosticcenters.update": "Actualizar centro",
    "forms.name": "Nombre",
    "forms.street": "Dirección",
    "forms.number": "Número",
    "forms.location": "Planta",
    "forms.zipCode": "Código postal",
    "forms.city": "Ciudad",
    "forms.province": "Provincia",
    "forms.non.observation": 'No se han añadido observaciones aún',

    /* 08.Informes */
    "stats.title": "Informes",
    "stats.tests": "Pruebas gestionadas",
    "stats.by-type": "Por tipología",
    "stats.by-user": "Por usuario",
    "stats.in-total": "En total",

    /* 09.Historias/Registros*/
    "history.title": 'Historial del paciente',
    "history.add-new": 'Nuevo Registro',
    "history.export": 'Exportar Registro',
    "history.summary": 'Sumario',
    "history.radiography": 'Radiografías',
    "history.other": 'Preplanificación',
    "history.stlModel": 'Modelos STL',
    "history.dicomViewer": 'Visores',
    "history.tac": 'Neoscope',
    "history.report": 'Informes',
    "history.summary.other.registries": "Otros Registros para este paciente",
    "history.summary.images": "Multimedia",
    "history.summary.no.multimedia": "No hay radiografías, Neoscope o archivos de preplanificación que mostrar aún.",
    "history.summary.observations": "Observaciones",
    "history.summary.observations-empty": "No se ha añadido ninguna observación aún",
    "history.summary.note": "Nota informativa",
    "history.summary.share": "Compartido con:",
    "history.summary.share-empty": "Aún no ha sido compartido con nadie",
    "history.summary.shows": "Visualizaciones (última semana)",
    "history.summary.share.doctor": "Compartir con",
    "history.summary.share.sharedDoctor": "Introduzca el email con el que quiere compartir el caso:",
    "history.summary.share.observations": "Observaciones:",


    /* 10.Registry */
    "registry.edit": 'Editar registro',
    "registry.name": 'Nombre del registro',
    "registry.button-add": 'Crear Registro',
    "registry.button-edit": 'Actualizar Registro',
    "registry.button-remove": 'Borrar Registro',
    "registry.export": 'Exportar Registro',
    "registry.authDoctors": 'Usuarios con acceso al registro (modo consulta)',

    /* 11.Usuarios */
    "forms.password": "Contraseña",
    "forms.repeatPassword": "Repetir contraseña",
    "forms.disabled": "Desactivado",
    "forms.cif": "CIF",
    "forms.activateMessage":"Escribe la contraseña de acceso para este usuario. Deberá informar al usuario de su nueva contraseña tras actualizarla.",
    "forms.updatePasswordMessage":"Escribe la nueva contraseña de acceso.",

    /* 111.Doctores */
    "doctor.title": "Doctores",
    "doctor.add-new": "Nuevo doctor",
    "doctor.createDoctor": "Añadir doctor",
    "forms.doctor": "Doctor",
    "forms.collegiateNumber": "Número de colegiado",
    "doctor.edit": "Editar doctor",

    /* 112.Clinicas */
    "clinic.title": "Clínicas",
    "clinic.add-new": "Nueva clínica",
    "clinic.createClinic": "Añadir clínica",
    "forms.clinic": "Clínica",
    "forms.clinicName": "Nombre de la clínica",
    "clinic.edit": "Editar clínica",

    /* 113.Operadores digitales */
    "technician.title": "Operadores digitales",
    "technician.add-new": "Nuevo operador",
    "technician.createTechnician": "Añadir operador",
    "forms.technician": "Operador digital",
    "technician.edit": "Editar operador",

    /* 114.Administradores */
    "admin.title": "Administradores",
    "admin.add-new": "Nuevo administrador",
    "admin.createAdmin": "Añadir administrador",
    "forms.admin": "Administrador",
    "admin.edit": "Editar administrador",

    /* 12.Validaciones */

    "forms.validation.firstName": "Por favor, introduce un nombre válido",
    "forms.validation.lastName": "Por favor, introduce un apellido válido",
    "forms.validation.diagnosticCenters": "Por favor, introduce un centro de diagnóstico",
    "forms.validation.email": "Por favor, introduce un email válido",
    "forms.validation.password": "Por favor, introduce una contraseña",
    "forms.validation.repeatPassword": "Por favor, vuelve a introducir la contraseña",
    "forms.validation.collegiateNumber": "Por favor, introduce un número de colegiado válido",
    "forms.validation.clinicName": "Por favor, introduce un número de clínica válido",

    "forms.validation.notEqualPasswords": "Error! Las contraseñas no son iguales",
    "forms.validation.dni": "Error! Dni incorrecto",
    "forms.validation.cif": "Error! Cif incorrecto",
    "forms.validation.avatar": "Error! Por favor, añade una imagen menor de 100KB",
    "forms.all": "Todos",


    /*13. Reports */
    "reports.platform-user": "Usuarios de la plataforma",
    "reports.registerPatients": "Pacientes registrados",
    "reports.testsPerformed": "Pruebas realizadas",
    "reports.accessesPlatform": "Accesos a la plataforma",
    "reports.this-month": "Este mes",
    "reports.this-week": "Esta semana",
    "reports.last-week": "Última semana",
    "reports.managed-test": "Pruebas gestionadas",
    "reports.filtered": "Informes filtrados por usuarios",
    "reports.platform": "Informes generales de la plataforma",
    "reports.findByLastName": "Buscar por apellidos",
    "reports.findByClinicName": "Buscar por nombre de clínica",

    /*14 Months*/
    "month.1" : "Ene",
    "month.2" : "Feb",
    "month.3" : "Mar",
    "month.4" : "Abr",
    "month.5" : "May",
    "month.6" : "Jun",
    "month.7" : "Jul",
    "month.8" : "Ago",
    "month.9" : "Sep",
    "month.10" : "Oct",
    "month.11" : "Nov",
    "month.12" : "Dic",

    /*15 Days*/
    "day.1" : "Lunes",
    "day.2" : "Martes",
    "day.3" : "Miercoles",
    "day.4" : "Jueves",
    "day.5" : "Viernes",
    "day.6" : "Sábado",
    "day.7" : "Domingo",

    /*16 NOTIFICATIONS*/
    "notify.success.userRequestCreateTitle" : "Solicitud registrada con éxito",
    "notify.success.userRequestCreateMessage" : "Deberá esperar a que un administrador lo valide para poder acceder a la plataforma.",
    "notify.error.alreadyUserRegister" : "Ya existe un usuario registrado con ese correo",
    "notify.error.emailAlreadyUsed" : "Ya existe un usuario registrado con ese correo",
    "notify.error.alreadyExistDNI" : "Ya existe un usuario registrado con ese DNI",
    "notify.error.anyError" : "Ha ocurrido un error inesperado",
    "notify.file.uploaded": "Se ha subido el archivo",
    "notify.file.uploaded.failed": "Fallo en la subida del archivo",
    "notify.file.uploaded.message": "Su archivo se ha subido con éxito",
    "notify.file.uploaded.failed.message": "Ha ocurrido algún error en la subida del archivo",
    "notify.upload.state": "Subiendo...",
    "notify.doctor.share": "Su solucitud ha sido procesada.",
    "notify.doctor.share.fill": "Debe introducir la información requerida.",
    "notify.doctor.share.failed": "Ha habido un problema con su solicitud por favor inténtelo de nuevo más tarde",
    "notify.doctor.history.failed": "Primero debes crear un registro asociado al paciente.",


    /* Misc */
    "date.error.lessThan365": "debe seleccionar menos de 365 días",
    "date.error.noValidRange": "debe seleccionar un rango de fechas válido"
};
