import React from "react";
import { Card } from "reactstrap";
import { Colxx } from "../../../components/CustomBootstrap";
import MultimediaBadge from "../../../components/MultimediaBadge/index";
import classnames from "classnames";
import history from '../../../history';
import { NotificationManager } from '../../../components/ReactNotifications';
import IntlMessage from '../../../util/IntlMessages';

const PatientDetailCard = (props) => {
  const { patient } = props;
  return (
    <Colxx xxs="12" key={patient._id} className="mb-3 p-0">
      <Card
        className={classnames("align-items-top d-flex flex-row")}
      >
        <div 
          className="d-flex flex-grow-1 min-width-zero" 
          style={{ cursor: 'pointer' }} 
          onClick={() => {
            if (patient.lastHistory) return history.push(`/app/pacientes/historial/${patient._id}/registro/${patient.lastHistory}`);
            return NotificationManager.create({
              type: 'error',
              title: <IntlMessage id={'notify.doctor.history.failed'}/>,
            });
          }}
        >
          <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
            <div className="d-flex flex-column">
              <p className="list-item-heading mb-0 p-0 truncate">
                {patient.firstName.concat(" ", patient.lastName)}
              </p>
              <p className="mb-1 text-muted text-small d-flex">
                {Intl.DateTimeFormat("es-ES", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                }).format(new Date(patient.createdAt))}
              </p>
            </div>
            <div className="w-20 w-sm-100">
              {patient.multimediaCount &&
                Object.keys(patient.multimediaCount).map((key, index) => (
                  
                    <MultimediaBadge
                      onClickCapture={(e) =>
                        this.handleClickOnBadge(e, patient, key)
                      }
                      multimedia={key}
                      key={index}
                    />
                  
                ))}
            </div>
          </div>
        </div>
      </Card>
    </Colxx>
  );
};

export default PatientDetailCard;
