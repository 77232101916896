import React from 'react'
import ButtonFullScreen from '../../components/FullscreenButton'
import ButtonDelete from '../../components/RemoveButton'
import ButtonDownload from '../../components/DownloadButton'
import FullScreenSTL from '../../components/FullScreenSTL'
import { withPortalCtxt } from "../Portal/Context";
import './style.scss'
import {isCorrectRole} from "../PrivateRoute";
import Thumbnail from '../../components/Thumbnails';


class STLViewer extends React.Component{
    state={
        fullScreen: false,
        width: this.props.initWidth,
        height:  this.props.initHeight,
    };

    static defaultProps = {
        initHeight: 250,
        initWidth: 250,
    };

    viewer = React.createRef();

    shouldComponentUpdate(){
        return false;
    }

    handleFullScreen = (e) => {
        e.preventDefault && e.preventDefault();
        e.stopPropagation && e.stopPropagation();
        this.props.handlePortalVisibility( true,()=> <FullScreenSTL media={this.props.media} idHistory={this.props.idHistory} type={this.props.type}/> );
    };

    downloadFile = () => {
        let link = document.createElement("a");
        link.download = this.props.media.name;
        link.href = this.props.media.url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        link.remove();
    };

    render(){
        return (
            <div className={'stl-wrapper-viewer'}>
                <Thumbnail
                    onClick={this.handleFullScreen}
                    type={'stl'}
                    alt={this.props.media.name}
                    betweenName={
                        <div className={'thumbnailButtons'}>
                            <ButtonFullScreen onClick={this.handleFullScreen}/>
                            <ButtonDownload onClick={this.downloadFile}/>
                            {isCorrectRole(['admin', 'technician']) && <ButtonDelete onClick={this.props.onDelete}/> }
                        </div>
                    }
                />

            </div>
        )
    }
}

export default withPortalCtxt(STLViewer);
