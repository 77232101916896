import React, { Fragment } from "react";
import {PortalCtxt} from '../../../components/Portal/Context'
import { parseToDayHour, emptyString } from '../../../util/Utils'
import IntlMessages from "../../../util/IntlMessages";
import {
    Row,
    Button,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText,
    CustomInput,
    DropdownItem,
    ButtonDropdown,
    Label,
    Input
} from "reactstrap";
import { NavLink } from "react-router-dom";

import { injectIntl } from 'react-intl';
import { Colxx } from "../../../components/CustomBootstrap";
import Pagination from "../../../components/List/Pagination";

import { filterRegistries, deleteRegistry } from "../../../redux/actions";
import { connect } from "react-redux";
import GetPatient from '../../../util/GetPatientFromReduxOrBD';
import classnames from "classnames";
import mouseTrap from "react-mousetrap";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import ContextMenuClass from '../../../components/ContextMenuClass';
import PageHeader from "../../../components/PageHeader";
import { getThumbnails } from "../utils";
import Carousel from '../../../components/Carousel';
import { isCorrectRole } from '../../../components/PrivateRoute';
import MultimediaBadge from "../../../components/MultimediaBadge";

function collect(props) {
    return { data: props.data };
}

class HistoryList extends ContextMenuClass {
    constructor(props) {
        super(props);
        this.onContextMenuClick = this.onContextMenuClick.bind(this);
        this.toggleSplit = this.toggleSplit.bind(this);
        this.state = {
            patientName: '',
            selectedItems: [],
            pageSizes: [8, 12, 24],
            selectedPageSize: 8,
            currentPage: 1,
            totalPage: 1,
            search: "",
            lastChecked: null,
            loading: false,
            dropdownSplitOpen: false
        }
    }

    static defaultProps = {
        data: [],
        loading: true
    };

    componentDidMount() {
        this.props.filterRegistries({ patientId: this.props.match.params.id }, undefined, this.goToCreateRegistryIfNoRegistryYet);
        const patient = GetPatient(this.props.match.params.id);
        this.setState({
            patientName: patient.firstName ? `${patient.firstName} ${patient.lastName}` : emptyString()
        });
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextProps.patientsData !== this.props.patientsData) {
            const patient = nextProps.patientsData && nextProps.patientsData.find(pat => pat._id === this.props.match.params.id);
            if (patient)
                this.setState({
                    patientName: `${patient.firstName} ${patient.lastName}`
                })
        }
    }

    goToCreateRegistryIfNoRegistryYet = (data) => {
        if(data.length === 0)
            this.props.history.push(`/app/pacientes/historial/${this.props.match.params.id}/crear`)
    };

    handleCheckChange(event, id) {
        if (
            event.target.tagName === "A" ||
            (event.target.parentElement &&
                event.target.parentElement.tagName === "A")
        ) {
            return true;
        }
        if (this.state.lastChecked == null) {
            this.setState({
                lastChecked: id
            });
        }

        let selectedItems = this.state.selectedItems;
        if (selectedItems.includes(id)) {
            selectedItems = selectedItems.filter(x => x !== id);
        } else {
            selectedItems.push(id);
        }
        this.setState({
            selectedItems
        });

        if (event.shiftKey) {
            let items = this.state.items;
            let start = this.getIndex(id, items, "id");
            let end = this.getIndex(this.state.lastChecked, items, "id");
            items = items.slice(Math.min(start, end), Math.max(start, end) + 1);
            selectedItems.push(
                ...items.map(item => {
                    return item.id;
                })
            );
            selectedItems = Array.from(new Set(selectedItems));
            this.setState({
                selectedItems
            });
        }
        document.activeElement.blur();
    }

    onChangePage(page) {
        this.setState(
            {
                currentPage: page
            },
            () => this.props.getPatients(page, this.state.selectedPageSize)
        );
    }

    toggleSplit() {
        this.setState(prevState => ({
            dropdownSplitOpen: !prevState.dropdownSplitOpen
        }));
    };

    handleChangeSelectAll(isToggle) {
        if (this.state.selectedItems.length >= this.props.data.length) {
            if (isToggle) {
                this.setState({
                    selectedItems: []
                });
            }
        } else {
            this.setState({
                selectedItems: this.props.data.map(x => x._id)
            });
        }
        document.activeElement.blur();
        return false;
    };


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.totalItemCount !== this.props.totalItemCount || prevState.selectedPageSize !== this.state.selectedPageSize) {
            this.setState({
                totalPage: Math.ceil(this.props.totalItemCount / this.state.selectedPageSize)
            });
        }
    }

    onViewInContext = (data) => {
        this.props.history.push(`./${this.props.match.params.id}/registro/${data.data}`);
    };


    onUpdateInContext = (data) => {
        this.props.history.push(`./${this.props.match.params.id}/editar/${data.data}`);
    };


    onDeleteInContext = (data) => {
        this.props.deleteRegistry(data.data);
    };

    renderActions = () => {
        //TODO: Commented DropDown because actions in block aren't developed

        return (
            <Fragment>
                <NavLink to={`/app/pacientes/historial/${this.props.match.params.id}/crear`}>
                    <Button
                        color="primary"
                        size="lg"
                    >
                        <IntlMessages id="history.add-new" />
                    </Button>
                </NavLink>
                {/*{"  "}*/}
                {/*<ButtonDropdown*/}
                {/*isOpen={this.state.dropdownSplitOpen}*/}
                {/*toggle={this.toggleSplit}*/}
                {/*>*/}
                {/*<div className="btn btn-primary pl-4 pr-0 check-button">*/}
                {/*<Label*/}
                {/*for="checkAll"*/}
                {/*className="custom-control custom-checkbox mb-0 d-inline-block"*/}
                {/*>*/}
                {/*<Input*/}
                {/*className="custom-control-input"*/}
                {/*type="checkbox"*/}
                {/*id="checkAll"*/}
                {/*checked={*/}
                {/*this.state.selectedItems.length >=*/}
                {/*this.props.data.length*/}
                {/*}*/}
                {/*onChange={() => this.handleChangeSelectAll(true)}*/}
                {/*/>*/}
                {/*<span*/}
                {/*className={`custom-control-label ${*/}
                {/*this.state.selectedItems.length > 0 &&*/}
                {/*this.state.selectedItems.length <*/}
                {/*this.props.data.length*/}
                {/*? "indeterminate"*/}
                {/*: ""*/}
                {/*}`}*/}
                {/*/>*/}
                {/*</Label>*/}
                {/*</div>*/}
                {/*<DropdownToggle*/}
                {/*caret*/}
                {/*color="primary"*/}
                {/*className="dropdown-toggle-split pl-2 pr-2"*/}
                {/*/>*/}
                {/*<DropdownMenu right>*/}
                {/*<DropdownItem>*/}
                {/*<IntlMessages id="layouts.delete" />*/}
                {/*</DropdownItem>*/}
                {/*<NavLink to={`/app/pacientes/editar/${this.props.match.params.id}`}>*/}
                {/*<DropdownItem>*/}
                {/*<IntlMessages id="patients.edit" />*/}
                {/*</DropdownItem>*/}
                {/*</NavLink>*/}
                {/*</DropdownMenu>*/}
                {/*</ButtonDropdown>*/}
            </Fragment>
        )
    };

    renderTitle() {
        return (<>
            <h1 className={'mb-1 pb-0'}><IntlMessages id="history.title" />
                <div>
                    <small className={'dident-text-light'}>{this.state.patientName}</small>
                </div>
            </h1>
        </>)
    }

    renderHeader() {
        return (

            <PageHeader
                heading={this.renderTitle()}
                match={this.props.match}
                actions={isCorrectRole(['admin', 'technician']) && this.renderActions()}
                finder={undefined}
            />
        );
    }

    render() {
        const { messages } = this.props.intl;
        if (this.props.totalItemCount === 0 || this.props.data.length <= 0) {
            return (
                <Fragment>
                    <div className="disable-text-selection">
                        {this.renderHeader()}
                        {/*{*/}
                        {/*    !this.props.loading ?*/}
                        {/*        <Row>*/}
                        {/*            <Colxx xxs="12" className="mb-3">*/}
                        {/*                <h2><IntlMessages id="general.no-results-found" /></h2>*/}
                        {/*            </Colxx>*/}
                        {/*        </Row>*/}
                        <div className="loading" />
                        {/*}*/}
                    </div>
                </Fragment>
            )
        }

        return (
            <PortalCtxt>
                <div className="disable-text-selection">
                    {this.renderHeader()}
                    <Row>
                        {!this.props.loading ?
                            this.props.data.map(registry => {
                                return (
                                    <Colxx xxs="12" sm='6' md='4' lg="3" key={registry._id} className="mb-3">
                                        <ContextMenuTrigger
                                            id="menu_id"
                                            data={registry._id}
                                            collect={collect}
                                        >
                                            <Card
                                                onClick={event =>
                                                    this.handleCheckChange(event, registry._id)
                                                }
                                                className={classnames("d-flex flex-column", {
                                                    active: this.state.selectedItems.includes(
                                                        registry._id
                                                    )
                                                })}
                                            >

                                                <div className="d-flex flex-column align-items-start slick-dotted " style={{zIndex:1}}>
                                                    { registry.multimediaCount && Object.keys(registry.multimediaCount).map((key, index) => {
                                                        return <MultimediaBadge multimedia={key} key={index} />
                                                    }) }
                                                </div>
                                                <NavLink to={`./${this.props.match.params.id}/registro/${registry._id}`}>
                                                    <Carousel items={getThumbnails(registry)} registry={registry} />
                                                </NavLink>

                                                <CardBody className={'p-4'}>
                                                    <div className={'d-flex flex-row justify-content-between'}>
                                                        <div>
                                                            <CardTitle className={'mb-1'}>{registry.name}</CardTitle>
                                                            <CardSubtitle className={'dident-text-light mb-1'}>{parseToDayHour(registry.createdAt)}</CardSubtitle>
                                                        </div>
                                                        {isCorrectRole(['admin', 'technician']) &&
                                                        <div className="custom-control custom-checkbox ">
                                                            {/*<CustomInput*/}
                                                            {/*    className="itemCheck mb-0"*/}
                                                            {/*    type="checkbox"*/}
                                                            {/*    id={`check_${registry._id}`}*/}
                                                            {/*    checked={this.state.selectedItems.includes(*/}
                                                            {/*        registry._id*/}
                                                            {/*    )}*/}
                                                            {/*    onChange={() => { }}*/}
                                                            {/*    label=""*/}
                                                            {/*/>*/}
                                                        </div>
                                                        }
                                                    </div>
                                                    {/*{*/}
                                                    {/*    registry.observations.map((obj, index) => <CardText key={index}>{obj.what}</CardText>)*/}
                                                    {/*}*/}

                                                </CardBody>
                                            </Card>
                                        </ContextMenuTrigger>
                                    </Colxx>
                                )
                            })
                            : <div className="loading" />
                        }
                        {!this.props.loading ?
                            <Pagination
                                currentPage={this.state.currentPage}
                                totalPage={this.state.totalPage}
                                onChangePage={i => this.onChangePage(i)}
                            />
                            : <div />
                        }
                    </Row>
                </div>

                <ContextMenu
                    id="menu_id"
                    onShow={e => this.onContextMenu(e, e.detail.data)}
                >
                    <MenuItem
                        onClick={this.onContextMenuClick}
                        data={{ action: "view" }}
                    >
                        <i className="iconsmind-Profile" /> <span>{messages["layouts.view"]}</span>
                    </MenuItem>
                    {isCorrectRole(['admin', 'technician']) &&
                    <>
                        <MenuItem
                            onClick={this.onContextMenuClick}
                            data={{ action: "update" }}
                        >
                            <i className="simple-icon-refresh" /> <span>{messages["layouts.update"]}</span>
                        </MenuItem>
                        <MenuItem
                            onClick={this.onContextMenuClick}
                            data={{ action: "delete" }}
                        >
                            <i className="simple-icon-trash" /> <span>{messages["layouts.delete"]}</span>
                        </MenuItem>
                    </>
                    }
                </ContextMenu>
            </PortalCtxt>
        );
    }
}

const mapStateToProps = ({ registries, patients }) => {
    const { data, loading, totalItemCount } = registries;
    const patientsData = patients.data;
    return { data, patientsData, loading, totalItemCount }
};

export default injectIntl(mouseTrap(connect(mapStateToProps, { filterRegistries, deleteRegistry })(HistoryList)))