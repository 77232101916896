import React, { Fragment } from "react";
import IntlMessages from "../../../util/IntlMessages";
import Avatar from '../../../components/Avatar';

import {
    Row,
    Button,
    Card,
    Badge} from "reactstrap";
import { NavLink } from "react-router-dom";

import { injectIntl } from 'react-intl';
import Finder from '../../../components/Finder'
import { Colxx } from "../../../components/CustomBootstrap";
import SelectorNumberOfItemsPerPage from "../../../components/SelectorNumberOfItemsPerPage";
import Pagination from "../../../components/List/Pagination";

import { getUsersByRole, deleteUser, filterUsers } from "../../../redux/actions";
import { connect } from "react-redux";

import classnames from "classnames";
import mouseTrap from "react-mousetrap";
import { ContextMenu, MenuItem } from "react-contextmenu";
import ContextMenuClass from '../../../components/ContextMenuClass';
import PageHeader from "../../../components/PageHeader";

import { roles } from "../../../data/roles";
import { isCorrectRole } from "../../../components/PrivateRoute";


class Users extends ContextMenuClass {
    constructor(props) {
        super(props);
        const rol = roles.find(rol => rol.url === this.props.match.params.role);
        this.onContextMenuClick = this.onContextMenuClick.bind(this);
        this.toggleSplit = this.toggleSplit.bind(this);
        this.state = {
            selectedItems: [],
            pageSizes: [8, 12, 24],
            selectedPageSize: 8,
            currentPage: 1,
            totalPage: 1,
            search: "",
            lastChecked: null,
            loading: false,
            dropdownSplitOpen: false,
            role: rol,
        };

        //only admins can access to admin/technician crud
        if (['admin', 'technician'].includes(this.state.role.key) && !isCorrectRole(['admin'])) {
            this.props.history.push('/app/usuarios');
        }
    }

    componentDidMount() {
        this.props.getUsersByRole(0, 8, this.state.role.key);
        this.setState({
            totalPage: Math.ceil(this.props.totalItemCount / this.state.selectedPageSize)
        })
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.totalItemCount !== this.props.totalItemCount || prevState.selectedPageSize !== this.state.selectedPageSize) {
            this.setState({
                totalPage: Math.ceil(this.props.totalItemCount / this.state.selectedPageSize)
            });
        }

    }

    handleCheckChange(event, id) {
        if (
            event.target.tagName === "A" ||
            (event.target.parentElement &&
                event.target.parentElement.tagName === "A")
        ) {
            return true;
        }
        if (this.state.lastChecked == null) {
            this.setState({
                lastChecked: id
            });
        }

        let selectedItems = this.state.selectedItems;
        if (selectedItems.includes(id)) {
            selectedItems = selectedItems.filter(x => x !== id);
        } else {
            selectedItems.push(id);
        }
        this.setState({
            selectedItems
        });

        if (event.shiftKey) {
            let items = this.state.items;
            let start = this.getIndex(id, items, "id");
            let end = this.getIndex(this.state.lastChecked, items, "id");
            items = items.slice(Math.min(start, end), Math.max(start, end) + 1);
            selectedItems.push(
                ...items.map(item => {
                    return item.id;
                })
            );
            selectedItems = Array.from(new Set(selectedItems));
            this.setState({
                selectedItems
            });
        }
        document.activeElement.blur();
    }
    handleKeyPress = (e, searchKey) => {
        if (e.key === "Enter") {
            this.setState(
                {
                    search: e.target.value.toLowerCase(),
                    filter: searchKey,
                }
            );

            const { role, selectedPageSize } = this.state;
            this.props.filterUsers({
                role: role.key,
                limit: selectedPageSize,
                sort: "createdAt",
                order: "DESC",
                [searchKey]: e.target.value.toLowerCase(),
            });
        }
    };
    handleOnSearchIconClick = (searchKey, value) => {

        this.setState(
          {
              search: value,
              filter: searchKey,
          }
        );

        const { role, selectedPageSize } = this.state;
        this.props.filterUsers({
            role: role.key,
            limit: selectedPageSize,
            sort: "createdAt",
            order: "DESC",
            [searchKey]: value,
        })
    }
    changePageSize = (size) =>{
        this.setState(
            {
                selectedPageSize: size,
                currentPage: 1
            },
          () => {
              const { filter, search, role } = this.state;

              if(filter && search) {
                  this.props.filterUsers({
                      role: role.key,
                      limit: size,
                      sort: "createdAt",
                      order: "DESC",
                      page: 0,
                      [filter]: search,
                  })
              } else {
                  this.props.getUsersByRole(0, size, this.state.role.key)
              }
          }
        );
    };
    onChangePage(page) {
        this.setState(
            {
                currentPage: page
            },
            () => {
                const { filter, search, role, selectedPageSize } = this.state;

                if(filter && search) {
                    this.props.filterUsers({
                        role: role.key,
                        limit: selectedPageSize,
                        sort: "createdAt",
                        order: "DESC",
                        page: page - 1,
                        [filter]: search,
                    })
                } else {
                    this.props.getUsersByRole(page - 1, this.state.selectedPageSize, this.state.role.key)
                }
            }

        );
    }

    toggleSplit() {
        this.setState(prevState => ({
            dropdownSplitOpen: !prevState.dropdownSplitOpen
        }));
    };
    handleChangeSelectAll(isToggle) {
        if (this.state.selectedItems.length >= this.props.data.length) {
            if (isToggle) {
                this.setState({
                    selectedItems: []
                });
            }
        } else {
            this.setState({
                selectedItems: this.props.data.map(x => x._id)
            });
        }
        document.activeElement.blur();
        return false;
    };


    onViewInContext = (data) => {
        this.props.history.push(`detalle/${data.data}`);
    };

    onUpdateInContext = (event,data) => {
        if(event.stopPropagation && event.preventDefault) {
            event.stopPropagation();
            event.preventDefault();
            this.props.history.push(`editar/${data.data}`);
        }
        else this.props.history.push(`editar/${event.data}`);
    };

    onDeleteInContext = (event,data) => {
        if(event && event.stopPropagation && event.preventDefault) {
            event.stopPropagation();
            event.preventDefault();
            this.props.deleteUser(data.data);
        }
        else this.props.deleteUser(event.data);
    };

    handleClickOnCard(event, entity){
        if( !event.target.classList.contains('badge') &&
            !event.target.classList.contains('mx-1') &&
            event.target.tagName !== 'I')
        {
            event.stopPropagation();
            event.preventDefault();
            this.props.history.push(`detalle/${entity._id}`)
        }
    }

    renderActions = () => {
        const { role } = this.state;
        //TODO: Commented DropDown because actions in block aren't developed
        return (
            <Fragment>
                <NavLink to={`/app/usuarios/${role.url}/crear`}>
                    <Button
                        color="primary"
                        size="lg"
                    >
                        <IntlMessages id={`${role.key}.create${role.key.charAt(0).toUpperCase() + role.key.slice(1)}`} />
                    </Button>
                </NavLink>
                {/*{"  "}*/}
                {/*<ButtonDropdown*/}
                    {/*isOpen={this.state.dropdownSplitOpen}*/}
                    {/*toggle={this.toggleSplit}*/}
                {/*>*/}
                    {/*<div className="btn btn-primary pl-4 pr-0 check-button">*/}
                        {/*<Label*/}
                            {/*for="checkAll"*/}
                            {/*className="custom-control custom-checkbox mb-0 d-inline-block"*/}
                        {/*>*/}
                            {/*<Input*/}
                                {/*className="custom-control-input"*/}
                                {/*type="checkbox"*/}
                                {/*id="checkAll"*/}
                                {/*checked={*/}
                                    {/*this.state.selectedItems.length >=*/}
                                    {/*this.props.data.length*/}
                                {/*}*/}
                                {/*onChange={() => this.handleChangeSelectAll(true)}*/}
                            {/*/>*/}
                            {/*<span*/}
                                {/*className={`custom-control-label ${*/}
                                    {/*this.state.selectedItems.length > 0 &&*/}
                                        {/*this.state.selectedItems.length <*/}
                                        {/*this.props.data.length*/}
                                        {/*? "indeterminate"*/}
                                        {/*: ""*/}
                                    {/*}`}*/}
                            {/*/>*/}
                        {/*</Label>*/}
                    {/*</div>*/}
                    {/*<DropdownToggle*/}
                        {/*caret*/}
                        {/*color="primary"*/}
                        {/*className="dropdown-toggle-split pl-2 pr-2"*/}
                    {/*/>*/}
                    {/*<DropdownMenu right>*/}
                        {/*<DropdownItem>*/}
                            {/*<IntlMessages id="layouts.delete" />*/}
                        {/*</DropdownItem>*/}
                    {/*</DropdownMenu>*/}
                {/*</ButtonDropdown>*/}
            </Fragment>
        )
    };

    renderHeader() {
        const { messages } = this.props.intl;
        const startIndex = (this.state.currentPage - 1) * this.state.selectedPageSize;
        const endIndex = (this.state.currentPage) * this.state.selectedPageSize;
        const { role } = this.state;
        const {selectedPageSize, pageSizes} = this.state;
        return (
            <PageHeader
                heading={<h1><IntlMessages id={`menu.${role.url}`} /></h1>}
                match={this.props.match}
                role={this.state.role.url}
                actions={this.renderActions()}
                finder={<Finder 
                    placeholder={messages["menu.search"]} 
                    searchKeys={role.filters.length > 0 ? role.filters : []} 
                    onKeyPress={this.handleKeyPress} 
                    onSearchIconClick={this.handleOnSearchIconClick}/>}
            >
                <SelectorNumberOfItemsPerPage
                    startIndex={startIndex}
                    endIndex={endIndex}
                    totalItemCount={this.props.totalItemCount}
                    selectedPageSize={selectedPageSize}
                    pageSizes={pageSizes}
                    changePageSize={this.changePageSize}
                />
            </PageHeader>
        );
    }
    render() {
        const { messages } = this.props.intl;

        if (this.props.totalItemCount === 0) {
            return (
                <Fragment>
                    <div className="disable-text-selection">
                        {this.renderHeader()}
                        {!this.props.loading ?
                            <Row>
                                <Colxx xxs="12" className="mb-3">
                                    <h2><IntlMessages id="general.no-results-found" /></h2>
                                </Colxx>
                            </Row>
                            : <div className="loading" />
                        }
                    </div>
                </Fragment>
            )
        }
        const dataUsers = Array.isArray(this.props.data) ? this.props.data : [this.props.data];
        return (
            <Fragment>
                <div className="disable-text-selection">
                    {this.renderHeader()}
                    <Row>
                        {!this.props.loading ?
                            dataUsers.map(user => {
                                return (
                                    <Colxx xxs="12" key={user._id} className="mb-3">
                                        {/*<ContextMenuTrigger*/}
                                        {/*    id="menu_id"*/}
                                        {/*    data={user._id}*/}
                                        {/*    collect={collect}*/}
                                        {/*>*/}
                                            <Card
                                                // onClick={event =>
                                                //     this.handleCheckChange(event, user._id)
                                                //}
                                                onClickCapture={(event)=>this.handleClickOnCard(event,user)}
                                                className={classnames("d-flex flex-row align-items-top", {
                                                    active: this.state.selectedItems.includes(
                                                        user._id
                                                    )
                                                })}
                                            >
                                                <NavLink
                                                    to={`detalle/${user._id}`}
                                                    className="d-flex list-thumbnail-wrapper"
                                                >
                                                    <Avatar patient={user} />

                                                </NavLink>

                                                <div className="d-flex flex-grow-1 min-width-zero">
                                                    <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                                                        <NavLink
                                                            to={`detalle/${user._id}`}
                                                            className="w-25 w-sm-100"
                                                        >
                                                            <p className="list-item-heading mb-1 truncate">
                                                                {user.role !== 'clinic' ? user.firstName.concat(" ", user.lastName) : user.clinicName}
                                                            </p>
                                                        </NavLink>
                                                        <p className="mb-1 text-muted text-small w-30 w-sm-100">
                                                            {user.email}
                                                        </p>
                                                        {user.key === 'doctor' && <p className="mb-1 text-muted text-small w-10 w-sm-100">
                                                            {user.collegiateNumber}
                                                        </p>}
                                                        <div className="w-30 w-sm-100">
                                                            <ul>
                                                                {user.diagnosticCenters.map((center, i) => {
                                                                    return <li className="mb-1 text-muted text-small" key={i}>{`${center.name}`}</li>
                                                                })}
                                                            </ul>
                                                        </div>
                                                        <div className="w-10 w-sm-100">
                                                            <Badge color={user.disabled ? 'dident-light' : 'primary'} pill className={'users-badge'}>
                                                                {user.disabled ? <IntlMessages id="general.disabled" /> : <IntlMessages id="general.enabled" />}
                                                            </Badge>
                                                        </div>
                                                    </div>
                                                    <div className="custom-control custom-checkbox pl-1 align-self-center pr-4">
                                                        <div className="flex-column d-flex d-md-block">
                                                            <a href="#" onClickCapture={(e) => this.onUpdateInContext(e,{data:user._id})} aria-label="Editar" title="Editar" className="mx-1 actions-icons p-2">
                                                                <i className="simple-icon-pencil"/>
                                                            </a>
                                                            <a href="#" onClickCapture={(e) => this.onDeleteInContext(e, {data:user._id})} aria-label="Borrar" title="Borrar" className="mx-1 actions-icons p-2">
                                                                <i className="simple-icon-trash"/>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card>
                                        {/*</ContextMenuTrigger>*/}
                                    </Colxx>
                                )
                            })
                            : <div className="loading" />
                        }
                        {!this.props.loading ?
                            <Pagination
                                currentPage={this.state.currentPage}
                                totalPage={this.state.totalPage}
                                onChangePage={i => this.onChangePage(i)}
                            />
                            : <div />
                        }
                    </Row>
                </div>

                <ContextMenu
                    id="menu_id"
                    onShow={e => this.onContextMenu(e, e.detail.data)}
                >
                    <MenuItem
                        onClick={this.onContextMenuClick}
                        data={{ action: "view" }}
                    >
                        <i className="iconsmind-Profile" /> <span>{messages["layouts.view"]}</span>
                    </MenuItem>
                    <MenuItem
                        onClick={this.onContextMenuClick}
                        data={{ action: "update" }}
                    >
                        <i className="simple-icon-refresh" /> <span>{messages["layouts.update"]}</span>
                    </MenuItem>
                    <MenuItem
                        onClick={this.onContextMenuClick}
                        data={{ action: "delete" }}
                    >
                        <i className="simple-icon-trash" /> <span>{messages["layouts.delete"]}</span>
                    </MenuItem>
                </ContextMenu>
            </Fragment>
        );
    }
}

const mapStateToProps = ({ users }) => {
    const { data, loading, totalItemCount } = users;
    return { data, loading, totalItemCount }
}

export default injectIntl(mouseTrap(connect(mapStateToProps, { getUsersByRole, deleteUser, filterUsers })(Users)))