import React from 'react';
import {
    Row,
} from "reactstrap";

import { Colxx, Separator } from "../CustomBootstrap";
import BreadcrumbContainer from "../BreadcrumbContainer";
import { isCorrectRole } from "../PrivateRoute";

const PageHeader = ({ match, heading, actions, finder, children, role }) => {
    return (
        <Row>
            <Colxx xxs="12">
                <Row className="mb-2">
                    <Colxx xxs={12} xs={actions ? 6 : 12} style={{ wordBreak: 'break-all' }}>
                        {heading && heading}
                    </Colxx>
                    {
                        actions && <Colxx xxs={12} xs={heading ? 6 : 12}>
                            <div className="float-right mb-2 w-xs-100 d-flex justify-content-end w-xs-100-childs">
                                {actions}
                            </div>
                        </Colxx>
                    }
                    <Colxx xxs={12}>
                        {isCorrectRole(['admin', 'technician', 'doctor', 'clinic']) && <BreadcrumbContainer
                            heading={heading}
                            match={match}
                            role={role}
                        />}
                        <div className="mb-2">
                            {finder && finder}
                            <div className="float-md-right mt-2">
                                {children}
                            </div>
                        </div>
                        <Separator className="mb-3" />
                    </Colxx>
                </Row>
            </Colxx>
        </Row>
    )
};

PageHeader.defaultProps = {
    match: 'String'
};

export default PageHeader