import React from 'react';

export const BadgeForTab = ({children, type, className = ''}) => {
 return <span className={`badge nav-badge ${className}`}>{children}</span>
};

class Tabs extends React.Component{
    constructor(props){
        super(props);
        this.state={
            active: this.props.active || 0
        }
    }

    handleActive = (e,num) =>{
        e.preventDefault && e.preventDefault();
        e.stopPropagation && e.stopPropagation();
        if(num !== this.state.active)
            this.setState({
                active: num
            })
    };


    render(){
        return(
            <>
                <div className={'w-100 mb-3 pl-3'}>
                    <nav className="dident-tabs nav"> {/*nav-justified*/}
                        {this.props.headers.map((item, index) => {
                            return (
                                <a key={index} className={`dident-nav-item nav-link ${item.badge && 'with-badge'} ${index === this.state.active ? 'active text-primary' : ''}`} onClick={e=>this.handleActive(e,index)}>
                                    {item.label}
                                    {item.badge && item.badge}
                                </a>
                            )
                        })}
                    </nav>
                </div>
                <div className={'w-100 mb-3 pl-3 pr-3'}>
                    <div className="position-relative dident-tabs-content" id="v-pills-tabContent">
                        {this.props.children.map((item, index) => {
                            return (
                                <div key={index} className={`tab-pane w-100 fade ${index === this.state.active ? 'active show' : 'd-none'}`} id="v-pills-home" role="tabpanel"
                                     aria-labelledby="v-pills-home-tab">
                                    {item}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </>
        )
    }
}

export default Tabs