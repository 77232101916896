import React from 'react';
import {Separator} from '../CustomBootstrap'
import IntlMessages, {injectIntl} from "../../util/IntlMessages";
import {CardText, CardSubtitle} from 'reactstrap'
import {isCorrectRole} from "../PrivateRoute";

const CardShare = ({id, what, who, when, last}) => {

    let name = who.id;
    if(who.name)
        name = who.name;
    else if(who.firstName)
        name = `${who.firstName} ${who.lastName}`;
    else if(who.clinicName)
        name = who.clinicName;

    return (
        <>
            <div className={'d-flex'}>
                <div className={'ml-3'}>
                    <CardText className={'dident-text-light mb-1'}>{ isCorrectRole(['admin', 'technician', 'doctor', 'clinic']) && ( `${name} |` )} {when ? new Date(when).toLocaleDateString() : ''}</CardText>
                    <CardSubtitle>{what}</CardSubtitle>
                </div>
            </div>

            {!last && <Separator className={'mt-0 mb-3'}/>}

        </>
    )
};


export default injectIntl(CardShare);