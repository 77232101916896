import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { NotificationContainer } from "../components/ReactNotifications";
import { defaultStartPath } from './../constants/defaultValues'


import AppLocale from '../lang';
import MainRoute from '../routes';
import login from '../routes/login'
import register from '../routes/register'
import error from '../routes/error'
import forgotPassword from '../routes/forgot-password'
import resetPassword from '../routes/reset-password'

import '../assets/css/vendor/bootstrap.min.css'
import 'react-perfect-scrollbar/dist/css/styles.css';
import '../assets/css/sass/themes/gogo.light.blue.scss';
/*
color options :
	 'light.purple'		'dark.purple'
	 'light.blue'		'dark.blue'
	 'light.green'		'dark.green'
	 'light.orange'		'dark.orange'
	 'light.red'		'dark.red'
*/

const InitialPath = ({ component: Component,  authUser,...rest }) =>
	<Route
		{...rest}
		render={props =>
			authUser
				? <Component {...props} />
				: <Redirect
					to={{
						pathname: '/login',
						state: { from: props.location }
					}}
				/>}
	/>;

class App extends Component {
	render() {
		const { location, match, locale } = this.props;
		const currentAppLocale = AppLocale[locale];
		if (location.pathname === '/'  || location.pathname==='/app'|| location.pathname==='/app/') {
			return (<Redirect to={defaultStartPath} />);
		}
		return (
				<Fragment>
					<IntlProvider
						locale={currentAppLocale.locale}
						messages={currentAppLocale.messages}
					>
						<Fragment>
                            <NotificationContainer />

							<Switch>
							<InitialPath
								path={`${match.url}app`}
								authUser={localStorage.getItem('access_token')}
								component={MainRoute}
							/>
							<Route path={`/login`} component={login} />
							<Route path={`/register`} component={register} />
							<Route path={`/forgot-password`} component={forgotPassword} />
							<Route path={`/reset-password/:token`} component={resetPassword} />
							<Route path={`/error`} component={error} />
							<Route path={`/`} component={error} />
							<Redirect to="/error" />
						</Switch>
						</Fragment>
					</IntlProvider>
				</Fragment>
		);
	}
}

const mapStateToProps = ({ authUser, settings }) => {
	const { locale } = settings;
	return { locale };
};

export default connect(mapStateToProps,{  })(App);

