import React, { Component, Fragment } from "react";
import IntlMessages from "../../util/IntlMessages";
import { Row, Card, CardTitle, Label, Input, Button } from "reactstrap";
import { Formik, Form } from 'formik';
import { NavLink } from "react-router-dom";
import { Colxx } from "../../components/CustomBootstrap";
import { connect } from "react-redux";
import { loginUser } from "../../redux/actions";
import { isUserLogged } from "../../components/PrivateRoute";
import history from "../../history";
import ErrorMessage from '../../components/ErrorMenssage';
class LoginLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            error: "",
            writing: false,
            typeUser: props.location.search.includes('user=patient') ? 'patient' : 'other'
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    onUserLogin = () => {
        if (this.state.email !== "" && this.state.password !== "") {
            this.props.loginUser(this.state, this.props.history);
            this.setState({
                writing: false
            })
        }
    };
    handleInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
            writing: true
        });
    }
    componentDidMount() {
        if(isUserLogged()){
            history.push('/app/');
        }
        document.body.classList.add("background");
    }
    componentWillUnmount() {
        document.body.classList.remove("background");
    }
    render() {
        return (
            <Fragment>
                <div className="fixed-background" />
                <main>
                    <div className="container">
                        <Row className="h-100">
                            <Colxx xxs="12" md="10" className="mx-auto my-auto">
                                <Card className="auth-card">
                                    <div className="position-relative image-side ">
                                        <p className="text-blue h2 fw-700">Dident Connect</p>
                                        <p className="text-blue mb-0 fw-600">
                                            La plataforma de gestión de pruebas digitales realizadas a los pacientes de Dident3d.
                                            <br />
                                            <br />
                                            Por favor, introduce a continuación tus datos de acceso o{" "}
                                            <NavLink to={`/register`} className="text-blue">
                                                <u>solicita acceso</u>
                                            </NavLink> a nuestra plataforma.
                                        </p>
                                    </div>
                                    <div className="form-side">
                                        <NavLink to={`/`} className="white">
                                            <span className="logo-single" />
                                        </NavLink>
                                        <CardTitle className="mb-4">
                                            <IntlMessages id="user.login-title" />
                                        </CardTitle>
                                        <Formik onSubmit={this.onUserLogin} >
                                            {({ errors, touched, isValidating }) => (
                                                <Form>
                                                    <Label className="form-group has-float-label mb-4">
                                                        <Input name="email" value={this.state.email} onChange={this.handleInputChange} />
                                                        <IntlMessages id={this.state.typeUser == 'patient' ? "user.dni" : "user.email"} />
                                                    </Label>
                                                    <Label className="form-group has-float-label mb-4">
                                                        <Input type="password" name="password" onChange={this.handleInputChange} />
                                                        <IntlMessages
                                                            id="user.password"
                                                            value={this.state.password}
                                                        />
                                                    </Label>

                                                    {(this.props.error && !this.state.writing) &&
                                                        <ErrorMessage className='mb-4' message={'user.error-password'} />}

                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <NavLink to={`/forgot-password`} hidden={this.state.typeUser === 'patient'}>
                                                            <IntlMessages id="user.forgot-password-question" />
                                                        </NavLink>
                                                        <Button
                                                            color="primary"
                                                            className="btn-shadow"
                                                            size="lg"
                                                        >
                                                            <IntlMessages id="user.login-button" />
                                                        </Button>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </Card>
                            </Colxx>
                        </Row>
                    </div>
                </main>
            </Fragment>
        );
    }
}
const mapStateToProps = ({ authUser }) => {
    const { user, loading, error } = authUser;
    return { user, loading, error };
};

export default connect(
    mapStateToProps,
    {
        loginUser
    }
)(LoginLayout);
