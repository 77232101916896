import React, { useEffect, useState, useRef } from 'react'
import { Row, Card, CardBody, UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { Colxx } from '../../../components/CustomBootstrap';
import IntlMessages from '../../../util/IntlMessages';
import { DropdownItem } from 'reactstrap';
import DefaultLineChart from '../../../components/Charts/DefaultLineChart/index';
import ManagedTestChart from '../../../components/Charts/ManagedTestChart';
import { connect } from 'react-redux';
import { filterUsers } from '../../../redux/users/actions';
import AsyncSelect from 'react-select/lib/Async';
import CustomSelectInput from '../../../components/CustomSelectInput/index';
import { injectIntl, FormattedMessage } from 'react-intl';
import { FormGroup } from 'reactstrap';
import { Label } from 'reactstrap';
import { Input } from 'reactstrap';
import { Form } from 'reactstrap';
import Select from 'react-select'


const FilteredByUserCharts = ({ accessesCurrentKey, testsCurrentKey, filterUsers, users, handleOnDropdownClick, intl }) => {

    const [selectedUser, setSelectedUser] = useState(undefined)
    const [checkedRole, setCheckedRole] = useState('all')
    const [loading, setLoading] = useState(false)

    const timer = useRef();

    useEffect(() => {
        !selectedUser && users && setSelectedUser({})
    }, [users, selectedUser]);

    const mappedUser = (user, role = '') => {
        if(role === 'clinic')
            return user.clinicName
        else
            return `${user.firstName} ${user.lastName}`};

    const loadOptions = (inputValue) => {
        let filters;
        if (checkedRole === 'clinic') {
            filters = { clinicName: inputValue }
        } else {
            filters = { lastName: inputValue }
        }

        if (timer.current) clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            filterUsers({ ...filters, role: checkedRole });
        }, 500);
    }

    const onRoleChange = (role) => {
        setCheckedRole(role); 
        setSelectedUser({}) 
        setLoading(true);
        
        setTimeout(() => {
            setLoading(false);
        }, 5)
    }
    return (
        <Row>
            <Colxx sm="12">
                <h3><IntlMessages id="reports.filtered" /></h3>
            </Colxx>
            <Colxx sm="12" className={'mb-5'}>
                <Form inline className={'mt-2 mb-2 checkboxsFinder'}>
                    {['all', 'doctor', 'clinic', 'technician', 'admin'].map((role, i) =>
                        <FormGroup key={i}>
                            <Label className={'mr-4'}>
                                <Input type="radio" name={`${role}`} checked={checkedRole === role} onClick={() => { onRoleChange(role) }} />
                                <FormattedMessage id={`forms.${role}`} />
                            </Label>
                        </FormGroup>)}
                </Form>
                <div className={'selectorWrapper'}>
                {checkedRole !== 'all' && !loading && 
                    <Select components={{ Input: CustomSelectInput }}
                        className="react-select"
                        options={users.map(user => ({ label: mappedUser(user, checkedRole), value: user }))}
                        classNamePrefix="react-select"
                        onInputChange={loadOptions}
                        onChange={(user) => setSelectedUser(user?.value ?? {})}
                        placeholder={checkedRole === 'clinic' ? intl.messages['reports.findByClinicName'] : intl.messages['reports.findByLastName']}
                        isClearable
                    />}
                </div>
                
            </Colxx>
            <Colxx sm="12" md="6" className="mb-4">
                <Card className="dashboard-filled-line-chart">
                    <CardBody>
                        <div className="float-left float-none-xs">
                            <div className="d-inline-block">
                                <h5 className="d-inline">
                                    <IntlMessages id="reports.accessesPlatform" />
                                </h5>
                                <span className="text-muted text-small d-block">
                                    <IntlMessages id="stats.by-user" />
                                </span>
                            </div>
                        </div>

                        <div className="btn-group float-right float-none-xs mt-2">
                            <UncontrolledDropdown>
                                <DropdownToggle
                                    caret
                                    color="primary"
                                    className="btn-xs"
                                    outline
                                >
                                    <IntlMessages id={`reports.${accessesCurrentKey}`} />
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem onClick={(e) => handleOnDropdownClick(e, 'this-week', 'accesses')}>
                                        <IntlMessages id="reports.this-week" />
                                    </DropdownItem>
                                    <DropdownItem onClick={(e) => handleOnDropdownClick(e, 'last-week', 'accesses')}>
                                        <IntlMessages id="reports.last-week" />
                                    </DropdownItem>
                                    <DropdownItem onClick={(e) => handleOnDropdownClick(e, 'this-month', 'accesses')}>
                                        <IntlMessages id="reports.this-month" />
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    </CardBody>

                    <div className="chart card-body pt-0">
                        <DefaultLineChart period={accessesCurrentKey} type="accesses" user={selectedUser} />
                    </div>
                </Card>
            </Colxx>
            <Colxx sm="12" md="6" className="mb-4">
                <Card className="dashboard-filled-line-chart">
                    <CardBody>
                        <div className="float-left float-none-xs">
                            <div className="d-inline-block">
                                <h5 className="d-inline">
                                    <IntlMessages id="reports.managed-test" />
                                </h5>
                                <span className="text-muted text-small d-block">
                                    <IntlMessages id="stats.in-total" />
                                </span>
                            </div>
                        </div>

                        <div className="btn-group float-right float-none-xs mt-2">
                            <UncontrolledDropdown>
                                <DropdownToggle
                                    caret
                                    color="primary"
                                    className="btn-xs"
                                    outline
                                >
                                    <IntlMessages id={`reports.${testsCurrentKey}`} />
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem onClick={(e) => handleOnDropdownClick(e, 'this-week', 'tests')}>
                                        <IntlMessages id="reports.this-week" />
                                    </DropdownItem>
                                    <DropdownItem onClick={(e) => handleOnDropdownClick(e, 'last-week', 'tests')}>
                                        <IntlMessages id="reports.last-week" />
                                    </DropdownItem>
                                    <DropdownItem onClick={(e) => handleOnDropdownClick(e, 'this-month', 'tests')}>
                                        <IntlMessages id="reports.this-month" />
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    </CardBody>

                    <div className="chart card-body pt-0">
                        <DefaultLineChart period={testsCurrentKey} type="tests" user={selectedUser} />
                    </div>
                </Card>
            </Colxx>
            <Colxx sm={12}>
                <ManagedTestChart user={selectedUser} />
            </Colxx>
        </Row>)
}

const mapStateToProps = (state) => ({
    users: state.users.data
})

export default injectIntl(connect(mapStateToProps, { filterUsers })(FilteredByUserCharts));