import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import api from "../../api/legal";
import History from '../../history';

import {
    CREATE_LEGAL_TEXT,
    GET_LEGAL_TEXT,
    UPDATE_LEGAL_TEXT,
} from "../../constants/actionTypes";

import {
    getLegalTextsSuccess,
    updateLegalTextsSuccess,
    createLegalTextsSuccess
} from "./actions";

const parseResponseToRedux = (res) => {
    const obj = {};
    if(Array.isArray(res))
        res.forEach(row=>{
            obj[row.type] = row.text
        });
    else{
        obj[res.type] = res.text
    }
    return obj
};

const getLegalTextsAsync = async () => {
    return await api.getLegal()
        .then(diagnosticcenter => diagnosticcenter)
        .catch(error => error);
};

function* getLegalTexts() {
    try {
        const texts = yield call(getLegalTextsAsync);
        if(texts.status === 200) {
            yield put(getLegalTextsSuccess(parseResponseToRedux(texts.data)));
        } else {
            console.log('Get legal texts failed : ', texts);
        }
    } catch (error) {
        console.log('Get legal texts failed : ', error);
    }
}

const createLegalTextsAsync = async (formData) => {
    return await api.createLegal(formData)
        .then(text => text)
        .catch(error => error);
};

function* createLegalTexts({payload}) {
    try {
        const res = yield call(createLegalTextsAsync, payload);
        if(res.status === 201) {
            yield put(createLegalTextsSuccess(parseResponseToRedux(res.data)));
            History.push({pathname:`/app/${payload.type}`, state:{content:payload.type}});
        } else {
            console.log('Create legal text failed : ', res);
        }
    } catch (error) {
        console.log('Create legal text error: ', error);
    }
}

const updateLegalTextsAsync = async (formData) => {
    return await api.updateLegal(formData)
        .then(text => text)
        .catch(error => error);
};

function* updateLegalTexts({payload}) {
    try {
        const res = yield call(updateLegalTextsAsync, payload);
        if(res.status === 202) {
            yield put(updateLegalTextsSuccess(parseResponseToRedux(res.data)));
            History.push({pathname:`/app/${payload.type}`, state:{content:payload.type}});
        } else {
            console.log('Update legal text failed : ', res);
        }
    } catch (error) {
        console.log('Update legal text error: ', error);
    }
}


export function* watchGetLegalTexts() {
    yield takeEvery(GET_LEGAL_TEXT, getLegalTexts);
}
export function* watchCreateLegalTexts() {
    yield takeLatest(CREATE_LEGAL_TEXT, createLegalTexts);
}

export function* watchUpdateLegalTexts() {
    yield takeLatest(UPDATE_LEGAL_TEXT, updateLegalTexts);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetLegalTexts),
        fork(watchUpdateLegalTexts),
        fork(watchCreateLegalTexts),
    ])
};