import React from 'react';
import { Component } from "react";

class ContextMenuFn extends Component {

    onDeleteInContext = (data) => {
        console.log('onDeleteInContext', data)
    };

    onViewInContext = (data) => {
        console.log('onViewInContext', data)
    };

    onUpdateInContext = (data) => {
        console.log('onUpdateInContext', data)
    };

    onValidateInContext = (data) => {
        console.log('onValidateInContext', data)
    };

    onContextMenuClick = (e, data) => {
        switch (data.action) {
            case 'delete':
                this.onDeleteInContext(data);
                break;
            case 'view':
                this.onViewInContext(data);
                break;
            case 'update':
                this.onUpdateInContext(data);
                break;
            case 'validate':
                this.onValidateInContext(data);
                break;
            default:
                console.log('nothing to do in action =>', data.action)
        }
    };

    onContextMenu = (e, data) => {
        const clickedDiagnosticCenterId = data.data;
        if (this.state.selectedItems)
            if (!this.state.selectedItems.includes(clickedDiagnosticCenterId)) {
                this.setState({
                    selectedItems: [clickedDiagnosticCenterId]
                });
            }
        return true;
    };
}

export default ContextMenuFn