import history from "../history";

export const mapOrder = (array, order, key) => {
    array.sort(function (a, b) {
        var A = a[key], B = b[key];
        if (order.indexOf(A + "") > order.indexOf(B + "")) {
            return 1;
        } else {
            return -1;
        }
    });
    return array;
};

export const parseToDayHour = (date) => {
    if(date) {
        date = new Date(date);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    }
    else return 'Date not Found'
};

export const emptyString = (date) => {
    return "\u200C"
};

export const toCamelCase = (string) => {
    const newString = [];
    for (let i=0; i < string.trim().length; i++){
        if (i === 0)
            newString[i] = string[i].toLowerCase();
        else if(string[i-1] === ' ' && string[i])
            newString[i] = string[i].toUpperCase();
        else if(string[i] !== ' ')
            newString[i] = string[i]
    }
    return newString.join('');
};

export const getDateWithFormat = () => {
    const today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1; //January is 0!

    var yyyy = today.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    return dd + '.' + mm + '.' + yyyy;
};

export const getCurrentTime = () => {
    const now = new Date();
    return now.getHours() + ":" + now.getMinutes()
};

export const addCommas = (nStr) => {
    nStr += "";
    var x = nStr.split(".");
    var x1 = x[0];
    var x2 = x.length > 1 ? "." + x[1] : "";
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, "$1" + "," + "$2");
    }
    return x1 + x2;
};

export const goBack = (url) => {
    if (window.history.state.idx === 0) {
        history.push(url);
    } else {
        history.back();
    }
}