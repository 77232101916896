import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import api from "../../api/patients";
import history from "../../history";
import React from 'react';
import {
    GET_PATIENTS,
    CREATE_PATIENT,
    CREATE_PATIENT_KEYROCK,
    DELETE_PATIENT,
    UPDATE_PATIENT,
    FILTER_PATIENTS
} from "../../constants/actionTypes";

import {
    getPatientsSuccess,
    createPatientSucess,
    deletePatientSuccess,
    updatePatientSuccess,
    createPatientKeyrockSuccess,
} from "./actions";
import {toCamelCase, goBack} from "../../util/Utils";
import {NotificationManager} from "../../components/ReactNotifications";
import IntlMessage from '../../util/IntlMessages'
const getPatientsAsync = async (page, limit) => {
    return await api.getPatients(page, limit)
        .then(patients => patients)
        .catch(error => error);
};

function* getPatients({payload}) {
    try {
        const {page, limit} = payload;
        const patients = yield call(getPatientsAsync, page, limit);
        if(patients.status === 200) {
            yield put(getPatientsSuccess(patients.data, patients.headers.total));
        } else {
            console.log('Get patients failed : ', patients);
        }
    } catch (error) {
        console.log('Get patients failed : ', error);
    }
}

const filterPatientsAsync = async ({filters}) => {
    return await api.filterPatients(filters)
        .then(patients => patients)
        .catch(error => error);
};

function* filterPatients({payload}) {
    try {
        const patients = yield call(filterPatientsAsync, payload);
        if(patients.status === 200) {
            yield put(getPatientsSuccess(Array.isArray(patients.data) ? patients.data : [patients.data], patients.headers.total));
        } else {
            console.log('Filter patients failed : ', patients);
        }
    } catch (error) {
        console.log('Filter patients failed : ', error);
    }
}

const createKeyrockAsync = async (id) => {
    return await api.createOrUpdatePatientKeyrock(id)
        .then(patients => {
            return patients
        })
        .catch(error => error);
};

function* createKeyrock({payload}) {
    try {
        const patients = yield call(createKeyrockAsync, payload);
        if(patients.status === 200 || patients.status === 204) {
            yield put(createPatientKeyrockSuccess(patients.data));
        }
    } catch (error) {
        console.log('Error creating/updating patient on keyrock: ', error);
    }
}


const createPatientAsync = async (formData) => {
    return await api.createPatient(formData)
        .then(patient => patient)
        .catch(error => error);
};

function* createPatient({payload}) {
    try {
        const patient = yield call(createPatientAsync, payload);
        if(patient.status === 201) {
            yield put(createPatientSucess(patient.data));
            history.push('/');
            history.push(`/app/pacientes/historial/${patient.data._id}/crear`);
        } else {
            NotificationManager.create({
                type: 'error',
                title: <IntlMessage id={`notify.error.${toCamelCase(patient.request.response)}`}/>,
            });        }
    } catch (error) {
        console.log('Create patient error: ', error);
    }
}

const updatePatientAsync = async (formData) => {
    return await api.updatePatient(formData)
        .then(patient => patient)
        .catch(error => error);
};

function* updatePatient({payload}) {
    try {
        const patient = yield call(updatePatientAsync, payload);
        if(patient.status === 202) {
            yield put(updatePatientSuccess(patient.data));
            goBack('/app/pacientes/lista');
        } else {
            NotificationManager.create({
                type: 'error',
                title: <IntlMessage id={`notify.error.${toCamelCase(patient.request.response)}`}/>,
            });        
        }
    } catch (error) {
        console.log('Update patient error: ', error);
    }
}

const deletePatientAsync = async (id) => {
    return await api.deletePatient(id)
        .then(patient => patient)
        .catch(error => error);
};

function* deletePatient({payload}) {
    try {
        const patient = yield call(deletePatientAsync, payload);
        if(patient.status === 204) {
            yield put(deletePatientSuccess(payload));
        } else {
            console.log('Delete patients failed : ', patient);
        }
    } catch (error) {
        console.log('Delete patient error: ', error);
    }
}

export function* watchGetPatients() {
    yield takeEvery(GET_PATIENTS, getPatients);
}

export function* watchCreatePatient() {
    yield takeEvery(CREATE_PATIENT, createPatient);
}

export function* watchCreateKeyrock() {
    yield takeEvery(CREATE_PATIENT_KEYROCK, createKeyrock);
}

export function* watchDeletePatient() {
    yield takeEvery(DELETE_PATIENT, deletePatient);
}

export function* watchUpdatePatient() {
    yield takeEvery(UPDATE_PATIENT, updatePatient);
}

export function* watchFilterPatient() {
    yield takeEvery(FILTER_PATIENTS, filterPatients);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetPatients),
        fork(watchFilterPatient),
        fork(watchCreatePatient),
        fork(watchDeletePatient),
        fork(watchUpdatePatient),
        fork(watchCreateKeyrock)
    ])
};