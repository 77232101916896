import React, { Component, Fragment } from "react";
import IntlMessages from "../../util/IntlMessages";
import {
  Row} from "reactstrap";
import { Colxx, Separator } from "../../components/CustomBootstrap";
import BreadcrumbContainer from "../../components/BreadcrumbContainer";
import "chartjs-plugin-datalabels";
import "react-circular-progressbar/dist/styles.css";



import { getPatientsTotal, getTestsTotal } from "../../redux/actions";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import GeneralPlatformUseCharts from "./components/GeneralPlatformUseCharts";
import FilteredByUserCharts from './components/FilteredByUserCharts';


class Stats extends Component {
  constructor(props){
    super(props);
    this.state = {
      accessesCurrentKey: 'last-week',
      testsCurrentKey: 'last-week',
    }
  }

  componentDidMount() {

  }

  handleOnDropdownClick = (e, period, chart) => {
    this.setState({
      [`${chart}CurrentKey`]: period,
    })
  }
  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <BreadcrumbContainer
              heading={<IntlMessages id="menu.stats" />}
              match={this.props.match}
            />
            <Separator className="mb-5" />
          </Colxx>
        </Row>

        <FilteredByUserCharts
          accessesCurrentKey={this.state.accessesCurrentKey} 
          testsCurrentKey={this.state.testsCurrentKey}
          handleOnDropdownClick={this.handleOnDropdownClick} />

          <hr className={'pb-2 pt-4'} />
        
        <GeneralPlatformUseCharts />

      </Fragment>
    );
  }
}

const mapStateToProps = ({ stats }) => {
  const { loading, totalPatients, totalTests } = stats;
  return { loading, totalPatients, totalTests }
};

export default injectIntl(connect(mapStateToProps, { getPatientsTotal, getTestsTotal })(Stats))