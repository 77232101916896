import React, { useMemo, useEffect, useRef, forwardRef } from 'react';
import { useReactToPrint } from 'react-to-print';

import HTMLtemplate from '../../../components/PDF/HTML/HTMLtemplate';
import '../../../components/PDF/HTML/HTML.css';
import { DECRYPT_KEY } from '../../../constants/defaultValues';

const CryptoJS = require("crypto-js");
class ComponentToPrint extends React.PureComponent {
  render() {
    return (
      <HTMLtemplate style={{display: "none"}} password={this.props.password}/>
    );
  }
}

const PrintPatient = forwardRef((props) => {
  const password = useMemo(() => CryptoJS.AES.decrypt(decodeURIComponent(props.match.params.password), DECRYPT_KEY).toString(CryptoJS.enc.Utf8), [props.match.params.password])
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    handlePrint();
    setTimeout(() => { 
      props.history.go(-1); }, 1000);
  }, [])

  return(
      <div>
          <ComponentToPrint password={password} ref={componentRef}/>
      </div>
  )
});

export default PrintPatient;
