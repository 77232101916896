import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from "redux-saga";
import reducers from './reducers';
import storage from 'redux-persist/lib/storage';
import {persistReducer, persistStore} from 'redux-persist'
import sagas from "./sagas";

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
    key: 'root',
    storage
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(
    persistedReducer,
    {},
    applyMiddleware(sagaMiddleware)
);

sagaMiddleware.run(sagas);

if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
        const nextRootReducer = require('./reducers');
        store.replaceReducer(nextRootReducer);
    });
}

export default store;
export const persistor = persistStore(store);
