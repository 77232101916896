import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Input,  } from 'reactstrap';
import { NotificationManager } from '../../../../components/ReactNotifications';
import IntlMessage from '../../../../util/IntlMessages';
import { shareRegistryDoctorEmail } from '../../../../api/registry';

const ShareDoctorModalBody = (props) => {
  const [emailBody, setEmailBody] = useState({
    patientId: props.registry.patientId,
    registryId: props.registry._id,
    sharedMail: '',
    observations: '',
  })

  const [onOkOption] = useState(props.onOkOption);

  useEffect(() => {
    if (props.onOkOption !== onOkOption) props.onToggle();
  }, [props.onOkOption, props, onOkOption]);

  return (
    <Form onSubmit={(e) => e.preventDefault()}>
      <FormGroup>
        <Label><IntlMessage id={'history.summary.share.sharedDoctor'}/></Label>
        <Input 
          type="email" 
          name="email" 
          onChange={(e) => setEmailBody({
            ...emailBody,
            sharedMail: e.target.value,
          })} 
          value={emailBody && emailBody.sharedMail}
        />
      </FormGroup>
      <FormGroup>
        <Label><IntlMessage id={'history.summary.share.observations'}/></Label>
        <Input 
          type="textarea" 
          name="text"
          onChange={(e) => setEmailBody({
            ...emailBody,
            observations: e.target.value, 
          })} 
          value={emailBody && emailBody.observations}
        />
      </FormGroup>
    
      <button
      onClick={() => {
        if (emailBody.observations && emailBody.sharedMail) {
          shareRegistryDoctorEmail(emailBody);
          setEmailBody({
            ...emailBody,
            sharedMail: '',
            observations: '',
          });
          NotificationManager.create({
            type: 'success',
            title: <IntlMessage id={'notify.doctor.share'}/>,
          }); 
        } else {
          NotificationManager.create({
            type: 'error',
            title: <IntlMessage id={'notify.doctor.share.fill'}/>,
          }); 
        }
      }} className="btn accept btn-primary ml-1 ">Enviar</button>
    </Form>
  );
}

export default ShareDoctorModalBody;