import React from 'react'

const index = ({ avatar }) => {
  return (
    <div>
      <img
        alt=''
        src={avatar}
        className="list-thumbnail responsive border-0 mt-4"
        style={{ width: '50%', height: '50%', backgroundColor: 'transparent' }}
      />
    </div>
  )
}

export default index
