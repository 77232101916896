import React from 'react';
import WithModal from "../Modal";
import IntlMessages from "../../util/IntlMessages";
import Loading from "../Dropzone/components/Loading";
import {DropdownItem} from "reactstrap";

class Body extends React.Component{
    state={
        loading: false
    };

    componentDidUpdate = (prevProps) => {
        if(this.props.onOkOption && prevProps.onOkOption !== this.props.onOkOption ) {
            this.props.onClick();
            this.setState({
                loading: true
            })
        }
    };

    render() {
        const {loading}  = this.state;
        return (
            loading ?
                <Loading className={"dropzone-loading-component loading"}/> :
                <IntlMessages
                    id="general.remove.registry.confirm"
                />
        )
    }
}

export const BodyModal = WithModal(Body);

class RemoveButton extends React.Component{

    render() {
        const {toggle} = this.props;
        return (
            <>
                <DropdownItem onClick={toggle}>
                    <IntlMessages id="registry.button-remove" />
                </DropdownItem>
            </>
        )
    }
}

export default RemoveButton;