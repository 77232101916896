import React, { Component, Fragment } from "react";
import IntlMessages from "../../../util/IntlMessages";
import DatePicker from "react-datepicker";
import AvatarPreview from '../../../components/AvatarPreview';
import { dateFormatOptions } from '../../../constants/defaultValues';
import { Colxx, Separator } from "../../../components/CustomBootstrap";
import BreadcrumbContainer from "../../../components/BreadcrumbContainer";
import CustomSelectInput from "../../../components/CustomSelectInput";
import { Formik, Form } from 'formik';
import { MAX_YEAR_RANGE, MIN_YEAR_RANGE } from "../../../constants/defaultValues";
import {
    Row,
    Card,
    CardTitle,
    FormGroup,
    Label,
    Input,
    CardBody,
    Button,
    InputGroup,
    InputGroupAddon
} from "reactstrap";
import Select, {Async as AsyncSelect} from "react-select";
import { injectIntl} from 'react-intl';
import "react-datepicker/dist/react-datepicker.css";
import { countries } from "../../../data/countries";
import {createPatient, getDiagnosticCenters, filterDiagnosticCenters, filterUsers} from "../../../redux/actions";
import { connect } from "react-redux";
import Observation from "../../../models/Observation";
import {findDOMNode} from "react-dom";

class CreatePatient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bornDate: null,
            country: null,
            firstName: "",
            lastName: "",
            DNI: "",
            telephone: "",
            diagnosticCenters: [],
            authDoctors:[],
            email: "",
            avatar: "",
            observations: []
        };
        this.valueInFilter = '';
        this.valueInFilterAuthDoctors = '';
        this.file=null;
        this.DOMfile=null;

        this.errorAvatarSize = false;
    }

    validate = (values) => {
        values = this.state;
        let errors = {};

        if (!values.bornDate) {
            errors.bornDate = 'Please select a born date';
        }

        if (!values.country) {
            errors.country = 'Please enter a country';
        }

        if (!values.firstName) {
            errors.firstName = 'Please enter first name';
        }

        if (!values.lastName) {
            errors.lastName = 'Please enter last name';
        }

        // if (!values.DNI) {
        //     errors.DNI = 'Please enter DNI';
        // }
        //
        // if (!values.telephone) {
        //     errors.telephone = 'Please enter telephone';
        // }

        if (values.diagnosticCenters.length === 0) {
            errors.diagnosticCenters = 'Please select diagnosticCenter';
        }

        if (values.authDoctors.length === 0) {
            errors.authDoctors = 'Please select doctor or clinic';
        }

        // if (!values.email) {
        //     errors.email = 'Please enter email';
        // }

        // if (!values.avatar) {
        //     errors.avatar = 'Please add an avatar (Max size: 100KB)';
        // }

        if(this.errorAvatarSize) {
            errors.avatar = 'Please add an avatar (Max size: 100KB)';
        }

        return errors;
    };

    componentDidMount(){
        if(this.file) {
            this.DOMfile = findDOMNode(this.file);
        }
        this.props.getDiagnosticCenters();
        this.props.filterUsers({role:'clinic,doctor'});

    }

    handleChangeFileInput = (e) => {
        const name = e.target.name;
        if (this.DOMfile && this.DOMfile.files && this.DOMfile.files[0]) {
          if (this.DOMfile.files[0].size <= 100000) {
            let reader = new FileReader();
            reader.readAsDataURL(this.DOMfile.files[0]);
            reader.onloadend = () => {
              this.setState({
                [name]: reader.result
              }, () => {
                this.errorAvatarSize = false;
              });
            };
          } else {
            this.errorAvatarSize = true;
          }
        }
      };

    handleChange = (e) =>{
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        })
    };

    handleCountryChange = (country)=>{
        this.setState({
            country: country.value
        });
    };

    handleObservationChange = (index, value) => {
        const aux = this.state.observations;
        aux[index] = value;

        this.setState({
            observations: [...aux]
        })
    };

    handleChangeMulti = (name,selectedOptions) => {
        this.setState({[name]: selectedOptions});
    };

    parseToOptions = () =>{
        const {centerOptions} = this.props;
        return centerOptions.map(center => ({
            label: center.name,
            value: center._id,
            key: center._id
        }))
    };

    loadOptions = (inputValue, callback) => {
        this.valueInFilter = inputValue;
        setTimeout(()=>{
            if(this.valueInFilter === inputValue){
                if (inputValue.length > 2)
                    this.props.filterDiagnosticCenters({name: inputValue});
                else
                    this.props.getDiagnosticCenters();

                setTimeout(() => {
                    callback(this.parseToOptions());
                }, 700);
            }
        },500);
    };

    parseToOptionsDoctors = (usersOptions) =>{
        return usersOptions.map(user => ({
            label: user.clinicName ? user.clinicName : user.firstName + ' ' + user.lastName,
            value: user._id,
            key: user._id
        }))
    };

    loadOptionsDoctors = (inputValue, callback) => {
        this.valueInFilterAuthDoctors = inputValue;
        setTimeout(()=> {
            if(this.valueInFilterAuthDoctors === inputValue){
                if (inputValue.length > 2){
                   new Promise((resolve => {
                        this.props.filterUsers({firstName: inputValue, role: 'doctor'}, (users)=>resolve(users));
                    })).then(doctors=>{
                        this.props.filterUsers({clinicName: inputValue, role: 'clinic'}, (clinics)=>callback(this.parseToOptionsDoctors([...doctors, ...clinics])));
                    });
                }
                else
                    this.props.filterUsers({role: 'clinic,doctor'}, (users)=>{
                        callback(this.parseToOptionsDoctors(users))
                    });

            }
        },500);
    };

    addObservation = (e) =>{
        e.preventDefault();
        e.stopPropagation();

        const aux = this.state.observations;
        aux.push('');

        this.setState({
            observations: [...aux]
        })
    };

    handleChangeDate = (date) => {
        const final_date = this.checkProperDate(date);
        this.setState({
            bornDate: final_date
        });
    };

    checkProperDate = (date) => {
        if (date) {
            const currentDate = new Date();
            if (date.getYear() > currentDate.getYear()) {
                if (date.getYear() > MAX_YEAR_RANGE) {
                    return currentDate;
                } else {
                    date.setYear(date.getYear() - MIN_YEAR_RANGE);
                }
            }
        }
        return date;
    }

    deleteObservation = (e, index) => {
        e.preventDefault();
        e.stopPropagation();

        const aux = this.state.observations;
        aux.splice(index,1);

        this.setState({
            observations: [...aux]
        })
    };

    handleSubmit = (e) =>{
        if(e.preventDefault)
            e.preventDefault();
        if(e.stopPropagation)
            e.stopPropagation();

        const patient = {...this.state};
        patient.observations = this.parseToObservations();
        patient.authDoctors = this.state.authDoctors.map(user => ({id: user.key, enabled: true}));
        patient.diagnosticCenters = this.state.diagnosticCenters.map(center => center.key);
        patient.avatar = this.state.avatar;
        this.props.createPatient(patient)
    };

    parseToObservations(){
        return this.state.observations.map(obs => new Observation({who: this.props.me , what:obs, when: new Date()}).getData())
    }

    render() {
        const {messages} = this.props.intl;

        return(
            <Fragment>
                <Row>
                    <Colxx xxs="12">
                        <BreadcrumbContainer
                            heading={<IntlMessages id="patients.createPatient" />}
                            match={this.props.match}
                        />
                        <Separator className="mb-5" />
                    </Colxx>
                </Row>

                <Row className="mb-4">
                    <Colxx xxs="12">
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <IntlMessages id="forms.patient" />
                                </CardTitle>
                                <Formik
                                    validate={this.validate}
                                    initialValues={this.state}
                                    onSubmit={this.handleSubmit}>
                                    {({ errors, touched, isValidating }) => (
                                        <Form className="av-tooltip tooltip-label-right">
                                            <FormGroup row>
                                                <Colxx sm={6}>
                                                    <FormGroup>
                                                        <Label for="firstName">
                                                            <IntlMessages id="forms.firstName" />
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            name="firstName"
                                                            id="firstName"
                                                            placeholder={messages["forms.firstName"]}
                                                            value={this.state.firstName}
                                                            onChange={this.handleChange}
                                                        />
                                                        {errors.firstName && touched.firstName && <div className="invalid-feedback d-block">{errors.firstName}</div>}
                                                    </FormGroup>
                                                </Colxx>

                                                <Colxx sm={6}>
                                                    <FormGroup>
                                                        <Label for="lastName">
                                                            <IntlMessages id="forms.lastName" />
                                                        </Label>
                                                        <Input
                                                            invalid={!!errors.lastName}
                                                            type="text"
                                                            name="lastName"
                                                            id="lastName"
                                                            placeholder={messages["forms.lastName"]}
                                                            value={this.state.lastName}
                                                            onChange={this.handleChange}
                                                        />
                                                        {errors.lastName && touched.lastName && <div className="invalid-feedback d-block">{errors.lastName}</div>}
                                                    </FormGroup>
                                                </Colxx>

                                                <Colxx sm={4}>
                                                    <FormGroup>
                                                        <Label for="DNI">
                                                            <IntlMessages id="forms.dni" />
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            name="DNI"
                                                            id="DNI"
                                                            placeholder={messages["forms.dni"]}
                                                            value={this.state.DNI}
                                                            onChange={this.handleChange}
                                                        />
                                                        {errors.DNI && touched.DNI && <div className="invalid-feedback d-block">{errors.DNI}</div>}
                                                    </FormGroup>
                                                </Colxx>
                                                <Colxx sm={4}>
                                                    <FormGroup>
                                                        <Label for="country">
                                                            <IntlMessages id="forms.country" />
                                                        </Label>
                                                        <Select
                                                            components={{ Input: CustomSelectInput }}
                                                            className="react-select"
                                                            classNamePrefix="react-select"
                                                            name="country"
                                                            id="country"
                                                            placeholder={messages["forms.country"]}
                                                            value={this.state.country ? countries.find(c => c.value === this.state.country) : null}
                                                            onChange={this.handleCountryChange}
                                                            options={countries.map((c,i) => {return({label:c.label, value: c.value, key: i})})}
                                                        />
                                                        {errors.country && touched.country && <div className="invalid-feedback d-block">{errors.country}</div>}
                                                    </FormGroup>
                                                </Colxx>
                                                <Colxx sm={4}>
                                                    <FormGroup>
                                                        <Label for="bornDate">
                                                            <IntlMessages id="forms.bornDate" />
                                                        </Label>
                                                        <DatePicker
                                                            id="bornDate"
                                                            name="bornDate"
                                                            selected={this.state.bornDate}
                                                            onChange={this.handleChangeDate}
                                                            dateFormat={dateFormatOptions.spanish}
                                                            placeholderText={messages["forms.bornDate"]}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                        />
                                                        {errors.bornDate && touched.bornDate && <div className="invalid-feedback d-block">{errors.bornDate}</div>}
                                                    </FormGroup>
                                                </Colxx>

                                                <Colxx sm={6}>
                                                    <FormGroup>
                                                        <Label for="telephone">
                                                            <IntlMessages id="forms.telephone" />
                                                        </Label>
                                                        <Input
                                                            type="telephone"
                                                            name="telephone"
                                                            id="telephone"
                                                            placeholder={messages["forms.telephone"]}
                                                            value={this.state.telephone}
                                                            onChange={this.handleChange}
                                                        />
                                                        {errors.telephone && touched.telephone && <div className="invalid-feedback d-block">{errors.telephone}</div>}
                                                    </FormGroup>
                                                </Colxx>
                                                <Colxx sm={6}>
                                                    <FormGroup>
                                                        <Label for="email">
                                                            <IntlMessages id="forms.email" />
                                                        </Label>
                                                        <Input
                                                            type="email"
                                                            name="email"
                                                            id="email"
                                                            placeholder={messages["forms.email"]}
                                                            value={this.state.email}
                                                            onChange={this.handleChange}
                                                        />
                                                        {errors.email && touched.email && <div className="invalid-feedback d-block">{errors.email}</div>}
                                                    </FormGroup>
                                                </Colxx>

                                                <Colxx sm={6}>
                                                    <FormGroup>
                                                        <Label for="diagnosticCenters">
                                                            <IntlMessages id="forms.diagnosticCenters" />
                                                        </Label>
                                                        <AsyncSelect
                                                            key={'field.diagnosticCenters'}
                                                            components={{ Input: CustomSelectInput }}
                                                            className="react-select"
                                                            classNamePrefix="react-select"
                                                            isMulti
                                                            name="diagnosticCenters"
                                                            id="diagnosticCenters"
                                                            placeholder={messages["forms.diagnosticCenters"]}
                                                            value={this.state.diagnosticCenters}
                                                            onChange={(opt)=>this.handleChangeMulti('diagnosticCenters', opt)}

                                                            defaultOptions
                                                            cacheOptions
                                                            loadOptions={this.loadOptions}
                                                        />
                                                        {errors.diagnosticCenters && touched.diagnosticCenters && <div className="invalid-feedback d-block">{errors.diagnosticCenters}</div>}
                                                    </FormGroup>
                                                </Colxx>

                                                <Colxx sm={6}>
                                                    <FormGroup>
                                                        <Label for="authDoctors">
                                                            <IntlMessages id="patients.authDoctors" />
                                                        </Label>
                                                        <AsyncSelect
                                                            key={'field.authDoctors'}
                                                            components={{ Input: CustomSelectInput }}
                                                            className="react-select"
                                                            classNamePrefix="react-select"
                                                            isMulti
                                                            name="authDoctors"
                                                            id="authDoctors"
                                                            placeholder={messages["forms.authDoctors"]}
                                                            value={this.state.authDoctors}
                                                            onChange={(opt)=>this.handleChangeMulti('authDoctors', opt)}
                                                            defaultOptions
                                                            cacheOptions
                                                            loadOptions={this.loadOptionsDoctors}
                                                        />
                                                        {errors.authDoctors && touched.authDoctors && <div className="invalid-feedback d-block">{errors.authDoctors}</div>}
                                                    </FormGroup>
                                                </Colxx>

                                                <Colxx sm={6}>
                                                    <FormGroup>
                                                        <Label for="avatar">
                                                            <IntlMessages id="forms.avatar" />
                                                        </Label>
                                                        <Input
                                                            ref={node => this.file = node}
                                                            type="file"
                                                            name="avatar"
                                                            id="avatar"
                                                            accept=".png, .jpg, .svg"
                                                            placeholder={messages["forms.avatar"]}
                                                            label={this.state.avatar ? this.state.avatar : 'Choose File'}
                                                            onChange={(e) => this.handleChangeFileInput(e)}
                                                        /> 
                                                        {errors.avatar && touched.avatar && <div className="invalid-feedback d-block">{errors.avatar}</div>}
                                                        {this.state.avatar && <AvatarPreview avatar={this.state.avatar} /> }
                                                    </FormGroup>
                                                </Colxx>
                                                <Colxx sm={12} key={`observations`} className={'mt-5'}>
                                                    <FormGroup>
                                                        <div className="d-flex justify-content-between align-items-end mb-2">
                                                            <Label for="observations">
                                                                <IntlMessages id="forms.observations" />
                                                            </Label>
                                                            <Button color={"default"} onClick={this.addObservation}>
                                                                <IntlMessages id="forms.add.observation" />
                                                            </Button>
                                                        </div>

                                                        {this.state.observations.map((obs, index)=>(

                                                            <InputGroup className={'mb-2'}>
                                                                <Input
                                                                    type="input"
                                                                    name={`observation ${index}`}
                                                                    id={`observation ${index}`}
                                                                    placeholder={messages["forms.observations"]}
                                                                    value={obs}
                                                                    onChange={(e)=>this.handleObservationChange(index,e.target.value)}
                                                                />
                                                                <InputGroupAddon addonType="append">
                                                                    <Button onClick={(e)=>this.deleteObservation(e,index)} color={'danger'}>X</Button>
                                                                </InputGroupAddon>
                                                            </InputGroup>

                                                        ))}
                                                    </FormGroup>
                                                </Colxx>
                                            </FormGroup>
                                            <Button color="primary" className="mt-4 float-right">
                                                <IntlMessages id="forms.create" />
                                            </Button>
                                        </Form> )}
                                </Formik>
                            </CardBody>
                        </Card>
                    </Colxx>
                </Row>
            </Fragment>
        )
    }
}

const mapStateToProps = ({diagnosticcenters, users, authUser}) => ({
    centerOptions: diagnosticcenters.data,
    usersOptions: users.data,
    me: authUser.user

});

export default injectIntl(connect(mapStateToProps, {createPatient, getDiagnosticCenters, filterDiagnosticCenters, filterUsers})(CreatePatient));