import React, { Component, Fragment } from "react";
import IntlMessages from "../../../util/IntlMessages";
import { Colxx } from "../../../components/CustomBootstrap";
import PageHeader from '../../../components/PageHeader';
import {
    Row,
    Card,
    CardBody,
    Button,
    ButtonDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
} from "reactstrap";
import {injectIntl} from "react-intl";
import { connect } from "react-redux";
import {NavLink} from "react-router-dom";
import mouseTrap from "react-mousetrap";
import {getCenter} from "../../../redux/diagnosticcenters/actions";
import {isCorrectRole} from "../../../components/PrivateRoute";
import RemoveRegistry from "../../../components/RemoveRegistry";

//export default class CenterDetail extends Component {
class CenterDetail extends Component {
    constructor(props) {
        super(props);
        this.toggleSplit = this.toggleSplit.bind(this);
        this.state = {
            dropdownSplitOpen: false,
            originalCenter: null,
            name: "",
            street: "",
            number: "",
            location: "",
            zipCode: "",
            city: "",
            province: "",
            country: "",
            loading: true
        };
    }
    componentDidMount(){
        const center = this.props.diagnosticCenters ? this.props.diagnosticCenters.find(center => center._id === this.props.match.params.id) : undefined;
        if(!center)
            this.props.getCenter(this.props.match.params.id);
        else
            this.setState({
                ...center
            })
    }

    componentDidUpdate(prevProps, prevState, prevContext){
        if(this.props.center !== this.state.originalCenter && prevProps.center !== this.props.center)
            this.setState({
                ...this.props.center,
                originalCenter: this.props.center
            })
    }

    toggleSplit() {
        this.setState(prevState => ({
            dropdownSplitOpen: !prevState.dropdownSplitOpen
        }));
    }

    renderActions(){
        return (
            isCorrectRole(['admin', 'technician']) ? <ButtonDropdown
                className="w-xs-100"
                isOpen={this.state.dropdownSplitOpen}
                toggle={this.toggleSplit}
            >
                <DropdownToggle
                    caret
                    color="primary"
                    className="dropdown-toggle-split pl-4 pr-4 text-uppercase w-xs-100"
                >
                    {<span className='mr-2'><IntlMessages id="general.actions" /></span>}
                </DropdownToggle>

                <DropdownMenu right>
                    <NavLink to={`/app/diagnosticcenters/lista`}>
                        <DropdownItem>
                            <IntlMessages id="general.back" />
                        </DropdownItem>
                    </NavLink>
                    <NavLink to={`/app/diagnosticcenters/editar/${this.props.match.params.id}`}>
                        <DropdownItem>
                            <IntlMessages id="general.edit" />
                        </DropdownItem>
                    </NavLink>
                </DropdownMenu>
            </ButtonDropdown> : null
        );
    }

    render() {
        return (
            <Fragment>

                <PageHeader
                    heading={<IntlMessages id="menu.diagnosticcenters" />}
                    match={this.props.match}
                    actions={this.renderActions()}
                />

                <Row>
                    {!this.props.loading ?
                        <Colxx xxs="12" className="mb-3">
                            <Card className="mb-4">
                                <CardBody>
                                    <h1 className="mb-4">{this.state.name}</h1>

                                    <p className="text-muted text-small mb-2"><IntlMessages id="forms.street" /></p>
                                    <p className="mb-3">{this.state.street}</p>

                                    <p className="text-muted text-small mb-2"><IntlMessages id="forms.number" /></p>
                                    <p className="mb-3">{this.state.number}</p>

                                    <p className="text-muted text-small mb-2"><IntlMessages id="forms.location" /></p>
                                    <p className="mb-3">{this.state.location}</p>

                                    <p className="text-muted text-small mb-2"><IntlMessages id="forms.zipCode" /></p>
                                    <p className="mb-3">{this.state.zipCode}</p>

                                    <p className="text-muted text-small mb-2"><IntlMessages id="forms.city" /></p>
                                    <p className="mb-3">{this.state.city}</p>

                                    <p className="text-muted text-small mb-2"><IntlMessages id="forms.province" /></p>
                                    <p className="mb-3">{this.state.province}</p>

                                    <p className="text-muted text-small mb-2"><IntlMessages id="forms.country" /></p>
                                    <p className="mb-3">{this.state.country}</p>


                                </CardBody>
                            </Card>
                        </Colxx>
                        : <div className="loading"/>
                    }
                </Row>
            </Fragment>
        );
    }
}

const mapStateToProps = ({diagnosticcenter, diagnosticcenters}) => ({
    center : diagnosticcenter.data,
    loading : diagnosticcenter.loading,
    diagnosticCenters: diagnosticcenters.data
});

export default injectIntl(mouseTrap(connect(mapStateToProps, {getCenter})(CenterDetail)))