import {
  GET_PATIENTS_TOTAL,
  GET_PATIENTS_TOTAL_SUCCESS,
  GET_TESTS_TOTAL,
  GET_TESTS_TOTAL_SUCCESS
} from "../../constants/actionTypes";

export const getPatientsTotal = () => ({
  type: GET_PATIENTS_TOTAL,
  payload: {}
});

export const getPatientsTotalSuccess = (total) => ({
  type: GET_PATIENTS_TOTAL_SUCCESS,
  payload: {total}
});

export const getTestsTotal = (doctorid = undefined) => ({
  type: GET_TESTS_TOTAL,
  payload: {doctorid}
});

export const getTestsTotalSuccess = (total) => ({
  type: GET_TESTS_TOTAL_SUCCESS,
  payload: {total}
});
