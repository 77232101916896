export const roles = [
    {
        url: 'doctores',
        key: 'doctor',
        filters: ['lastName', 'firstName', 'email'],
        hash: '72f4be89d6ebab1496e21e38bcd7c8ca0a68928af3081ad7dff87e772eb350c2',

    }, {
        url: 'clinicas',
        key: 'clinic',
        filters: ['clinicName', 'email'],
        hash: 'bbfeb74a4b4216dfd0f7b10edf5743e6781e9f8ac7888a3392878a52c821a3b1',
    },
    {
        url: 'operadores',
        key: 'technician',
        filters: ['lastName', 'firstName', 'email'],
        hash: '29ba9d9cef5a66461116a24938bb9307e005c35aa1bb909f16aa5e85bd767480',
    },
    {
        url: 'administradores',
        key: 'admin',
        filters: ['lastName', 'firstName', 'email'],
        hash: '8c6976e5b5410415bde908bd4dee15dfb167a9c873fc4bb8a81f6f2ab448a918',
    },
    {
        url: 'pacientes',
        key: 'patient',
        filters: ['lastName', 'firstName', 'email'],
        hash: '2295ff7a8bd8b3f2884c6482146e3ded0417f72072c079fbe223e13e83a0388e',
    }
];
