import React from 'react';
import Loading from '../../components/Dropzone/assets/loading.gif'
import '../Thumbnails/style.scss'
import imageNoFound from '../../assets/img/imageNoFound.png';

class InsistentImg extends React.Component{

    constructor(props){
        super(props);
        this.times = 0;
        this.tried = false;
        this.img = null;
    }

    componentDidMount(){
        this.requestFile();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.src !== prevProps.src) {
            this.times= 0;
            this.requestFile()
        }
    }

    requestFile = () => {
        this.img.src = Loading;
        if(!this.tried && this.times < 4 && this.props.src && this.props.src !== '-') {
            this.tried = !this.tried;
            setTimeout(()=>{
                this.times++;
                if(this.img) {
                    this.img.src = this.props.src;
                    this.tried = !this.tried
                }
            },200)
        }
        else this.img.src= imageNoFound

    };

    render() {
        const { noWrap, alt, onClick, betweenName, src, _id } = this.props;
        if(!noWrap)
            return(
                <div className={'thumbnail-wrapper'}>
                    <img
                        key={`Insistent-Img-${_id}`}
                        className={onClick ? 'dident-cursor-pointer insistent-img-hover': '' }
                        onClick={ onClick }
                        onError={ this.requestFile }
                        ref={ node => this.img = node }
                        src={ src !== '-' ? src : imageNoFound }
                        alt={ alt }
                    />
                    {betweenName && betweenName}
                    <p className={'alt'}>{alt}</p>
                </div>
            );

        return(
            <>
                <img
                    key={`Insistent-Img-${_id}`}
                    className={onClick ? 'dident-cursor-pointer insistent-img-hover': '' }
                    onClick={ onClick }
                    onError={ this.requestFile }
                    ref={ node => this.img = node }
                    src={ src !== '-' ? src : imageNoFound }
                    alt={ alt }
                />
                {betweenName && betweenName}
                <p className={'alt'}>{alt}</p>
            </>
        )
    }
}

export default InsistentImg