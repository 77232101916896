import React from 'react';
import { connect } from 'react-redux';
import { getLoggedUser } from '../../../redux/actions';
import { roles } from '../../../data/roles';
import {compose} from 'redux';

const withProfile = (WrappedComponent) => {
    return class Profile extends React.Component {

        constructor(props) {
            super(props);
        }

        componentDidMount() {
            this.props.getLoggedUser();
        }

        render() {
            const { logUser } = this.props;
            const role = logUser && roles.find(role => role.key === logUser.role);
            return <div>{logUser && <WrappedComponent {...this.props} role={role} match={{ ...this.props.match, params: { role: role.url, id: logUser._id } }} />}</div>
        }
    }
};

const mapStateToProps = ({ loggedUser }) => {
    const logUser = loggedUser.user;
    return { logUser };
};

export default compose(connect(mapStateToProps, { getLoggedUser }), withProfile);