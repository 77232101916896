import React from 'react';
import {Separator} from '../CustomBootstrap'
import IntlMessages, {injectIntl} from "../../util/IntlMessages";

const CardShare = ({id, img, name, enabled=false, date, onDelete, onChangeVisibility, last}) => {

    const handleDelete =(e)=>{
        e.preventDefault && e.preventDefault();
        e.stopPropagation && e.stopPropagation();
        onDelete(id);
    };

    const handleChangeVisibility =(e)=>{
        e.preventDefault && e.preventDefault();
        e.stopPropagation && e.stopPropagation();
        onChangeVisibility(id);
    };

    return (
        <>
            <div className={'d-flex align-items-center '}>
                <div 
                    style={{backgroundImage: `url("${img}")`}}
                    className={`dident-avatar-md rounded-circle ${!enabled ? 'img-grey' :''}`}
                />
                <div className={'ml-3'}>
                    <p className={'m-0 p-0'}>{name}</p>
                    <div className={'d-flex'}>
                    {
                        onDelete &&
                        <a href={'#'} className={'m-0 p-0 mr-3'} onClick={handleDelete}>
                            <div className={'d-flex '}>
                                <i className="simple-icon-user-unfollow mr-1 cardShare-unfollow" />
                                <IntlMessages id="general.stop-sharing" />
                            </div>
                        </a>
                    }

                    {
                        onChangeVisibility &&
                        <a href={'#'} className={`m-0 p-0 ${enabled ? '' : 'text-decoration-line-through'} `} onClick={handleChangeVisibility}>
                            <div className={'d-flex '}>
                                <i className={`simple-icon-eye mr-1 cardShare-visibility`}/>
                                <IntlMessages id="general.change-visibility" />
                            </div>
                        </a>
                    }
                    </div>
                </div>
            </div>

            {!last && <Separator className={'mt-3 mb-3'}/>}

        </>
    )
};


export default injectIntl(CardShare);