import {
    GET_PATIENTS,
    GET_PATIENTS_SUCCESS,
    DELETE_PATIENTS_SUCCESS,
    DELETE_PATIENT, 
    UPDATE_PATIENT, 
    CREATE_PATIENT,
    CREATE_PATIENT_KEYROCK,
    CREATE_PATIENT_KEYROCK_SUCCESS, 
    CREATE_PATIENT_SUCCESS,
    UPLOAD_FILE_PATIENT,
    UPLOAD_FILE_PATIENT_SUCCESS,
    UPLOAD_FILE_PATIENT_ERROR,
} from "../../constants/actionTypes";

const INIT_STATE = {
    data: [],
    loading: false,
    totalItemCount: 0,
    uploadFileLoading: 0,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
    let index, aux;
    switch (action.type) {
        case GET_PATIENTS:
        case DELETE_PATIENT:
        case CREATE_PATIENT:
        case CREATE_PATIENT_KEYROCK:
        case UPDATE_PATIENT:
            return { 
                ...state, loading: true 
            };

        case DELETE_PATIENTS_SUCCESS:
            aux = state.data;
            index = state.data.indexOf(
                state.data.find(obj => obj._id === action.payload)
            );

            if(index > -1)
                aux.splice(index,1);
            return { ...state, data: [...aux], loading: false, totalItemCount: state.totalItemCount - 1};

        case GET_PATIENTS_SUCCESS:
            return { ...state, loading: false, data: action.payload.patients, totalItemCount: action.payload.total };
    
        case CREATE_PATIENT_KEYROCK_SUCCESS:
            aux = state.data;
            index = state.data.indexOf(
                state.data.find(obj => obj._id === action.payload._id)
            );

            if(index > -1)
                aux[index].password = action.payload.password;
            
            return { ...state, loading: false };

        case CREATE_PATIENT_SUCCESS:
            aux = state.data;
            index = state.data.indexOf(
                state.data.find(obj => obj._id === action.payload)
            );
            if(index > -1)
                aux.push(action.payload)
            return { ...state, data: [...aux], loading: false, totalItemCount: state.totalItemCount + 1};
        case UPLOAD_FILE_PATIENT:
            return {
                ...state, 
                uploadFileLoading: state.uploadFileLoading + 1,
            }
        case UPLOAD_FILE_PATIENT_SUCCESS:
            return {
                ...state,
                uploadFileLoading: state.uploadFileLoading - 1,
            }
        case UPLOAD_FILE_PATIENT_ERROR:
            return {
                ...state,
                uploadFileLoading: state.uploadFileLoading - 1,
            }
        default:
            return { ...state };
    }
}