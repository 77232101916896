import React, { Component } from 'react';
import { Route, withRouter, Switch,Redirect } from 'react-router-dom';
import { defaultStartPath } from './../constants/defaultValues'

import TopNav from '../containers/TopNav'
import Sidebar from '../containers/Sidebar';
import Footer from '../containers/Footer';

import TextDisplayer from './configuration/textDisplayer'
import patients from "./patients";
import configuration from "./configuration";
import stats from "./stats";
import users from "./users";
import diagnosticcenters from "./diagnosticcenters";
import withProfile from './users/profile';
import UserDetail from './users/detalle/';
import UserEdit from './users/editar/';

import { connect } from 'react-redux';

import PrivateRoute from '../components/PrivateRoute';
const ProfileComponent = withProfile(UserDetail);
const EditProfile = withProfile(UserEdit);

class MainApp extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { match, containerClassnames} = this.props;
        return (
            <div id="app-container" className={containerClassnames}>
                <TopNav history={this.props.history} />
                <Sidebar/>
                <main>
                    <div className="container-fluid">
                        <Switch>
                            <Route exact path={`${match.url}/condition`} component={TextDisplayer}  />
                            <Route exact path={`${match.url}/privacy`} component={TextDisplayer}  />
                            <Route exact path={`${match.url}/legal`} component={TextDisplayer} />
                            <Route exact path={`${match.url}/profile`} component={ProfileComponent} />
                            <Route path={`${match.url}/profile/editar`} component={EditProfile} />
                            <Route path={`${match.url}/pacientes`} component={patients} />
                            <PrivateRoute path={`${match.url}/stats`} allowedRoles={['admin']} component={stats} errorEndPoint={defaultStartPath} checkRole />
                            <PrivateRoute path={`${match.url}/diagnosticcenters`} allowedRoles={['admin']} component={diagnosticcenters} checkRole errorEndPoint={defaultStartPath}/>
                            <PrivateRoute path={`${match.url}/usuarios`} component={users} allowedRoles={['admin', 'technician']} checkRole errorEndPoint={defaultStartPath} />
                            <PrivateRoute path={`${match.url}/editor-de-texto/:type`} component={configuration} allowedRoles={['admin']} checkRole errorEndPoint={defaultStartPath} />
                            <Redirect to="/error" />
                        </Switch>
                    </div>
                    <Footer/>
                </main>
            </div>
        );
    }
}
const mapStateToProps = ({ menu }) => {
    const { containerClassnames} = menu;
    return { containerClassnames };
};

export default withRouter(connect(mapStateToProps, {})(MainApp));