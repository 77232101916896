/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Error Page
*/


module.exports = {
    /* 01.General */
    "general.copyright": "Gogo React © Todos los derechos reservados.",
    "general.back": "Back",
    "general.actions": "Actions",
    "general.no-results-found": "No results found.",
    "general.edit": "Edit",
    "general.delete": "Delete",
    "general.share": "Share",
    "general.change-visibility": "Change visibility",
    "general.stop-sharing": "Stop sharing",
    "general.sharing-expiration": 'Expire {day} at {time}',
    "general.enabled": "Enabled",
    "general.disabled": "Disabled",
    "general.yes": "Yes",
    "general.no": "No",
    "general.accept": "ACCEPT",
    "general.cancel": "CANCEL",
    "general.generate.document.confirm": "Are you sure you want to generate the PDF?",
    "general.generate.confirm.title": "Generate File",
    "general.generate.document.error": "Do you want to edit patient and add a DNI?",
    "general.generate.error.title": "Error in PDF",
    "general.remove.confirm": "Are you sure you want to delete this file?",
    "general.remove.patient.confirm": "Are you sure you want to delete this patient?",
    "general.remove.registry.confirm": "Are you sure you want to delete this registry?",
    "general.remove.confirm.title": "Confirm Deletion",



    /* 02.Inicio de sesión de usuario, cierre de sesión, registro */
    "user.login-title": "Login",
    "user.register": "Register",
    "user.forgot-password": "You forgot your password",
    "user.email": "Email",
    "user.dni": "DNI",
    "user.password": "Password",
    "user.forgot-password-question": "Have you forgotten your password?",
    "user.forgot-password.title": "Recover Password",
    "user.forgot-password.text":"If you have forgotten your password, enter your email address and press the 'Reset' button. You will receive an email with the steps to reset it. {separator} If you are not yet registered",
    "user.forgot-password.register": "request access",
    "user.forgot-password.text2": "to our platform",
    "user.fullname": "Full name",
    "user.login-button": "LOGIN",
    "user.register-button": "REGISTER",
    "user.reset-password-button": "RESET",
    "user.reset-password": "Reset Password",
    "user.register.title": "Access Request",
    "user.register.text": "Please use this form to register. If you are a member, please ",
    "user.register.text.login": "login",
    "user.register.rol": "Tipo de usuario",
    "user.register.confirmation": "Information sent successfully",
    "user.error-password": "Incorrect email address or password",
    "user.reset-password.title": "Resetting Password",
    "user.reset-password.text": "We are proceeding to reset your password. Please pay attention to the information that will appear on the right to know how to proceed next.",


    /* 03.Menú */
    "menu.app": "Home",
    "menu.teamViwer": "Download TeamViwer",
    "menu.gogo": "Gogo",
    "menu.start": "Start",
    "menu.second-menu": "Second Menu",
    "menu.second": "Second",
    "menu.ui": "UI",
    "menu.charts": "Charts",
    "menu.chat": "Chat",
    "menu.survey": "Survey",
    "menu.todo": "Notes",
    "menu.search": "Search",
    "menu.pacientes": "Patients",
    "menu.toActivate": "To Activate",
    "menu.diagnosticcenters": "Diagnostic Centers",
    "menu.usuarios": "Users",
    "menu.stats": "Stats",
    "menu.config": "Settings",
    "menu.lista": "List",
    "menu.crear": "Add",
    "menu.detalle": "Detail",
    "menu.historial": "History",
    "menu.registro": "Registry",
    "menu.doctores": "Doctors",
    "menu.clinicas": "Clinics",
    "menu.operadores": "Technicians",
    "menu.administradores": "Admins",
    "menu.editar": "Editar",
    "menu.profile": "Profile",
    "menu.logOut": "Log out",
    "menu.viewProfile": "View profile",
    "menu.editTexts": "Text editor",
    "menu.condition": "Terms of use",
    "menu.privacy": "Privacy policy",
    "menu.legal": "Legal conditions",
    "menu.solicitudes": "Requests",

    /* 04.Error  */
    "layouts.error-title": "Ops, an error has occurred!",
    "layouts.error-code": "Error code",
    "layouts.go-back-home": "BACK TO HOME",
    "layouts.delete": "Delete",
    "layouts.another-action": "Other action",
    "layouts.update": "Update",
    "layouts.activate": "Activate",
    "layouts.view": "View",
    "layouts.validate": "Validate",

    /* 05.Patients */
    "patienst.title": "Patients",
    "patients.add-new": "New patient",
    "patients.createPatient": "Add patient",
    "patients.authDoctors": "Doctores autorizados",
    "patients.edit": "Editar paciente",

    /* 06.Forms */
    "forms.patient": "Patient",
    "forms.avatar": "Photo",
    "forms.firstName": "Name",
    "forms.lastName": "Lastname",
    "forms.dni": "ID number",
    "forms.country": "Country",
    "forms.bornDate": "Birth date",
    "forms.email": "Email",
    "forms.telephone": "Phone",
    "forms.diagnosticCenters": "Diagnostic Centers",
    "forms.patients": "Pacientes",
    "forms.observations": "Observations",
    "forms.create": "Create",
    "forms.update": "Update",
    "forms.upload-files": "Upload Files",
    "forms.upload-files-error": "You can only upload files with the following extensions:",
    "forms.upload-files-error-name": "There is already one or more file uploaded with the same name",
    "forms.edit": "Edit",
    "forms.role": "Role",
    "form.createAt": 'Create at:',
    "changePassword": 'Change Password',
    "form.email.sent": 'Check your emails inbox',
    "form.reset-password.processing": 'Processing password relief',
    "form.reset-password.200": 'Check the email, we have sent you your new password',
    "form.reset-password.400": 'The password reset link has expired',
    "form.reset-password.404": 'No password reset request found',
    "form-go-back": 'Back',
    "form-go-forward": 'Next',


    /* 07.Diagnostic Centers */
    "diagnosticcenters.add-new": "New center",
    "diagnosticcenters.create": "Add center",
    "diagnosticcenters.update": "Update center",
    "forms.name": "Name",
    "forms.street": "Address",
    "forms.number": "Number",
    "forms.location": "Floor",
    "forms.zipCode": "Zip Code",
    "forms.city": "City",
    "forms.province": "Province",
    "forms.add.observation": "Add Observation",
    "forms.registry-data": "Registry data",
    "forms.non.observation": 'No observations added yet',

    /* 08.Stats */
    "stats.title": "Tests",
    "stats.tests": "Managed tests",
    "stats.by-type": "By type",
    "stats.by-user": "By user",
    "stats.in-total": "In total",

    /* 09.History*/
    "history.title": 'Patient History',
    "history.add-new": 'New History',
    "history.export": 'Export Registry',
    "history.summary": 'Summary',
    "history.radiography": 'Radiography',
    "history.other": 'Preplanning',
    "history.stlModel": 'STL Models',
    "history.dicomViewer": 'Visors',
    "history.tac": 'Neoscope',
    "history.report": 'Reports',
    "history.summary.images": "Medias",
    "history.summary.other.registries": "Other Registries for this Patient",
    "history.summary.no.multimedia": "There are no x-rays, Neoscope or pre-planning files to show yet.",
    "history.summary.observations": "Observations",
    "history.summary.observations-empty": "No comments added yet",
    "history.summary.note": "Information note:",
    "history.summary.share": "Shared with:",
    "history.summary.share-empty": "Hasn't been shared with anyone yet",
    "history.summary.shows": "Visualizations (last week)",
    "history.summary.share.doctor": "Share with",
    "history.summary.share.sharedDoctor": "Enter the email you want to share the registry with:",
    "history.summary.share.observations": "Observations:",


    /* 10.Registry */
    "registry.edit": 'Edit Registry',
    "registry.name": 'Registry name',
    "registry.button-add": 'Add Registry',
    "registry.button-edit": 'Update Registry',
    "registry.button-delete": 'Remove Registry',
    "registry.export": 'Export Registry',
    "registry.authDoctors": 'Users with access to the registry (query mode)',

    /* 11.Users */
    "forms.disabled": "Disabled",
    "forms.password": "Password",
    "forms.repeatPassword": "Repeat Password",
    "forms.cif": "CIF",


    /* 111.Doctors */
    "doctor.title": "Doctors",
    "doctor.add-new": "New doctor",
    "doctor.createDoctor": "Add doctor",
    "forms.doctor": "Doctor",
    "forms.collegiateNumber": "Collegiate number",
    "doctor.edit": "Edit doctor",

    /* 112.Clinics */
    "clinic.title": "Clinics",
    "clinic.add-new": "New clinic",
    "clinic.createClinic": "Add clinic",
    "forms.clinic": "Clinic",
    "forms.clinicName": "Clinic name",
    "clinic.edit": "Edit clinic",

    /* 113.Technicians */
    "technician.title": "Technicians",
    "technician.add-new": "New technician",
    "technician.createTechnician": "Add technician",
    "forms.technician": "Technician",
    "technician.edit": "Edit technician",

    /* 114.Admins */
    "admin.title": "Admins",
    "admin.add-new": "New admin",
    "admin.createAdmin": "Add admin",
    "forms.admin": "Admin",
    "admin.edit": "Edit admin",

    /* 12.Validations */

    "forms.validation.firstName": "Please, enter a valid first name",
    "forms.validation.lastName": "Please, enter a valid last name",
    "forms.validation.diagnosticCenters": "Please, enter a diagnostic center",
    "forms.validation.email": "Please, enter enter a valid email",
    "forms.validation.password": "Please, enter a valid password",
    "forms.validation.repeatPassword": "Please, enter your password again",
    "forms.validation.collegiateNumber": "Please, enter a valid collegiate number",
    "forms.validation.clinicName": "Please, enter a valid clinic name",

  "forms.validation.notEqualPasswords": "Error! Passwords are not the same",
  "forms.validation.dni": "Error! Dni is not correct",
  "forms.validation.cif": "Error! Cif is not correct",
  "forms.validation.avatar": "Error! Please add an image less than 100KB",
  "forms.all": "All",

  /* 13. Reports */
  "reports.platform-user": "Platform's users",
  "reports.registerPatients": "Register patients",
  "reports.testsPerformed": "Tests performed",
  "reports.accessesPlatform": "Accesses to the platform",
  "reports.this-month": "This month",
  "reports.this-week": "This week",
  "reports.last-week": "Last week",
  "reports.general": "General",
  "reports.managed-test": "Managed tests",
  "reports.filtered": "Reports filtered by users",
  "reports.platform": "General reports of the platform",
  "reports.findByLastName": "Find by lastname",
  "reports.findByClinicName": "Find by clinic name",


    /*14 Months*/
    "month.1" : "Jan",
    "month.2" : "Feb",
    "month.3" : "Mar",
    "month.4" : "Apr",
    "month.5" : "May",
    "month.6" : "Jun",
    "month.7" : "Jul",
    "month.8" : "Ago",
    "month.9" : "Sep",
    "month.10" : "Oct",
    "month.11" : "Nov",
    "month.12" : "Dic",

    /*15 Days*/
    "day.1" : "Monday",
    "day.2" : "Tuesday",
    "day.3" : "Wednesday",
    "day.4" : "Thursday",
    "day.5" : "Friday",
    "day.6" : "Saturday",
    "day.7" : "Sunday",

    /*16 NOTIFICATIONS*/
    "notify.success.userRequestCreateTitle" : "Successfully registered user",
    "notify.success.userRequestCreateMessage" : "You will have to wait for an administrator to validate it in order to access the platform.",
    "notify.error.alreadyUserRegister" : "There is already a registered user with that email",
    "notify.error.emailAlreadyUsed" : "There is already a registered user with that email",
    "notify.error.alreadyExistDNI" : "There is already a registered user with that DNI",
    "notify.error.anyError" : "Unexpected error has occurred",
    "notify.file.uploaded": "File uploaded",
    "notify.file.uploaded.failed": "File upload failed",
    "notify.file.uploaded.message": "Your file has been uploaded successfully",
    "notify.file.uploaded.failed.message": "Something wrong happened",
    "notify.upload.state": "Uploading...",
    "notify.doctor.share": "Your request has been processed.",
    "notify.doctor.share.fill": "You must enter the required information.",
    "notify.doctor.share.failed": "There is something wrong with your request please try again later",
    "notify.doctor.history.failed": "First you need to create a patient registry.",

    /* Misc */
    "date.error.lessThan365": "you must select less than 365 days",
    "date.error.noValidRange": "you must select a valid date range"
};
