import React, { useMemo } from 'react'
import DefaultAvatar from '../../assets/img/avatarImg-square.png';


const Avatar = ({ patient, className }) => {

  const avatar = useMemo(() => patient.avatar && patient.avatar !== '' ? patient.avatar : DefaultAvatar, [patient.avatar]);
 
  return (
    <div
      style={{ backgroundImage: `url("${avatar}")`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition:'center center', backgroundColor: 'transparent', margin: '2%' }}
      className={`list-thumbnail responsive border-0 ${className}`}
    />
  )
}

export default Avatar;
