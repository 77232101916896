import React from 'react';
import {
    Row,
    Card,
    CardTitle,
    CardText
} from 'reactstrap';
import { Colxx } from "../../../../components/CustomBootstrap";
import RegistryVisualizations from "../../../../components/Charts/RegitryVisualizations";
import CardShare from "../../../../components/CardShare";
import CardComment from "../../../../components/CardComments";
import {emptyString} from "../../../../util/Utils";
import { filterUsers, updateRegistry,deleteRegistry } from '../../../../redux/actions'
import IntlMessages from '../../../../util/IntlMessages'
import { connect } from 'react-redux'
import AvatarImg from '../../../../assets/img/avatarImg.png'
import ClinicImg from '../../../../assets/img/clinicImg.jpg'
import { getThumbnails } from "../../utils";
import Carousel from '../../../../components/Carousel';
import { isCorrectRole } from '../../../../components/PrivateRoute';
import BottomRegistryButton from '../../../../components/BottomRegistryButton';
import BottomAddRegistryButton from '../../../../components/BottomAddRegistryButton';
import WithModal from '../../../../components/Modal';
import AuthDoctorsCombo from './authDoctorsCombo';
import ObservationsModalBody from './ObservationsModalBody';
import ShareDoctorModalBody from './ShareDoctorModalBody';
import { injectIntl} from 'react-intl';
import Thumbnail from "../../../../components/BottomThumbnail";
import {BodyModal} from "../../../../components/RemoveRegistry";

const AuthDoctorsModal = WithModal((props) =>
    <AuthDoctorsCombo {...props} />);

const ObservationsModal = WithModal((props) =>
    <ObservationsModalBody {...props} />);

const ShareDoctorModal = WithModal((props) =>
    <ShareDoctorModalBody {...props} />);
class Summary extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            authDoctors: this.props.registry.authDoctors || [],
            deleteModal: false,
            observationModal: false,
            shareDoctorModal: false,
            registryIdForDelete: null,
            modal: false,
            registry: {}
        }
    }

    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    };

    observationToggle = () => {
        this.setState(prevState => ({
            observationModal: !prevState.observationModal,
        }));
    };

    shareDoctorToggle = () => {
        this.setState(prevState => ({
            shareDoctorModal: !prevState.shareDoctorModal,
        }));
    }

    deleteToggle = (registryIdForDelete) => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
            registryIdForDelete: registryIdForDelete ? registryIdForDelete : null
        }));
    };

    handleDeleteRegistry = () => {
        if (this.state.registryIdForDelete)
            this.props.deleteRegistry(this.state.registryIdForDelete, this.deleteToggle);
    };


    componentWillUpdate(nextProps, nextState, nextContext) {
        if (this.props.registry.authDoctors !== nextProps.registry.authDoctors)
            this.setState({
                authDoctors: nextProps.registry.authDoctors
            })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.registry !== this.props.registry)
            this.setState({registry : this.props.registry});

        if(prevProps.registryData !== this.props.registryData) {
            console.log(this.props.registryData);
            this.setState({registry: this.props.registry})
        }

    }

    /** Delete Doctor */
    handleStopSharing = (idDoctor) => {
        const prevDoctors = this.props.registry.authDoctors.map(doc => ({ id: doc.id, enabled: doc.enabled }));
        const index = prevDoctors.indexOf(prevDoctors.find(doc => doc.id === idDoctor));
        prevDoctors.splice(index, 1);
        const newRegistry = { _id: this.props.registry._id, authDoctors: prevDoctors };
        this.props.updateRegistry(newRegistry,false);
        this.deleteDoctorFromState(idDoctor);
    };

    deleteDoctorFromState = (idDoctor) => {
        const prevDoctors = this.state.authDoctors;
        const index = prevDoctors.indexOf(prevDoctors.find(doc => doc.id === idDoctor));
        prevDoctors.splice(index, 1);
        this.setState({
            authDoctors: prevDoctors
        });
    };

    /** Stop Sharing Doctor */
    onChangeVisibility = (idDoctor) =>{
        const prevDoctors = this.props.registry.authDoctors.map(doc=>({ id:doc.id, enabled: idDoctor === doc.id ? !doc.enabled : doc.enabled }));
        const newRegistry = {_id: this.props.registry._id, authDoctors: prevDoctors};
        this.props.updateRegistry(newRegistry, false);
        this.changeVisibilityDoctorFromState(idDoctor);
    };

    changeVisibilityDoctorFromState = (idDoctor) => {
        const prevDoctors = this.state.authDoctors;
        for (let i = 0; i < prevDoctors.length; i++) {
            if (prevDoctors[i].id === idDoctor) {
                prevDoctors[i].enabled = !prevDoctors[i].enabled;
                break;
            }
        }
        this.setState({ authDoctors: prevDoctors });
    };


    calcImg = (entity) => {
        if (entity.role && entity.role === 'doctor')
            return entity.avatar || AvatarImg;
        else if (entity.role && entity.role === 'clinic')
            return entity.avatar || ClinicImg;
        return AvatarImg
    };

    parseToOptionsDoctors = (users = this.props.usersOptions) =>{
        return users.map(user => {
            return {
                label: user.name ? user.name : user.clinicName ? user.clinicName : `${user.firstName} ${user.lastName}`,
                value: user.id ? user.id: user._id,
                key: user.id ? user.id: user._id,
                enabled: user.enabled,
            }
        })
    };

    loadOptionsDoctors = (inputValue, callback) => {
        this.valueInFilterAuthDoctors = inputValue;
        setTimeout(()=>{
            if(this.valueInFilterAuthDoctors === inputValue){
                if (inputValue.length > 2)
                    this.props.filterUsers({firstName: inputValue, role: 'clinic,doctor'});
                else
                    this.props.filterUsers({role: 'clinic,doctor'});

                setTimeout(() => {
                    callback(this.parseToOptionsDoctors());
                }, 700);
            }
        },500);
    };


    render() {

        const { authDoctors, registry, modal, deleteModal, observationModal, shareDoctorModal } = this.state;
        const { registries, history, match } = this.props;
        const registryInUrl = match.params.id;
        const { multimedia } = registry;
        const items = getThumbnails(multimedia);
        return (
            <>
                <Row className={`d-block`}>
                    <Colxx xxs={12} lg={3} md={6} key={'Slide'} className="float-left">
                        <Carousel className={'mb-4'} items={items} registry={registry}/>
                        {isCorrectRole(['admin', 'technician']) && (
                        <Card body className={'mb-4'}>
                            <CardTitle className={'mb-3 pb-0'}>
                                <IntlMessages id="history.summary.shows" />
                            </CardTitle>
                            <RegistryVisualizations registry={registry}/>
                        </Card>)}

                        {isCorrectRole(['doctor', 'clinic'])
                        && <Card body className="my-3">
                                <div className={'d-flex justify-content-between align-items-center'}>
                                    <CardText className={'m-0 p-0'}>
                                        <IntlMessages id="history.summary.share.doctor" />
                                    </CardText>
                                    <i onClick={this.shareDoctorToggle} className="iconsmind-Add summary-info" style={{cursor: 'pointer'}}/>
                                </div>
                            </Card>
                        }
                        <a target={'_blank'} href={"https://www.jweb.es/didentconnect/docs/nota-informativa.pdf"} className={'mt-4'}>
                            <Card body>
                                <div className={'d-flex justify-content-between align-items-center'}>
                                    <CardText className={'m-0 p-0'}>
                                        <IntlMessages id="history.summary.note" />
                                    </CardText>
                                    <i className="iconsmind-Information summary-info" />
                                </div>
                            </Card>
                        </a>
                    </Colxx>
                    {isCorrectRole(['admin', 'technician']) && <Colxx xxs={12} lg={9} md={6} key={'Share-information'} className="float-left mb-4">
                        <Card body>
                            <CardTitle className={'mb-3 pb-0 mt-2 d-flex justify-content-between'}>
                                <IntlMessages id="history.summary.share" />
                                <i onClick={this.toggle} className="simple-icon-plus addAuthDoctor"/>
                            </CardTitle>
                            {
                                authDoctors.length > 0 ?
                                    authDoctors.map((entity, index) => (
                                        <CardShare
                                            key={index}
                                            last={index === authDoctors.length - 1}
                                            id={entity.id}
                                            enabled={entity.enabled}
                                            img={this.calcImg(entity)}
                                            name={entity.name || emptyString()}
                                            onDelete={this.handleStopSharing}
                                            onChangeVisibility={this.onChangeVisibility}
                                        />))
                                    :
                                    <IntlMessages id="history.summary.share-empty" />
                            }
                        </Card>
                    </Colxx>}
                    <Colxx xxs="12" md={6} lg={{ size: 9, order: 3 }} key={'Views-Observations'} className="float-right">
                        <Card body className={'mb-1'}>
                            <CardTitle className={'mb-3 pb-0 mt-2 d-flex justify-content-between'}>
                                <IntlMessages id="history.summary.observations" />
                                <i onClick={this.observationToggle} className="simple-icon-plus addAuthDoctor"/>
                            </CardTitle>
                            {
                                (this.props.registry && this.props.registry.observations) &&
                                this.props.registry.observations.length > 0 ?
                                    this.props.registry.observations.map((comment, index) => (
                                        <CardComment
                                            last={index === this.props.registry.observations.length - 1}
                                            what={comment.what}
                                            when={comment.when}
                                            who={comment.who}
                                        />))
                                    :
                                    <IntlMessages id="history.summary.observations-empty" />
                            }
                        </Card>
                    </Colxx>
                    <Colxx xxs="12" md={6} lg={{ size: 9, order: 3 }} key={'Views-Thumbnails'} className="float-right">
                        <Card body className={'mb-1 mt-4'}>
                            <CardTitle className={'mb-3 pb-0 mt-2'}>
                                <IntlMessages id="history.summary.images" />:
                            </CardTitle>
                            <div className={'d-flex flex-wrap'} style={{
                                flexDirection: "row",
                                justifyContent: "center"
                            }}>
                                {
                                    multimedia &&
                                    multimedia.filter(media=>["radiography","tac","other"].includes(media.mediaType)).length > 0 ?
                                        multimedia.map(media => {
                                                if(["radiography","tac","other"].includes(media.mediaType))
                                                    return (<Thumbnail
                                                        type={media.mediaType}
                                                        idHistory={this.props.registry._id}
                                                        media={media}
                                                        key={`Summary-Thumbnail-${media._id}-${media.mediaType} `}
                                                    />)
                                            }
                                        ) :
                                        <div style={{
                                            display: 'block',
                                            width: '100%'
                                        }}>
                                            <IntlMessages id={"history.summary.no.multimedia"}/>
                                        </div>
                                }
                            </div>

                        </Card>
                        {
                            <Card body className={'mb-5 mt-4'}>
                                <CardTitle className={'mb-3 pb-0 mt-2'}>
                                    <IntlMessages id="history.summary.other.registries" />:
                                </CardTitle>
                                <div className={'d-flex flex-row flex-wrap'}>
                                    {isCorrectRole(['admin', 'technician']) && <BottomAddRegistryButton history={history} patientID={match.params.patient}/>}
                                    {
                                        registries.map(registry =>
                                            <BottomRegistryButton
                                                key={`Button-Access-${registry._id}`}
                                                modalToggle={ this.deleteToggle }
                                                registry={ registry }
                                                active={ registry._id === registryInUrl }
                                                history={ history }
                                            />
                                        )
                                    }
                                </div>
                            </Card>
                        }

                    </Colxx>
                </Row>

                <AuthDoctorsModal
                    isOpen={modal}
                    toggle={this.toggle}
                    title={<IntlMessages id="general.share" />}
                    okOption={() => {}}
                    cancelOption={() => {}}
                    value={this.parseToOptionsDoctors(this.state.authDoctors)}
                    loadOptions={this.loadOptionsDoctors}
                    messages={this.props.intl.messages}
                    registry={this.props.registry}
                />

                <ObservationsModal
                    isOpen={observationModal}
                    toggle={this.observationToggle}
                    title={<IntlMessages id="history.summary.observations" />}
                    okOption={() => {}}
                    cancelOption={() => {}}
                    // value={this.parseToOptionsDoctors(this.state.authDoctors)}
                    // loadOptions={this.loadOptionsDoctors}
                    messages={this.props.intl.messages}
                    registry={this.props.registry}
                />

                <ShareDoctorModal
                    isOpen={shareDoctorModal}
                    toggle={this.shareDoctorToggle}
                    title={<IntlMessages id="history.summary.share.doctor" />}
                    okOption={() => {}}
                    cancelOption={() => {}}
                    messages={this.props.intl.messages}
                    registry={this.props.registry}
                />

                <BodyModal
                    okOption={() => {}}
                    cancelOption={() => {}}
                    onClick={this.handleDeleteRegistry}
                    isOpen={deleteModal}
                    toggle={this.deleteToggle}
                    title={<IntlMessages id="general.remove.confirm.title" />}
                />
            </>
        )
    }
}

const mapStateToProps = ({users, registries}) => ({
    usersOptions: users.data,
    registriesData: registries.data,
});

export default injectIntl(connect(mapStateToProps, { filterUsers, updateRegistry, deleteRegistry })(Summary))