import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';

export {
    injectIntl
}

export default injectIntl(FormattedMessage, {
  withRef: false
});
