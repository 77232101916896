import React from 'react';
import { LineShadow } from "..";
import api from "../../../api/users";
import registryApi from "../../../api/registry";
import { ThemeColors } from '../../../util/ThemeColors';


const daysOfTheWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

class DefaultLineChart extends React.Component {
    constructor(props) {
        super(props);

        const legend = {
            display: false,
        };

        const data = {
            labels: [],
            datasets: [
                {
                    data: [],
                    borderColor: ThemeColors().themeColor1,
                    pointBackgroundColor: ThemeColors().foregroundColor,
                    pointBorderColor: ThemeColors().themeColor1,
                    pointHoverBackgroundColor: ThemeColors().themeColor1,
                    pointHoverBorderColor: ThemeColors().foregroundColor,
                    pointRadius: 4,
                    pointBorderWidth: 2,
                    pointHoverRadius: 5,
                    fill: true,
                    borderWidth: 2,
                    backgroundColor: ThemeColors().themeColor1_10
                }
            ]
        }

        const options = {
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
                backgroundColor: ThemeColors().foregroundColor,
                titleFontColor: ThemeColors().primaryColor,
                borderColor: ThemeColors().separatorColor,
                borderWidth: 0.5,
                bodyFontColor: ThemeColors().primaryColor,
                bodySpacing: 10,
                xPadding: 15,
                yPadding: 15,
                cornerRadius: 0.15
            },
            scales: {
                yAxes: [
                    {
                        gridLines: {
                            display: true,
                            lineWidth: 1,
                            color: "rgba(0,0,0,0.1)",
                            drawBorder: false
                        },
                        ticks: {
                            beginAtZero: true,
                            padding: 20
                        }
                    }
                ],
                xAxes: [
                    {
                        gridLines: {
                            display: false
                        }
                    }
                ]
            },
            plugins: {
                datalabels: {
                    display: false,
                },
            }
        }

        this.state = {
            data,
            options,
            legend,
            period: this.props.period,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.period !== this.props.period || prevProps.user !== this.props.user) {
            this.calcPeriod();
            this.getData();
        }
    }

    calcPeriod() {
        let monday, today, sunday;

        let date;
        const { data, options } = this.state;

        switch (this.props.period) {
            case 'this-month':
                date = new Date();
                let firstDayOfTheMonth = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0);
                let firstDay = firstDayOfTheMonth.toISOString().slice(0, 10);
                today = date.toISOString().slice(0, 10);
                data.labels = this.parseToNumber(this.getLabelsOfDays(new Date(firstDayOfTheMonth), date));

                this.state = {
                    startDate: firstDay,
                    startDateD: new Date(firstDayOfTheMonth),
                    endDate: today,
                    endDateD: date,
                    data,
                    period: this.props.period,
                    options,
                }

                break;

            case 'this-week':
                date = new Date();
                monday = this.getMondayOfCurrentWeek().toISOString().slice(0, 10);
                today = date.toISOString().slice(0, 10);
                data.labels = this.parseToDayOfWeek(this.getLabelsOfDays(new Date(monday), date))

                this.state = {
                    startDate: monday,
                    endDate: today,
                    startDateD: new Date(monday),
                    endDateD: date,
                    data,
                    period: this.props.period,
                    options,
                }
                break;

            case 'last-week':
                let thisMonday = this.getMondayOfCurrentWeek();
                let firstDayLastWeek = this.getLastWeek(thisMonday);
                let firstDayLastWeekPlus6 = new Date(firstDayLastWeek.getTime() + (1000 * 60 * 60 * 24 * 6));

                monday = firstDayLastWeek.toISOString().slice(0, 10);
                sunday = firstDayLastWeekPlus6.toISOString().slice(0, 10);

                data.labels = this.parseToDayOfWeek(this.getLabelsOfDays(new Date(monday), new Date(sunday)));

                this.state = {
                    startDate: monday,
                    endDate: sunday,
                    startDateD: new Date(monday),
                    endDateD: new Date(sunday),
                    data,
                    period: this.props.period,
                    options,
                }
                break;
            default:
                break;

        }
    }

    getData = () => {
        const { startDate, endDate, startDateD, endDateD } = this.state;
        const { user } = this.props;
        new Promise(async (resolve) => {
            let incomingData
            //request to the server
            if (this.props.type === 'accesses') {
                incomingData = await api.getUsersAccessesPlatform({ startDate, endDate, user: user._id || '' });
            } else {
                incomingData = await registryApi.getTestsManaged({ startDate, endDate, user: user._id || '' });
            }
            if (incomingData.data) {
                const newData = {...this.state.data};
                const newOptions = this.state.options;
                const { data } = incomingData;
                data.sort(function (a, b) {
                    return new Date(a.date) - new Date(b.date);
                });
                const labels = this.parseToDate(this.getLabelsOfDays(startDateD, endDateD));
                newData.datasets[0].data = labels.map(label => {
                    const foundData = incomingData.data.find((data) => data.date === label);
                    return foundData ? foundData.total : 0
                });

                this.setState({
                    data: newData,
                    options: newOptions,
                });
            }
            resolve();
        })
    }

    countResults = (array, classifier) => {
        return array.reduce(function (counter, item) {
            var p = (classifier)(item);
            counter[p] = counter.hasOwnProperty(p) ? counter[p] + 1 : 1;
            return counter;
        }, {})

    }

    getLastWeek = (today) => {
        var lastWeek = new Date(today.getTime() - (1000 * 60 * 60 * 24 * 7));
        return lastWeek;
    }

    getLabelsOfDays = (startDate, endDate) => {
        const interval = 1000 * 60 * 60 * 24;
        const duration = endDate - startDate;
        const steps = duration / interval;
        return Array.from({ length: steps + 1 }, (v, i) => new Date(startDate.valueOf() + (interval * i)));
    }

    parseToNumber = (array) => {
        return array.map(item => item.getDate());
    }

    parseToDayOfWeek = (array) => {
        return array.map(item => daysOfTheWeek[item.getDay()]);
    }

    isZeroZero = (date) => {
        return date.getMinutes() === 0 && date.getHours() === 0 && date.getSeconds() === 0;
    }

    parseToDate = (array) => {
        return array.map(item => { 
            let finalItem = item;
            if(this.isZeroZero(item)){
                finalItem = new Date(item.getTime() + (1000 * 60 * 60 * 12));
            }
            return finalItem.toISOString().slice(0, 10)});       
    }

    daysInMonth = (month, year) => new Date(year, month, 0).getDate();

    getMondayOfCurrentWeek = () => {
        let now = new Date();
        let day = now.getDay();
        let diff = now.getDate() - day + (day === 0 ? -6 : 1);
        return new Date(now.setDate(diff));
    }

    render() {
        return <LineShadow data={this.state.data} options={this.state.options} legend={this.state.legend} />
    }
}

export default DefaultLineChart;
