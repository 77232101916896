import Utils from './Utils';

const schema = {
    _id: {
        defaultValue: null //UUID
    },
    DNI: {
        defaultValue: '' //string
    },
    authDoctors: {
        defaultValue: [] //array
    },
    diagnosticCenters: {
        defaultValue: [] //array
    },
    observations: {
        defaultValue: [] //array
    },
    avatar: {
        defaultValue: '' //string
    },
    bornDate: {
        defaultValue: '' //date
    },
    createAt: {
        defaultValue: '' //date
    },
    country: {
        defaultValue: '' //string
    },
    email: {
        defaultValue: '' //string
    },
    firstName: {
        defaultValue: '' //string
    },
    lastName: {
        defaultValue: '' //string
    },
    telephone: {
        defaultValue: '' //string
    },

};

class Patient extends Utils{

    constructor(obj){
        super(schema, obj);
    }

}

export default Patient;