import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PrivateRoute from '../../components/PrivateRoute';

import list from './lista';
import imprimir from './imprimir';
import create from './crear';
import edit from './editar';
import detail from './detalle';
import createHistory from './../history/create';
import editHistory from './../history/update';

export default ({ match }) => (
    <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/lista`} />
        <Route path={`${match.url}/lista`} component={list} />
        <Route path={`${match.url}/imprimir/:password`} component={imprimir} />
        <PrivateRoute path={`${match.url}/crear`} component={create} allowedRoles={['admin', 'technician']} checkRole />
        <PrivateRoute path={`${match.url}/historial/:patient/crear`} component={createHistory} allowedRoles={['admin', 'technician']} checkRole />
        <PrivateRoute path={`${match.url}/historial/:patient/editar/:id`} component={editHistory} allowedRoles={['admin', 'technician']} checkRole />
        <Route path={`${match.url}/historial/:id`} component={detail} />
        <PrivateRoute path={`${match.url}/editar/:id`} component={edit} allowedRoles={['admin', 'technician']} checkRole />
        <Redirect to="/error" />
    </Switch>
);