import React, { Component } from "react";
import "./style.scss";
import UploadCloud from './assets/uploadCloud.svg';
import Thumbnail from './components/Thumbnail';
import Loading from './components/Loading';
import LoaderBar from './../LoaderBar';

const Cloud = UploadCloud;

const checkIsInclude = (array,string) => {
    let found = false;
    array.forEach(row => {
        row = row.replace('.','');
        if(string && string.toLowerCase() === row.toLowerCase())
            found = true;
    });

    return found
};

class Dropzone extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            hightlight: false,
            fileList: [],
        };
        this.fileInputRef = React.createRef();

        this.openFileDialog = this.openFileDialog.bind(this);
        this.onFilesAdded = this.onFilesAdded.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.handlePropsOnFilesAdded = this.handlePropsOnFilesAdded.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    componentDidUpdate(prevProps, prevState, prevContext){
        if(prevState.fileList !== this.state.fileList)
            this.props.onFilesChanged(this.state.fileList);

        if(this.props.fileList !== prevProps.fileList && this.props.fileList !== this.state.fileList)
            this.setState({
                fileList: this.props.fileList
            });

        if(prevProps.error !== this.props.error && this.props.error !== this.state.error)
            this.setState({
                error: this.props.error
            })


    }

    openFileDialog() {
        if (this.props.disabled || this.props.loading) return;
        this.fileInputRef.current.click();
    }

    onFilesAdded(evt) {
        if (this.props.disabled || this.props.loading) return;
        const files = evt.target.files;
        this.handlePropsOnFilesAdded(files);
    }

    filterForbiddenFiles(files){
        const cleanFiles = [];
        let error = null;

        if(this.state.error)
            this.setState({error: null});

        files.forEach(f=>{
            let type = f.name.split('.');
            type = type[type.length-1];
            if(checkIsInclude(this.props.accept, type))
                cleanFiles.push(f);
            else
                error = 'extension';
        });

        if(this.state.error !== error)
            this.setState({error: error});

        return cleanFiles;
    }

    handlePropsOnFilesAdded(files){
        if (this.props.onFilesAdded) {
            let array = this.fileListToArray(files);
            array = this.filterForbiddenFiles(array);
            this.setState({
                fileList: [...this.state.fileList, ...array]
            }, ()=> this.props.onFilesAdded(this.state.fileList))
        }
    }

    onDragOver(evt) {
        evt.preventDefault && evt.preventDefault();
        if (this.props.disabled || this.props.loading) return;
        this.setState({ hightlight: true });
    }

    onDragLeave() {
        this.setState({ hightlight: false });
    }

    onDrop(event) {
        event.preventDefault && event.preventDefault();
        if (this.props.disabled || this.props.loading) return;
        const files = event.dataTransfer.files;
        this.handlePropsOnFilesAdded(files);
        this.setState({ hightlight: false });
    }

    fileListToArray(list) {
        const array = [];
        const namesFilesIncludes = this.state.fileList.map(file => file.name);
        for (let i = 0; i < list.length; i++) {
            if(!namesFilesIncludes.includes(list.item(i).name))
                array.push(list.item(i));
        }
        return array;
    }

    handleDeleteFile = (e,file) =>{
        if (this.props.disabled || this.props.loading) return;
        return this.onDelete(e,file)
    };

    onDelete(e,file){
        e.preventDefault();
        e.stopPropagation();
        const fileFinded = this.state.fileList.find(f => f === file);

        if(fileFinded) {
            const index = this.state.fileList.indexOf(fileFinded);
            if(index > -1) {
                const newFileList = this.state.fileList;
                newFileList.splice(index, 1);
                this.setState({
                    fileList: [...newFileList]
                })
            }
        }
    }

    calcErrorMessage = () =>{
        if(this.state.error === 'extension')
            return (
                <>
                    <span>{this.props.errorFileExtension}</span>
                    <span>{` ${this.props.accept}`}</span>
                </>
            );
        return <span>{this.props.errorFileName}</span>;
    };

    render() {
        const { fileList, error } = this.state;
        const { title, accept, iconDelete, loading, percentUploaded } = this.props;

        return (
            <>
                <div className={'drop-wrapped'}>
                    {/*{ loading && <Loading className={"dropzone-loading-component loading"}/> }*/}
                    { loading && <LoaderBar charge={percentUploaded}/> }
                    <div
                        className={`Dropzone ${this.state.hightlight ? "Highlight" : ""} ${fileList.length === 0 ? 'withOutFiles' : 'withFiles'} ${loading ? 'drop-loading' : ''}`}
                        onDragOver={this.onDragOver}
                        onDragLeave={this.onDragLeave}
                        onDrop={this.onDrop}
                        onClick={this.openFileDialog}
                        style={{ cursor: this.props.disabled ? "default" : "pointer" }}
                    >
                        <input
                            ref={this.fileInputRef}
                            className={`FileInput`}
                            type="file"
                            accept={accept}
                            multiple
                            onChange={this.onFilesAdded}
                        />
                        {
                            fileList.length <= 0 ?
                                (
                                    <>
                                        <img src={Cloud}/>
                                        <span>{title}</span>
                                    </>
                                ) :
                                (
                                    fileList.map((file) => {
                                        return <Thumbnail file={file} icon={iconDelete} onDelete={this.handleDeleteFile} key={file.name}/>
                                    })
                                )
                        }

                    </div>
                    { error && <div className={'show-info-error-message'}>{this.calcErrorMessage()}</div>}
                </div>
            </>
        );
    }
}

Dropzone.defaultProps={
    title:'Upload Files',
    error: false,
    errorFileExtension:'You can only upload files with the following extensions:',
    errorFileName:'You can only upload one file with the same name:',
    accept: '',
    loading: true,
    iconDelete: undefined,
    onFilesAdded: () => {},
    onFilesChanged: () => {}
};

export default Dropzone;